import React, {useEffect, useContext, useState} from 'react';
import {Link} from 'react-router-dom';
import Axios from 'axios';
import {LoadingOutlined} from '@ant-design/icons';
import {Button, Col, Divider, notification, Row, Space, Spin, Typography} from 'antd';
import {Formik} from "formik";
import {Form, FormItem, Input, ResetButton, SubmitButton, Select, Switch} from "formik-antd";
import axios from "axios";
import * as Yup from "yup";
import * as _ from 'lodash';

import DEGREES from '../../lib/ASDdegrees.json';
import {UserContext} from '../../App';
import {SERVER_URL} from '../../config';
import InstitutionsSearch from "../../components/controls/InstitutionsSearch";
import CKEditor from '../../components/base/CKEditor';

const STATES = require('../../lib/states.json');

const TAGS = [
    {label: 'TOP_RANKED', value: 'https://prnt.sc/RyAeUAAgLkhL'},
];

const {Text} = Typography;

const Edit = (props) => {
    const currentuser = useContext(UserContext);
    const [touData, setTouData] = useState(undefined);
    const [isWorking, setWorking] = useState(false);
    const [specializations, setSpecializations] = useState([]);
    const [marketContexts, setMarketContexts] = useState([]);
    const [providers, setProviders] = useState([]);

    const {id, provider} = props.match.params;
    const urlProvider = provider === 'ASD' ? 'asd' : 'tou';

    const getDataForCodes = async (type) => {
        const specData = await Axios.get(`${SERVER_URL}/affiliate-institution-code/${type}/all`, {
            withCredentials: false,
            headers: {Authorization: `Bearer ${currentuser.data.token}`},
        });
        return specData.data;
    }

    const fetchSpecializations = async () => {
        try {
            setWorking(true);
            const specData = await getDataForCodes('SPECIALIZATION');
            if (specData) {
                setSpecializations(specData);
            }
            setWorking(false);
        } catch (error) {
            setWorking(false);
            console.log('Error: ', error);
        }
    };
    const fetchMarketContext = async () => {
        try {
            setWorking(true);
            const specData = await getDataForCodes('MARKET_CONTEXT');
            if (specData) {
                setMarketContexts(specData);
            }
            setWorking(false);
        } catch (error) {
            setWorking(false);
            console.log('Error: ', error);
        }
    };

    const fetchProviders = async () => {
        try {
            setWorking(true);
            const {data: providers} = await axios.get(`${SERVER_URL}/affiliate-institution-provider`, {
                withCredentials: false,
                headers: {Authorization: `Bearer ${currentuser.data.token}`},
            });
            if (providers) {
                setProviders(providers);
            }
            setWorking(false);
        } catch (error) {
            setWorking(false);
            console.log('Error: ', error);
        }
    }

    useEffect(() => {

        fetchMarketContext().catch(console.error);
        fetchSpecializations().catch(console.error);
        fetchProviders().catch(console.error);

    }, [currentuser.data.token]);

    useEffect(() => {
        const fetchTOUByID = async () => {
            try {
                const {data} = await Axios.get(`${SERVER_URL}/${urlProvider}/${id}`, {
                    withCredentials: false,
                    headers: {Authorization: `Bearer ${currentuser.data.token}`},
                });
                const unitId = _.get(data, 'unitId', undefined);
                data.unitId = unitId;
                console.log('data: ', data);
                setTouData(data);
            } catch (error) {
                console.log(error);
            }
        }
        if (id !== 'new') {
            fetchTOUByID();
        } else {
            setTouData({
                name: undefined,
                urlSegment: undefined,
                marketContext: undefined,
                programs: [],
                unitId: undefined,
                programName: undefined,
                degree: undefined,
                specialization: undefined,
                affLink: undefined,
                provider: undefined,
            });
        }
    }, [currentuser.data.token, id]);


    async function handleSubmit(fields, {setSubmitting}) {
        fields.marketContext = fields.marketContext || null;
        try {
            if (id === 'new') {
                await axios.post(`${SERVER_URL}/${urlProvider}`, fields, {
                    withCredentials: false,
                    headers: {Authorization: `Bearer ${currentuser.data.token}`},
                });
            } else {
                await axios.put(`${SERVER_URL}/${urlProvider}/${id}`, fields, {
                    withCredentials: false,
                    headers: {Authorization: `Bearer ${currentuser.data.token}`},
                });
            }
            notification.success({
                message: 'Success',
                description: `${fields.provider} updated successfully`,
                duration: 5,
            });
        } catch (e) {
            notification.error({
                message: 'Error',
                description: e.message,
                duration: 0,
            });
        }
        setSubmitting(false);
    }

    const touSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        // marketContext: Yup.string().required('market context is required').nullable(),
        degree: Yup.string().required('Degree is required').nullable(),
        programName: Yup.string().required('Name is required'),
        programId: Yup.string().nullable(),
        specialization: Yup.string().required('Specialization is required').nullable(),
        affLink: Yup.string().url('Must be a valid URL').required('Affiliate Link is required').nullable(),
        unitId: Yup.number().required('Unit ID is required'),
        provider: Yup.string().required('Provider is required'),
    });


    if (!touData) {
        return (
            <div style={{textAlign: 'center'}}>
                <LoadingOutlined spin style={{fontSize: '3rem', marginTop: '5rem'}}/>
            </div>
        )
    }
    return (
        <div style={{padding: '8px'}}>
            <Spin spinning={isWorking} tip='Working...'>
                <div className='actions-block'>
                    <Link to='/admin/affiliate-links'>
                        <Button type='primary'>Back</Button>
                    </Link>
                </div>

                <div>
                    <Formik initialValues={touData} enableReinitialize={true} validationSchema={touSchema}
                            onSubmit={handleSubmit}>
                        {({values, errors, setFieldValue}) => (

                            <Form layout="vertical">
                                <Row gutter={16}>
                                    <Col span={6}>
                                        <FormItem
                                            name="unitId"
                                            label="Institution name / Unit ID"
                                            required={true}
                                        >
                                            <InstitutionsSearch
                                                value={values.unitId}
                                                placeholder="Search for institution"
                                                onChange={(value, institution) => {
                                                    setFieldValue('unitId', value);
                                                    setFieldValue('name', institution.name);
                                                }}/>
                                        </FormItem>
                                    </Col>
                                    <Col span={6}>
                                        <FormItem
                                            name="programId"
                                            label="Program ID"
                                        >
                                            <Input name="programId"/>
                                        </FormItem>
                                    </Col>
                                    <Col span={12}>
                                        <FormItem
                                            name="name"
                                            label="Institution Name"
                                            required={true}
                                        >
                                            <Input name="name"/>
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={6}>
                                        <FormItem
                                            name="provider"
                                            label="Provider"
                                            required={true}
                                        >
                                            <Select name="provider" disabled={provider === 'ASD'}>
                                                {providers.map((provider, index) => (
                                                    <Select.Option key={index} disabled={!provider.active}
                                                                   value={provider.code}>{provider.name}</Select.Option>
                                                ))}
                                            </Select>
                                        </FormItem>
                                    </Col>
                                    <Col span={8}>
                                        <FormItem
                                            name="programName"
                                            label="Program Name"
                                            required={true}
                                        >
                                            <Input name="programName"/>
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={6}>
                                        <FormItem
                                            name="marketContext"
                                            label="Market Context"
                                        >
                                            <Select name="marketContext" showSearch allowClear filterOption={
                                                (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                                    onChange={(value) => {
                                                        console.log(value);
                                                        setFieldValue('marketContext', value);
                                                    }}
                                            >
                                                {marketContexts.map((item, index) => <Select.Option key={index}
                                                                                                    value={item.value}>{item.label}</Select.Option>)}
                                            </Select>
                                        </FormItem>
                                    </Col>
                                    <Col span={6}>
                                        <FormItem
                                            name="degree"
                                            label="Degree"
                                            required={true}
                                        >
                                            <Select name="degree">
                                                {DEGREES.map((degree, index) => (
                                                    <Select.Option key={index}
                                                                   value={degree.title}>{degree.title}</Select.Option>
                                                ))}
                                            </Select>
                                        </FormItem>
                                    </Col>
                                    <Col span={6}>
                                        <FormItem
                                            name="specialization"
                                            label="Specialization"
                                            required={true}
                                        >
                                            <Select name="specialization" showSearch allowClear filterOption={
                                                (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }>
                                                {specializations.map((specialization, index) => <Select.Option
                                                    key={index}
                                                    value={specialization.value}>{specialization.label}</Select.Option>)}
                                            </Select>
                                        </FormItem>
                                    </Col>
                                    <Col span={6}>
                                        <FormItem
                                            name="specializationSecondary"
                                            label="Specialization Secondary"
                                        >
                                            <Select name="specializationSecondary" showSearch allowClear filterOption={
                                                (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }>
                                                {specializations.map((specialization, index) => <Select.Option
                                                    key={index}
                                                    value={specialization.value}>{specialization.label}</Select.Option>)}
                                            </Select>
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={24}>
                                        <FormItem
                                            name="affLink"
                                            label="Affiliate Link"
                                            required={true}
                                        >
                                            <Input name="affLink"/>
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Divider orientation="left">Extended data</Divider>
                                <Row gutter={16}>
                                    <Col span={3}>
                                        <FormItem
                                            name="online"
                                            label="Online"
                                        >
                                            <Switch name="online"/>
                                        </FormItem>
                                    </Col>
                                    <Col span={3}>
                                        <FormItem name="isSpotlight" label="Is Spotlight">
                                            <Switch name="isSpotlight"/>
                                        </FormItem>
                                    </Col>
                                    <Col span={10}>
                                        <FormItem
                                            name="restrictedStates"
                                            label="Restricted States"
                                        > <Row>
                                            <Col span={20}>
                                                <Select name="restrictedStates" mode="multiple">
                                                    {STATES.map((state, index) => (
                                                        <Select.Option key={index}
                                                                       value={state.abbreviation}>{state.name}</Select.Option>
                                                    ))}
                                                </Select>
                                            </Col>
                                            <Col span={12}>
                                                <Button onClick={
                                                    () => {
                                                        const fillStates = STATES.map((state) => state.abbreviation);
                                                        setFieldValue('restrictedStates', fillStates);
                                                    }
                                                }>Select All</Button>
                                                <Button onClick={() => {
                                                    setFieldValue('restrictedStates', [])
                                                }}>Clear</Button>
                                            </Col>
                                        </Row>
                                        </FormItem>
                                    </Col>
                                    <Col span={6}>
                                        <FormItem label="Tags" name="tags">
                                            <Select allowClear mode="multiple" name="tags">
                                                {TAGS.map((tag, index) => (
                                                    <Select.Option key={index}
                                                                   value={tag.label}>{tag.label}</Select.Option>
                                                ))}
                                            </Select>
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <FormItem
                                            name="headLine"
                                            label="Spotlight content"
                                        >
                                            <CKEditor content={values.headLine} setContent={
                                                (text) => {
                                                    setFieldValue('headLine', text);
                                                }
                                            }/>
                                        </FormItem>
                                    </Col>
                                    <Col span={12}>
                                        <FormItem
                                            name="description"
                                            label="Internal notes"
                                        >
                                            <Input.TextArea rows={2} name="description"/>
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        {typeof errors.programs === 'string' &&
                                            <Text type="danger">{errors.programs} </Text>}
                                    </Col>
                                </Row>

                                <SubmitButton type="primary" disabled={false}>
                                    Submit
                                </SubmitButton>
                                <ResetButton>Reset</ResetButton>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Spin>
        </div>
    );
};

export default Edit;
