import React from 'react';
import { Divider, Collapse } from 'antd';

const { Panel } = Collapse;

const Repayment = ({ repayment }) => {
  return (
    <div className='panel-body'>
      <div style={{ display: 'flex' }}>
        <div>
          <h3 style={{ color: 'red' }}>2018</h3>
          <Collapse>
            <Panel header='DBRR' key={1}>
              {/* 1 year */}
              <p>
                <strong>1-year repayment rate borrower count:</strong>{' '}
                {repayment?.['2018']?.['1_yr_db_fed_repayment']?.count ?? 'N/A'}
              </p>

              <p>
                <strong>1-year repayment rate numerator:</strong>{' '}
                {repayment?.['2018']?.['1_yr_db_fed_repayment']?.numerator &&
                repayment?.['2018']?.['1_yr_db_fed_repayment']?.count
                  ? (
                      repayment?.['2018']?.['1_yr_db_fed_repayment']?.numerator /
                      repayment?.['2018']?.['1_yr_db_fed_repayment']?.count
                    ).toFixed(2)
                  : 'N/A'}
              </p>

              <p>
                <strong>1-year repayment rate denominator:</strong>{' '}
                {repayment?.['2018']?.['1_yr_db_fed_repayment']?.denominator &&
                repayment?.['2018']?.['1_yr_db_fed_repayment']?.count
                  ? (
                      repayment?.['2018']?.['1_yr_db_fed_repayment']?.denominator /
                      repayment?.['2018']?.['1_yr_db_fed_repayment']?.count
                    ).toFixed(2)
                  : 'N/A'}
              </p>

              <p>
                <strong>1-year repayment rate:</strong>{' '}
                {repayment?.['2018']?.['1_yr_db_fed_repayment']?.rate?.toFixed(4) ?? 'N/A'}
              </p>

              {/* 4 year */}
              <Divider />
              <p>
                <strong>4-year repayment rate borrower count:</strong>{' '}
                {repayment?.['2018']?.['4_yr_db_fed_repayment']?.count ?? 'N/A'}
              </p>

              <p>
                <strong>4-year repayment rate numerator:</strong>{' '}
                {repayment?.['2018']?.['4_yr_db_fed_repayment']?.numerator &&
                repayment?.['2018']?.['4_yr_db_fed_repayment']?.count
                  ? (
                      repayment?.['2018']?.['4_yr_db_fed_repayment']?.numerator /
                      repayment?.['2018']?.['4_yr_db_fed_repayment']?.count
                    ).toFixed(2)
                  : 'N/A'}
              </p>

              <p>
                <strong>4-year repayment rate denominator:</strong>{' '}
                {repayment?.['2018']?.['4_yr_db_fed_repayment']?.denominator &&
                repayment?.['2018']?.['4_yr_db_fed_repayment']?.count
                  ? (
                      repayment?.['2018']?.['4_yr_db_fed_repayment']?.denominator /
                      repayment?.['2018']?.['4_yr_db_fed_repayment']?.count
                    ).toFixed(2)
                  : 'N/A'}
              </p>

              <p>
                <strong>4-year repayment rate:</strong>{' '}
                {repayment?.['2018']?.['4_yr_db_fed_repayment']?.rate?.toFixed(4) ?? 'N/A'}
              </p>

              {/* 5 year */}
              <Divider />
              <p>
                <strong>5-year repayment rate borrower count:</strong>{' '}
                {repayment?.['2018']?.['5_yr_db_fed_repayment']?.count ?? 'N/A'}
              </p>

              <p>
                <strong>5-year repayment rate numerator:</strong>{' '}
                {repayment?.['2018']?.['5_yr_db_fed_repayment']?.numerator &&
                repayment?.['2018']?.['5_yr_db_fed_repayment']?.count
                  ? (
                      repayment?.['2018']?.['5_yr_db_fed_repayment']?.numerator /
                      repayment?.['2018']?.['5_yr_db_fed_repayment']?.count
                    ).toFixed(2)
                  : 'N/A'}
              </p>

              <p>
                <strong>5-year repayment rate denominator:</strong>{' '}
                {repayment?.['2018']?.['5_yr_db_fed_repayment']?.denominator &&
                repayment?.['2018']?.['5_yr_db_fed_repayment']?.count
                  ? (
                      repayment?.['2018']?.['5_yr_db_fed_repayment']?.denominator /
                      repayment?.['2018']?.['5_yr_db_fed_repayment']?.count
                    ).toFixed(2)
                  : 'N/A'}
              </p>

              <p>
                <strong>5-year repayment rate:</strong>{' '}
                {repayment?.['2018']?.['5_yr_db_fed_repayment']?.rate?.toFixed(4) ?? 'N/A'}
              </p>

              {/* 10 year */}
              <Divider />
              <p>
                <strong>10-year repayment rate borrower count:</strong>{' '}
                {repayment?.['2018']?.['10_yr_db_fed_repayment']?.count ?? 'N/A'}
              </p>

              <p>
                <strong>10-year repayment rate numerator:</strong>{' '}
                {repayment?.['2018']?.['10_yr_db_fed_repayment']?.numerator &&
                repayment?.['2018']?.['10_yr_db_fed_repayment']?.count
                  ? (
                      repayment?.['2018']?.['10_yr_db_fed_repayment']?.numerator /
                      repayment?.['2018']?.['10_yr_db_fed_repayment']?.count
                    ).toFixed(2)
                  : 'N/A'}
              </p>

              <p>
                <strong>10-year repayment rate denominator:</strong>{' '}
                {repayment?.['2018']?.['20_yr_db_fed_repayment']?.denominator &&
                repayment?.['2018']?.['20_yr_db_fed_repayment']?.count
                  ? (
                      repayment?.['2018']?.['20_yr_db_fed_repayment']?.denominator /
                      repayment?.['2018']?.['20_yr_db_fed_repayment']?.count
                    ).toFixed(2)
                  : 'N/A'}
              </p>

              <p>
                <strong>10-year repayment rate:</strong>{' '}
                {repayment?.['2018']?.['10_yr_db_fed_repayment']?.rate?.toFixed(4) ?? 'N/A'}
              </p>

              {/* 20 year */}
              <Divider />
              <p>
                <strong>20-year repayment rate borrower count:</strong>{' '}
                {repayment?.['2018']?.['20_yr_db_fed_repayment']?.count ?? 'N/A'}
              </p>

              <p>
                <strong>20-year repayment rate numerator:</strong>{' '}
                {repayment?.['2018']?.['20_yr_db_fed_repayment']?.numerator &&
                repayment?.['2018']?.['20_yr_db_fed_repayment']?.count
                  ? (
                      repayment?.['2018']?.['20_yr_db_fed_repayment']?.numerator /
                      repayment?.['2018']?.['20_yr_db_fed_repayment']?.count
                    ).toFixed(2)
                  : 'N/A'}
              </p>

              <p>
                <strong>20-year repayment rate denominator:</strong>{' '}
                {repayment?.['2018']?.['20_yr_db_fed_repayment']?.denominator &&
                repayment?.['2018']?.['20_yr_db_fed_repayment']?.count
                  ? (
                      repayment?.['2018']?.['20_yr_db_fed_repayment']?.denominator /
                      repayment?.['2018']?.['20_yr_db_fed_repayment']?.count
                    ).toFixed(2)
                  : 'N/A'}
              </p>

              <p>
                <strong>20-year repayment rate:</strong>{' '}
                {repayment?.['2018']?.['20_yr_db_fed_repayment']?.rate?.toFixed(4) ?? 'N/A'}
              </p>
            </Panel>

            <Panel header='BBRR' key={2}>
              <p>
                <strong>Federal student loan 1-year borrower count: </strong>
                {repayment?.['2018']?.['1_yr_bb_fed_repayment']?.count ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers in default after 1 year: </strong>
                {repayment?.['2018']?.['1_yr_bb_fed_repayment']?.default ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers in delinquency after 1 year: </strong>
                {repayment?.['2018']?.['1_yr_bb_fed_repayment']?.delinquent ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers in forbearance after 1 year: </strong>
                {repayment?.['2018']?.['1_yr_bb_fed_repayment']?.forbearance ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers in deferment after 1 year: </strong>
                {repayment?.['2018']?.['1_yr_bb_fed_repayment']?.deferment ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers not making progress after 1 year: </strong>
                {repayment?.['2018']?.['1_yr_bb_fed_repayment']?.noprogress ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers making progress after 1 year: </strong>
                {repayment?.['2018']?.['1_yr_bb_fed_repayment']?.makingprogress ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers paid in full after 1 year: </strong>
                {repayment?.['2018']?.['1_yr_bb_fed_repayment']?.fullypaid ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers with all loans discharged after 1 year: </strong>
                {repayment?.['2018']?.['1_yr_bb_fed_repayment']?.discharge ?? 'N/A'}
              </p>

              <Divider />

              <p>
                <strong>Federal student loan 2-year borrower count: </strong>
                {repayment?.['2018']?.['2_yr_bb_fed_repayment']?.count ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers in default after 2 years: </strong>
                {repayment?.['2018']?.['2_yr_bb_fed_repayment']?.default ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers in delinquency after 2 years: </strong>
                {repayment?.['2018']?.['2_yr_bb_fed_repayment']?.delinquent ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers in forbearance after 2 years: </strong>
                {repayment?.['2018']?.['2_yr_bb_fed_repayment']?.forbearance ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers in deferment after 2 years: </strong>
                {repayment?.['2018']?.['2_yr_bb_fed_repayment']?.deferment ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers not making progress after 2 years: </strong>
                {repayment?.['2018']?.['2_yr_bb_fed_repayment']?.noprogress ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers making progress after 2 years: </strong>
                {repayment?.['2018']?.['2_yr_bb_fed_repayment']?.makingprogress ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers paid in full after 2 years: </strong>
                {repayment?.['2018']?.['2_yr_bb_fed_repayment']?.fullypaid ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers with all loans discharged after 2 years: </strong>
                {repayment?.['2018']?.['2_yr_bb_fed_repayment']?.discharge ?? 'N/A'}
              </p>

              <Divider />

              <p>
                <strong>Federal student loan 3-year borrower count: </strong>
                {repayment?.['2018']?.['3_yr_bb_fed_repayment']?.count ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers in default after 3 years: </strong>
                {repayment?.['2018']?.['3_yr_bb_fed_repayment']?.default ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers in delinquency after 3 years: </strong>
                {repayment?.['2018']?.['3_yr_bb_fed_repayment']?.delinquent ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers in forbearance after 3 years: </strong>
                {repayment?.['2018']?.['3_yr_bb_fed_repayment']?.forbearance ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers in deferment after 3 years: </strong>
                {repayment?.['2018']?.['3_yr_bb_fed_repayment']?.deferment ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers not making progress after 3 years: </strong>
                {repayment?.['2018']?.['3_yr_bb_fed_repayment']?.noprogress ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers making progress after 3 years: </strong>
                {repayment?.['2018']?.['3_yr_bb_fed_repayment']?.makingprogress ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers paid in full after 3 years: </strong>
                {repayment?.['2018']?.['3_yr_bb_fed_repayment']?.fullypaid ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers with all loans discharged after 3 years: </strong>
                {repayment?.['2018']?.['3_yr_bb_fed_repayment']?.discharge ?? 'N/A'}
              </p>

              <Divider />

              <p>
                <strong>Federal student loan 4-year borrower count: </strong>
                {repayment?.['2018']?.['4_yr_bb_fed_repayment']?.count ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers in default after 4 years: </strong>
                {repayment?.['2018']?.['4_yr_bb_fed_repayment']?.default ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers in delinquency after 4 years: </strong>
                {repayment?.['2018']?.['4_yr_bb_fed_repayment']?.delinquent ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers in forbearance after 4 years: </strong>
                {repayment?.['2018']?.['4_yr_bb_fed_repayment']?.forbearance ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers in deferment after 4 years: </strong>
                {repayment?.['2018']?.['4_yr_bb_fed_repayment']?.deferment ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers not making progress after 4 years: </strong>
                {repayment?.['2018']?.['4_yr_bb_fed_repayment']?.noprogress ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers making progress after 4 years: </strong>
                {repayment?.['2018']?.['4_yr_bb_fed_repayment']?.makingprogress ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers paid in full after 4 years: </strong>
                {repayment?.['2018']?.['4_yr_bb_fed_repayment']?.fullypaid ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers with all loans discharged after 4 years: </strong>
                {repayment?.['2018']?.['4_yr_bb_fed_repayment']?.discharge ?? 'N/A'}
              </p>
            </Panel>
          </Collapse>
        </div>

        <div style={{ marginLeft: '200px' }}>
          <h3 style={{ color: 'red' }}>2019</h3>
          <Collapse>
            <Panel header='DBRR' key={1}>
              {/* 1 year */}
              <p>
                <strong>1-year repayment rate borrower count:</strong>{' '}
                {repayment?.['2019']?.['1_yr_db_fed_repayment']?.count ?? 'N/A'}
              </p>

              <p>
                <strong>1-year repayment rate numerator:</strong>{' '}
                {repayment?.['2019']?.['1_yr_db_fed_repayment']?.numerator &&
                repayment?.['2019']?.['1_yr_db_fed_repayment']?.count
                  ? (
                      repayment?.['2019']?.['1_yr_db_fed_repayment']?.numerator /
                      repayment?.['2019']?.['1_yr_db_fed_repayment']?.count
                    ).toFixed(2)
                  : 'N/A'}
              </p>

              <p>
                <strong>1-year repayment rate denominator:</strong>{' '}
                {repayment?.['2019']?.['1_yr_db_fed_repayment']?.denominator &&
                repayment?.['2019']?.['1_yr_db_fed_repayment']?.count
                  ? (
                      repayment?.['2019']?.['1_yr_db_fed_repayment']?.denominator /
                      repayment?.['2019']?.['1_yr_db_fed_repayment']?.count
                    ).toFixed(2)
                  : 'N/A'}
              </p>

              <p>
                <strong>1-year repayment rate:</strong>{' '}
                {repayment?.['2019']?.['1_yr_db_fed_repayment']?.rate?.toFixed(4) ?? 'N/A'}
              </p>

              {/* 4 year */}
              <Divider />
              <p>
                <strong>4-year repayment rate borrower count:</strong>{' '}
                {repayment?.['2019']?.['4_yr_db_fed_repayment']?.count ?? 'N/A'}
              </p>

              <p>
                <strong>4-year repayment rate numerator:</strong>{' '}
                {repayment?.['2019']?.['4_yr_db_fed_repayment']?.numerator &&
                repayment?.['2019']?.['4_yr_db_fed_repayment']?.count
                  ? (
                      repayment?.['2019']?.['4_yr_db_fed_repayment']?.numerator /
                      repayment?.['2019']?.['4_yr_db_fed_repayment']?.count
                    ).toFixed(2)
                  : 'N/A'}
              </p>

              <p>
                <strong>4-year repayment rate denominator:</strong>{' '}
                {repayment?.['2019']?.['4_yr_db_fed_repayment']?.denominator &&
                repayment?.['2019']?.['4_yr_db_fed_repayment']?.count
                  ? (
                      repayment?.['2019']?.['4_yr_db_fed_repayment']?.denominator /
                      repayment?.['2019']?.['4_yr_db_fed_repayment']?.count
                    ).toFixed(2)
                  : 'N/A'}
              </p>

              <p>
                <strong>4-year repayment rate:</strong>{' '}
                {repayment?.['2019']?.['4_yr_db_fed_repayment']?.rate?.toFixed(4) ?? 'N/A'}
              </p>

              {/* 5 year */}
              <Divider />
              <p>
                <strong>5-year repayment rate borrower count:</strong>{' '}
                {repayment?.['2019']?.['5_yr_db_fed_repayment']?.count ?? 'N/A'}
              </p>

              <p>
                <strong>5-year repayment rate numerator:</strong>{' '}
                {repayment?.['2019']?.['5_yr_db_fed_repayment']?.numerator &&
                repayment?.['2019']?.['5_yr_db_fed_repayment']?.count
                  ? (
                      repayment?.['2019']?.['5_yr_db_fed_repayment']?.numerator /
                      repayment?.['2019']?.['5_yr_db_fed_repayment']?.count
                    ).toFixed(2)
                  : 'N/A'}
              </p>

              <p>
                <strong>5-year repayment rate denominator:</strong>{' '}
                {repayment?.['2019']?.['5_yr_db_fed_repayment']?.denominator &&
                repayment?.['2019']?.['5_yr_db_fed_repayment']?.count
                  ? (
                      repayment?.['2019']?.['5_yr_db_fed_repayment']?.denominator /
                      repayment?.['2019']?.['5_yr_db_fed_repayment']?.count
                    ).toFixed(2)
                  : 'N/A'}
              </p>

              <p>
                <strong>5-year repayment rate:</strong>{' '}
                {repayment?.['2019']?.['5_yr_db_fed_repayment']?.rate?.toFixed(4) ?? 'N/A'}
              </p>

              {/* 10 year */}
              <Divider />
              <p>
                <strong>10-year repayment rate borrower count:</strong>{' '}
                {repayment?.['2019']?.['10_yr_db_fed_repayment']?.count ?? 'N/A'}
              </p>

              <p>
                <strong>10-year repayment rate numerator:</strong>{' '}
                {repayment?.['2019']?.['10_yr_db_fed_repayment']?.numerator &&
                repayment?.['2019']?.['10_yr_db_fed_repayment']?.count
                  ? (
                      repayment?.['2019']?.['10_yr_db_fed_repayment']?.numerator /
                      repayment?.['2019']?.['10_yr_db_fed_repayment']?.count
                    ).toFixed(2)
                  : 'N/A'}
              </p>

              <p>
                <strong>10-year repayment rate denominator:</strong>{' '}
                {repayment?.['2019']?.['10_yr_db_fed_repayment']?.denominator &&
                repayment?.['2019']?.['10_yr_db_fed_repayment']?.count
                  ? (
                      repayment?.['2019']?.['10_yr_db_fed_repayment']?.denominator /
                      repayment?.['2019']?.['10_yr_db_fed_repayment']?.count
                    ).toFixed(2)
                  : 'N/A'}
              </p>

              <p>
                <strong>10-year repayment rate:</strong>{' '}
                {repayment?.['2019']?.['10_yr_db_fed_repayment']?.rate?.toFixed(4) ?? 'N/A'}
              </p>

              {/* 20 year */}
              <Divider />
              <p>
                <strong>20-year repayment rate borrower count:</strong>{' '}
                {repayment?.['2019']?.['20_yr_db_fed_repayment']?.count ?? 'N/A'}
              </p>

              <p>
                <strong>20-year repayment rate numerator:</strong>{' '}
                {repayment?.['2019']?.['20_yr_db_fed_repayment']?.numerator &&
                repayment?.['2019']?.['20_yr_db_fed_repayment']?.count
                  ? (
                      repayment?.['2019']?.['20_yr_db_fed_repayment']?.numerator /
                      repayment?.['2019']?.['20_yr_db_fed_repayment']?.count
                    ).toFixed(2)
                  : 'N/A'}
              </p>

              <p>
                <strong>20-year repayment rate denominator:</strong>{' '}
                {repayment?.['2019']?.['20_yr_db_fed_repayment']?.denominator &&
                repayment?.['2019']?.['20_yr_db_fed_repayment']?.count
                  ? (
                      repayment?.['2019']?.['20_yr_db_fed_repayment']?.denominator /
                      repayment?.['2019']?.['20_yr_db_fed_repayment']?.count
                    ).toFixed(2)
                  : 'N/A'}
              </p>

              <p>
                <strong>20-year repayment rate:</strong>{' '}
                {repayment?.['2019']?.['20_yr_db_fed_repayment']?.rate?.toFixed(4) ?? 'N/A'}
              </p>
            </Panel>

            <Panel header='BBRR' key={2}>
              <p>
                <strong>Federal student loan 1-year borrower count: </strong>
                {repayment?.['2019']?.['1_yr_bb_fed_repayment']?.count ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers in default after 1 year: </strong>
                {repayment?.['2019']?.['1_yr_bb_fed_repayment']?.default ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers in delinquency after 1 year: </strong>
                {repayment?.['2019']?.['1_yr_bb_fed_repayment']?.delinquent ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers in forbearance after 1 year: </strong>
                {repayment?.['2019']?.['1_yr_bb_fed_repayment']?.forbearance ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers in deferment after 1 year: </strong>
                {repayment?.['2019']?.['1_yr_bb_fed_repayment']?.deferment ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers not making progress after 1 year: </strong>
                {repayment?.['2019']?.['1_yr_bb_fed_repayment']?.noprogress ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers making progress after 1 year: </strong>
                {repayment?.['2019']?.['1_yr_bb_fed_repayment']?.makingprogress ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers paid in full after 1 year: </strong>
                {repayment?.['2019']?.['1_yr_bb_fed_repayment']?.fullypaid ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers with all loans discharged after 1 year: </strong>
                {repayment?.['2019']?.['1_yr_bb_fed_repayment']?.discharge ?? 'N/A'}
              </p>

              <Divider />

              <p>
                <strong>Federal student loan 2-year borrower count: </strong>
                {repayment?.['2019']?.['2_yr_bb_fed_repayment']?.count ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers in default after 2 years: </strong>
                {repayment?.['2019']?.['2_yr_bb_fed_repayment']?.default ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers in delinquency after 2 years: </strong>
                {repayment?.['2019']?.['2_yr_bb_fed_repayment']?.delinquent ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers in forbearance after 2 years: </strong>
                {repayment?.['2019']?.['2_yr_bb_fed_repayment']?.forbearance ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers in deferment after 2 years: </strong>
                {repayment?.['2019']?.['2_yr_bb_fed_repayment']?.deferment ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers not making progress after 2 years: </strong>
                {repayment?.['2019']?.['2_yr_bb_fed_repayment']?.noprogress ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers making progress after 2 years: </strong>
                {repayment?.['2019']?.['2_yr_bb_fed_repayment']?.makingprogress ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers paid in full after 2 years: </strong>
                {repayment?.['2019']?.['2_yr_bb_fed_repayment']?.fullypaid ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers with all loans discharged after 2 years: </strong>
                {repayment?.['2019']?.['2_yr_bb_fed_repayment']?.discharge ?? 'N/A'}
              </p>

              <Divider />

              <p>
                <strong>Federal student loan 3-year borrower count: </strong>
                {repayment?.['2019']?.['3_yr_bb_fed_repayment']?.count ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers in default after 3 years: </strong>
                {repayment?.['2019']?.['3_yr_bb_fed_repayment']?.default ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers in delinquency after 3 years: </strong>
                {repayment?.['2019']?.['3_yr_bb_fed_repayment']?.delinquent ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers in forbearance after 3 years: </strong>
                {repayment?.['2019']?.['3_yr_bb_fed_repayment']?.forbearance ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers in deferment after 3 years: </strong>
                {repayment?.['2019']?.['3_yr_bb_fed_repayment']?.deferment ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers not making progress after 3 years: </strong>
                {repayment?.['2019']?.['3_yr_bb_fed_repayment']?.noprogress ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers making progress after 3 years: </strong>
                {repayment?.['2019']?.['3_yr_bb_fed_repayment']?.makingprogress ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers paid in full after 3 years: </strong>
                {repayment?.['2019']?.['3_yr_bb_fed_repayment']?.fullypaid ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers with all loans discharged after 3 years: </strong>
                {repayment?.['2019']?.['3_yr_bb_fed_repayment']?.discharge ?? 'N/A'}
              </p>

              <Divider />

              <p>
                <strong>Federal student loan 4-year borrower count: </strong>
                {repayment?.['2019']?.['4_yr_bb_fed_repayment']?.count ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers in default after 4 years: </strong>
                {repayment?.['2019']?.['4_yr_bb_fed_repayment']?.default ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers in delinquency after 4 years: </strong>
                {repayment?.['2019']?.['4_yr_bb_fed_repayment']?.delinquent ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers in forbearance after 4 years: </strong>
                {repayment?.['2019']?.['4_yr_bb_fed_repayment']?.forbearance ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers in deferment after 4 years: </strong>
                {repayment?.['2019']?.['4_yr_bb_fed_repayment']?.deferment ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers not making progress after 4 years: </strong>
                {repayment?.['2019']?.['4_yr_bb_fed_repayment']?.noprogress ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers making progress after 4 years: </strong>
                {repayment?.['2019']?.['4_yr_bb_fed_repayment']?.makingprogress ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers paid in full after 4 years: </strong>
                {repayment?.['2019']?.['4_yr_bb_fed_repayment']?.fullypaid ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers with all loans discharged after 4 years: </strong>
                {repayment?.['2019']?.['4_yr_bb_fed_repayment']?.discharge ?? 'N/A'}
              </p>
            </Panel>
          </Collapse>
        </div>

        <div style={{ marginLeft: '200px' }}>
          <h3 style={{ color: 'red' }}>2020</h3>
          <Collapse>
            <Panel header='DBRR' key={1}>
              {/* 1 year */}
              <p>
                <strong>1-year repayment rate borrower count:</strong>{' '}
                {repayment?.['2020']?.['1_yr_db_fed_repayment']?.count ?? 'N/A'}
              </p>

              <p>
                <strong>1-year repayment rate numerator:</strong>{' '}
                {repayment?.['2020']?.['1_yr_db_fed_repayment']?.numerator &&
                repayment?.['2020']?.['1_yr_db_fed_repayment']?.count
                  ? (
                      repayment?.['2020']?.['1_yr_db_fed_repayment']?.numerator /
                      repayment?.['2020']?.['1_yr_db_fed_repayment']?.count
                    ).toFixed(2)
                  : 'N/A'}
              </p>

              <p>
                <strong>1-year repayment rate denominator:</strong>{' '}
                {repayment?.['2020']?.['1_yr_db_fed_repayment']?.denominator &&
                repayment?.['2020']?.['1_yr_db_fed_repayment']?.count
                  ? (
                      repayment?.['2020']?.['1_yr_db_fed_repayment']?.denominator /
                      repayment?.['2020']?.['1_yr_db_fed_repayment']?.count
                    ).toFixed(2)
                  : 'N/A'}
              </p>

              <p>
                <strong>1-year repayment rate:</strong>{' '}
                {repayment?.['2020']?.['1_yr_db_fed_repayment']?.rate?.toFixed(4) ?? 'N/A'}
              </p>

              {/* 4 year */}
              <Divider />
              <p>
                <strong>4-year repayment rate borrower count:</strong>{' '}
                {repayment?.['2020']?.['4_yr_db_fed_repayment']?.count ?? 'N/A'}
              </p>

              <p>
                <strong>4-year repayment rate numerator:</strong>{' '}
                {repayment?.['2020']?.['4_yr_db_fed_repayment']?.numerator &&
                repayment?.['2020']?.['4_yr_db_fed_repayment']?.count
                  ? (
                      repayment?.['2020']?.['4_yr_db_fed_repayment']?.numerator /
                      repayment?.['2020']?.['4_yr_db_fed_repayment']?.count
                    ).toFixed(2)
                  : 'N/A'}
              </p>

              <p>
                <strong>4-year repayment rate denominator:</strong>{' '}
                {repayment?.['2020']?.['4_yr_db_fed_repayment']?.denominator &&
                repayment?.['2020']?.['4_yr_db_fed_repayment']?.count
                  ? (
                      repayment?.['2020']?.['4_yr_db_fed_repayment']?.denominator /
                      repayment?.['2020']?.['4_yr_db_fed_repayment']?.count
                    ).toFixed(2)
                  : 'N/A'}
              </p>

              <p>
                <strong>4-year repayment rate:</strong>{' '}
                {repayment?.['2020']?.['4_yr_db_fed_repayment']?.rate?.toFixed(4) ?? 'N/A'}
              </p>

              {/* 5 year */}
              <Divider />
              <p>
                <strong>5-year repayment rate borrower count:</strong>{' '}
                {repayment?.['2020']?.['5_yr_db_fed_repayment']?.count ?? 'N/A'}
              </p>

              <p>
                <strong>5-year repayment rate numerator:</strong>{' '}
                {repayment?.['2020']?.['5_yr_db_fed_repayment']?.numerator &&
                repayment?.['2020']?.['5_yr_db_fed_repayment']?.count
                  ? (
                      repayment?.['2020']?.['5_yr_db_fed_repayment']?.numerator /
                      repayment?.['2020']?.['5_yr_db_fed_repayment']?.count
                    ).toFixed(2)
                  : 'N/A'}
              </p>

              <p>
                <strong>5-year repayment rate denominator:</strong>{' '}
                {repayment?.['2020']?.['5_yr_db_fed_repayment']?.denominator &&
                repayment?.['2020']?.['5_yr_db_fed_repayment']?.count
                  ? (
                      repayment?.['2020']?.['5_yr_db_fed_repayment']?.denominator /
                      repayment?.['2020']?.['5_yr_db_fed_repayment']?.count
                    ).toFixed(2)
                  : 'N/A'}
              </p>

              <p>
                <strong>5-year repayment rate:</strong>{' '}
                {repayment?.['2020']?.['5_yr_db_fed_repayment']?.rate?.toFixed(4) ?? 'N/A'}
              </p>

              {/* 10 year */}
              <Divider />
              <p>
                <strong>10-year repayment rate borrower count:</strong>{' '}
                {repayment?.['2020']?.['10_yr_db_fed_repayment']?.count ?? 'N/A'}
              </p>

              <p>
                <strong>10-year repayment rate numerator:</strong>{' '}
                {repayment?.['2020']?.['10_yr_db_fed_repayment']?.numerator &&
                repayment?.['2020']?.['10_yr_db_fed_repayment']?.count
                  ? (
                      repayment?.['2020']?.['10_yr_db_fed_repayment']?.numerator /
                      repayment?.['2020']?.['10_yr_db_fed_repayment']?.count
                    ).toFixed(2)
                  : 'N/A'}
              </p>

              <p>
                <strong>10-year repayment rate denominator:</strong>{' '}
                {repayment?.['2020']?.['10_yr_db_fed_repayment']?.denominator &&
                repayment?.['2020']?.['10_yr_db_fed_repayment']?.count
                  ? (
                      repayment?.['2020']?.['10_yr_db_fed_repayment']?.denominator /
                      repayment?.['2020']?.['10_yr_db_fed_repayment']?.count
                    ).toFixed(2)
                  : 'N/A'}
              </p>

              <p>
                <strong>10-year repayment rate:</strong>{' '}
                {repayment?.['2020']?.['10_yr_db_fed_repayment']?.rate?.toFixed(4) ?? 'N/A'}
              </p>

              {/* 20 year */}
              <Divider />
              <p>
                <strong>20-year repayment rate borrower count:</strong>{' '}
                {repayment?.['2020']?.['20_yr_db_fed_repayment']?.count ?? 'N/A'}
              </p>

              <p>
                <strong>20-year repayment rate numerator:</strong>{' '}
                {repayment?.['2020']?.['20_yr_db_fed_repayment']?.numerator &&
                repayment?.['2020']?.['20_yr_db_fed_repayment']?.count
                  ? (
                      repayment?.['2020']?.['20_yr_db_fed_repayment']?.numerator /
                      repayment?.['2020']?.['20_yr_db_fed_repayment']?.count
                    ).toFixed(2)
                  : 'N/A'}
              </p>

              <p>
                <strong>20-year repayment rate denominator:</strong>{' '}
                {repayment?.['2020']?.['20_yr_db_fed_repayment']?.denominator &&
                repayment?.['2020']?.['20_yr_db_fed_repayment']?.count
                  ? (
                      repayment?.['2020']?.['20_yr_db_fed_repayment']?.denominator /
                      repayment?.['2020']?.['20_yr_db_fed_repayment']?.count
                    ).toFixed(2)
                  : 'N/A'}
              </p>

              <p>
                <strong>20-year repayment rate:</strong>{' '}
                {repayment?.['2020']?.['20_yr_db_fed_repayment']?.rate?.toFixed(4) ?? 'N/A'}
              </p>
            </Panel>

            <Panel header='BBRR' key={2}>
              <p>
                <strong>Federal student loan 1-year borrower count: </strong>
                {repayment?.['2020']?.['1_yr_bb_fed_repayment']?.count ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers in default after 1 year: </strong>
                {repayment?.['2020']?.['1_yr_bb_fed_repayment']?.default ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers in delinquency after 1 year: </strong>
                {repayment?.['2020']?.['1_yr_bb_fed_repayment']?.delinquent ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers in forbearance after 1 year: </strong>
                {repayment?.['2020']?.['1_yr_bb_fed_repayment']?.forbearance ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers in deferment after 1 year: </strong>
                {repayment?.['2020']?.['1_yr_bb_fed_repayment']?.deferment ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers not making progress after 1 year: </strong>
                {repayment?.['2020']?.['1_yr_bb_fed_repayment']?.noprogress ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers making progress after 1 year: </strong>
                {repayment?.['2020']?.['1_yr_bb_fed_repayment']?.makingprogress ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers paid in full after 1 year: </strong>
                {repayment?.['2020']?.['1_yr_bb_fed_repayment']?.fullypaid ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers with all loans discharged after 1 year: </strong>
                {repayment?.['2020']?.['1_yr_bb_fed_repayment']?.discharge ?? 'N/A'}
              </p>

              <Divider />

              <p>
                <strong>Federal student loan 2-year borrower count: </strong>
                {repayment?.['2020']?.['2_yr_bb_fed_repayment']?.count ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers in default after 2 years: </strong>
                {repayment?.['2020']?.['2_yr_bb_fed_repayment']?.default ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers in delinquency after 2 years: </strong>
                {repayment?.['2020']?.['2_yr_bb_fed_repayment']?.delinquent ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers in forbearance after 2 years: </strong>
                {repayment?.['2020']?.['2_yr_bb_fed_repayment']?.forbearance ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers in deferment after 2 years: </strong>
                {repayment?.['2020']?.['2_yr_bb_fed_repayment']?.deferment ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers not making progress after 2 years: </strong>
                {repayment?.['2020']?.['2_yr_bb_fed_repayment']?.noprogress ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers making progress after 2 years: </strong>
                {repayment?.['2020']?.['2_yr_bb_fed_repayment']?.makingprogress ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers paid in full after 2 years: </strong>
                {repayment?.['2020']?.['2_yr_bb_fed_repayment']?.fullypaid ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers with all loans discharged after 2 years: </strong>
                {repayment?.['2020']?.['2_yr_bb_fed_repayment']?.discharge ?? 'N/A'}
              </p>

              <Divider />

              <p>
                <strong>Federal student loan 3-year borrower count: </strong>
                {repayment?.['2020']?.['3_yr_bb_fed_repayment']?.count ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers in default after 3 years: </strong>
                {repayment?.['2020']?.['3_yr_bb_fed_repayment']?.default ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers in delinquency after 3 years: </strong>
                {repayment?.['2020']?.['3_yr_bb_fed_repayment']?.delinquent ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers in forbearance after 3 years: </strong>
                {repayment?.['2020']?.['3_yr_bb_fed_repayment']?.forbearance ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers in deferment after 3 years: </strong>
                {repayment?.['2020']?.['3_yr_bb_fed_repayment']?.deferment ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers not making progress after 3 years: </strong>
                {repayment?.['2020']?.['3_yr_bb_fed_repayment']?.noprogress ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers making progress after 3 years: </strong>
                {repayment?.['2020']?.['3_yr_bb_fed_repayment']?.makingprogress ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers paid in full after 3 years: </strong>
                {repayment?.['2020']?.['3_yr_bb_fed_repayment']?.fullypaid ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers with all loans discharged after 3 years: </strong>
                {repayment?.['2020']?.['3_yr_bb_fed_repayment']?.discharge ?? 'N/A'}
              </p>

              <Divider />

              <p>
                <strong>Federal student loan 4-year borrower count: </strong>
                {repayment?.['2020']?.['4_yr_bb_fed_repayment']?.count ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers in default after 4 years: </strong>
                {repayment?.['2020']?.['4_yr_bb_fed_repayment']?.default ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers in delinquency after 4 years: </strong>
                {repayment?.['2020']?.['4_yr_bb_fed_repayment']?.delinquent ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers in forbearance after 4 years: </strong>
                {repayment?.['2020']?.['4_yr_bb_fed_repayment']?.forbearance ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers in deferment after 4 years: </strong>
                {repayment?.['2020']?.['4_yr_bb_fed_repayment']?.deferment ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers not making progress after 4 years: </strong>
                {repayment?.['2020']?.['4_yr_bb_fed_repayment']?.noprogress ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers making progress after 4 years: </strong>
                {repayment?.['2020']?.['4_yr_bb_fed_repayment']?.makingprogress ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers paid in full after 4 years: </strong>
                {repayment?.['2020']?.['4_yr_bb_fed_repayment']?.fullypaid ?? 'N/A'}
              </p>

              <p>
                <strong>Percentage of federal student loan borrowers with all loans discharged after 4 years: </strong>
                {repayment?.['2020']?.['4_yr_bb_fed_repayment']?.discharge ?? 'N/A'}
              </p>
            </Panel>
          </Collapse>
        </div>
      </div>
    </div>
  );
};

export default Repayment;
