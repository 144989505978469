import React from 'react';
import { Image, Tooltip } from 'antd';

const CollapseImageTooltip = ({ title, image, width, height, imageWidth, imageHeight }) => {
  return (
    <Tooltip
      overlayInnerStyle={{ width: width ? width : '500px', height }}
      title={
        imageWidth && imageHeight ? (
          <Image style={{ width: imageWidth, height: imageHeight }} src={image} />
        ) : (
          <Image src={image} />
        )
      }
      placement='right'
    >
      <span>{title}</span>
    </Tooltip>
  );
};

export default CollapseImageTooltip;
