import React from 'react';
import { Tabs } from 'antd';
import Programs from './Programs';

const { TabPane } = Tabs;

const AllProgramsTab = ({ majors, gradPrograms, associates, doctorate, currentuser, name }) => {
  return (
    <div className='panel-body'>
      <Tabs defaultActiveKey={'ASSOCIATE'}>
        <TabPane tab='ASSOCIATE PROGRAMS' key='ASSOCIATE PROGRAMS'>
          <Programs programs={associates} degree="Associate's Degree" currentuser={currentuser} name={name} />
        </TabPane>

        <TabPane tab='MAJORS' key='MAJORS'>
          <Programs programs={majors} degree='Bachelors Degree' currentuser={currentuser} name={name} />
        </TabPane>

        <TabPane tab='GRAD PROGRAMS' key='GRAD PROGRAMS'>
          <Programs
            programs={gradPrograms}
            degree="Master's Degree"
            doctorate={doctorate}
            currentuser={currentuser}
            name={name}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default AllProgramsTab;
