import React, { useRef } from 'react';
import BubblesList from '../../pages/ranking content/BubblesList';
import { Input, notification, Select, Collapse, Modal } from 'antd';
import * as _ from 'lodash';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import CKEditor from '../base/CKEditor';

const { TextArea } = Input;

const RankingContent = ({
  modal,
  degree,
  setModal,
  fetchTrigger,
  setFetchTrigger,
  content,
  setContent,
  bubbles,
  setBubbles,
  refreshBubbles,
  setRefreshBubbles,
  allSpotlights,
  working,
  setWorking,
  currentuser,
  allCareers,
}) => {
  const titleInput = useRef();

  const onOk = async () => {
    if (!modal.title || modal.title === '') {
      titleInput.current.focus();
      notification.error({
        message: '<h1> title is required.',
        placement: 'topRight',
        duration: 2,
      });
      return;
    }

    try {
      setWorking(true);

      let body = {
        title: modal.title,
        content,
        listName: modal.listName,
        metaTitle: modal.metaTitle,
        metaDescription: modal.metaDescription,
        bobbleDescription: modal.bubbleDescription,
        metaUrl: modal.metaUrl,
        metaImage: modal.metaImage,
        bubbles: bubbles,
        spotlight: modal.spotlight,
        shortcode: modal.shortcode,
        relatedCareers: modal.relatedCareers,
      };

      if (modal.isProgramLevel) {
        await Axios.patch(`${SERVER_URL}/rankings-programs/${modal.id}`, body, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
      } else if (modal.emi) {
        body.description = body.content;
        delete body.content;
        delete body.relatedCareers;
        await Axios.patch(
          `${SERVER_URL}/rankings-emi`,
          { content: body },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` },
          },
        );
      } else {
        await Axios.patch(`${SERVER_URL}/rankings-category-content-old/${modal.id}`, body, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
      }

      setWorking(false);
      setFetchTrigger(!fetchTrigger);
      setRefreshBubbles(Math.random());
      setModal({ visible: false });

      notification.success({
        message: `Content (${degree.toLowerCase()}) saved`,
        placement: 'bottomRight',
        duration: 2,
      });
    } catch (error) {
      console.log('Error on save content', error);
      setWorking(false);
      setModal({ visible: false });
      notification.error({
        message: 'Error',
        description: 'Problem with saving content',
        placement: 'bottomRight',
        duration: 2,
      });
    }
  };

  return (
    <Modal
      centered
      width='70%'
      visible={modal.visible}
      title={
        <span>
          EDIT -{' '}
          <strong>
            {modal.category} ({degree.toUpperCase()})
          </strong>
        </span>
      }
      okText='SAVE'
      cancelText='CANCEL'
      maskClosable={false}
      onOk={onOk}
      onCancel={() => {
        setModal({ visible: false });
        setFetchTrigger(!fetchTrigger);
      }}
      confirmLoading={working}
    >
      <div>
        <p style={{ fontSize: 15, marginTop: 20, marginRight: 10 }}>{'<h1> '}</p>
        <Input
          name='title'
          value={modal.title}
          placeholder={'Title for <h1>...'}
          onChange={(e) => setModal({ ...modal, title: e.target.value })}
          ref={titleInput}
        />
      </div>

      <div>
        <p style={{ fontSize: 15, marginTop: 20, marginRight: 10 }}>Content </p>
        <CKEditor content={content} setContent={setContent} />
      </div>

      <br></br>

      {modal.category !== 'Search' && (
        <>
          <Collapse>
            <Collapse.Panel key='1' header='Bubbles'>
              <BubblesList data={bubbles} handleBubbles={setBubbles} refresh={refreshBubbles} />
            </Collapse.Panel>
          </Collapse>
        </>
      )}

      {modal.category !== 'Search' && (
        <div>
          <p style={{ fontSize: 15, marginTop: 20, marginRight: 10 }}>List Name </p>
          <Input
            name='listName'
            value={modal.listName}
            placeholder={'Example List Name'}
            onChange={(e) => setModal({ ...modal, listName: e.target.value })}
          />
        </div>
      )}

      <div>
        <p style={{ fontSize: 15, marginTop: 20, marginRight: 10 }}>Meta Title</p>
        <Input
          name='metaTitle'
          value={modal.metaTitle}
          onChange={(e) => setModal({ ...modal, metaTitle: e.target.value })}
          maxLength={70}
        />
      </div>

      <div>
        <p style={{ fontSize: 15, marginTop: 20, marginRight: 10 }}>Meta Description</p>
        <TextArea
          name='metaDescription'
          value={modal.metaDescription}
          maxLength={180}
          rows={3}
          onChange={(e) => setModal({ ...modal, metaDescription: e.target.value })}
        />
      </div>

      <div>
        <p style={{ fontSize: 15, marginTop: 20, marginRight: 10 }}>Meta Url</p>
        <Input name='metaUrl' value={modal.metaUrl} onChange={(e) => setModal({ ...modal, metaUrl: e.target.value })} />
      </div>

      <div>
        <p style={{ fontSize: 15, marginTop: 20, marginRight: 10 }}>Meta Image</p>
        <Input
          name='metaImage'
          value={modal.metaImage}
          onChange={(e) => setModal({ ...modal, metaImage: e.target.value })}
        />
      </div>

      <br></br>

      <div>
        {modal.metaImage && (
          <img
            src={modal.metaImage}
            style={{
              width: '200px',
              border: '0.5px solid #bbb',
              background: '#eee',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
            alt='metaImage'
          />
        )}
      </div>

      <div>
        <p style={{ fontSize: 15, marginTop: 20, marginRight: 10 }}>Bubble Description</p>
        <TextArea
          name='bubbleDescription'
          value={modal.bubbleDescription}
          maxLength={180}
          rows={3}
          onChange={(e) => setModal({ ...modal, bubbleDescription: e.target.value })}
        />
      </div>

      {/* SPOTLIGHT */}
      <div style={{ marginTop: '20px', marginBottom: '10px' }}>
        <label htmlFor='spotlight'>Spotlight: </label>
        <Select
          allowClear
          showSearch
          name='spotlight'
          value={modal.spotlight}
          onChange={(value) => setModal({ ...modal, spotlight: value })}
          style={{ width: '400px', marginLeft: '4px', fontSize: '15px' }}
          placeholder='Select spotlight...'
        >
          {allSpotlights.map((item) => (
            <Select.Option key={`${item._id}`} value={item._id}>
              {item.CMSDisplayName}
            </Select.Option>
          ))}
        </Select>
      </div>

      {/* SHORTCODE */}
      <div style={{ marginTop: '20px', marginBottom: '10px' }}>
        <label htmlFor='shortcode'>Shortcode </label>
        <TextArea
          name='shortcode'
          value={modal.shortcode}
          rows={2}
          onChange={(e) => setModal({ ...modal, shortcode: e.target.value })}
        />
      </div>

      {modal.cipcode ? (
        <div>
          {/* RELATED CAREERS */}
          <div style={{ marginTop: '20px', marginBottom: '10px' }}>
            <label htmlFor='relatedCareers'>Related careers: </label>
            <Select
              allowClear
              showSearch
              optionFilterProp='children'
              mode='multiple'
              name='relatedCareers'
              value={modal.relatedCareers}
              onChange={(value) => setModal({ ...modal, relatedCareers: value })}
              style={{ width: '800px', marginLeft: '4px', fontSize: '15px' }}
              placeholder='Select careers...'
            >
              {allCareers.map((item) => (
                <Select.Option key={`${item._id}`} value={item._id}>
                  {item.occCode} - {item.careerName}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>
      ) : null}
    </Modal>
  );
};
export default RankingContent;
