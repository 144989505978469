import React from 'react';
import { Link } from 'react-router-dom';
import { Layout, Menu, Tooltip } from 'antd';
import { ROUTES } from '../../config';

const { SubMenu } = Menu;
const { Sider } = Layout;

const NavMenu = (props) => {
  const { isCollapsed, setCollapsed, user } = props;

  return (
    <Sider
      theme='dark'
      breakpoint='lg'
      onBreakpoint={(broken) => setCollapsed(broken)}
      trigger={null}
      collapsible
      collapsed={isCollapsed}
    >
      <Link to='/admin'>
        <Tooltip title='Homepage'>
          <h3 style={{ color: '#fff', margin: '17px', fontSize: 23 }}>Team Odeon</h3>
        </Tooltip>
      </Link>

      <Menu theme='dark' mode='inline' defaultSelectedKeys={[window.location.pathname]} inlineIndent={12}>
        {ROUTES.filter((r) => user && user.role && r.allowed.includes(user.role[0])).map((route) => (
          <SubMenu
            key={route.label.toLowerCase()}
            title={
              isCollapsed ? (
                <route.icon />
              ) : (
                <span>
                  <route.icon />
                  &nbsp;{route.label}
                </span>
              )
            }
          >
            {route.children
              .filter((item) => item.showInMenu && user && user.role && item.allowed.includes(user.role[0]))
              .map((item) => (
                <Menu.Item key={item.path}>
                  <Link to={item.path} title={item.label}>
                    {item.label}
                  </Link>
                </Menu.Item>
              ))}
          </SubMenu>
        ))}
      </Menu>
    </Sider>
  );
};
export default NavMenu;
