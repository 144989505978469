import React, { useState, useEffect, useContext } from 'react';
import { Spin, Button, Table, Tooltip, notification, Popconfirm, Tag, Input, Space } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { UserContext } from '../../../App';
import {
  PlusCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
  SyncOutlined,
  FilterFilled,
} from '@ant-design/icons';
import Axios from 'axios';
import { SERVER_URL } from '../../../config';

const renderSortByValue = (sortBy) => {
  let renderString = '';
  switch (sortBy) {
    case 'economicScore':
      renderString = 'Economic Score';
      break;
    case 'earnings':
      renderString = 'Earnings';
      break;
    case 'debt':
      renderString = 'Debt';
      break;
    case 'cost':
      renderString = 'Cost';
      break;
    case 'acc_rate_medical':
      renderString = 'Acceptance Rate';
      break;
  }

  return renderString;
};

const renderSortDirectionValue = (sortDirection) => {
  let renderDirection = '';
  switch (sortDirection) {
    case 'asc':
      renderDirection = 'Ascending';
      break;
    case 'desc':
      renderDirection = 'Descending';
      break;
  }

  return renderDirection;
};

const CustomTopLists = () => {
  const history = useHistory();
  const [working, setWorking] = useState(false);
  const [fetchTrigger, setFetchTrigger] = useState(false);
  const [data, setData] = useState([]);
  const currentUser = useContext(UserContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setWorking(true);
        const res = await Axios.get(`${SERVER_URL}/rankings-custom-top-lists`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentUser.data.token}` },
        });

        if (res && res.data) {
          setData(res.data);
        }

        setWorking(false);
      } catch (error) {
        setWorking(false);
        console.log(error.message);
        notification.error({
          message: 'Error',
          description: 'Fetching all custom top lists failed!',
          placement: 'bottomRight',
        });
      }
    };

    fetchData();
  }, [fetchTrigger, currentUser]);

  let searchInput;
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size='small'
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />,
    onFilter: (value, record) => {
      if (dataIndex === 'cipcode' && !record[dataIndex]) {
        return record.cipcodes ? record.cipcodes.some((cip) => cip.includes(value)) : '';
      }
      return record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '';
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const deleteHandler = async (id) => {
    try {
      setWorking(true);

      await Axios.delete(`${SERVER_URL}/rankings-custom-top-lists/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentUser.data.token}` },
      });

      setWorking(false);
      setFetchTrigger(!fetchTrigger);

      notification.success({
        message: 'Success',
        description: 'Top list removed',
        placement: 'bottomRight',
      });
    } catch (error) {
      setWorking(false);
      console.log(error.response.data.message);
      notification.error({
        message: 'Error',
        description: error.response.data.message,
        placement: 'bottomRight',
      });
    }
  };

  const columns = [
    {
      title: 'TITLE',
      dataIndex: ['title'],
      key: 'title',
      ...getColumnSearchProps('title'),
    },
    {
      title: 'CIPCODE',
      dataIndex: ['cipcode'],
      key: 'cipcode',
      ...getColumnSearchProps('cipcode'),
      render: (text, record) => {
        if (record.multipleCips) {
          return record.cipcodes.map((cip) => <Tag color='blue'>{cip}</Tag>);
        } else if (record.cipcode) {
          return <Tag color='blue'>{record.cipcode}</Tag>;
        } else return null;
      },
    },
    {
      title: 'DEGREE',
      dataIndex: ['degree'],
      key: 'degree',
      ...getColumnSearchProps('degree'),
    },
    {
      title: 'SORTED BY',
      dataIndex: ['sortBy'],
      key: 'sortBy',
      render: (text) => renderSortByValue(text),
    },
    {
      title: 'SORT ORDER',
      dataIndex: ['sortDirection'],
      key: 'sortDirection',
      render: (text) => renderSortDirectionValue(text),
    },
    {
      title: 'NUMBER OF SCHOOLS',
      dataIndex: ['itemsSize'],
      key: 'itemsSize',
    },
    {
      title: 'ONLINE',
      dataIndex: ['online'],
      key: 'online',
      filters: [
        {
          text: 'Online',
          value: 'Online',
        },
      ],
      onFilter: (value, record) => record.online,
      filterIcon: (filtered) => <FilterFilled style={{ color: filtered ? 'red' : 'white', fontSize: '1rem' }} />,
      render: (text, record) => (record.online ? 'Yes' : 'No'),
    },
  ];

  columns.push({
    title: 'Action',
    render: (text, record) => {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '15px',
          }}
        >
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='table-actions'>
            <Tooltip title='Edit top list'>
              <EditOutlined onClick={() => history.push(`/admin/rankings/custom-top-lists/edit/${record._id}`)} />
            </Tooltip>
          </div>

          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='table-actions'>
            <Tooltip title='Remove top list'>
              <Popconfirm
                title='Are you sure you want to delete this top list?'
                onConfirm={async () => deleteHandler(record._id)}
                onCancel={() => console.log('Cancel')}
                okText='Yes'
                cancelText='No'
              >
                <DeleteOutlined />
              </Popconfirm>
            </Tooltip>
          </div>
        </div>
      );
    },
  });

  const handleRefresh = async () => {
    try {
      setWorking(true);
      let cipcodes = null;

      for (const list of data) {
        let urlQuery = list.sortBy ? `?sortBy=${list.sortBy}` : `?sortBy=economicScore`;
        if (list.degree && list.cipcode) {
          urlQuery += `&degree=${list.degree}&cipcode=${list.cipcode}`;
        }
        if (list.degree && list.cipcodes && list.cipcodes.length > 0 && list.multipleCips) {
          urlQuery += `&degree=${list.degree}`;
          cipcodes = list.cipcodes;
        } else {
          cipcodes = null;
        }

        // fetch the data
        const result = await Axios.post(
          `${SERVER_URL}/rankings-custom-top-lists${urlQuery}`,
          { unitids: list.items.map((item) => item.unitid), cipcodes },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentUser.data.token}` },
          },
        );

        // check for errors
        if (!result || !result.data || result.data.length === 0) {
          setWorking(false);
          notification.error({
            message: 'Error',
            description: `Problem with refreshing toplist (${list.title})`,
            placement: 'bottomRight',
          });
          return;
        }

        // don't override description
        result.data.forEach((item) => {
          const foundItem = list.items.find((el) => el.unitid === item.unitid);
          if (foundItem) {
            item.description = foundItem.description;
          }
        });

        // save
        await Axios.patch(
          `${SERVER_URL}/rankings-custom-top-lists/${list._id}`,
          { items: result.data },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentUser.data.token}` },
          },
        );
      }

      setWorking(false);
      notification.success({
        message: 'Success',
        description: 'All toplists refreshed',
        placement: 'bottomRight',
      });
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Error',
        description: 'Problem with refreshing toplist data',
        placement: 'bottomRight',
      });
    }
  };

  return (
    <div className='content-wrapper'>
      <Spin spinning={working} tip='Working...'>
        <div className='actions-block flex'>
          <Link to={`/admin/rankings/custom-top-lists/new`}>
            <Tooltip title='Create new custom top list'>
              <Button type='primary' style={{ marginRight: '8px', float: 'left' }}>
                <PlusCircleOutlined />
                NEW CUSTOM TOP LIST
              </Button>
            </Tooltip>
          </Link>

          <Tooltip title='Refresh toplist data'>
            <Button type='danger' onClick={handleRefresh}>
              <SyncOutlined spin={working} />
              REFRESH ALL
            </Button>
          </Tooltip>
        </div>

        <div>
          <Table
            size='middle'
            // onChange={onChangeTable}
            bordered
            dataSource={data}
            columns={columns}
            rowKey={(record) => record._id}
            // rowSelection={rowSelection}
            pagination={{
              pageSize: 20,
              // total: total || 0,
              // current: page,
              // defaultCurrent: 1,
              // position: 'bottom',
              // showSizeChanger: false,
              // onChange: (page) => pageHandler(page),
              // hideOnSinglePage: true,
            }}
          />
        </div>
      </Spin>
    </div>
  );
};

export default CustomTopLists;
