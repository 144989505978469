import React, { useState, useEffect, useContext, useRef } from 'react';
import { Tabs, notification, Spin, Tooltip, Modal, Input, Button } from 'antd';
import { SearchOutlined, DeleteOutlined, ExclamationCircleOutlined, EditOutlined } from '@ant-design/icons';
import OnlineList from './OnlineList';
import { UserContext } from '../../../App';
import { useHistory } from 'react-router-dom';
import Axios from 'axios';
import { SERVER_URL } from '../../../config';
import Highlighter from 'react-highlight-words';
import ONLINE from '../../../lib/online.json';

const { TabPane } = Tabs;

const OnlineRankings = () => {
  const history = useHistory();

  const degreeQuery =
    history.location.search && history.location.search.includes('?tab=')
      ? history.location.search.split('=')[1]
      : 'BACHELORS';

  const currentuser = useContext(UserContext);
  const [data, setData] = useState([]);
  const [tab, setTab] = useState(degreeQuery);
  const [working, setWorking] = useState(false);
  const [fetchTrigger, setFetchTrigger] = useState(false);
  const [selectedForRefresh, setSelectedForRefresh] = useState([]);
  const [filter, setFilter] = useState(null);
  const [search, setSearch] = useState({});
  const [modal, setModal] = useState({
    visible: false,
    category: '',
    title: '',
    content: '',
    metaTitle: '',
    metaDescription: '',
    bobbleDescription: '',
    metaImage: '',
    metaUrl: '',
    listName: '',
    bubbles: [],
    degree: null,
    spotlight: null,
    shortcode: null,
  });
  const [content, setContent] = useState('');
  const [refresh, setRefresh] = useState(null);
  const [bubbles, setBubbles] = useState([]);
  const [allSpotlights, setAllSpotlights] = useState([]);

  const titleInput = useRef();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setWorking(true);
        const degree = tab === 'BACHELORS' ? 'Bachelors Degree' : "Master's Degree";

        let urlQuery = `?degree=${degree}`;
        if (filter) urlQuery += `&filter=${filter}`;

        const resOnline = await Axios.get(`${SERVER_URL}/rankings-online${urlQuery}`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });

        if (resOnline) {
          setData(resOnline.data);
        }

        // fetch spotlights
        const spotlightRes = await Axios.get(`${SERVER_URL}/spotlight`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });

        if (spotlightRes) {
          setAllSpotlights(spotlightRes.data);
        }

        setWorking(false);
      } catch (error) {
        setWorking(false);
        console.log(error);
        notification.error({
          placement: 'bottomRight',
          description: 'Error on fetching online rankings',
          message: 'Error',
        });
      }
    };

    fetchData();
  }, [tab, fetchTrigger, filter, currentuser.data.token]);

  // Coulmn search
  let searchInput;
  const getColumnSearchProps = (dataIndex, name) => {
    if (Array.isArray(dataIndex)) dataIndex = dataIndex.join('.');
    const prop = dataIndex;
    return {
      filteredValue: (filter && filter[prop] && [filter[prop]]) || null,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              ref={(node) => {
                searchInput = node;
              }}
              placeholder={`Search ${prop}`}
              value={selectedKeys && selectedKeys.length > 0 ? selectedKeys : [search[prop]]}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, prop)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />

            <Button
              type='primary'
              onClick={() => handleSearch(selectedKeys, confirm, prop)}
              size='small'
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>

            <Button onClick={() => handleReset(clearFilters, prop)} size='small' style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        );
      },
      filterIcon: (filtered) => {
        if (search && search[prop]) {
          filtered = true;
        } else {
          filtered = false;
        }
        return <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />;
      },
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => {
            searchInput.select();
          });
        }
      },
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (text) =>
        search[prop] ? (
          <Highlighter
            title={text}
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[search[prop]]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          <span title={text}>{text}</span>
        ),
    };
  };

  const filterHandler = (prop, value) => {
    if (Array.isArray(prop)) prop = prop.join('.');

    let filterQuery = null;
    let prevFilter = {};
    if (filter) {
      prevFilter = JSON.parse(filter);
    }

    if (value !== null) {
      prevFilter[prop] = value;
      filterQuery = `${JSON.stringify({ [prop]: !isNaN(value) ? +value : [value.toString()], ...prevFilter })}`;
    } else {
      delete prevFilter[prop];
      filterQuery = `${JSON.stringify({ ...prevFilter })}`;
    }

    // HTML URL encoding => solves error: `Unexpected end of JSON input`
    if (filterQuery.includes('&')) filterQuery = filterQuery.replace('&', '%26');

    setFilter(filterQuery);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    if (Array.isArray(dataIndex)) dataIndex = dataIndex.join('.');
    const newSearch = { ...search };
    newSearch[dataIndex] = selectedKeys[0] ? selectedKeys[0] : search[dataIndex];
    setSearch(newSearch);
    filterHandler(dataIndex, selectedKeys[0] ? selectedKeys[0] : search[dataIndex]);
    confirm();
  };

  const handleReset = (clearFilters, prop) => {
    if (Array.isArray(prop)) prop = prop.join('.');
    const newSearch = { ...search };
    setSearch(newSearch);
    delete newSearch[prop];
    filterHandler(prop, null);
    clearFilters();
  };

  const columns = [
    {
      title: 'CIPCODE',
      dataIndex: ['cipcode'],
      key: 'cipcode',
      ...getColumnSearchProps(['cipcode']),
    },
    {
      title: 'CATEGORY',
      dataIndex: ['category'],
      key: 'category',
      ...getColumnSearchProps(['category']),
      render: (text, record) => (!record.parent ? text : record.parent.category),
    },
    {
      title: 'ADDITIONAL',
      dataIndex: 'category',
      key: 'category-additional',
      render: (text, record) => (!record.parent ? null : text),
      // ...getColumnSearchProps('name'),
    },
    {
      title: 'TOTAL SCHOOLS',
      dataIndex: 'items',
      key: 'items',
      align: 'right',
      render: (text, record) => record.items ?? 0,
      // ...getColumnSearchProps(['createdBy', 'email']),
    },
    {
      title: 'CREATED AT',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'right',
      // ...getColumnSearchProps('name'),
    },
    {
      title: 'UPDATED AT',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      align: 'right',
      // ...getColumnSearchProps('name'),
    },
  ];

  columns.push(
    {
      title: 'Content',
      render: (text, record) => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '15px',
            }}
          >
            <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
              <Tooltip title='Edit Content' placement='topLeft'>
                <EditOutlined style={{ textDecoration: 'none', color: 'black' }} onClick={() => modalHandler(record)} />
              </Tooltip>
            </div>
          </div>
        );
      },
    },
    {
      title: 'Action',
      render: (text, record) => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '15px',
            }}
          >
            {record.category !== 'Search' && (
              <Tooltip title='View Online Ranking' placement='topLeft'>
                <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='table-actions'>
                  <EditOutlined
                    onClick={() =>
                      history.push(
                        `/admin/rankings/${tab === 'BACHELORS' ? 'undergraduate' : 'graduate'}/${
                          record._id
                        }?online=${tab}`,
                      )
                    }
                  />
                </div>
              </Tooltip>
            )}

            {deleteHandler && (
              <Tooltip title='Delete Raking' placement='topLeft'>
                {record.category !== 'Search' && (
                  <div
                    style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}
                    className='table-actions'
                    onClick={async () => {
                      deleteHandler(record);
                    }}
                  >
                    <DeleteOutlined />
                  </div>
                )}
              </Tooltip>
            )}
          </div>
        );
      },
    },
  );

  const deleteHandler = async (record) => {
    Modal.confirm({
      width: 700,
      icon: <ExclamationCircleOutlined style={{ display: 'block', fontSize: '2rem' }} />,
      content: (
        <div style={{ marginLeft: '3.5rem' }}>
          {record.parent ? (
            'Are you sure you want to delete this online ranking?'
          ) : (
            <span>
              Are you sure you want to delete this online ranking? This will {<b>delete all additional rankings</b>}{' '}
              related to this main ranking.{' '}
            </span>
          )}
        </div>
      ),
      async onOk() {
        await Axios.delete(`${SERVER_URL}/rankings-online/${record._id}`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });

        notification.success({
          message: 'Online ranking is deleted',
          placement: 'bottomRight',
        });

        setFetchTrigger(!fetchTrigger);
        Modal.destroyAll();
      },
      onCancel() {
        Modal.destroyAll();
      },
    });
  };

  const modalHandler = (record) => {
    setModal({
      visible: true,
      id: record._id,
      category: record.category,
      title: record.content ? record.content.title : '',
      listName: record.content ? record.content.listName : '',
      metaTitle: record.content ? record.content.metaTitle : '',
      metaDescription: record.content ? record.content.metaDescription : '',
      bobbleDescription: record.content ? record.content.bobbleDescription : '',
      metaImage: record.content ? record.content.metaImage : '',
      metaUrl: record.content ? record.content.metaUrl : '',
      degree: record.degree,
      spotlight: record.spotlight,
      shortcode: record.shortcode,
    });
    setContent(record.content && record.content.content ? record.content.content : '');
    if (record.content && record.content.bubbles && record.content.bubbles.length > 0) {
      setBubbles(record.content.bubbles);
    } else setBubbles([]);
  };

  let tableData = [];
  if (data && data?.length > 0) {
    tableData = data.map((item) => {
      item.createdAt = new Date(item.createdAt).toLocaleString('en-US');
      item.updatedAt = new Date(item.updatedAt).toLocaleString('en-US');
      return item;
    });
  }

  const onOk = async () => {
    if (!modal.title || modal.title === '') {
      titleInput.current.focus();
      notification.error({
        message: '<h1> title is required.',
        placement: 'topRight',
        duration: 2,
      });
      return;
    }

    try {
      setWorking(true);
      const body = {
        title: modal.title,
        content,
        listName: modal.listName,
        metaTitle: modal.metaTitle,
        metaDescription: modal.metaDescription,
        bobbleDescription: modal.bobbleDescription,
        metaUrl: modal.metaUrl,
        metaImage: modal.metaImage,
        bubbles: bubbles,
        spotlight: modal.spotlight,
        shortcode: modal.shortcode,
      };

      await Axios.patch(`${SERVER_URL}/rankings-programs/${modal.id}?online=true`, body, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      setWorking(false);

      setFetchTrigger(!fetchTrigger);
      setRefresh(Math.random());
      setModal({ visible: false });
      notification.success({
        message: 'Content (online) saved',
        placement: 'bottomRight',
        duration: 2,
      });
    } catch (error) {
      console.log('Error on save content', error);
      setWorking(false);
      setModal({ visible: false });
      notification.error({
        message: 'Error',
        description: error.response.data.msg,
        placement: 'bottomRight',
        duration: 2,
      });
    }
  };

  const handleRefresh = async () => {
    if (!selectedForRefresh || selectedForRefresh.length === 0) return;

    const degree = tab === 'BACHELORS' ? 'Bachelors Degree' : "Master's Degree";
    const type = tab === 'BACHELORS' ? 'undergrad' : 'grad';

    setWorking(true);

    try {
      for (const selectedID of selectedForRefresh) {
        const foundRanking = data.find((item) => item._id === selectedID);
        let newMethodRes;

        // Institution level
        if (foundRanking && !foundRanking.isProgramLevel) {
          if (!foundRanking.parent) {
            newMethodRes = await Axios.get(
              `${SERVER_URL}/rankings-new-methodology-creation?category=${foundRanking.categoryUrl}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${currentuser.data.token}` },
              },
            );
          } else {
            newMethodRes = await Axios.get(
              `${SERVER_URL}/rankings-new-methodology-creation?category=${foundRanking.parent.categoryUrl}&additional=${foundRanking.categoryUrl}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${currentuser.data.token}` },
              },
            );
          }
          // Program level
        } else if (foundRanking && foundRanking.isProgramLevel) {
          newMethodRes = await Axios.get(
            `${SERVER_URL}/rankings-new-methodology-creation?degree=${degree}&cipcode=${foundRanking.cipcode}&isProgramLevel=true&online=true`,
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${currentuser.data.token}` },
            },
          );
        } else {
          setWorking(false);
          notification.error({
            message: `Problem with refreshing online rankings`,
            placement: 'bottomRight',
          });
          return;
        }

        if (!newMethodRes) {
          setWorking(false);
          notification.error({
            message: `Problem with refreshing online rankings`,
            placement: 'bottomRight',
          });
          return;
        }

        await Axios.patch(
          `${SERVER_URL}/rankings-universal/${selectedID}?online=true`,
          {
            items: newMethodRes.data.ranking.rankingItems,
          },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` },
          },
        );

        // replace school links in rankings
        await Axios.get(`${SERVER_URL}/rankings-update-sp-link?id=${selectedID}&online=true`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
      }

      // sitemap
      await Axios.get(`${SERVER_URL}/rankings-sitemap-refresh?type=${type}&online=true`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: `${tab === 'BACHELORS' ? 'Bachelors' : "Master's"} online rankings refreshed`,
        placement: 'bottomRight',
      });

      setFetchTrigger(!fetchTrigger);

      setWorking(false);
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: `Problem with refreshing ${
          tab === 'BACHELORS' ? 'bachelors' : "master's"
        } online rankings. Please try later.`,
        placement: 'bottomRight',
      });
    }
  };

  const onSelectChange = (selectedRowKeys) => {
    setSelectedForRefresh([...selectedRowKeys]);
  };

  const rowSelection = {
    selectedForRefresh,
    onChange: onSelectChange,
  };

  const handleSitemap = async () => {
    const type = tab === 'BACHELORS' ? 'undergrad' : 'grad';

    try {
      setWorking(true);

      // sitemap
      await Axios.get(`${SERVER_URL}/rankings-sitemap-refresh?type=${type}&online=true`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: 'Online Sitemap updated',
        placement: 'bottomRight',
      });

      setWorking(false);
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Problem with refreshing Online sitemap',
        placement: 'bottomRight',
      });
    }
  };

  const handleSPLinks = async () => {
    const degree = tab === 'MASTERS' ? "Master's Degree" : 'Bachelors Degree';

    try {
      setWorking(true);

      // replace school links in rankings
      await Axios.get(`${SERVER_URL}/rankings-update-sp-link?online=true&degree=${degree}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: 'Online SP links updated',
        placement: 'bottomRight',
      });

      setWorking(false);
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Problem with refreshing Online school profile links',
        placement: 'bottomRight',
      });
    }
  };

  const handleRefreshAll = async () => {
    try {
      setWorking(true);

      const type = tab === 'BACHELORS' ? 'undergrad' : 'grad';
      const degree = tab === 'MASTERS' ? "Master's Degree" : 'Bachelors Degree';

      // STEP 1: refresh institution level
      if (tab === 'BACHELORS') {
        await Axios.patch(
          `${SERVER_URL}/rankings?type=undergrad&online=true`,
          {},
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` },
          },
        );
      }

      // STEP 2: refresh program level
      const cipcodesBody = data.map((item) => ({
        cipcode: item.cipcode,
        category: item.category,
        categoryUrl: item.categoryUrl,
        degree: item.degree,
        parent: item.parent,
      }));

      await Axios.patch(
        `${SERVER_URL}/rankings?type=${type}&isProgramLevel=true&online=true`,
        { cipcodes: cipcodesBody },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );

      // STEP 3: refresh sitemap and empty pages
      await Axios.get(`${SERVER_URL}/rankings-sitemap-refresh?type=${type}&online=true`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      // STEP 4: replace school links in rankings
      await Axios.get(`${SERVER_URL}/rankings-update-sp-link?online=true&degree=${degree}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      setWorking(false);
      setFetchTrigger(!fetchTrigger);

      notification.success({
        message: 'Online rankings saved',
        placement: 'bottomRight',
      });
    } catch (error) {
      setWorking(false);
      console.log(error.response);
      notification.error({
        message: 'Problem with refreshing online rankings. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  return (
    <div className='content-wrapper'>
      <Spin spinning={working} tip='Working...'>
        <Tabs
          defaultActiveKey={degreeQuery}
          onChange={(value) => {
            setTab(value);
            history.push(`/admin/rankings/online?tab=${value}`);
            setSelectedForRefresh([]);
          }}
        >
          <TabPane tab='BACHELORS' key='BACHELORS'>
            <OnlineList
              tab='BACHELORS'
              tableData={tableData}
              columns={columns}
              handleRefresh={handleRefresh}
              handleRefreshAll={handleRefreshAll}
              rowSelection={rowSelection}
              selectedForRefresh={selectedForRefresh}
              modal={modal}
              setModal={setModal}
              bubbles={bubbles}
              setBubbles={setBubbles}
              content={content}
              setContent={setContent}
              working={working}
              fetchTrigger={fetchTrigger}
              setFetchTrigger={setFetchTrigger}
              onOk={onOk}
              titleInput={titleInput}
              allSpotlights={allSpotlights}
              ONLINE={ONLINE}
              handleSitemap={handleSitemap}
              handleSPLinks={handleSPLinks}
              refresh={refresh}
            />
          </TabPane>

          <TabPane tab='MASTERS' key='MASTERS'>
            <OnlineList
              tab='MASTERS'
              tableData={tableData}
              columns={columns}
              handleRefresh={handleRefresh}
              handleRefreshAll={handleRefreshAll}
              rowSelection={rowSelection}
              selectedForRefresh={selectedForRefresh}
              modal={modal}
              setModal={setModal}
              bubbles={bubbles}
              setBubbles={setBubbles}
              content={content}
              setContent={setContent}
              working={working}
              fetchTrigger={fetchTrigger}
              setFetchTrigger={setFetchTrigger}
              onOk={onOk}
              titleInput={titleInput}
              allSpotlights={allSpotlights}
              ONLINE={ONLINE}
              handleSitemap={handleSitemap}
              handleSPLinks={handleSPLinks}
              refresh={refresh}
            />
          </TabPane>
        </Tabs>
      </Spin>
    </div>
  );
};

export default OnlineRankings;
