import React, { useState, useEffect, useContext } from 'react';
import Axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import { notification, Button, Layout } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { UserForm } from '../../components/forms';

const EditUser = (props) => {
  const currentuser = useContext(UserContext);
  const history = useHistory();
  const [result, setResult] = useState(false);
  const [user, fetchUser] = useAxios('', null, currentuser.data.token, 'get');
  const [avatar, fetchAvatar] = useAxios('', null, currentuser.data.token, 'get');
  const { userId, imgId } = props.match.params;

  useEffect(() => {
    if (imgId) {
      fetchAvatar(`${SERVER_URL}/images/${imgId}`, {});
    }
    if (userId && !['admin'].includes(currentuser.data.role[0]) && userId !== currentuser.data.id) {
      history.push('/admin');
    } else if (userId) {
      fetchUser(`${SERVER_URL}/users/${userId}`, []);
    }
  }, [userId, imgId, fetchUser, currentuser, fetchAvatar, history]);

  const updateUser = async (data) => {
    try {
      await Axios.put(
        `${SERVER_URL}/users/${userId}`,
        { ...data },
        { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
      );
      notification.success({
        message: 'Item is updated.',
        placement: 'bottomRight',
      });
    } catch (err) {
      notification.error({
        message: 'Problem with update. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  const createUser = async (data) => {
    try {
      await Axios.post(`${SERVER_URL}/register`, { ...data });
      notification.success({
        message: 'User created.',
        placement: 'bottomRight',
      });
      setResult(true);
    } catch (err) {
      notification.error({
        message:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Problem with creating user. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  return (
    <div className='content-wrapper'>
      {user && user.data && ['admin'].includes(user.data.role[0]) && (
        <div className='actions-block'>
          <Link to='/admin/users'>
            <Button type='primary'>All users</Button>
          </Link>
        </div>
      )}

      <div className='content'>
        {userId && user.isLoading && (
          <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem', marginLeft: '45%' }} />
        )}
        {userId && !user.isLoading && user.isError && <h2 style={{ marginTop: '5rem' }}>Something went wrong :(</h2>}
        <Layout className='site-layout-background'>
          {userId && !user.isLoading && !user.isError && user.data && user.data.email && !imgId && (
            <UserForm
              token={currentuser.data.token}
              data={user.data}
              updateHandler={updateUser}
              loggedInUserId={currentuser.data.id}
              userId={userId}
              SERVER_URL={SERVER_URL}
            />
          )}

          {userId && !user.isLoading && !user.isError && user.data && user.data.email && imgId && avatar.data && (
            <UserForm
              token={currentuser.data.token}
              avatar={avatar.data}
              data={user.data}
              updateHandler={updateUser}
              loggedInUserId={currentuser.data.id}
              userId={userId}
              SERVER_URL={SERVER_URL}
            />
          )}

          {!userId && user && !user.data && !imgId && (
            <UserForm
              data={null}
              createHandler={createUser}
              token={currentuser.data.token}
              result={result}
              setResult={setResult}
              SERVER_URL={SERVER_URL}
            />
          )}

          {!userId && imgId && avatar.data && (
            <UserForm
              data={null}
              avatar={avatar.data}
              createHandler={createUser}
              token={currentuser.data.token}
              result={result}
              setResult={setResult}
              SERVER_URL={SERVER_URL}
            />
          )}
        </Layout>
      </div>
    </div>
  );
};

export default EditUser;
