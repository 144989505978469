import React from 'react';

const GradRates = ({
  totalGradRate,
  totalGradRatePell,
  gradRateOM,
  gradRatePellOM,
  gradRateNoPellOM,
  studentOMPercentages,
  act,
  actPell,
  pellPerformanceGap,
  adjustedPellGradRate,
  gr100,
  gr150,
  gr200,
}) => {
  return (
    <div className='panel-body'>
      <div style={{ display: 'flex' }}>
        <div style={{ marginRight: '50px' }}>
          <p>
            <b>Total grad rate:</b> {totalGradRate ? totalGradRate.toFixed(2) + '%' : 'N/A'}
          </p>

          <p>
            <b>Grad rate 4 years:</b>{' '}
            {gradRateOM && gradRateOM.gradRate4Yrs ? gradRateOM.gradRate4Yrs.toFixed(2) + '%' : 'N/A'}
          </p>
          <p>
            <b>Grad rate 6 years:</b>{' '}
            {gradRateOM && gradRateOM.gradRate6Yrs ? gradRateOM.gradRate6Yrs.toFixed(2) + '%' : 'N/A'}
          </p>
          <p>
            <b>Grad rate 8 years:</b>{' '}
            {gradRateOM && gradRateOM.gradRate8Yrs ? gradRateOM.gradRate8Yrs.toFixed(2) + '%' : 'N/A'}
          </p>

          <p>
            <b>Average completion time:</b> {act ? act.toFixed(3) : 'N/A'}
          </p>
        </div>

        <div style={{ marginRight: '50px' }}>
          <p>
            <b>Total pell grad rate:</b> {totalGradRatePell ? totalGradRatePell.toFixed(2) + '%' : 'N/A'}
          </p>

          <p>
            <b>Pell grad rate 4 years:</b>{' '}
            {gradRatePellOM && gradRatePellOM.gradRate4YrsPell
              ? gradRatePellOM.gradRate4YrsPell.toFixed(2) + '%'
              : 'N/A'}
          </p>
          <p>
            <b>Pell grad rate 6 years:</b>{' '}
            {gradRatePellOM && gradRatePellOM.gradRate6YrsPell
              ? gradRatePellOM.gradRate6YrsPell.toFixed(2) + '%'
              : 'N/A'}
          </p>
          <p>
            <b>Pell grad rate 8 years:</b>{' '}
            {gradRatePellOM && gradRatePellOM.gradRate8YrsPell
              ? gradRatePellOM.gradRate8YrsPell.toFixed(2) + '%'
              : 'N/A'}
          </p>

          <p>
            <b>Pell average completion time:</b> {actPell ? actPell.toFixed(3) : 'N/A'}
          </p>

          <p>
            <b>Pell performance gap:</b> {pellPerformanceGap ? pellPerformanceGap.toFixed(2) : 'N/A'}
          </p>

          <p>
            <b>Adjusted pell grad rate:</b> {adjustedPellGradRate ? adjustedPellGradRate.toFixed(2) + '%' : 'N/A'}
          </p>

          <p>
            <b>Total no pell grad rate:</b>{' '}
            {gradRateNoPellOM && gradRateNoPellOM.totalGradRateNoPell
              ? gradRateNoPellOM.totalGradRateNoPell.toFixed(2) + '%'
              : 'N/A'}
          </p>
        </div>

        <div style={{ marginRight: '50px' }}>
          <p>
            <b>Degree/certificate within 100% of normal time:</b> {gr100 ? gr100 + '%' : 'N/A'}
          </p>

          <p>
            <b>Degree/certificate within 150% of normal time:</b> {gr150 ? gr150 + '%' : 'N/A'}
          </p>

          <p>
            <b>Degree/certificate within 200% of normal time:</b> {gr200 ? gr200 + '%' : 'N/A'}
          </p>
        </div>

        <div style={{ marginRight: '50px' }}>
          <p>
            <b>Full time student:</b>{' '}
            {studentOMPercentages && studentOMPercentages.fullTimeStudent
              ? studentOMPercentages.fullTimeStudent.toFixed(2) + '%'
              : 'N/A'}
          </p>
          <p>
            <b>Part time student:</b>{' '}
            {studentOMPercentages && studentOMPercentages.partTimeStudent
              ? studentOMPercentages.partTimeStudent.toFixed(2) + '%'
              : 'N/A'}
          </p>
          <p>
            <b>First time student:</b>{' '}
            {studentOMPercentages && studentOMPercentages.firstTimeStudent
              ? studentOMPercentages.firstTimeStudent.toFixed(2) + '%'
              : 'N/A'}
          </p>
          <p>
            <b>Non first time student:</b>{' '}
            {studentOMPercentages && studentOMPercentages.nonFirstTimeStudent
              ? studentOMPercentages.nonFirstTimeStudent.toFixed(2) + '%'
              : 'N/A'}
          </p>
        </div>
      </div>
    </div>
  );
};

export default GradRates;
