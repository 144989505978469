import React, { useState, useEffect, useContext, useRef } from 'react';
import { Form, notification, Spin, Input, Button, Collapse, Image, Divider, Select, Table, Switch } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';
import { useHistory } from 'react-router-dom';

import Editor from '../../components/base/autocomplete/Editor';
import CollapseImageTooltip from '../../components/base/CollapseImageTooltip';
import BubblesList from './BubblesList';

// images
import h1AndDesc from '../../images for tooltips/ranking/h1 and description.png';
import section1Img from '../../images for tooltips/ranking/section 1.png';
import section2Img from '../../images for tooltips/ranking/section 2.png';
import section3Img from '../../images for tooltips/ranking/section 3.png';
import section4Img from '../../images for tooltips/ranking/section 4.png';
import section5Img from '../../images for tooltips/ranking/section 5.png';
import section6Img from '../../images for tooltips/ranking/section 6.png';
import section7Img from '../../images for tooltips/ranking/section 7.png';
import bubblesImg from '../../images for tooltips/ranking/bubbles.png';
import schoolListImg from '../../images for tooltips/ranking/school list title.png';
import statesMapImg from '../../images for tooltips/ranking/states map title.png';
import shortcodeImg from '../../images for tooltips/ranking/shortcode2.png';

const { TextArea } = Input;

const initialForm = {
  relatedCareers: [],
  useNewTemplate: false,
};

function extractContentInsideHashes(inputString) {
  const regex = /#(?!\s)(\S*?)#/g;
  const matches = [];
  let match;

  while ((match = regex.exec(inputString)) !== null) {
    matches.push(match[1]);
  }

  return matches;
}

const extractCipContent = (inputString) => {
  const regexCip = /#\[(.*?)\]#/g;
  const matches = [];
  let match;

  while ((match = regexCip.exec(inputString)) !== null) {
    matches.push(match[1]);
  }

  return matches;
};

const SingleRankingEditPage = (props) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const currentuser = useContext(UserContext);
  const { id } = props.match.params;
  const h1Ref = useRef();

  const [working, setWorking] = useState(false);
  const [data, setData] = useState(null);
  const [suggestionList, setSuggestionList] = useState([]);
  const [allCareers, setAllCareers] = useState([]);
  const [earningsData, setEarningsData] = useState([]);
  const [earningsDataByState, setEarningsDataByState] = useState([]);
  const [onlineRankings, setOnlineRankings] = useState([]);
  const [bubbles, setBubbles] = useState([]);
  const [refreshBubbles, setRefreshBubbles] = useState(null);
  const [wpLink, setWpLink] = useState(null);
  const [showAdditional, setShowAdditional] = useState(false);

  // dynamic content states
  const [contentDesc, setContentDesc] = useState('');
  const [section1Text, setSection1Text] = useState('');
  const [section2Text, setSection2Text] = useState('');
  const [section3Text, setSection3Text] = useState('');
  const [section4Text, setSection4Text] = useState('');
  const [section5Text, setSection5Text] = useState('');
  const [section6Text, setSection6Text] = useState('');
  const [section7Text, setSection7Text] = useState('');

  // H1s
  const [h1, setH1] = useState(null);
  const [section1H1, setSection1H1] = useState(null);
  const [section2H1, setSection2H1] = useState(null);
  const [section3H1, setSection3H1] = useState(null);
  const [section4H1, setSection4H1] = useState(null);
  const [section5H1, setSection5H1] = useState(null);
  const [section6H1, setSection6H1] = useState(null);
  const [section7H1, setSection7H1] = useState(null);
  const [metaTitle, setMetaTitle] = useState(null);
  const [metaDescription, setMetaDescription] = useState(null);
  const [metaImage, setMetaImage] = useState(null);
  const [onlineBachLabel, setOnlineBachLabel] = useState(null);
  const [onlineMasterLabel, setOnlineMasterLabel] = useState(null);
  const [schoolListH1, setSchoolListH1] = useState(null);
  const [statesMapH1, setStatesMapH1] = useState(null);
  const [shortcode, setShortcode] = useState(null);

  useEffect(() => {
    const fetchRanking = async () => {
      try {
        setWorking(true);

        // ranking content data
        const resData = await Axios.get(`${SERVER_URL}/rankings-universal-content/${id}`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
        if (resData && resData.data) {
          setData(resData.data);
          setWpLink(resData.data.link);
          setShowAdditional(resData.data.showAdditional);
          const content = resData.data?.contentNewDesign;

          // default auto pull related careers from cip/soc file
          const resCipSoc = await Axios.get(`${SERVER_URL}/cip-soc-get-careers/${resData.data.cipcode}`, {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` },
          });
          const careersFromCipSoc = resCipSoc && resCipSoc.data ? resCipSoc.data.map((item) => item._id) : [];

          form.setFieldsValue({
            relatedCareers: resData.data?.relatedCareers || careersFromCipSoc,
            useNewTemplate: resData.data?.useNewTemplate,
          });

          // set content
          setContentDesc(content?.h1AndDesc?.text || '');
          setSection1Text(content?.section1?.text || '');
          setSection2Text(content?.section2?.text || '');
          setSection3Text(content?.section3?.text || '');
          setSection4Text(content?.section4?.text || '');
          setSection5Text(content?.section5?.text || '');
          setSection6Text(content?.section6?.text || '');
          setSection7Text(content?.section7?.text || '');

          // set H1s and meta
          setH1(content?.h1AndDesc?.h1);
          setSection1H1(content?.section1?.h1);
          setSection2H1(content?.section2?.h1);
          setSection3H1(content?.section3?.h1);
          setSection4H1(content?.section4?.h1);
          setSection5H1(content?.section5?.h1);
          setSection6H1(content?.section6?.h1);
          setSection7H1(content?.section7?.h1);
          setMetaTitle(content?.meta?.metaTitle);
          setMetaDescription(content?.meta?.metaDescription);
          setMetaImage(content?.meta?.metaImage);
          setOnlineBachLabel(content?.section5?.onlineBachLabel);
          setOnlineMasterLabel(content?.section5?.onlineMasterLabel);
          setSchoolListH1(content?.schoolListH1);
          setStatesMapH1(content?.statesMapH1);
          setShortcode(content?.shortcodeSection?.shortcode);

          // set bubbles
          setBubbles(content?.bubbles ? content.bubbles : []);
        }

        // all careers
        const careerRes = await Axios.get(`${SERVER_URL}/careers-ranking-dropdown`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
        if (careerRes && careerRes.data) {
          setAllCareers(careerRes.data);
        }

        if (resData && resData.data) {
          // earnings data by all degrees
          const salaryResAllDegrees = await Axios.get(
            `${SERVER_URL}/rankings-program-earnings-degrees/${resData.data.cipcode}`,
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${currentuser.data.token}` },
            },
          );
          if (salaryResAllDegrees && salaryResAllDegrees.data) {
            setEarningsData(salaryResAllDegrees.data);
          }

          // earnings data by state
          const salaryResByState = await Axios.get(
            `${SERVER_URL}/rankings-program-earnings-states/${resData.data.cipcode}/${resData.data.degree}`,
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${currentuser.data.token}` },
            },
          );
          if (salaryResByState && salaryResByState.data) {
            setEarningsDataByState(salaryResByState.data);
          }

          // online rankings find
          const onlineRankingsRes = await Axios.get(`${SERVER_URL}/rankings-program-online/${resData.data.cipcode}`, {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` },
          });
          if (onlineRankingsRes && onlineRankingsRes.data) {
            setOnlineRankings(onlineRankingsRes.data);
          }

          // autocomplete vars
          const autocompleteVarsRes = await Axios.get(
            `${SERVER_URL}/rankings-new-content-autocomplete-vars/${resData.data.cipcode}/${resData.data.degree}`,
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${currentuser.data.token}` },
            },
          );
          if (autocompleteVarsRes && autocompleteVarsRes.data) {
            setSuggestionList(autocompleteVarsRes.data);
          }
        }

        setWorking(false);
      } catch (error) {
        setWorking(false);
        console.log(error);
        notification.error({
          message: 'Error',
          description: 'Problem with fetching ranking.',
          placement: 'bottomRight',
        });
      }
    };

    fetchRanking();
  }, []);

  const handleDynamicText = (content) => {
    let varArr = [];
    const vars = extractContentInsideHashes(content);
    const varsCips = extractCipContent(content);

    vars.forEach((el) => {
      const foundConst = suggestionList.find((item) => item.key === `#${el}#`);
      if (foundConst) {
        varArr.push(foundConst);
      }
    });
    varsCips.forEach((el) => {
      const foundConst = suggestionList.find((item) => item.key === `#[${el}]#`);
      if (foundConst) {
        varArr.push(foundConst);
      }
    });

    return varArr;
  };

  const handleSubmit = async () => {
    const values = await form.validateFields();

    if (!h1) {
      notification.error({
        message: 'Error',
        description: 'Must input <h1> tag in "H1 and description" section',
        placement: 'bottomRight',
      });
      h1Ref.current.focus();
      return;
    }

    const autocompleteVarsUsed = [
      ...handleDynamicText(contentDesc),
      ...handleDynamicText(section1Text),
      ...handleDynamicText(section2Text),
      ...handleDynamicText(section3Text),
      ...handleDynamicText(section4Text),
      ...handleDynamicText(section5Text),
      ...handleDynamicText(section6Text),
      ...handleDynamicText(section7Text),
    ].filter((value, index, self) => index === self.findIndex((t) => t.key === value.key));

    const bodyContent = {
      h1AndDesc: { h1, text: contentDesc },
      meta: { metaTitle, metaDescription, metaImage },
      section1: { h1: section1H1, text: section1Text },
      section2: { h1: section2H1, text: section2Text },
      section3: { h1: section3H1, text: section3Text, earningsDataByState },
      section4: { h1: section4H1, text: section4Text },
      section5: {
        h1: section5H1,
        text: section5Text,
        onlineBachLabel,
        onlineMasterLabel,
      },
      section6: { h1: section6H1, text: section6Text },
      section7: { h1: section7H1, text: section7Text, earningsDataByDegree: earningsData },
      bubbles,
      schoolListH1,
      statesMapH1,
      shortcodeSection: { shortcode },
      autocompleteVarsUsed,
    };

    try {
      setWorking(true);

      await Axios.patch(
        `${SERVER_URL}/rankings-new-content/${id}`,
        {
          contentNewDesign: bodyContent,
          useNewTemplate: values.useNewTemplate,
          relatedCareers: values.relatedCareers,
        },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );

      setWorking(false);
      notification.success({
        message: 'Ranking updated',
        placement: 'bottomRight',
      });
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Error',
        description: 'Problem with updating ranking.',
        placement: 'bottomRight',
      });
    }
  };

  return (
    <div className='content-wrapper' style={{ marginBottom: '30px' }}>
      <Spin spinning={working} tip='Working...'>
        <div className='actions-block flex'>
          <h4>
            <strong>
              {data?.category} {data?.cipcode ? `(${data?.degree} - ${data?.cipcode})` : ''}
            </strong>
          </h4>

          <Button type='primary' style={{ marginRight: '8px', float: 'left' }} onClick={() => history.goBack()}>
            ALL RANKINGS
          </Button>
        </div>

        <Form form={form} initialValues={initialForm} onFinish={handleSubmit} name='basic'>
          <Collapse defaultActiveKey={['h1']}>
            {/* h1 and description */}
            <Collapse.Panel
              key='h1'
              header={<CollapseImageTooltip title='H1 and description' image={h1AndDesc} height='160px' />}
              id='h1AndDescID'
            >
              <Form.Item label='<h1>'>
                <Input ref={h1Ref} value={h1} onChange={(e) => setH1(e.target.value)} />
              </Form.Item>

              <Divider />

              <label htmlFor='h1AndDesc'>Description:</label>
              <Editor
                content={contentDesc}
                setContent={setContentDesc}
                constants={suggestionList}
                name='h1AndDesc'
                tooltipImage={h1AndDesc}
                elemID='h1AndDescID'
              />
            </Collapse.Panel>

            {/* meta tags */}
            <Collapse.Panel key='meta' header='Meta tags'>
              <Form.Item label='Meta Title'>
                <Input maxLength={70} value={metaTitle} onChange={(e) => setMetaTitle(e.target.value)} />
              </Form.Item>

              <Form.Item label='Meta Description'>
                <TextArea
                  maxLength={180}
                  rows={3}
                  value={metaDescription}
                  onChange={(e) => setMetaDescription(e.target.value)}
                />
              </Form.Item>

              <Form.Item label='Meta Image'>
                <Input value={metaImage} onChange={(e) => setMetaImage(e.target.value)} />
              </Form.Item>

              {metaImage && (
                <Image
                  src={metaImage}
                  style={{
                    width: '200px',
                    border: '0.5px solid #bbb',
                    background: '#eee',
                    borderRadius: '5px',
                    cursor: 'pointer',
                  }}
                  alt='metaImage'
                />
              )}
            </Collapse.Panel>

            {/* SECTION 1 */}
            <Collapse.Panel
              key='section 1'
              header={<CollapseImageTooltip title='Section 1' image={section1Img} height='160px' />}
              id='section1ID'
            >
              <Form.Item label='Heading'>
                <Input value={section1H1} onChange={(e) => setSection1H1(e.target.value)} />
              </Form.Item>

              <Divider />

              <label htmlFor='section1Text'>Text:</label>
              <Editor
                content={section1Text}
                setContent={setSection1Text}
                constants={suggestionList}
                name='section1Text'
                tooltipImage={section1Img}
                elemID='section1ID'
              />
            </Collapse.Panel>

            {/* SECTION 2 */}
            <Collapse.Panel
              key='section 2'
              header={<CollapseImageTooltip title='Section 2' image={section2Img} height='160px' />}
              id='section2ID'
            >
              <Form.Item label='Heading'>
                <Input value={section2H1} onChange={(e) => setSection2H1(e.target.value)} />
              </Form.Item>

              <Divider />

              <label htmlFor='section2Text'>Text:</label>
              <Editor
                content={section2Text}
                setContent={setSection2Text}
                constants={suggestionList}
                name='section2Text'
                tooltipImage={section2Img}
                elemID='section2ID'
              />
            </Collapse.Panel>

            {/* SCHOOL LIST HEADING */}
            <Collapse.Panel
              key='list name'
              header={<CollapseImageTooltip title='School List Heading' image={schoolListImg} height='230px' />}
              id='schoolListH1'
            >
              <Form.Item label='Heading'>
                <Input value={schoolListH1} onChange={(e) => setSchoolListH1(e.target.value)} />
              </Form.Item>
            </Collapse.Panel>

            {/* SHORTCODE */}
            <Collapse.Panel
              key='shortcode'
              header={<CollapseImageTooltip title='Shortcode' image={shortcodeImg} height='140px' />}
              id='shortcode'
            >
              <Form.Item label='Shortcode'>
                <Input value={shortcode} onChange={(e) => setShortcode(e.target.value)} />
              </Form.Item>
            </Collapse.Panel>

            {/* STATES MAP HEADING */}
            <Collapse.Panel
              key='states map heading'
              header={<CollapseImageTooltip title='States Map Heading' image={statesMapImg} height='190px' />}
              id='statesMapH1'
            >
              <Form.Item label='Heading'>
                <Input value={statesMapH1} onChange={(e) => setStatesMapH1(e.target.value)} />
              </Form.Item>
            </Collapse.Panel>

            {/* SECTION 3 */}
            <Collapse.Panel
              key='section 3'
              header={<CollapseImageTooltip title='Section 3' image={section3Img} height='210px' />}
              id='section3ID'
            >
              <Form.Item label='Heading'>
                <Input value={section3H1} onChange={(e) => setSection3H1(e.target.value)} />
              </Form.Item>

              <Divider />

              <label htmlFor='section3Text'>Text:</label>
              <Editor
                content={section3Text}
                setContent={setSection3Text}
                constants={suggestionList}
                name='section3Text'
                tooltipImage={section3Img}
                elemID='section3ID'
              />

              <Divider />

              <Table
                size='small'
                bordered
                dataSource={earningsDataByState}
                columns={[
                  {
                    title: 'STATE',
                    dataIndex: ['state'],
                    key: 'state',
                  },
                  {
                    title: 'SALARY',
                    dataIndex: ['salary'],
                    key: 'salary',
                  },
                ]}
                rowKey={(record) => record.state}
                pagination={false}
                showHeader={false}
              />
            </Collapse.Panel>

            {/* SECTION 4 */}
            <Collapse.Panel
              key='section 4'
              header={<CollapseImageTooltip title='Section 4' image={section4Img} height='220px' />}
              id='section4ID'
            >
              <Form.Item label='Heading'>
                <Input value={section4H1} onChange={(e) => setSection4H1(e.target.value)} />
              </Form.Item>

              <Divider />

              <label htmlFor='section4Text'>Text:</label>
              <Editor
                content={section4Text}
                setContent={setSection4Text}
                constants={suggestionList}
                name='section4Text'
                tooltipImage={section4Img}
                elemID='section4ID'
              />

              <Divider />

              <Form.Item name='relatedCareers' label='Related careers'>
                <Select allowClear showSearch optionFilterProp='children' mode='multiple'>
                  {allCareers.map((item) => (
                    <Select.Option key={`${item._id}`} value={item._id}>
                      {item.occCode} - {item.careerName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Collapse.Panel>

            {/* SECTION 5 */}
            <Collapse.Panel
              key='section 5'
              header={<CollapseImageTooltip title='Section 5' image={section5Img} height='240px' />}
              id='section5ID'
            >
              <Form.Item label='Heading'>
                <Input value={section5H1} onChange={(e) => setSection5H1(e.target.value)} />
              </Form.Item>

              <Divider />

              <label htmlFor='section5Text'>Text:</label>
              <Editor
                content={section5Text}
                setContent={setSection5Text}
                constants={suggestionList}
                name='section5Text'
                tooltipImage={section5Img}
                elemID='section5ID'
              />

              <Divider />

              {onlineRankings.length > 0
                ? onlineRankings.map((onlineRanking) => {
                    const onlineDegree =
                      onlineRanking.degree === "Master's Degree" ? 'onlineMasterLabel' : 'onlineBachLabel';
                    const onlineReactState =
                      onlineRanking.degree === "Master's Degree" ? onlineMasterLabel : onlineBachLabel;

                    return (
                      <Form.Item
                        key={onlineDegree}
                        label={
                          <a href={onlineRanking.link} target='_blank' rel='noreferrer' style={{ color: 'blue' }}>
                            {onlineRanking.degree} label
                          </a>
                        }
                      >
                        <Input
                          value={onlineReactState}
                          onChange={(e) =>
                            onlineDegree === 'onlineMasterLabel'
                              ? setOnlineMasterLabel(e.target.value)
                              : setOnlineBachLabel(e.target.value)
                          }
                        />
                      </Form.Item>
                    );
                  })
                : 'This cipcode has no online rankings.'}
            </Collapse.Panel>

            {/* SECTION 6 */}
            <Collapse.Panel
              key='section 6'
              header={<CollapseImageTooltip title='Section 6' image={section6Img} height='100px' />}
              id='section6ID'
            >
              <Form.Item label='Heading'>
                <Input value={section6H1} onChange={(e) => setSection6H1(e.target.value)} />
              </Form.Item>

              <Divider />

              <label htmlFor='section6Text'>Text:</label>
              <Editor
                content={section6Text}
                setContent={setSection6Text}
                constants={suggestionList}
                name='section6Text'
                tooltipImage={section6Img}
                elemID='section6ID'
              />
            </Collapse.Panel>

            {/* SECTION 7 */}
            <Collapse.Panel
              key='section 7'
              header={<CollapseImageTooltip title='Section 7' image={section7Img} height='150px' />}
              id='section7ID'
            >
              <Form.Item label='Heading'>
                <Input value={section7H1} onChange={(e) => setSection7H1(e.target.value)} />
              </Form.Item>

              <Divider />

              <label htmlFor='section7Text'>Text:</label>
              <Editor
                content={section7Text}
                setContent={setSection7Text}
                constants={suggestionList}
                name='section7Text'
                tooltipImage={section7Img}
                elemID='section7ID'
              />

              <Divider />

              <Table
                size='small'
                bordered
                dataSource={earningsData}
                columns={[
                  {
                    title: 'DEGREE',
                    dataIndex: ['degree'],
                    key: 'degree',
                  },
                  {
                    title: 'SALARY',
                    dataIndex: ['salary'],
                    key: 'salary',
                  },
                ]}
                rowKey={(record) => record.degree}
                pagination={false}
                showHeader={false}
              />
            </Collapse.Panel>

            {/* SIMILAR PROGRAMS */}
            <Collapse.Panel
              key='similarPrograms'
              header={<CollapseImageTooltip title='Similar Programs' image={bubblesImg} height='160px' />}
              id='bubbleCollapse'
            >
              <BubblesList data={bubbles} handleBubbles={setBubbles} refresh={refreshBubbles} />
            </Collapse.Panel>
          </Collapse>

          <br></br>

          <Form.Item name='useNewTemplate' label='Use new template' valuePropName='checked'>
            <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} loading={working} />
          </Form.Item>

          <div style={{ display: 'flex' }}>
            <Form.Item style={{ marginTop: '10px' }}>
              <Button type='primary' htmlType='submit'>
                SAVE
              </Button>
            </Form.Item>

            <Button type='link' style={{ marginLeft: '10px', marginTop: '10px' }} disabled={showAdditional}>
              <a href={wpLink ? wpLink : '#'} target='_blank' rel='noopener noreferrer'>
                Link to WP page
              </a>
            </Button>
          </div>
        </Form>
      </Spin>
    </div>
  );
};

export default SingleRankingEditPage;
