import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { Button } from 'antd';
import ReactJson from 'react-json-view';

const ViewScrape = (props) => {
  const currentuser = useContext(UserContext);
  const [payscale, fetchPayscale] = useAxios('', {}, currentuser.data.token, 'get');
  const { id } = props.match.params;

  useEffect(() => {
    if (id) fetchPayscale(`${SERVER_URL}/payscale-data/${id}`);
  }, [id, fetchPayscale]);

  return (
    <div className='content-wrapper'>
      <div className='panel'>
        <div className='actions-block'>
          <Link to='/admin/payscale'>
            <Button type='primary'>All Payscale Data</Button>
          </Link>
        </div>

        <div className='panel-body'>
          {payscale.data && (
            <>
              <h2>
                <strong>Job Title:&nbsp;</strong>
                <span>{payscale.data?.jobTitle}</span>
              </h2>

              <h2>
                <strong>Report Type:&nbsp;</strong>
                <span>{payscale.data?.report}</span>
              </h2>

              <h2>
                <strong>State:&nbsp;</strong>
                <span>{payscale.data?.state}</span>
              </h2>

              <h2>
                <strong>Degree Level:&nbsp;</strong>
                <span>{payscale.data?.degreeLevel}</span>
              </h2>

              <h2>
                <strong>Raw Data:</strong>
              </h2>

              <div style={{ marginTop: '12px' }}>
                {payscale.data?.data && (
                  <ReactJson
                    name={null}
                    src={payscale.data.data}
                    collapsed={3}
                    displayDataTypes={false}
                    enableClipboard={false}
                    style={{ fontSize: '14px' }}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewScrape;
