import React from 'react';
import { Select, Button, Descriptions, Divider } from 'antd';

const AutocompleteDropdown = ({ selectedSugg, suggestions, onSelect, onInsert, selectedConstant }) => {
  const handleInsert = () => {
    onInsert(selectedSugg);
  };

  return (
    <div style={{ marginBottom: '15px', display: 'flex' }}>
      <Select
        allowClear
        showSearch
        optionFilterProp='children'
        name='suggestion'
        value={selectedSugg}
        onChange={(value) => onSelect(value)}
        style={{ width: '400px', marginLeft: '4px', fontSize: '13px' }}
        placeholder='Suggestions...'
      >
        {suggestions.map((item) => (
          <Select.Option key={item} value={item}>
            {item}
          </Select.Option>
        ))}
      </Select>

      <Divider type='vertical' style={{ height: '30px' }} />

      <Button type='default' onClick={handleInsert} disabled={!selectedSugg}>
        INSERT INTO TEXT
      </Button>

      {selectedConstant ? (
        <>
          <Divider type='vertical' style={{ height: '30px' }} />
          <div style={{ maxHeight: '20px' }}>
            <Descriptions bordered size='small'>
              <Descriptions.Item>{selectedConstant.description}</Descriptions.Item>
            </Descriptions>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default AutocompleteDropdown;
