import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import * as _ from 'lodash';
import { Button, Input, Table, Tooltip, Modal, notification } from 'antd';
import { EyeOutlined, SearchOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import Axios from 'axios';
import { SERVER_URL } from '../../config';

const CareerTable = ({
  data,
  deleteHandler,
  viewPath,
  filter,
  setFilter,
  editHandler,
  modal,
  setModal,
  currentuser,
  setWorking,
  fetchTrigger,
  setFetchTrigger,
  sortHandler,
}) => {
  const history = useHistory();

  let searchInput;
  const getColumnSearchProps = (dataIndex) => {
    if (Array.isArray(dataIndex)) dataIndex = dataIndex.join('.');
    const prop = dataIndex;
    return {
      filteredValue:
        (_.pickBy(filter, _.identity) && _.pickBy(filter, _.identity)[prop] && [_.pickBy(filter, _.identity)[prop]]) ||
        null,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <Input
              ref={(node) => {
                searchInput = node;
              }}
              placeholder={`Search ${prop}`}
              value={selectedKeys && selectedKeys.length > 0 ? selectedKeys : [_.pickBy(filter, _.identity)[prop]]}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={(e) => handleSearch(e, selectedKeys, confirm, prop)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />

            <Button
              type='primary'
              onClick={(e) => handleSearch(e, selectedKeys, confirm, prop)}
              size='small'
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>

            <Button onClick={(e) => handleReset(e, clearFilters, prop)} size='small' style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        );
      },
      filterIcon: (filtered) => {
        filtered = !!_.pickBy(filter, _.identity)[prop];
        return <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />;
      },
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => {
            searchInput.select();
          });
        }
      },
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (text) => {
        return _.pickBy(filter, _.identity)[prop] ? (
          <Highlighter
            title={text}
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[_.pickBy(filter, _.identity)[prop]]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          <span title={text}>{text}</span>
        );
      },
    };
  };

  const handleSearch = async (e, selectedKeys, confirm, dataIndex) => {
    e.preventDefault();
    confirm();
    setFilter({ ..._.pickBy(filter, _.identity), [dataIndex]: selectedKeys[0] });
  };

  const handleReset = async (e, clearFilters, prop) => {
    e.preventDefault();
    setFilter({ ...filter, [prop]: undefined });
    clearFilters();
  };

  const columns = [
    {
      title: 'TITLE',
      dataIndex: 'careerName',
      key: 'careerName',
      fixed: 'left',
      width: 350,
      ...getColumnSearchProps('careerName'),
    },
    {
      title: 'CODE',
      dataIndex: 'occCode',
      key: 'occCode',
      fixed: 'left',
      ...getColumnSearchProps('occCode'),
    },
    {
      title: 'AVERAGE SALARY',
      dataIndex: 'A_MEDIAN',
      key: 'A_MEDIAN',
      ...getColumnSearchProps('A_MEDIAN'),
      render: (text, record) => {
        return record.BLS && record.BLS.A_MEDIAN && record.BLS.A_MEDIAN !== '#'
          ? parseInt(record.BLS.A_MEDIAN).toLocaleString('us')
          : text;
      },
    },
    {
      title: 'TOTAL EMPLOYMENT',
      dataIndex: 'TOT_EMP',
      key: 'TOT_EMP',
      width: 150,
      ...getColumnSearchProps('TOT_EMP'),
      render: (text, record) => {
        return record.BLS && record.BLS.TOT_EMP && record.BLS.TOT_EMP !== '#'
          ? parseInt(record.BLS.TOT_EMP).toLocaleString('us')
          : text;
      },
    },
    {
      title: 'ANNUAL OPPENINGS',
      dataIndex: 'annualOpenings',
      key: 'annualOpenings',
      width: 150,
      ...getColumnSearchProps('annualOpenings'),
      render: (text, record) => {
        return record.BLS && record.BLS.annualOpenings && record.BLS.annualOpenings;
      },
    },
    {
      title: 'ESTIMATED GROWTH INCREASE',
      dataIndex: 'estimatedIncrease',
      key: 'estimatedIncrease',
      width: 150,
      ...getColumnSearchProps('estimatedIncrease'),
      render: (text, record) => {
        return record.BLS && record.BLS.estimatedIncrease && record.BLS.estimatedIncrease !== '#'
          ? parseInt(record.BLS.estimatedIncrease).toLocaleString('us')
          : text;
      },
    },
    {
      title: 'JOBS PER 1000',
      dataIndex: 'JOBS_1000',
      key: 'JOBS_1000',
      width: 150,
      ...getColumnSearchProps('JOBS_1000'),
      render: (text, record) => {
        return record.BLS && record.BLS.JOBS_1000 && record.BLS.JOBS_1000;
      },
    },
    {
      title: 'Location quotient',
      dataIndex: 'LOC_QUOTIENT',
      key: 'LOC_QUOTIENT',
      width: 150,
      ...getColumnSearchProps('LOC_QUOTIENT'),
      render: (text, record) => {
        return record.BLS && record.BLS.LOC_QUOTIENT && record.BLS.LOC_QUOTIENT;
      },
    },
    {
      title: 'Typical Entry-level Education',
      dataIndex: 'entryLevelEdu',
      key: 'entryLevelEdu',
      ...getColumnSearchProps('entryLevelEdu'),
    },
    {
      title: 'CIPCODES',
      key: 'CIPCODES',
      align: 'center',
      render: (text, record) => (
        <>
          {record.cipcodes ? (
            <Tooltip title={`View all cipcodes`}>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => history.push(`/admin/career-cip-soc/${record.occCode}`)}
              >
                <EyeOutlined style={{ textDecoration: 'none', color: 'black', fontSize: '15px' }} />
              </div>
            </Tooltip>
          ) : (
            'N/A'
          )}
        </>
      ),
    },
    {
      title: 'CREATED AT',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => new Date(text).toLocaleString('en-US'),
    },
    {
      title: 'UPDATED AT',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text) => new Date(text).toLocaleString('en-US'),
    },
  ];

  columns.push({
    title: 'Action',
    fixed: 'right',
    render: (text, record) => (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '15px',
        }}
      >
        {viewPath && (
          <Tooltip title={`View Career`} placement='topLeft'>
            <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='table-actions'>
              <Link to={viewPath + record._id}>
                <EyeOutlined style={{ textDecoration: 'none', color: 'black' }} />
              </Link>
            </div>
          </Tooltip>
        )}

        {editHandler && (
          <Tooltip title={`Edit Career`} placement='topLeft'>
            <div
              style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}
              className='table-actions'
              onClick={() => editHandler(record._id)}
            >
              <EditOutlined style={{ textDecoration: 'none', color: 'black' }} />
            </div>
          </Tooltip>
        )}

        {deleteHandler && (
          <Tooltip title={`Delete ${record.careerName}`} placement='topLeft'>
            <div
              style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}
              className='table-actions'
              onClick={async () => {
                deleteHandler(record._id);
              }}
            >
              <DeleteOutlined />
            </div>
          </Tooltip>
        )}
      </div>
    ),
  });

  const onChangeTable = (pagination, filters, sorter) => {
    Object.keys(filters).map((prop) => {
      filter[prop] = Array.isArray(filters[prop]) ? filters[prop][0] : filters[prop];
    });
    setFilter({ ...filter, ...{ page: pagination.current, pageSize: pagination.pageSize } });
    sortHandler(sorter.field, sorter.order);
  };

  const onOk = async () => {
    try {
      setWorking(true);
      await Axios.patch(
        `${SERVER_URL}/career/${modal.id}`,
        { wpLink: modal.wpLink, wpName: modal.wpName },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );
      setWorking(false);

      setModal({ visible: false });
      notification.success({
        message: 'Career updated',
        placement: 'bottomRight',
      });
      setFetchTrigger(!fetchTrigger);
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Error',
        description: 'Problem updating career link',
        placement: 'bottomRight',
      });
    }
  };

  return (
    <div>
      <Table
        size='middle'
        onChange={onChangeTable}
        bordered
        dataSource={data}
        columns={columns}
        rowKey={(record) => record._id}
        // pagination={{
        //   pageSize: 20,
        //   total: total || 0,
        //   current: page,
        //   defaultCurrent: 1,
        //   position: 'bottom',
        //   showSizeChanger: false,
        //   onChange: (page) => pageHandler(page),
        //   hideOnSinglePage: true,
        // }}
        id='careersTable'
        scroll={{
          x: 2000,
        }}
      />

      <Modal
        centered
        width='50%'
        visible={modal.visible}
        title={`EDIT - ${modal.careerName}`}
        okText='SAVE'
        cancelText='CANCEL'
        maskClosable={false}
        onOk={onOk}
        onCancel={() => {
          setModal({ visible: false });
        }}
        getContainer={document.getElementById('careersTable')}
      >
        <div>
          <label htmlFor='wpLink'>WP Link</label>
          <Input name='wpLink' value={modal.wpLink} onChange={(e) => setModal({ ...modal, wpLink: e.target.value })} />

          <br></br>
          <br></br>

          <label htmlFor='wpName'>WP Name</label>
          <Input name='wpName' value={modal.wpName} onChange={(e) => setModal({ ...modal, wpName: e.target.value })} />
        </div>
      </Modal>
    </div>
  );
};

export default CareerTable;
