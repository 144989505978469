import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import {
  Table,
  Button,
  Tooltip,
  notification,
  Spin,
  Input,
  Select,
  Modal,
  Form,
  Col,
  Row,
  Space,
  // Radio,
  Checkbox,
} from 'antd';
import {
  SearchOutlined,
  EyeOutlined,
  ExportOutlined,
  CheckOutlined,
  SyncOutlined,
  FilterFilled,
} from '@ant-design/icons';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';
import Highlighter from 'react-highlight-words';
import CIP_CODES from '../../lib/cscCipcodes.json';
import CARNEGIE_NAMES from '../../lib/carnegieNames.json';
import ExportToExcelCustomForProfiles from '../../components/csv/ExportExcelCustomizeProfiles';
import axios from 'axios';
import * as _ from 'lodash';

const CARNEGIE_SET = CARNEGIE_NAMES.map((item) => {
  return {
    values: item.Values ? item.Values : [{ Code: item.Code, Name: item.Name }],
    label: item.Called ? item.Called : item.Name,
    code:
      item.Values && item.Values.length > 1
        ? `${item.Values[0].Code}-${item.Values[item.Values.length - 1].Code}`
        : item.Values && item.Values.length === 1
        ? item.Values[0].Code
        : !item.Values
        ? item.Code
        : null,
  };
});

const SORT = {
  ascend: 1,
  descend: -1,
};

const DEGREES = [
  { label: 'Certificate', value: 'Undergraduate Certificate or Diploma' },
  { label: "Associate's", value: "Associate's Degree" },
  { label: "Bachelor's", value: 'Bachelors Degree' },
  { label: "Master's", value: "Master's Degree" },
  { label: 'Doctoral', value: 'Doctoral Degree' },
];

const REGIONS = [{ value: 'North' }, { value: 'South' }, { value: 'Midwest' }, { value: 'West' }];

let initialSearch = {
  control: undefined,
  degree: undefined,
  cipcode: undefined,
  region: undefined,
  carnegie: undefined,
  online: undefined,
  naFilter: undefined,
};

const SchoolProfiles = ({ history }) => {
  const [form] = Form.useForm();
  const currentuser = useContext(UserContext);
  const [working, setWorking] = useState(false);
  const [data, setData] = useState([]);
  const [degree, setDegree] = useState(null);
  const [cipcode, setCipcode] = useState(null);
  const [limit, setLimit] = useState(_.get(history, 'location.state.limit', 10));
  const [online, setOnline] = useState(null);
  const [visible, setVisible] = useState(null);
  const [page, setPage] = useState(_.get(history, 'location.state.page', 1));
  const [totalDocs, setTotalDocs] = useState(0);

  // customize excel data
  const [useAdmissions, setUseAdmissions] = useState(false);
  const [useChar, setUseChar] = useState(false);
  const [useKPI, setUseKPI] = useState(false);
  const [useCost, setUseCost] = useState(false);
  const [useGradRates, setUseGradRates] = useState(false);
  const [useRepayment, setUseRepayment] = useState(false);
  const [useCrime, setUseCrime] = useState(false);

  // Calculate the number of checked checkboxes
  const checkedCount = [useChar, useCost, useGradRates, useAdmissions, useKPI, useRepayment, useCrime].filter(
    Boolean,
  ).length;

  // Handler function that limits checkbox selection to 2
  const handleCheckboxChange = (setter) => (e) => {
    if (e.target.checked && checkedCount >= 2) {
      notification.warning({
        message: 'Warning',
        description: 'Maximum of 2 options can be checked',
        placement: 'bottomRight',
      });
      return;
    }
    setter(e.target.checked);
  };

  // inti search
  initialSearch = _.pickBy(_.get(history, 'location.state', null), _.identity);

  useEffect(() => {
    const historyState = _.pickBy(_.get(history, 'location.state', null), _.identity);
    getData(historyState).catch(console.error);
    form.setFieldsValue(initialSearch);
  }, []);

  const fetchSchoolProfiles = async (urlQuery) => {
    setWorking(true);
    const res = await axios.get(`${SERVER_URL}/all-school-profiles${urlQuery ? '?' + urlQuery : ''}`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
    });
    if (res) {
      setData(res.data.profiles);
      setTotalDocs(res.data.totalDocs);
      // const {page, pageSize} = _.pickBy(form.getFieldsValue(), _.identity);
      // setPage(page);
      // setLimit(pageSize);
    }
    setWorking(false);
  };

  let searchInput;
  const getColumnSearchProps = (dataIndex, name) => {
    if (Array.isArray(dataIndex)) dataIndex = dataIndex.join('.');
    const prop = dataIndex;
    return {
      filteredValue:
        (_.pickBy(form.getFieldsValue(), _.identity) &&
          _.pickBy(form.getFieldsValue(), _.identity)[prop] && [_.pickBy(form.getFieldsValue(), _.identity)[prop]]) ||
        null,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <Input
              ref={(node) => {
                searchInput = node;
              }}
              placeholder={`Search ${prop}`}
              value={
                selectedKeys && selectedKeys.length > 0
                  ? selectedKeys
                  : [_.pickBy(form.getFieldsValue(), _.identity)[prop]]
              }
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={(e) => handleSearch(e, selectedKeys, confirm, prop)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />

            <Button
              type='primary'
              onClick={(e) => handleSearch(e, selectedKeys, confirm, prop)}
              size='small'
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>

            <Button onClick={(e) => handleReset(e, clearFilters, prop)} size='small' style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        );
      },
      filterIcon: (filtered) => {
        if (_.pickBy(form.getFieldsValue(), _.identity)[prop]) {
          filtered = true;
        } else {
          filtered = false;
        }
        return <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />;
      },
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => {
            searchInput.select();
          });
        }
      },
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (text) =>
        _.pickBy(form.getFieldsValue(), _.identity)[prop] ? (
          <Highlighter
            title={text}
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[_.pickBy(form.getFieldsValue(), _.identity)[prop]]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          <span title={text}>{text}</span>
        ),
    };
  };

  const handleSearch = async (e, selectedKeys, confirm, dataIndex) => {
    e.preventDefault();
    confirm();
    form.setFieldsValue({
      [dataIndex]: selectedKeys[0] ? selectedKeys[0] : _.pickBy(form.getFieldsValue(), _.identity)[dataIndex],
    });
    await getData({ ..._.pickBy(form.getFieldsValue(), _.identity) });
  };

  const handleReset = async (e, clearFilters, prop) => {
    e.preventDefault();
    form.setFieldsValue({ [prop]: undefined });
    await getData({ ..._.pickBy(form.getFieldsValue(), _.identity) });
    clearFilters();
  };

  const sortHandler = async (prop, order) => {
    if (!prop) return;
    if (Array.isArray(prop)) prop = prop.join('.');

    let sortQuery = null;
    if (order) {
      sortQuery = `${JSON.stringify({ [prop]: SORT[order] })}`;
    }
    //
    // setSort(sortQuery);
    form.setFieldsValue({ sort: sortQuery });
    await getData({ ..._.pickBy(form.getFieldsValue(), _.identity) });
  };

  let columns = [];

  const columnsBasic = [
    {
      title: 'UNITID',
      width: 100,
      dataIndex: 'unitid',
      key: 'unitid',
      ...getColumnSearchProps(['unitid']),
      render: (text, record) => {
        return (
          <>
            <span title={text}>{text}</span>
            {record.logo && (
              <Tooltip title='Logo present'>
                <CheckOutlined style={{ color: 'green', marginLeft: '5px' }} />
              </Tooltip>
            )}
          </>
        );
      },
      fixed: 'left',
    },
    {
      title: 'NAME',
      width: 120,
      dataIndex: ['name'],
      key: 'name',
      ...getColumnSearchProps(['name']),
      fixed: 'left',
    },
    {
      title: 'STATE',
      dataIndex: ['state'],
      key: 'state',
      ...getColumnSearchProps(['state']),
    },
  ];

  const columnsPremiums = [
    {
      title: 'EARNINGPLUS',
      dataIndex: ['degreePremium', 'premiumAmount'],
      key: 'premiumAmount',
      render: (value) => (value ? value.toFixed(0) : 'N/A'),
      sorter: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'EARNINGPLUS %',
      dataIndex: ['degreePremium', 'premiumPercentage'],
      key: 'premiumPercentage',
      render: (value) => (value ? value.toFixed(2) : 'N/A'),
      sorter: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'ECONOMIC SCORE',
      dataIndex: ['degreePremium', 'rankingScore'],
      key: 'rankingScore',
      filters: [
        {
          text: 'Non N/A',
          value: 'Non N/A',
        },
      ],
      filteredValue: _.pickBy(form.getFieldsValue(), _.identity).naFilter ? ['Non N/A'] : null,
      filterIcon: (filtered) => <FilterFilled style={{ color: filtered ? 'red' : 'white', fontSize: '1rem' }} />,
      render: (value) => (value ? value.toFixed(2) : 'N/A'),
      sorter: true,
      sortDirections: ['ascend', 'descend'],
    },
  ];

  if (!cipcode) {
    if (!online) {
      columns = [
        ...columnsBasic,
        {
          title: 'TOTAL STUDENTS',
          dataIndex: ['UGDS'],
          key: 'UGDS',
          render: (value) => (value ? value : 'N/A'),
          sorter: true,
          sortDirections: ['ascend', 'descend'],
        },
        {
          title: 'NET ANNUAL COST',
          dataIndex: ['Average net price'],
          key: 'Average net price',
          render: (value) => (value ? value.toLocaleString('en-US') : 'N/A'),
          sorter: true,
          sortDirections: ['ascend', 'descend'],
        },
        {
          title: 'YEARS TO GRADUATE',
          dataIndex: ['Average completion time'],
          key: 'Average completion time',
          render: (value) => (value ? value.toFixed(2) : 'N/A'),
          sorter: true,
          sortDirections: ['ascend', 'descend'],
        },
        {
          title: 'EARNINGS 10y',
          dataIndex: ['MD_EARN_WNE_P10'],
          key: 'MD_EARN_WNE_P10',
          render: (value) => (value ? value.toFixed(0) : 'N/A'),
          sorter: true,
          sortDirections: ['ascend', 'descend'],
        },
        {
          title: 'EARNINGS 4y',
          dataIndex: ['MD_EARN_WNE_4YR'],
          key: 'MD_EARN_WNE_4YR',
          render: (value) => (value ? value.toFixed(0) : 'N/A'),
          sorter: true,
          sortDirections: ['ascend', 'descend'],
        },
        {
          title: 'PAYBACK',
          dataIndex: ['Payback'],
          key: 'Payback',
          render: (value) => (value && typeof value === 'number' ? value.toFixed(2) : 'N/A'),
          sorter: true,
          sortDirections: ['ascend', 'descend'],
        },
        ...columnsPremiums,
      ];
    } else {
      columns = [
        ...columnsBasic,
        {
          title: 'NUMBER OF BRANCHES',
          dataIndex: ['BRANCHES'],
          key: 'BRANCHES',
          render: (value) => (value ? value : 'N/A'),
          sorter: true,
          sortDirections: ['ascend', 'descend'],
        },
        {
          title: 'ONLINE/HYBRID',
          dataIndex: ['onlineOrHybrid'],
          key: 'onlineOrHybrid',
          render: (value) => (value ? value : 'N/A'),
          sorter: true,
          sortDirections: ['ascend', 'descend'],
        },
        {
          title: 'NET ANNUAL COST',
          dataIndex: ['Average net price'],
          key: 'Average net price',
          render: (value) => (value ? value.toLocaleString('en-US') : 'N/A'),
          sorter: true,
          sortDirections: ['ascend', 'descend'],
        },
        {
          title: 'EARNINGS',
          dataIndex: ['MD_EARN_WNE_P10'],
          key: 'MD_EARN_WNE_P10',
          render: (value) => (value ? value.toFixed(0) : 'N/A'),
          sorter: true,
          sortDirections: ['ascend', 'descend'],
        },
        {
          title: 'PAYBACK',
          dataIndex: ['Payback'],
          key: 'Payback',
          render: (value) => (value && typeof value === 'number' ? value.toFixed(2) : 'N/A'),
          sorter: true,
          sortDirections: ['ascend', 'descend'],
        },
        ...columnsPremiums,
      ];
    }
  } else {
    if (degree === "Master's Degree" || degree === 'Doctoral Degree') {
      if (!online) {
        columns = [
          ...columnsBasic,
          {
            title: 'DEGREE',
            dataIndex: ['credential'],
            key: 'credential',
            // ...getColumnSearchProps(['credential']),
          },
          {
            title: 'CONFERRALS',
            dataIndex: ['conferrals'],
            key: 'conferrals',
            sorter: true,
            sortDirections: ['ascend', 'descend'],
          },
          {
            title: 'DEBT',
            dataIndex: ['debt'],
            key: 'debt',
            render: (value) => (value ? value.toFixed(0) : 'N/A'),
            sorter: true,
            sortDirections: ['ascend', 'descend'],
          },
          {
            title: 'DEBT TO EARNINGS',
            dataIndex: ['earnDebtRatio'],
            key: 'earnDebtRatio',
            render: (value) => (typeof value === 'number' ? value.toFixed(2) : 'N/A'),
            sorter: true,
            sortDirections: ['ascend', 'descend'],
          },
          {
            title: 'EARNINGS',
            dataIndex: ['EARN'],
            key: 'EARN',
            render: (value, record) => {
              if (record.EARN_USED) {
                return (
                  <Tooltip title={`${record.EARN_USED} earnings`} color='blue' placement='left'>
                    {value ? value.toFixed(0) : 'N/A'}
                  </Tooltip>
                );
              }
              return value ? value.toFixed(0) : 'N/A';
            },
            sorter: true,
            sortDirections: ['ascend', 'descend'],
          },
          ...columnsPremiums,
        ];
      } else {
        columns = [
          ...columnsBasic,
          {
            title: 'DEGREE',
            dataIndex: ['credential'],
            key: 'credential',
            // ...getColumnSearchProps(['credential']),
          },
          {
            title: 'ONLINE/HYBRID',
            dataIndex: ['onlineOrHybrid'],
            key: 'onlineOrHybrid',
            render: (value) => (value ? value : 'N/A'),
            sorter: true,
            sortDirections: ['ascend', 'descend'],
          },
          {
            title: 'CONFERRALS',
            dataIndex: ['conferrals'],
            key: 'conferrals',
            sorter: true,
            sortDirections: ['ascend', 'descend'],
          },
          {
            title: 'DEBT',
            dataIndex: ['debt'],
            key: 'debt',
            render: (value) => (value ? value.toFixed(0) : 'N/A'),
            sorter: true,
            sortDirections: ['ascend', 'descend'],
          },
          {
            title: 'DEBT TO EARNINGS',
            dataIndex: ['earnDebtRatio'],
            key: 'earnDebtRatio',
            render: (value) => (typeof value === 'number' ? value.toFixed(2) : 'N/A'),
            sorter: true,
            sortDirections: ['ascend', 'descend'],
          },
          {
            title: 'EARNINGS',
            dataIndex: ['EARN'],
            key: 'EARN',
            render: (value, record) => {
              if (record.EARN_USED) {
                return (
                  <Tooltip title={`${record.EARN_USED} earnings`} color='blue' placement='left'>
                    {value ? value.toFixed(0) : 'N/A'}
                  </Tooltip>
                );
              }
              return value ? value.toFixed(0) : 'N/A';
            },
            sorter: true,
            sortDirections: ['ascend', 'descend'],
          },
          ...columnsPremiums,
        ];
      }
    } else {
      if (!online) {
        columns = [
          ...columnsBasic,
          {
            title: 'DEGREE',
            dataIndex: ['credential'],
            key: 'credential',
            // ...getColumnSearchProps(['credential']),
          },
          {
            title: 'CONFERRALS',
            dataIndex: ['conferrals'],
            key: 'conferrals',
            sorter: true,
            sortDirections: ['ascend', 'descend'],
          },
          {
            title: 'NET ANNUAL COST',
            dataIndex: ['Average net price'],
            key: 'Average net price',
            render: (value) => (value ? value.toLocaleString('en-US') : 'N/A'),
            sorter: true,
            sortDirections: ['ascend', 'descend'],
          },
          {
            title: 'EARNINGS',
            dataIndex: ['EARN'],
            key: 'EARN',
            render: (value) => (value ? value.toFixed(0) : 'N/A'),
            sorter: true,
            sortDirections: ['ascend', 'descend'],
          },
          {
            title: 'PAYBACK',
            dataIndex: ['Payback'],
            key: 'Payback',
            render: (value) => (value && typeof value === 'number' ? value.toFixed(2) : 'N/A'),
            sorter: true,
            sortDirections: ['ascend', 'descend'],
          },
          ...columnsPremiums,
        ];
      } else {
        columns = [
          ...columnsBasic,
          {
            title: 'DEGREE',
            dataIndex: ['credential'],
            key: 'credential',
            // ...getColumnSearchProps(['credential']),
          },
          {
            title: 'ONLINE/HYBRID',
            dataIndex: ['onlineOrHybrid'],
            key: 'onlineOrHybrid',
            render: (value) => (value ? value : 'N/A'),
            sorter: true,
            sortDirections: ['ascend', 'descend'],
          },
          {
            title: 'CONFERRALS',
            dataIndex: ['conferrals'],
            key: 'conferrals',
            sorter: true,
            sortDirections: ['ascend', 'descend'],
          },
          {
            title: 'EARNINGS',
            dataIndex: ['EARN'],
            key: 'EARN',
            render: (value) => (value ? value.toFixed(0) : 'N/A'),
            sorter: true,
            sortDirections: ['ascend', 'descend'],
          },
          {
            title: 'PAYBACK',
            dataIndex: ['Payback'],
            key: 'Payback',
            render: (value) => (value && typeof value === 'number' ? value.toFixed(2) : 'N/A'),
            sorter: true,
            sortDirections: ['ascend', 'descend'],
          },
          ...columnsPremiums,
        ];
      }
    }
  }

  const queryString = { ..._.pickBy(form.getFieldsValue(), _.identity), page };

  if (!cipcode) {
    columns.push({
      title: 'PERCENTAGE AGAINST OTHER SCHOOLS',
      dataIndex: ['esCompare'],
      key: 'esCompare',
    });
  }

  columns.push({
    title: 'Action',
    width: 70,
    render: (text, record) => {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '15px',
          }}
        >
          <Tooltip title={`View Profile`} placement='topLeft'>
            <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='table-actions'>
              <Link
                to={{
                  pathname: `/admin/school-profiles/${record._id}`,
                  state: { ...queryString },
                }}
              >
                <EyeOutlined style={{ textDecoration: 'none', color: 'black' }} />
              </Link>
            </div>
          </Tooltip>
        </div>
      );
    },
    fixed: 'right',
  });

  const onChangeTable = async (pagination, filter, sorter) => {
    let naFilter = false;
    if (filter.rankingScore && filter.rankingScore.length > 0) {
      if ('Non N/A' === filter.rankingScore[0]) {
        naFilter = true;
      }
    }

    const name = filter.name ? filter.name[0] : undefined;
    const state = filter.state ? filter.state[0] : undefined;
    const unitid = filter.unitid ? filter.unitid[0] : undefined;
    form.setFieldsValue({ name, state, unitid, naFilter });
    await pageHandler(pagination.current, pagination.pageSize);
    await sortHandler(sorter.field, sorter.order);
  };

  const resetFilters = async () => {
    form.resetFields();
    setPage(1);
    setLimit(10);
    await getData({});
  };

  async function syncToWP() {
    try {
      setWorking(true);
      await axios.get(`${SERVER_URL}/sp-slug-wp`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      setWorking(false);
      notification.success({
        message: 'Success',
        placement: 'bottomRight',
        description: 'School Profiles synced to WP',
      });
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Problem with syncing school profiles to WP',
        placement: 'bottomRight',
      });
    }
  }

  async function syncToWPDash() {
    try {
      setWorking(true);
      await axios.get(`${SERVER_URL}/sp-dashboard-wp`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      setWorking(false);
      notification.success({
        message: 'Success',
        placement: 'bottomRight',
        description: 'School Dashboards synced to WP',
      });
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Problem with syncing school dashboards to WP',
        placement: 'bottomRight',
      });
    }
  }

  async function pageHandler(page, pageSize) {
    setPage(page);
    setLimit(pageSize);
    form.setFieldsValue({ page, limit: pageSize });
    await getData({ ..._.pickBy(form.getFieldsValue(), _.identity) });
  }

  async function getData(values) {
    const clearedValues = _.pickBy(values, _.identity);
    const result = new URLSearchParams(clearedValues).toString();
    await fetchSchoolProfiles(result);

    // (re)set degree after location change
    if (values.degree) {
      setDegree(values.degree);
    } else {
      setDegree(null);
    }

    // (re)set cipcode after location change
    if (values.cipcode) {
      setCipcode(values.cipcode);
    } else {
      setCipcode(null);
    }
  }

  async function handleValuesChange(changedValues, allValues) {
    setCipcode(allValues.cipcode);
    setOnline(allValues.onlineOrHybrid);
    setDegree(allValues.degree);
    await getData({ ...allValues });
  }

  // const changeRadios = (e) => {
  //   switch (e.target.value) {
  //     case 'Characteristics':
  //       setUseChar(true);
  //       setUseCost(false);
  //       setUseGradRates(false);
  //       setUseAdmissions(false);
  //       setUseKPI(false);
  //       setUseRepayment(false);
  //       setUseCrime(false);
  //       break;
  //     case 'Cost':
  //       setUseChar(false);
  //       setUseCost(true);
  //       setUseGradRates(false);
  //       setUseAdmissions(false);
  //       setUseKPI(false);
  //       setUseRepayment(false);
  //       setUseCrime(false);
  //       break;
  //     case 'Graduation Rates':
  //       setUseChar(false);
  //       setUseCost(false);
  //       setUseGradRates(true);
  //       setUseAdmissions(false);
  //       setUseKPI(false);
  //       setUseRepayment(false);
  //       setUseCrime(false);
  //       break;
  //     case 'Admissions':
  //       setUseChar(false);
  //       setUseCost(false);
  //       setUseGradRates(false);
  //       setUseAdmissions(true);
  //       setUseKPI(false);
  //       setUseRepayment(false);
  //       setUseCrime(false);
  //       break;
  //     case 'KPI':
  //       setUseChar(false);
  //       setUseCost(false);
  //       setUseGradRates(false);
  //       setUseAdmissions(false);
  //       setUseKPI(true);
  //       setUseRepayment(false);
  //       setUseCrime(false);
  //       break;
  //     case 'Repayment':
  //       setUseChar(false);
  //       setUseCost(false);
  //       setUseGradRates(false);
  //       setUseAdmissions(false);
  //       setUseKPI(false);
  //       setUseRepayment(true);
  //       setUseCrime(false);
  //       break;
  //     case 'Crime':
  //       setUseChar(false);
  //       setUseCost(false);
  //       setUseGradRates(false);
  //       setUseAdmissions(false);
  //       setUseKPI(false);
  //       setUseRepayment(false);
  //       setUseCrime(true);
  //       break;
  //     default:
  //       setUseChar(false);
  //       setUseCost(false);
  //       setUseGradRates(false);
  //       setUseAdmissions(false);
  //       setUseKPI(false);
  //       setUseRepayment(false);
  //       setUseCrime(false);
  //       break;
  //   }
  // };

  return (
    <div className='content-wrapper'>
      <Spin spinning={working} tip='Working...'>
        <div className='actions-block'>
          <Space>
            <Button type='primary' onClick={resetFilters} style={{ marginLeft: '2px' }}>
              RESET FILTERS
            </Button>
            <Form form={form} layout='inline' onValuesChange={handleValuesChange}>
              <Form.Item hidden={true} name='page'>
                <Input />
              </Form.Item>
              <Form.Item hidden={true} name='sort'>
                <Input />
              </Form.Item>
              <Form.Item hidden={true} name='limit'>
                <Input />
              </Form.Item>
              <Form.Item hidden={true} name='name'>
                <Input />
              </Form.Item>
              <Form.Item hidden={true} name='state'>
                <Input />
              </Form.Item>
              <Form.Item hidden={true} name='unitid'>
                <Input />
              </Form.Item>
              <Form.Item hidden={true} name='naFilter'>
                <Input />
              </Form.Item>
              <Row wrap={false} gutter={1}>
                <Col>
                  <Form.Item name='control' style={{ marginRight: '2px' }}>
                    <Select allowClear style={{ width: '200px', marginLeft: '1px' }} placeholder='Control...'>
                      <Select.Option key='Public' value='Public'>
                        Public
                      </Select.Option>
                      <Select.Option key='Private for-profit' value='Private for-profit'>
                        Private for-profit
                      </Select.Option>
                      <Select.Option key='Private not-for-profit' value='Private not-for-profit'>
                        Private not-for-profit
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name='degree' style={{ marginRight: '0px' }}>
                    <Select allowClear style={{ width: '150px', marginLeft: '1px' }} placeholder='Degree...'>
                      {DEGREES.map(({ value, label }, index) => (
                        <Select.Option
                          key={`${value}_${index}`}
                          value={value}
                          disabled={!cipcode && ["Master's", 'Doctoral'].includes(label)}
                        >
                          {label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name='region' style={{ marginRight: '1px' }}>
                    <Select allowClear style={{ width: '150px', marginLeft: '4px' }} placeholder='Region...'>
                      {REGIONS.map(({ value }, index) => (
                        <Select.Option key={`${value}_${index}`} value={value}>
                          {value}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name='carnegie' style={{ marginRight: '1px' }}>
                    <Select
                      allowClear
                      showSearch
                      style={{ width: '200px', marginLeft: '1px' }}
                      placeholder='Carnegie Class...'
                    >
                      {CARNEGIE_SET.map(({ values, label, code }, index) => (
                        <Select.Option key={`${label}_${index}`} value={values[0].Code}>
                          ({code}) {label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name='online' style={{ marginRight: '1px' }}>
                    <Select allowClear style={{ width: '100px', marginLeft: '1px' }} placeholder='Online...'>
                      <Select.Option key='Online' value='online'>
                        Online
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name='cipcode'>
                    <Select
                      allowClear
                      showSearch
                      optionFilterProp='children'
                      style={{ width: '300px', fontSize: '13px', marginLeft: '4px' }}
                      placeholder='Program...'
                    >
                      {CIP_CODES.map((cip, index) => (
                        <Select.Option
                          key={`${cip.code}_${index}`}
                          value={cip.code}
                          style={{ fontSize: '12px' }}
                          title={cip.label}
                        >
                          {cip.code} - {cip.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Space>
        </div>
        <div>
          <Table
            size='middle'
            title={() => (
              <Space>
                <span>{`Total Schools: ${totalDocs}`}</span>
                {!currentuser.data.role.includes('guest') && (
                  <>
                    <Tooltip title='Send hardcoded UNITIDs to WP'>
                      <Button type='danger' onClick={syncToWP} style={{ marginLeft: '2px' }}>
                        <SyncOutlined spin={working} />
                        SYNC PROFILES TO WP
                      </Button>
                    </Tooltip>

                    <Tooltip title='Send school dashboard UNITIDs to WP'>
                      <Button type='danger' onClick={syncToWPDash} style={{ marginLeft: '2px' }}>
                        <SyncOutlined spin={working} />
                        SYNC DASHBOARD TO WP
                      </Button>
                    </Tooltip>

                    <Button
                      size='small'
                      style={{ marginLeft: '2px' }}
                      title='Export to excel'
                      onClick={() => setVisible(true)}
                    >
                      <ExportOutlined /> {'Export to excel'}
                    </Button>
                  </>
                )}
              </Space>
            )}
            onChange={onChangeTable}
            bordered
            dataSource={[...data]}
            columns={columns}
            rowKey={(record) => (record.credential ? record._id + record.credential : record._id)}
            pagination={{
              current: page,
              total: totalDocs,
              pageSize: limit || 10,
            }}
            scroll={{
              x: 1600,
            }}
          />
        </div>
        <Modal
          centered
          width='30%'
          visible={visible}
          title='Customize Excel output'
          // okText='EXPORT'
          okButtonProps={{ hidden: true }}
          cancelText='CANCEL'
          maskClosable={false}
          // onOk={() => setVisible(false)}
          onCancel={() => setVisible(false)}
          confirmLoading={working}
        >
          {/* <Radio.Group onChange={(e) => changeRadios(e)}>
            <Space direction='vertical'>
              <Radio value={'Characteristics'}>Characteristics</Radio>
              <Radio value={'Cost'}>Cost</Radio>
              <Radio value={'Graduation Rates'}>Graduation Rates</Radio>
              <Radio value={'Admissions'}>Admissions</Radio>
              <Radio value={'KPI'}>KPI</Radio>
              <Radio value={'Repayment'}>Repayment</Radio>
              <Radio value={'Crime'}>Crime</Radio>
            </Space>
          </Radio.Group> */}
          <Checkbox checked={useChar} onChange={handleCheckboxChange(setUseChar)}>
            Characteristics
          </Checkbox>
          <br></br>
          <br></br>
          <Checkbox checked={useCost} onChange={handleCheckboxChange(setUseCost)}>
            Cost
          </Checkbox>
          <br></br>
          <br></br>
          <Checkbox checked={useGradRates} onChange={handleCheckboxChange(setUseGradRates)}>
            Graduation Rates
          </Checkbox>
          <br></br>
          <br></br>
          <Checkbox checked={useAdmissions} onChange={handleCheckboxChange(setUseAdmissions)}>
            Admissions
          </Checkbox>
          <br></br>
          <br></br>
          <Checkbox checked={useKPI} onChange={handleCheckboxChange(setUseKPI)}>
            KPI
          </Checkbox>
          <br></br>
          <br></br>
          <Checkbox checked={useRepayment} onChange={handleCheckboxChange(setUseRepayment)}>
            Repayment
          </Checkbox>
          <br></br>
          <br></br>
          <Checkbox checked={useCrime} onChange={handleCheckboxChange(setUseCrime)}>
            Crime
          </Checkbox>
          <br></br>
          <br></br>

          <ExportToExcelCustomForProfiles
            setWorking={setWorking}
            urlQuery={queryString}
            useKPI={useKPI}
            useChar={useChar}
            useCost={useCost}
            useGradRates={useGradRates}
            useAdmissions={useAdmissions}
            useRepayment={useRepayment}
            useCrime={useCrime}
            setVisible={setVisible}
            cipcode={cipcode}
            degree={degree}
            propsToRemove={[
              '_id',
              'MD_EARN_WNE_P10',
              'UGDS',
              // 'Average net price',
              // 'Average completion time',
              'Payback',
              'degreePremium',
            ]}
          />
        </Modal>
      </Spin>
    </div>
  );
};

export default SchoolProfiles;
