import React, { useState, useEffect, useContext } from 'react';
import { Button, Tooltip, Table, Input, Switch, notification, Spin, Popconfirm } from 'antd';
import {
  SearchOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
  SyncOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import Axios from 'axios';
import { UserContext } from '../../App';
import { SERVER_URL } from '../../config';
import { Link, useHistory } from 'react-router-dom';
import RankingContent from '../../components/forms/RankingContent';

const Undergraduate = () => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [refresh, setRefresh] = useState(null);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState(null);
  const [search, setSearch] = useState({});
  const [modal, setModal] = useState({
    visible: false,
    cipcode: null,
    category: '',
    categoryUrl: '',
    title: '',
    content: '',
    metaTitle: '',
    metaDescription: '',
    bobbleDescription: '',
    metaImage: '',
    metaUrl: '',
    listName: '',
    bubbles: [],
    degree: null,
    spotlight: null,
    emi: false,
    shortcode: null,
    relatedCareers: [],
  });
  const [fetchTrigger, setFetchTrigger] = useState(false);
  const [working, setWorking] = useState(false);
  const [content, setContent] = useState('');
  const [selectedForRefresh, setSelectedForRefresh] = useState([]); // use to refresh
  const [bubbles, setBubbles] = useState([]);
  const [allSpotlights, setAllSpotlights] = useState([]);
  const [allCareers, setAllCareers] = useState([]);

  useEffect(() => {
    let urlQuery = '?type=undergraduate';
    if (filter) urlQuery += `&filter=${filter}`;

    const fetchData = async () => {
      try {
        setWorking(true);
        const res = await Axios.get(`${SERVER_URL}/rankings${urlQuery}`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
        if (res) {
          setData(res.data);
          // const plRankings = res.data
          //   .filter((item) => item.isProgramLevel && !item.showAdditional)
          //   .map((item) => ({
          //     _id: item._id,
          //     category: item.category,
          //     cipcode: item.cipcode,
          //     categoryUrl: item.categoryUrl,
          //     parent: item.parent,
          //     content: item.content,
          //   }));
          // setAllRankingsPL(plRankings);
        }

        const spotlightRes = await Axios.get(`${SERVER_URL}/spotlight`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
        if (spotlightRes) {
          setAllSpotlights(spotlightRes.data);
        }

        const careerRes = await Axios.get(`${SERVER_URL}/careers-ranking-dropdown`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
        if (careerRes) {
          setAllCareers(careerRes.data);
        }

        // const websiteRes = await Axios.get(`${SERVER_URL}/website-degreechoices`, {
        //   withCredentials: false,
        //   headers: { Authorization: `Bearer ${currentuser.data.token}` },
        // });
        // if (websiteRes) {
        //   setWebsite(websiteRes.data);
        // }

        setWorking(false);
      } catch (error) {
        setWorking(false);
        console.log(error);
      }
    };

    fetchData();
  }, [filter, fetchTrigger, currentuser.data.token]);

  // Coulmn search
  let searchInput;
  const getColumnSearchProps = (dataIndex) => {
    if (Array.isArray(dataIndex)) dataIndex = dataIndex.join('.');
    const prop = dataIndex;
    return {
      filteredValue: (filter && filter[prop] && [filter[prop]]) || null,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              ref={(node) => {
                searchInput = node;
              }}
              placeholder={`Search ${prop}`}
              value={selectedKeys}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, prop)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />

            <Button
              type='primary'
              onClick={() => handleSearch(selectedKeys, confirm, prop)}
              size='small'
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>

            <Button onClick={() => handleReset(clearFilters, prop)} size='small' style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        );
      },
      filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />,
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => {
            searchInput.select();
          });
        }
      },
      // sorter: true,
      // defaultSortOrder: prop === 'title' ? 'ascend' : undefined,
      // sortDirections: ['ascend', 'descend'],
      render: (text) =>
        search[prop] ? (
          <Highlighter
            title={text}
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[search[prop]]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          <span title={text}>{text}</span>
        ),
    };
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    if (Array.isArray(dataIndex)) dataIndex = dataIndex.join('.');
    const newSearch = { ...search };
    newSearch[dataIndex] = selectedKeys[0];
    setSearch(newSearch);
    filterHandler(dataIndex, selectedKeys[0]);
    confirm();
  };

  const handleReset = (clearFilters, prop) => {
    if (Array.isArray(prop)) prop = prop.join('.');
    const newSearch = { ...search };
    setSearch(newSearch);
    delete newSearch[prop];
    filterHandler(prop, null);
    clearFilters();
  };

  const filterHandler = (prop, value) => {
    if (Array.isArray(prop)) prop = prop.join('.');

    let filterQuery = null;
    let prevFilter = {};
    if (filter) {
      prevFilter = JSON.parse(filter);
    }

    if (value !== null) {
      prevFilter[prop] = value;
      filterQuery = `${JSON.stringify({ [prop]: !isNaN(value) ? +value : [value.toString()], ...prevFilter })}`;
    } else {
      delete prevFilter[prop];
      filterQuery = `${JSON.stringify({ ...prevFilter })}`;
    }

    setFilter(filterQuery);
    // setFetchTrigger(!fetchTrigger);
  };

  const columns = [
    {
      title: 'CIPCODE',
      dataIndex: ['cipcode'],
      key: 'cipcode',
      ...getColumnSearchProps(['cipcode']),
    },
    {
      title: 'CATEGORY',
      dataIndex: ['category'],
      key: 'category',
      ...getColumnSearchProps(['category']),
      render: (text, record) => (!record.parent ? text : record.parent.category),
    },
    {
      title: 'ADDITIONAL',
      dataIndex: 'category',
      key: 'category-additional',
      render: (text, record) => (!record.parent ? null : text),
      // ...getColumnSearchProps('name'),
    },
    // {
    //   title: 'DEGREE',
    //   dataIndex: 'degree',
    //   key: 'degree',
    //   ...getColumnSearchProps('degree'),
    // },
    {
      title: 'TOTAL SCHOOLS',
      dataIndex: 'items',
      key: 'items',
      align: 'right',
      render: (text, record) => record.items ?? 0,
      // ...getColumnSearchProps(['createdBy', 'email']),
    },
    {
      title: 'CREATED AT',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'right',
      // ...getColumnSearchProps('name'),
    },
    {
      title: 'UPDATED AT',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      align: 'right',
      // ...getColumnSearchProps('name'),
    },
  ];

  const deleteHandler = async (record) => {
    try {
      setWorking(true);

      if (record.emi) {
        await Axios.delete(`${SERVER_URL}/rankings-emi`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
      } else if (record.isProgramLevel) {
        await Axios.delete(`${SERVER_URL}/rankings-universal/${record._id}`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
      } else {
        await Axios.delete(`${SERVER_URL}/rankings-universal/${record._id}`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
      }

      setWorking(false);
      notification.success({
        message: 'Ranking is deleted',
        placement: 'bottomRight',
      });

      setFetchTrigger(!fetchTrigger);
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Error',
        description: 'Problem with deleting ranking',
        placement: 'bottomRight',
      });
    }
  };

  const modalHandler = (record) => {
    setModal({
      visible: true,
      id: record._id,
      cipcode: record.cipcode,
      category: record.category,
      categoryUrl: record.categoryUrl,
      title: record.content
        ? record.content.title
          ? record.content.title
          : record.content.h1
          ? record.content.h1
          : ''
        : '',
      listName: record.content ? record.content.listName : '',
      metaTitle: record.content ? record.content.metaTitle : '',
      metaDescription: record.content ? record.content.metaDescription : '',
      bobbleDescription: record.content ? record.content.bobbleDescription : '',
      metaImage: record.content ? record.content.metaImage : '',
      metaUrl: record.content ? record.content.metaUrl : '',
      degree: record.degree,
      additional: record.parent && !record.degree ? true : false,
      spotlight: record.spotlight,
      shortcode: record.shortcode,
      emi: record.emi,
      relatedCareers: record.relatedCareers,
      similarPrograms: record.similarPrograms,
      isProgramLevel: record.isProgramLevel,
    });

    // filter out the option on the modal (can't select itself)
    // setAllRankingsPL(allRankingsPL.filter((item) => item._id !== record._id));

    setContent(
      record.content && record.content.content
        ? record.content.content
        : record.content && record.content.description
        ? record.content.description
        : '',
    );

    if (record.content && record.content.bubbles && record.content.bubbles.length > 0) {
      setBubbles(record.content.bubbles);
    } else setBubbles([]);
  };

  const handleActivate = async (id, checked) => {
    try {
      setWorking(true);
      await Axios.patch(
        `${SERVER_URL}/rankings-universal/${id}`,
        {
          active: checked,
        },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );

      setWorking(false);
      setFetchTrigger(!fetchTrigger);

      notification.success({
        message: 'Success',
        description: 'Ranking updated',
      });
    } catch (error) {
      console.log(error);
      setWorking(false);
      notification.error({
        message: 'Error',
        description: 'Problem with (de)activating ranking. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  columns.push(
    {
      title: 'Content',
      render: (text, record) => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '15px',
            }}
          >
            <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
              <Tooltip title='Edit Content' placement='topLeft'>
                <EditOutlined style={{ textDecoration: 'none', color: 'black' }} onClick={() => modalHandler(record)} />
              </Tooltip>
            </div>
          </div>
        );
      },
    },
    {
      title: 'New Content',
      render: (text, record) => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '15px',
            }}
          >
            {record.isProgramLevel && (
              <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
                <Tooltip title='Edit new design content'>
                  <EditOutlined
                    style={{ textDecoration: 'none', color: 'black' }}
                    onClick={() => history.push(`/admin/rankings-content/${record._id}`)}
                  />
                </Tooltip>

                {record.useNewTemplate && (
                  <Tooltip title='Using new design'>
                    <CheckOutlined style={{ color: 'green', marginLeft: '10px' }} />
                  </Tooltip>
                )}
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: 'Action',
      render: (text, record) => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '15px',
            }}
          >
            {record.category !== 'Search' && (
              <Tooltip title='Edit Ranking' placement='topLeft'>
                <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='table-actions'>
                  <EditOutlined
                    onClick={() =>
                      record.isProgramLevel
                        ? history.push(`/admin/rankings/undergraduate/${record._id}`)
                        : record.emi
                        ? history.push(`/admin/rankings/emi/`)
                        : history.push(`/admin/rankings/institutional/${record._id}`)
                    }
                  />
                </div>
              </Tooltip>
            )}

            {deleteHandler && (
              <Tooltip title='Delete Raking' placement='topLeft'>
                {record.category !== 'Search' && (
                  <Popconfirm
                    title={`Are you sure you want to delete this ranking?`}
                    onConfirm={async () => {
                      deleteHandler(record);
                    }}
                    onCancel={() => console.log('Cancel')}
                    okText='Yes'
                    cancelText='No'
                  >
                    <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='table-actions'>
                      <DeleteOutlined />
                    </div>
                  </Popconfirm>
                )}
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      title: 'Active',
      render: (text, record) => {
        return (
          <div>
            {record.isProgramLevel && (
              <Switch
                checked={record.active}
                onChange={(checked) => handleActivate(record._id, checked)}
                disabled={record.degree === 'Undergraduate Certificate or Diploma' || record?.parent?.active === false}
              />
            )}
          </div>
        );
      },
    },
  );

  let tableData = [];
  if (data && data?.length > 0) {
    tableData = data.map((item) => {
      item.createdAt = new Date(item.createdAt).toLocaleString('en-US');
      item.updatedAt = new Date(item.updatedAt).toLocaleString('en-US');
      return item;
    });
  }

  const handleRefresh = async () => {
    if (!selectedForRefresh || selectedForRefresh.length === 0) return;

    setWorking(true);
    try {
      for (const selectedID of selectedForRefresh) {
        const foundRanking = data.find((item) => item._id === selectedID);

        // institution level
        if (foundRanking && !foundRanking.isProgramLevel) {
          if (foundRanking.category === 'Search') {
            // undergrad search
            await Axios.post(
              `${SERVER_URL}/rankings-undergrad-search-save`,
              {},
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${currentuser.data.token}` },
              },
            );
          } else if (foundRanking.emi) {
            await Axios.get(`${SERVER_URL}/rankings-emi-creation`, {
              withCredentials: false,
              headers: { Authorization: `Bearer ${currentuser.data.token}` },
            });
          } else {
            let newMethodRes;
            if (!foundRanking.parent) {
              newMethodRes = await Axios.get(
                `${SERVER_URL}/rankings-new-methodology-creation?category=${foundRanking.categoryUrl}`,
                {
                  withCredentials: false,
                  headers: { Authorization: `Bearer ${currentuser.data.token}` },
                },
              );
            } else {
              newMethodRes = await Axios.get(
                `${SERVER_URL}/rankings-new-methodology-creation?category=${foundRanking.parent.categoryUrl}&additional=${foundRanking.categoryUrl}`,
                {
                  withCredentials: false,
                  headers: { Authorization: `Bearer ${currentuser.data.token}` },
                },
              );
            }

            if (!newMethodRes) {
              setWorking(false);
              notification.error({
                message: `Problem with refreshing ${foundRanking.category}`,
                placement: 'bottomRight',
              });
              return;
            }

            await Axios.patch(
              `${SERVER_URL}/rankings-universal/${selectedID}`,
              {
                items: newMethodRes.data.ranking.rankingItems,
                weightedAveragesNational: newMethodRes.data.ranking.weightedAveragesNational,
              },
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${currentuser.data.token}` },
              },
            );
          }
        } else if (foundRanking && foundRanking.isProgramLevel) {
          const newMethodRes = await Axios.get(
            `${SERVER_URL}/rankings-new-methodology-creation?degree=Bachelors Degree&cipcode=${foundRanking.cipcode}&isProgramLevel=true`,
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${currentuser.data.token}` },
            },
          );

          if (!newMethodRes) {
            setWorking(false);
            notification.error({
              message: `Problem with refreshing ${foundRanking.category}`,
              placement: 'bottomRight',
            });
            return;
          }

          await Axios.patch(
            `${SERVER_URL}/rankings-universal/${selectedID}`,
            {
              items: newMethodRes.data.ranking.rankingItems,
              weightedAveragesNational: newMethodRes.data.ranking.weightedAveragesNational,
            },
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${currentuser.data.token}` },
            },
          );
        } else return;

        // replace school links in rankings
        await Axios.get(`${SERVER_URL}/rankings-update-sp-link?id=${selectedID}`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
      }

      // refresh sitemap and empty pages
      await Axios.get(`${SERVER_URL}/rankings-sitemap-refresh?type=undergrad`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: 'Undergrad rankings refreshed.',
        placement: 'bottomRight',
      });

      setFetchTrigger(!fetchTrigger);

      setWorking(false);
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Problem with refreshing undergrad rankings. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  const onSelectChange = (selectedRowKeys) => {
    setSelectedForRefresh([...selectedRowKeys]);
  };

  const rowSelection = {
    selectedForRefresh,
    onChange: onSelectChange,
  };

  const handleSitemap = async () => {
    try {
      setWorking(true);

      // sitemap
      await Axios.get(`${SERVER_URL}/rankings-sitemap-refresh?type=undergrad`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: 'BC Sitemap updated',
        placement: 'bottomRight',
      });

      setWorking(false);
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Problem with refreshing BC sitemap',
        placement: 'bottomRight',
      });
    }
  };

  const handleSPLinks = async () => {
    try {
      setWorking(true);

      // replace school links in rankings
      await Axios.get(`${SERVER_URL}/rankings-update-sp-link?degree=Bachelors Degree`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: 'School Profiles links updated',
        placement: 'bottomRight',
      });

      setWorking(false);
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Problem with refreshing SP links inside rankings',
        placement: 'bottomRight',
      });
    }
  };

  const handleRefreshAll = async () => {
    try {
      setWorking(true);

      // STEP 1: refresh all institution level
      await Axios.patch(
        `${SERVER_URL}/rankings?type=undergrad`,
        {},
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );

      // STEP 2: refresh EMI
      await Axios.get(`${SERVER_URL}/rankings-emi-creation`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      // STEP 3: refresh undergrad search
      await Axios.post(
        `${SERVER_URL}/rankings-undergrad-search-save`,
        {},
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );

      // STEP 4: refresh program level
      const cipcodesBody = data
        .filter((item) => item.isProgramLevel)
        .map((item) => ({
          cipcode: item.cipcode,
          category: item.category,
          categoryUrl: item.categoryUrl,
          degree: item.degree,
          parent: item.parent,
        }));

      await Axios.patch(
        `${SERVER_URL}/rankings?type=undergrad&isProgramLevel=true`,
        { cipcodes: cipcodesBody },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );

      // STEP 5: refresh sitemap and empty pages
      await Axios.get(`${SERVER_URL}/rankings-sitemap-refresh?type=undergrad`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      // STEP 6: replace school links in rankings
      await Axios.get(`${SERVER_URL}/rankings-update-sp-link?degree=Bachelors Degree`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      setWorking(false);
      setFetchTrigger(!fetchTrigger);

      notification.success({
        message: 'Rankings saved.',
        placement: 'bottomRight',
      });
    } catch (error) {
      setWorking(false);
      console.log(error.response);
      notification.error({
        message: 'Problem with refreshing rankings. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  return (
    <div className='content-wrapper'>
      <Spin spinning={working} tip='Working...'>
        <div className='actions-block flex'>
          <Link to={'/admin/rankings/new/undergraduate'}>
            <Tooltip title='Create new ranking'>
              <Button type='primary'>
                <PlusCircleOutlined />
                NEW RANKING
              </Button>
            </Tooltip>
          </Link>

          <Tooltip title='/best-colleges'>
            <Button type='danger' onClick={handleSitemap}>
              <SyncOutlined spin={working} />
              Refresh Sitemap
            </Button>
          </Tooltip>

          <Button type='danger' onClick={handleSPLinks}>
            <SyncOutlined spin={working} />
            Refresh SP Links
          </Button>

          <Button type='danger' onClick={handleRefreshAll}>
            <SyncOutlined spin={working} />
            Refresh All
          </Button>

          <Button
            type='danger'
            onClick={handleRefresh}
            disabled={!selectedForRefresh || selectedForRefresh.length === 0}
          >
            Refresh Selected Ranking(s)
          </Button>

          <h2>Total: {data.length}</h2>
        </div>
        <div>
          <Table
            size='middle'
            // onChange={onChangeTable}
            bordered
            dataSource={tableData}
            columns={columns}
            rowKey={(record) => record._id}
            rowSelection={rowSelection}
            pagination={{
              pageSize: 20,
              // total: total || 0,
              // current: page,
              // defaultCurrent: 1,
              // position: 'bottom',
              // showSizeChanger: false,
              // onChange: (page) => pageHandler(page),
              // hideOnSinglePage: true,
            }}
          />
        </div>

        <RankingContent
          modal={modal}
          degree={'undergraduate'}
          setModal={setModal}
          fetchTrigger={fetchTrigger}
          setFetchTrigger={setFetchTrigger}
          content={content}
          setContent={setContent}
          bubbles={bubbles}
          setBubbles={setBubbles}
          refreshBubbles={refresh}
          setRefreshBubbles={setRefresh}
          allSpotlights={allSpotlights}
          working={working}
          setWorking={setWorking}
          currentuser={currentuser}
          allCareers={allCareers}
          // allRankingsPL={allRankingsPL}
          // website={website}
        />
      </Spin>
    </div>
  );
};

export default Undergraduate;
