import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Input, Table, Tag, Tooltip } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  SearchOutlined,
  HistoryOutlined,
  SyncOutlined,
  LoadingOutlined,
} from '@ant-design/icons';

const WebsiteTable = ({
  SERVER_URL,
  token,
  data,
  deleteHandler,
  columnKeys,
  title,
  editPath,
  viewPath,
  revisionPath,
  used,
  syncHandler,
}) => {
  const [sync, setSync] = useState('');
  let searchInput;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size='small'
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />,
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex) ? record[dataIndex[0]][dataIndex[1]] : record[dataIndex];
      if (!filterRecord || !value) return false;
      return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const Status = ({ item }) => {
    let status = <LoadingOutlined spin />;
    if (item) {
      if (item === 'Connection OK') {
        status = (
          <Tag title='Connection with WP is OK' color='green'>
            CONNECTION OK
          </Tag>
        );
      }
      if (item.toLowerCase().includes('timeout')) {
        status = (
          <Tag title='Timeout Error connecting to WP. The Website might be down or very slow.' color='red'>
            CONNECTION ERROR
          </Tag>
        );
      }
      if (item.toLowerCase().includes('username')) {
        status = (
          <Tag title='Admin username error, unable to establish connection.' color='red'>
            ADMIN USERNAME ERROR
          </Tag>
        );
      }
      if (item.toLowerCase().includes('password')) {
        status = (
          <Tag title='Admin password error, unable to establish connection.' color='red'>
            ADMIN PASSWORD ERROR
          </Tag>
        );
      }
      if (item.toLowerCase().includes('enotfound')) {
        status = (
          <Tag title='Internet error. Url might be wrong.' color='red'>
            INTERNET ERROR
          </Tag>
        );
      }
    }
    return <div style={{ textAlign: 'center' }}>{status}</div>;
  };

  const columns = columnKeys.map((item) => {
    return {
      key: item,
      title: item.toUpperCase(),
      dataIndex: item,
      render: (value) => (item === 'status' ? <Status item={value} /> : value),
      ...getColumnSearchProps(item),
    };
  });

  columns.push({
    title: 'Action',
    render: (text, record) => (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
        <Tooltip title={`Edit ${title.toLowerCase()}`}>
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
            <Link
              to={
                editPath === '/admin/edit-website/'
                  ? editPath + record._id + '/list-career-widgets'
                  : editPath + record._id
              }
            >
              <EditOutlined style={{ textDecoration: 'none', color: 'black' }} />
            </Link>
          </div>
        </Tooltip>

        {viewPath && (
          <Tooltip title={`View ${title.toLowerCase()}`}>
            <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
              <Link to={viewPath + record._id}>
                <EyeOutlined style={{ textDecoration: 'none', color: 'black' }} />
              </Link>
            </div>
          </Tooltip>
        )}

        <Tooltip title={`Delete ${title.toLowerCase()}`}>
          <div
            title='Synchronize'
            style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}
            onClick={async () => {
              deleteHandler(record);
            }}
          >
            <DeleteOutlined />
          </div>
        </Tooltip>

        {revisionPath && (
          <Tooltip title={`Revision ${title.toLowerCase()}`}>
            <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
              <Link to={revisionPath + record._id}>
                <HistoryOutlined style={{ textDecoration: 'none', color: 'black' }} />
              </Link>
            </div>
          </Tooltip>
        )}

        {syncHandler && (
          <Tooltip title='Synchronize'>
            <div
              style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}
              onClick={async () => {
                setSync(record._id);
                await syncHandler(record._id);
                setSync('');
              }}
            >
              <SyncOutlined spin={sync === record._id} />
            </div>
          </Tooltip>
        )}
      </div>
    ),
  });

  return (
    <div>
      <Table
        size='middle'
        bordered
        dataSource={data}
        columns={columns}
        rowKey={(record) => record._id}
        pagination={{
          defaultPageSize: 20,
          position: 'bottom',
          showSizeChanger: false,
          pageSizeOptions: ['10', '20', '50', '100'],
          hideOnSinglePage: true,
        }}
      />
    </div>
  );
};

export default WebsiteTable;
