import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../App';
import axios from 'axios';
import { SERVER_URL } from '../../config';
import { Button, Form, Input, Modal, Spin, Switch, Table, Select, notification, Space, Image } from 'antd';
import InstitutionsSearch from '../../components/controls/InstitutionsSearch';
import { CheckOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import CKEditor from '../../components/base/CKEditor';

const STATES = require('../../lib/states.json');

export default function Spotlight() {
  const [form] = Form.useForm();
  const [working, setWorking] = useState(false);
  const [data, setData] = useState([]);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [providers, setProviders] = useState([]);
  const [content, setContent] = useState('');
  const [tags, setTags] = useState([]);
  const currentUser = useContext(UserContext);

  const loadData = async () => {
    try {
      setWorking(true);
      const { data } = await axios.get(`${SERVER_URL}/spotlight`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentUser.data.token}` },
      });
      setData(data);

      const tagsRes = await axios.get(`${SERVER_URL}/affiliate-institution-tag/all`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentUser.data.token}` },
      });
      setTags(tagsRes.data);

      setWorking(false);
    } catch (e) {
      console.log(e);
      setWorking(false);
    }
  };

  useEffect(() => {
    const fetchProviders = async () => {
      try {
        setWorking(true);
        const { data: providers } = await axios.get(`${SERVER_URL}/affiliate-institution-provider`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentUser.data.token}` },
        });
        if (providers) {
          setProviders(providers);
        }
        setWorking(false);
      } catch (error) {
        setWorking(false);
        console.log('Error: ', error);
      }
    };

    fetchProviders();
    loadData().catch((e) => console.log(e));
  }, [currentUser.data.token]);

  const columns = [
    {
      title: 'CMS display name',
      dataIndex: 'CMSDisplayName',
      key: 'CMSDisplayName',
    },
    {
      title: 'Institute Name',
      dataIndex: 'institutionName',
      key: 'institutionName',
    },
    {
      title: 'Link',
      dataIndex: 'affLink',
      key: 'affLink',
      render: (link) =>
        link ? (
          <Button type='link' href={link} target='_blank'>
            Link
          </Button>
        ) : null,
    },
    {
      title: 'Provider',
      dataIndex: 'provider',
      key: 'provider',
    },
    {
      title: 'Online',
      dataIndex: 'online',
      key: 'online',
      render: (online) => (online ? <CheckOutlined /> : null),
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      align: 'center',
      render: (active) => (active ? <CheckOutlined /> : null),
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      render: (text, record) => (
        <Space>
          <DeleteOutlined onClick={() => handleDelete(record._id)} />
          <EditOutlined onClick={() => handleEdit(record)} />
        </Space>
      ),
    },
  ];

  if (!data) return null;

  let handleAdd = async () => {
    try {
      let data = await form.validateFields();
      const { _id } = data;
      setWorking(true);

      if (content) data.content = content;

      if (_id) {
        await axios.put(`${SERVER_URL}/spotlight/${_id}`, data, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentUser.data.token}` },
        });
      } else {
        await axios.post(`${SERVER_URL}/spotlight`, data, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentUser.data.token}` },
        });
      }

      await loadData();
      setWorking(false);
      setIsAddModalVisible(false);
      form.resetFields();
      setContent('');
    } catch (e) {
      console.log(e);
      setWorking(false);
    }
  };

  function handleDelete(id) {
    Modal.confirm({
      title: 'Are you sure    you want to delete this spotlight?',
      onOk: async () => {
        try {
          setWorking(true);
          await axios.delete(`${SERVER_URL}/spotlight/${id}`, {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentUser.data.token}` },
          });
          await loadData();
          setWorking(false);
          notification.success({
            message: 'Success',
            description: 'Spotlight deleted successfully',
          });
        } catch (e) {
          console.log(e);
          setWorking(false);
          notification.error({
            message: 'Error',
            description: 'Error deleting spotlight',
          });
        }
      },
    });
  }

  function handleEdit(record) {
    form.setFieldsValue(record);
    setContent(record.content);
    setIsAddModalVisible(true);
  }

  let handleCancel = () => {
    form.resetFields();
    setIsAddModalVisible(false);
  };
  return (
    <div className='content-wrapper'>
      <div className='actions-block flex'>
        <div>
          <Button onClick={() => setIsAddModalVisible(true)} type='primary' style={{ marginRight: '8px' }}>
            Add
          </Button>
        </div>
      </div>
      <Spin spinning={working} tip='Working...'>
        <Table dataSource={data} columns={columns} />
      </Spin>
      <Modal
        width={800}
        title='Add Spotlight'
        visible={isAddModalVisible}
        centered
        onOk={handleAdd}
        onCancel={handleCancel}
        okText='SAVE'
      >
        <Form confirmLoading={working} form={form} initialValues={{}} labelCol={{ span: 10 }} wrapperCol={{ span: 14 }}>
          <Form.Item name='_id' hidden={true}>
            <Input />
          </Form.Item>
          <Form.Item
            name='unitId'
            label='Institution name / Unit ID'
            rules={[{ required: true, message: 'Please input Institution name' }]}
          >
            <InstitutionsSearch
              value={form.getFieldsValue('unitId')?.value}
              placeholder='Search for institution'
              onChange={(value, institution) => {
                console.log(value, institution);
                form.setFieldsValue({
                  unitId: value,
                  institutionName: institution.name,
                  logoUrl: institution.logoUrl,
                });
              }}
            />
          </Form.Item>
          <Form.Item
            name='institutionName'
            label='Institution Name'
            rules={[{ required: true, message: 'Please input name' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label='Logo URL'>
            <Form.Item name='logoUrl' rules={[{ required: true, message: 'Please input logo URL' }]}>
              <Input />
            </Form.Item>
            <Form.Item name='logoUrl' noStyle valuePropName={'src'}>
              <Image
                width={100}
                fallback='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mPMKi+vBwAEhAHZqxu2SgAAAABJRU5ErkJggg=='
              />
            </Form.Item>
          </Form.Item>
          <Form.Item name='provider' label='Provider' rules={[{ required: true, message: 'Please input provider' }]}>
            <Select>
              {providers.map((provider, index) => (
                <Select.Option key={index} disabled={!provider.active} value={provider.code}>
                  {provider.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name='affLink' label='Link' rules={[{ required: true, message: 'Please input link' }]}>
            <Input />
          </Form.Item>
          <Form.Item name='headLine' label='Headline' rules={[{ required: true, message: 'Please input headline' }]}>
            <Input />
          </Form.Item>
          {/* <Form.Item name='content' label='Content'>   */}
          {/* rules={[{required: true, message: 'Please input content'}]} */}
          {/* <Input.TextArea/> */}
          <div style={{ maxWidth: '500px', marginLeft: '310px', marginBottom: '15px' }}>
            <CKEditor name='content' content={content} setContent={setContent} />
          </div>
          {/* </Form.Item> */}
          <Form.Item name='online' label='Online' valuePropName='checked'>
            <Switch />
          </Form.Item>
          <Form.Item name='tags' label='Tags'>
            <Select allowClear mode='multiple'>
              {tags.map((tag, index) => (
                <Select.Option key={index} value={tag.name}>
                  {tag.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label='Restricted States'>
            <Form.Item name='restrictedStates'>
              <Select mode='multiple'>
                {STATES.map((state, index) => (
                  <Select.Option key={index} value={state.abbreviation}>
                    {state.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Button
              onClick={() => {
                const fillStates = STATES.map((state) => state.abbreviation);
                form.setFieldsValue({ restrictedStates: fillStates });
              }}
            >
              Select All
            </Button>
            <Button
              onClick={() => {
                form.setFieldsValue({ restrictedStates: [] });
              }}
            >
              Clear
            </Button>
          </Form.Item>
          <Form.Item name='active' label='Active' valuePropName='checked'>
            <Switch />
          </Form.Item>
          <Form.Item
            name='CMSDisplayName'
            label='CMS display name'
            rules={[{ required: true, message: 'Please input CMS display name' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
