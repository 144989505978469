import React, { useState, useEffect, useContext } from 'react';
import { Input, notification, Spin, Button, Select, Form, InputNumber, Divider, Tooltip } from 'antd';
import Axios from 'axios';
import { UserContext } from '../../App';
import { SERVER_URL } from '../../config';
import RV_OPTIONS from '../../lib/updated_rvOptions.json';

const ShortcodeFOS = () => {
  const [form] = Form.useForm();
  const currentuser = useContext(UserContext);
  const [working, setWorking] = useState(false);
  const [website, setWebsite] = useState(null);
  const [rvBlock, setRVBlock] = useState({
    rvCategory: null,
    rvDegree: null,
    rvSubject: null,
    rvType: null,
    rvLayout: null,
    buttonText: null,
    resultLimit: null,
    moreButtonText: null,
  });

  const RV_LAYOUT = rvBlock.rvType === 'qdf' ? RV_OPTIONS.rv_qdf_layout : RV_OPTIONS.rv_el_layout;

  useEffect(() => {
    const fetchShortcode = async () => {
      try {
        setWorking(true);

        const shortcodeRes = await Axios.get(`${SERVER_URL}/field-of-study-shortcodes`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
        if (shortcodeRes && shortcodeRes.data) {
          setRVBlock(shortcodeRes.data.rvBlock);
        }

        const websiteRes = await Axios.get(`${SERVER_URL}/website-degreechoices`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
        if (websiteRes && websiteRes.data) {
          setWebsite(websiteRes.data.url);
        }

        setWorking(false);
      } catch (error) {
        setWorking(false);
        console.log(error);
        notification.error({
          message: 'Problem with fetching data',
          placement: 'bottomRight',
        });
      }
    };

    fetchShortcode();
  }, []);

  const handleSave = async () => {
    try {
      setWorking(true);

      await Axios.patch(
        `${SERVER_URL}/field-of-study-shortcodes`,
        { rvBlock },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );

      setWorking(false);
      notification.success({
        message: 'RV saved',
        placement: 'bottomRight',
      });
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Problem with saving data',
        placement: 'bottomRight',
      });
    }
  };

  return (
    <div className='content-wrapper'>
      <Spin spinning={working} tip='Working...'>
        <div className='actions-block flex'>
          <Button type='primary'>
            <a href={`${website}college-rankings/`} target='_blank' style={{ textDecoration: 'none' }}>
              Go to page
            </a>
          </Button>
        </div>

        <Form form={form} initialValues={{}} name='rvOptions'>
          <Divider>RV OPTIONS</Divider>
          <Form.Item label='RV Type'>
            <Select
              allowClear
              showSearch
              optionFilterProp='children'
              value={rvBlock.rvType}
              style={{ width: '500px' }}
              onChange={(value) => {
                if (value === 'qdf') {
                  setRVBlock({
                    ...rvBlock,
                    rvType: value,
                    rvLayout: null,
                    resultLimit: null,
                    moreButtonText: null,
                  });
                } else {
                  setRVBlock({ ...rvBlock, rvType: value, rvLayout: null });
                }
              }}
            >
              {RV_OPTIONS.rv_data_types.map((item) => (
                <Select.Option key={item.name} value={item.value}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label='RV Degree'>
            <Tooltip title='Degree is dynamically populated based on the selected degree'>
              <Select
                allowClear
                showSearch
                optionFilterProp='children'
                value={rvBlock.rvDegree}
                style={{ width: '500px' }}
                onChange={(value) => setRVBlock({ ...rvBlock, rvDegree: value })}
                disabled
              >
                {RV_OPTIONS.rv_data_degrees.map((item) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Tooltip>
          </Form.Item>

          <Form.Item label='RV Category'>
            <Tooltip title='Category is dynamically populated from Cipcodes ADMIN section'>
              <Select
                allowClear
                showSearch
                optionFilterProp='children'
                value={rvBlock.rvCategory}
                style={{ width: '500px' }}
                onChange={(value) => setRVBlock({ ...rvBlock, rvCategory: value })}
                disabled
              >
                {RV_OPTIONS.rv_data_categories.map((item) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Tooltip>
          </Form.Item>

          <Form.Item label='RV Subject'>
            <Tooltip title='Subject is dynamically populated based on the selected program (Cipcodes ADMIN section)'>
              <Select
                allowClear
                showSearch
                optionFilterProp='children'
                value={rvBlock.rvSubject}
                style={{ width: '500px' }}
                onChange={(value) => setRVBlock({ ...rvBlock, rvSubject: value })}
                disabled
              >
                {[...new Set(RV_OPTIONS.rv_data_subject)].map((item) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Tooltip>
          </Form.Item>

          <Form.Item label='RV Layout'>
            <Select
              allowClear
              showSearch
              optionFilterProp='children'
              value={rvBlock.rvLayout}
              style={{ width: '500px' }}
              onChange={(value) => setRVBlock({ ...rvBlock, rvLayout: value })}
            >
              {RV_LAYOUT.map((item) => (
                <Select.Option key={item} value={item}>
                  {item}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label='RV button text'>
            <Input
              value={rvBlock.buttonText}
              onChange={(e) => setRVBlock({ ...rvBlock, buttonText: e.target.value })}
              style={{ width: '500px' }}
            />
          </Form.Item>

          {rvBlock.rvType === 'el' ? (
            <>
              <Form.Item label='Result Limit'>
                <InputNumber
                  value={rvBlock.resultLimit}
                  onChange={(value) => setRVBlock({ ...rvBlock, resultLimit: value })}
                />
              </Form.Item>

              <Form.Item label='More button text'>
                <Input
                  value={rvBlock.moreButtonText}
                  onChange={(e) => setRVBlock({ ...rvBlock, moreButtonText: e.target.value })}
                  style={{ width: '500px' }}
                />
              </Form.Item>
            </>
          ) : null}

          <Button type='primary' onClick={handleSave} style={{ marginLeft: '10px' }}>
            SAVE
          </Button>
        </Form>
      </Spin>
    </div>
  );
};

export default ShortcodeFOS;
