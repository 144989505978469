import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Input, Table, Tag, Tooltip, Popconfirm } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  SearchOutlined,
  HistoryOutlined,
  SyncOutlined,
  CopyOutlined,
} from '@ant-design/icons';

const UserTable = ({
  data,
  deleteHandler,
  duplicatePath,
  columnKeys,
  title,
  editPath,
  viewPath,
  revisionPath,
  used,
  syncHandler,
}) => {
  const [sync, setSync] = useState('');
  let searchInput;

  const getColumnSearchProps = (dataIndex) => {
    if (!['createdAt', 'updatedAt'].includes(dataIndex)) {
      return {
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
          return (
            <div style={{ padding: 8 }}>
              <Input
                ref={(node) => {
                  searchInput = node;
                }}
                placeholder={`Search ${dataIndex}`}
                value={selectedKeys[0]}
                onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
              />
              <Button
                type='primary'
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                size='small'
                style={{ width: 90, marginRight: 8 }}
              >
                Search
              </Button>
              <Button onClick={() => handleReset(clearFilters, dataIndex)} size='small' style={{ width: 90 }}>
                Reset
              </Button>
            </div>
          );
        },
        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />,
        onFilter: (value, record) => {
          const filterRecord = Array.isArray(dataIndex) ? record[dataIndex[0]][dataIndex[1]] : record[dataIndex];
          if (!filterRecord || !value) return false;
          return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
        },
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => {
              searchInput.select();
            });
          }
        },
        sorter: (a, b, sortDirection) => {
          const isArray = Array.isArray(dataIndex);
          const stringA = isArray ? a[dataIndex[0]][dataIndex[1]].toUpperCase() : a[dataIndex].toUpperCase();
          const stringB = isArray ? b[dataIndex[0]][dataIndex[1]].toUpperCase() : b[dataIndex].toUpperCase();
          if (stringA < stringB) {
            return -1;
          }
          if (stringA > stringB) {
            return 1;
          }
          return 0;
        },
        sortDirections: ['ascend', 'descend'],
      };
    } else if (['createdAt', 'updatedAt'].includes(dataIndex)) {
      return {
        sorter: (a, b) => new Date(a[dataIndex]) - new Date(b[dataIndex]),
        sortDirections: ['ascend', 'descend'],
      };
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters, prop) => {
    clearFilters();
  };

  const columns = columnKeys.map((item) => {
    const dataIndex = ['university', 'program'].includes(item.toLowerCase()) ? [item, 'name'] : item;
    return {
      key: item,
      title: item.toUpperCase(),
      dataIndex,
      ...getColumnSearchProps(dataIndex),
    };
  });

  if (used) {
    columns.push({
      title: 'Status',
      render: (item) => {
        let status = null;
        status = used.includes(item._id) ? <Tag color='green'>Used</Tag> : <Tag color='lime'>Not used</Tag>;
        return status;
      },
    });
  }

  columns.push({
    title: 'Action',
    render: (text, record) => (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '15px',
        }}
      >
        <Tooltip title={`Edit ${title}`} placement='topLeft'>
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='table-actions'>
            <Link
              to={
                editPath === '/admin/edit-website/'
                  ? editPath + record._id + '/list-numbered-list'
                  : editPath + record._id
              }
            >
              <EditOutlined style={{ textDecoration: 'none', color: 'black' }} />
            </Link>
          </div>
        </Tooltip>

        {viewPath && (
          <Tooltip title={`View ${title}`} placement='topLeft'>
            <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='table-actions'>
              <Link to={viewPath + record._id}>
                <EyeOutlined style={{ textDecoration: 'none', color: 'black' }} />
              </Link>
            </div>
          </Tooltip>
        )}

        {duplicatePath && (
          <Tooltip title={`Duplicate ${title}`} placement='topLeft'>
            <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='table-actions'>
              <Link to={duplicatePath + record._id}>
                <CopyOutlined />
              </Link>
            </div>
          </Tooltip>
        )}

        {/* <Tooltip title={`Delete ${title}`} placement='topLeft'> */}
        <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='table-actions'>
          <Popconfirm
            title='Are you sure you want to delete this user?'
            onConfirm={async () => deleteHandler(record._id, record.url)}
            onCancel={() => console.log('Cancel')}
            okText='Yes'
            cancelText='No'
          >
            <DeleteOutlined />
          </Popconfirm>
        </div>
        {/* </Tooltip> */}

        {revisionPath && (
          <Tooltip title={`Revision ${title}`} placement='topLeft'>
            <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='table-actions'>
              <Link to={revisionPath + record._id}>
                <HistoryOutlined style={{ textDecoration: 'none', color: 'black' }} />
              </Link>
            </div>
          </Tooltip>
        )}

        {syncHandler && (
          <Tooltip title='Synchronize' placement='topLeft'>
            <div
              style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}
              className='table-actions'
              onClick={async () => {
                setSync(record._id);
                await syncHandler(record._id);
                setSync('');
              }}
            >
              <SyncOutlined spin={sync === record._id} />
            </div>
          </Tooltip>
        )}
      </div>
    ),
  });

  return (
    <div>
      <Table
        size='middle'
        bordered
        dataSource={data}
        columns={columns}
        rowKey={(record) => record._id}
        pagination={{
          pageSize: 20,
          position: 'bottom',
          showSizeChanger: false,
          hideOnSinglePage: true,
        }}
      />
    </div>
  );
};

export default UserTable;
