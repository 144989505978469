import React, { useState, useEffect, useContext } from 'react';
import Axios from 'axios';
import * as _ from 'lodash';
import { Link, useHistory, useParams, useLocation } from 'react-router-dom';
import { Button, Spin, Tabs } from 'antd';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';
import { SettingOutlined } from '@ant-design/icons';

// import tab components
import Characteristics from './tabs/Characteristics';
import Cost from './tabs/Cost';
import Admissions from './tabs/Admissions';
import GradRates from './tabs/GradRates';
import EMI from './tabs/EMI';
import Online from './tabs/Online';
import Admin from './tabs/Admin';
import Repayment from './tabs/Repayment';
import AllProgramsTab from './tabs/AllProgramsTab';
import Crime from './tabs/Crime';

const { TabPane } = Tabs;

const SchoolProfile = () => {
  const currentuser = useContext(UserContext);
  const history = useHistory();
  const [working, setWorking] = useState(false);
  const [data, setData] = useState([]);
  const [associates, setAssociates] = useState([]); // all associate programs
  const [majors, setMajors] = useState([]); // all bachelor programs
  const [gradPrograms, setGradPrograms] = useState([]); // all master programs
  const [onlineAssoc, setOnlineAssoc] = useState([]); // online associates
  const [onlineBach, setOnlineBach] = useState([]); // online bachelors
  const [onlineMasters, setOnlineMasters] = useState([]); // online masters
  const [doctorate, setDoctorate] = useState([]); // doctorate programs

  const search = useLocation().search;
  const tab = new URLSearchParams(search).get('tab');

  const { id } = useParams();

  useEffect(() => {
    try {
      setWorking(true);
      const fetchSchoolProfileById = async () => {
        const res = await Axios.get(`${SERVER_URL}/all-school-profiles/${id}`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
        if (res) {
          setData(res.data);
          if (res.data.programs && res.data.programs.length > 0) {
            setMajors(res.data.programs.filter((prog) => prog.credential === 'Bachelors Degree'));
            setGradPrograms(
              res.data.programs
                .filter((prog) => prog.credential === "Master's Degree")
                .map((prog) => ({
                  ...prog,
                  debt: prog.DEBT_ALL_STGP_EVAL_MDN
                    ? prog.DEBT_ALL_STGP_EVAL_MDN
                    : prog.DEBT_ALL_STGP_EVAL_MEAN
                    ? prog.DEBT_ALL_STGP_EVAL_MEAN
                    : prog.PREV_DEBT_ALL_STGP_EVAL_MDN
                    ? prog.PREV_DEBT_ALL_STGP_EVAL_MDN
                    : prog.PREV_DEBT_ALL_STGP_EVAL_MEAN,
                })),
            );
            setAssociates(res.data.programs.filter((prog) => prog.credential === "Associate's Degree"));
            setDoctorate(res.data.programs.filter((prog) => prog.credential === 'Doctoral Degree'));
            setOnlineBach(
              res.data.programs.filter(
                (prog) =>
                  prog.credential === 'Bachelors Degree' &&
                  prog.EARN &&
                  prog.conferrals > 9 &&
                  (prog.distance['All programs'] > 0 || prog.distance['Some programs'] > 0),
              ),
            );
            setOnlineMasters(
              res.data.programs.filter(
                (prog) =>
                  prog.credential === "Master's Degree" &&
                  prog.EARN &&
                  prog.conferrals > 5 &&
                  (prog.DEBT_ALL_STGP_EVAL_MDN ||
                    prog.DEBT_ALL_STGP_EVAL_MEAN ||
                    prog.PREV_DEBT_ALL_STGP_EVAL_MDN ||
                    prog.PREV_DEBT_ALL_STGP_EVAL_MEAN) &&
                  (prog.distance['All programs'] > 0 || prog.distance['Some programs'] > 0),
              ),
            );
            setOnlineAssoc(
              res.data.programs.filter(
                (prog) =>
                  prog.credential === "Associate's Degree" &&
                  prog.EARN &&
                  prog.conferrals > 5 &&
                  (prog.distance['All programs'] > 0 || prog.distance['Some programs'] > 0),
              ),
            );
          }
        }
      };
      setWorking(false);
      fetchSchoolProfileById();
    } catch (error) {
      setWorking(false);
      console.log(error);
    }
  }, [currentuser.data.token]);

  return (
    <div className='content-wrapper'>
      <Spin spinning={working} tip='Working...'>
        <div className='actions-block flex'>
          <h4>
            <strong>
              {data.name} ({data.unitid})
            </strong>
          </h4>

          <Link to={{ pathname: '/admin/school-profiles', state: _.get(history, 'location.state') }}>
            <Button type='primary'>All School Profiles</Button>
          </Link>
        </div>

        <Tabs defaultActiveKey={tab && tab !== '' ? tab : 'CHARACTERISTICS'}>
          <TabPane tab='CHARACTERISTICS' key='CHARACTERISTICS'>
            <Characteristics
              unitid={data.unitid}
              city={data.city}
              state={data.state}
              region={data.region}
              control={data.control}
              LOCALE={data.LOCALE}
              ICLEVEL={data.ICLEVEL}
              UGDS={data.UGDS}
              totalSize={data.totalSize}
              inStateStudentPerc={data.inStateStudentPerc}
              campusStudentPerc={data.campusStudentPerc}
              foreignStudentPerc={data.foreignStudentPerc}
              carnegieClass={data.carnegieClass}
              FIRST_GEN={data.FIRST_GEN}
              WOMENONLY={data.WOMENONLY}
              MENONLY={data.MENONLY}
              HSI={data.HSI}
              HBCU={data.HBCU}
              RELIGIOUS_AFFILIATION={data.RELIGIOUS_AFFILIATION}
              VET1={data.VET1}
              VET2={data.VET2}
              MAIN_CAMPUS={data.MAIN_CAMPUS}
              PSET4FLG={data.PSET4FLG}
              UGOFFER={data.UGOFFER}
              MALE={data.MALE}
              FEMALE={data.FEMALE}
              RACE_ETHNICITY={data.RACE_ETHNICITY}
              fullOnline={data.fullOnline}
              AGE_ENTRY={data.AGE_ENTRY}
              AGEGE24={data.AGEGE24}
              VETERAN={data.VETERAN}
              MARRIED={data.MARRIED}
              UG25ABV={data.UG25ABV}
              DEP_STAT_PCT_IND={data.DEP_STAT_PCT_IND}
              PBI={data.PBI}
              student_faculty_ratio={data.student_faculty_ratio}
              RACE_ETHNICITY_FACULTY={data.RACE_ETHNICITY_FACULTY}
            />
          </TabPane>

          <TabPane tab='COST' key='COST'>
            <Cost
              control={data.control}
              TUITIONFEE_IN={data.TUITIONFEE_IN}
              TUITIONFEE_OUT={data.TUITIONFEE_OUT}
              BOOKSUPPLY={data.BOOKSUPPLY}
              ROOMBOARD_ON={data.ROOMBOARD_ON}
              ROOMBOARD_OFF={data.ROOMBOARD_OFF}
              OTHEREXPENSE_ON={data.OTHEREXPENSE_ON}
              OTHEREXPENSE_OFF={data.OTHEREXPENSE_OFF}
              averageNetPrice={data.NPT4_PUB ? data.NPT4_PUB : data.NPT4_PRIV}
              averageNetPriceLowIncome_0_to_30k={data.NPT41_PUB ? data.NPT41_PUB : data.NPT41_PRIV}
              averageNetPriceLowIncome_30k_to_48k={data.NPT42_PUB ? data.NPT42_PUB : data.NPT42_PRIV}
              averageNetPriceLowIncome_48k_to_75k={data.NPT43_PUB ? data.NPT43_PUB : data.NPT43_PRIV}
              averageNetPriceLowIncome_75k_to_110k={data.NPT44_PUB ? data.NPT44_PUB : data.NPT44_PRIV}
              averageNetPriceLowIncome_110k_plus={data.NPT45_PUB ? data.NPT45_PUB : data.NPT45_PRIV}
              grants={data.grants}
              cost_number_of_students={data.cost_number_of_students}
              tuitionReciprocity={data.tuitionReciprocity}
            />
          </TabPane>

          <TabPane tab='REPAYMENT' key='REPAYMENT'>
            <Repayment repayment={data.repayment} />
          </TabPane>

          <TabPane tab='ADMISSIONS' key='ADMISSIONS'>
            <Admissions
              admPerc={
                data.admissions && data.admissions['Admissions percentage']
                  ? data.admissions['Admissions percentage']
                  : 'N/A'
              }
              openAdmissions={data['Open admissions']}
              applicantsTotal={
                data.admissions && data.admissions['Applicants total'] ? data.admissions['Applicants total'] : 'N/A'
              }
              admissionsTotal={
                data.admissions && data.admissions['Admissions total'] ? data.admissions['Admissions total'] : 'N/A'
              }
              enrolled={
                data.admissions && data.admissions['Enrolled total'] ? data.admissions['Enrolled total'] : 'N/A'
              }
              gpaRequired={data.admissions && data.admissions['GPA required'] ? data.admissions['GPA required'] : 'N/A'}
              testPolicy={
                data.admissions && data.admissions['Admission test scores']
                  ? data.admissions['Admission test scores']
                  : 'N/A'
              }
              percentSubmittingSAT={
                data.admissions && data.admissions['Percent submitting SAT']
                  ? data.admissions['Percent submitting SAT']
                  : 'N/A'
              }
              percentSubmittingACT={
                data.admissions && data.admissions['Percent submitting ACT']
                  ? data.admissions['Percent submitting ACT']
                  : 'N/A'
              }
              SATVR25={data.admissions && data.admissions['SAT verbal 25'] ? data.admissions['SAT verbal 25'] : 'N/A'}
              SATVR75={data.admissions && data.admissions['SAT verbal 75'] ? data.admissions['SAT verbal 75'] : 'N/A'}
              SATMT25={data.admissions && data.admissions['SAT math 25'] ? data.admissions['SAT math 25'] : 'N/A'}
              SATMT75={data.admissions && data.admissions['SAT math 75'] ? data.admissions['SAT math 75'] : 'N/A'}
              SATWR25={data.admissions && data.admissions['SAT writing 25'] ? data.admissions['SAT writing 25'] : 'N/A'}
              SATWR75={data.admissions && data.admissions['SAT writing 75'] ? data.admissions['SAT writing 75'] : 'N/A'}
              SATVRMID={
                data.admissions && data.admissions['SAT verbal mid'] ? data.admissions['SAT verbal mid'] : 'N/A'
              }
              SATMTMID={data.admissions && data.admissions['SAT math mid'] ? data.admissions['SAT math mid'] : 'N/A'}
              SATWRMID={
                data.admissions && data.admissions['SAT writing mid'] ? data.admissions['SAT writing mid'] : 'N/A'
              }
              SAT_AVG={data.admissions && data.admissions['SAT average'] ? data.admissions['SAT average'] : 'N/A'}
              ACTCOMP25={data.admissions && data.admissions['ACT comp 25'] ? data.admissions['ACT comp 25'] : 'N/A'}
              ACTCOMP75={data.admissions && data.admissions['ACT comp 75'] ? data.admissions['ACT comp 75'] : 'N/A'}
              ACTENG25={
                data.admissions && data.admissions['ACT english 25'] ? data.admissions['ACT english 25'] : 'N/A'
              }
              ACTENG75={
                data.admissions && data.admissions['ACT english 75'] ? data.admissions['ACT english 75'] : 'N/A'
              }
              ACTMT25={data.admissions && data.admissions['ACT math 25'] ? data.admissions['ACT math 25'] : 'N/A'}
              ACTMT75={data.admissions && data.admissions['ACT math 75'] ? data.admissions['ACT math 75'] : 'N/A'}
              ACTWR25={data.admissions && data.admissions['ACT writing 25'] ? data.admissions['ACT writing 25'] : 'N/A'}
              ACTWR75={data.admissions && data.admissions['ACT writing 75'] ? data.admissions['ACT writing 75'] : 'N/A'}
              ACTCOMPMID={data.admissions && data.admissions['ACT comp mid'] ? data.admissions['ACT comp mid'] : 'N/A'}
              ACTENGMID={
                data.admissions && data.admissions['ACT english mid'] ? data.admissions['ACT english mid'] : 'N/A'
              }
              ACTMTMID={data.admissions && data.admissions['ACT math mid'] ? data.admissions['ACT math mid'] : 'N/A'}
              ACTWRMID={
                data.admissions && data.admissions['ACT writing mid'] ? data.admissions['ACT writing mid'] : 'N/A'
              }
              AVG_GPA={data.AVG_GPA}
              earlyAdmissions={data.earlyAdmissions}
              acceptance_rate_data_medical={data.acceptance_rate_data}
            />
          </TabPane>

          <TabPane tab='GRAD RATES' key='GRAD_RATES'>
            <GradRates
              totalGradRate={data.OMgradRate}
              totalGradRatePell={data.gradRateTotalPellOM}
              gradRateOM={data.gradRateOM}
              gradRatePellOM={data.gradRatePellOM}
              gradRateNoPellOM={data.gradRateNoPellOM}
              studentOMPercentages={data.student_OM_percentages}
              act={data['Average completion time']}
              actPell={data['Average completion time pell']}
              pellPerformanceGap={data['Pell performance gap']}
              adjustedPellGradRate={data['Adjusted pell grad rate']}
              gr100={data.L4GR100}
              gr150={data.L4GR150}
              gr200={data.L4GR200}
            />
          </TabPane>

          <TabPane tab='KPI' key='KPI'>
            <EMI
              earnings={data.MD_EARN_WNE_P10}
              earnings4yr={data.MD_EARN_WNE_4YR}
              cost={data['Average net price']}
              ACT={data['Average completion time']}
              payback={data.Payback}
              degreePremium={data.degreePremium}
              earnings10y={data.MD_EARN_WNE_INC1_P10}
              countLow1={data.COUNT_WNE_INC1_P10}
              earningsLow2={data.MD_EARN_WNE_INC2_P10}
              countLow2={data.COUNT_WNE_INC2_P10}
              earningsLow3={data.MD_EARN_WNE_INC3_P10}
              countLow3={data.COUNT_WNE_INC3_P10}
              costPriv={data.NPT41_PRIV}
              costPub={data.NPT41_PUB}
              ACT_pell={data['Average completion time pell']}
              paybackEMI={data.Payback10perc}
              degreePremiumEMI={data.degreePremiumEMI}
              EMI_percentile={data.EMI_percentile}
              EMI_number={data.EMI}
              EMI_rank={data.rankEMI}
              pellPerc={data.grants && data.grants['Pell percentage'] ? data.grants['Pell percentage'] : 'N/A'}
              costIncome={data.costIncome}
            />
          </TabPane>

          <TabPane tab='ONLINE' key='ONLINE'>
            <Online
              onlineBach={onlineBach}
              onlineMasters={onlineMasters}
              onlineAssoc={onlineAssoc}
              totalBachelorsOnline={
                data.onlineHybrid99 && data.onlineHybrid99.totalBachelorsOnline
                  ? data.onlineHybrid99.totalBachelorsOnline
                  : 'N/A'
              }
              totalMastersOnline={
                data.onlineHybrid99 && data.onlineHybrid99.totalMastersOnline
                  ? data.onlineHybrid99.totalMastersOnline
                  : 'N/A'
              }
              exclusiveOnlineBachelors={
                data.onlineHybrid99 && data.onlineHybrid99.exclusiveOnlineBachelors
                  ? data.onlineHybrid99.exclusiveOnlineBachelors
                  : 'N/A'
              }
              exclusiveOnlineMasters={
                data.onlineHybrid99 && data.onlineHybrid99.exclusiveOnlineMasters
                  ? data.onlineHybrid99.exclusiveOnlineMasters
                  : 'N/A'
              }
              hybridBachelors={
                data.onlineHybrid99 && data.onlineHybrid99.hybridBachelors ? data.onlineHybrid99.hybridBachelors : 'N/A'
              }
              hybridMasters={
                data.onlineHybrid99 && data.onlineHybrid99.hybridMasters ? data.onlineHybrid99.hybridMasters : 'N/A'
              }
            />
          </TabPane>

          <TabPane tab='PROGRAMS' key='PROGRAMS'>
            <AllProgramsTab
              majors={majors}
              gradPrograms={gradPrograms}
              associates={associates}
              doctorate={doctorate}
              currentuser={currentuser}
              name={data.name}
            />
          </TabPane>

          <TabPane tab='CRIME' key='CRIME'>
            <Crime crimeData={data.crimeData} />
          </TabPane>

          {!currentuser.data.role.includes('guest') && (
            <TabPane
              tab={
                <span style={{ color: 'red' }}>
                  <SettingOutlined />
                  ADMIN
                </span>
              }
              key='ADMIN'
            >
              <Admin
                isMain={data.MAIN_CAMPUS}
                otherData={data.otherData}
                name={data.name}
                website={data.website}
                id={id}
                token={currentuser.data.token}
                unitid={data.unitid}
                carnegieClass={data.carnegieClass}
                rank={data.rank}
                branches={data.branches}
                degreePremium={data.degreePremium}
                isBranch={data.isBranch}
                websiteURL={data.websiteURL}
                slug={data.slug}
                earnLessThenHS={data.earnLessThenHS}
                earnings={data.MD_EARN_WNE_P10}
                hsSalary={data.hsSalary}
              />
            </TabPane>
          )}
        </Tabs>
      </Spin>
    </div>
  );
};

export default SchoolProfile;
