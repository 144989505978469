import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { useLocation, useHistory } from 'react-router-dom';
import { EyeInvisibleOutlined, EyeTwoTone, LoadingOutlined, QuestionCircleFilled } from '@ant-design/icons';
import { Input, Button, Form, Tabs, Layout, Typography, Tag, Tooltip } from 'antd';
import { TopListForm } from '../forms';
import { TopListTable, CareersWidgetsTable, DegreeWidgetsTable } from '../tables';
import { Link } from 'react-router-dom';

const { TabPane } = Tabs;
const { Sider, Content } = Layout;

const formInit = {
  _id: null,
  name: '',
  url: '',
  username: '',
  password: '',
};

const TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'link',
  'links',
  'website',
  'wpBlockName',
  'createdBy',
  'schoolProps',
  'idWebsite',
  'type',
  'ctaLink',
  'listItems',
];

const WebsiteForm = ({
  isNew,
  careers,
  ipedsTopLists,
  onSubmit,
  website,
  newList,
  syncCareerWidgets,
  syncDeleteCareerWidgets,
  syncDegreeWidgets,
  syncDeleteDegreeWidgets,
  topList,
  eatTopLists,
  editList,
  SERVER_URL,
  token,
}) => {
  const history = useHistory();
  const location = useLocation();
  const [form] = Form.useForm();
  const [connectionStatus, setConnectionStatus] = useState();
  const view = location.pathname.includes('view-');
  const viewTable = location.pathname.includes('list-');
  const [selectedCareers, setSelectedCareers] = useState([]);
  const [selectedIPEDSTopLists, setSelectedIPEDSTopLists] = useState([]);

  let tabSelectedGet;
  if (location.pathname.includes('top-list')) tabSelectedGet = '1';
  if (location.pathname.includes('eat-top-list')) tabSelectedGet = '2';
  if (location.pathname.includes('career-widgets')) tabSelectedGet = '3';
  if (location.pathname.includes('degree-widgets')) tabSelectedGet = '4';

  const [tabSelected, setTabSelected] = useState(tabSelectedGet);

  useEffect(() => {
    if (website?._id) {
      Axios.get(`${SERVER_URL}/website-test/${website._id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => {
          setConnectionStatus(res.data.message);
        })
        .catch((err) => {
          const message = err.response ? err.response.data.message : err.message;
          setConnectionStatus(message);
        });
    }
  }, [website, SERVER_URL, token]);

  if (website) {
    ['createdAt', 'updatedAt', '__v'].forEach((key) => delete website[key]);
  }
  let initialValues = { ...formInit, ...website };

  let columnKeysTopLists,
    topListTableData,
    eatTopListTableData,
    columnKeysEatTopLists,
    careerWidgetsTableData,
    columnKeysCareerWidgets,
    degreeWidgetsTableData,
    columnKeysDegreeWidgets;

  if (topList && topList.length > 0) {
    topListTableData = topList.map((item) => {
      item.createdAt = new Date(item.createdAt).toLocaleString('en-US');
      item.updatedAt = new Date(item.updatedAt).toLocaleString('en-US');
      return item;
    });
    const keys = Object.keys(topList[0]);
    columnKeysTopLists = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
  }

  if (eatTopLists && eatTopLists.length > 0) {
    eatTopListTableData = eatTopLists.map((item) => {
      item.createdAt = new Date(item.createdAt).toLocaleString('en-US');
      item.updatedAt = new Date(item.updatedAt).toLocaleString('en-US');
      return item;
    });
    const keys = Object.keys(eatTopLists[0]);
    columnKeysEatTopLists = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
  }

  if (careers.length > 0) {
    careerWidgetsTableData = careers;
    columnKeysCareerWidgets = newList ? ['occCode', 'careerName'] : ['occCode', 'careerName', 'widgets'];
  }

  if (ipedsTopLists && ipedsTopLists?.length > 0) {
    degreeWidgetsTableData = ipedsTopLists;
    columnKeysDegreeWidgets = newList
      ? ['topListName', 'createdAt', 'updatedAt']
      : ['topListName', 'widgets', 'createdAt', 'updatedAt'];
  }

  const onSubmitPage = async (val) => {
    if (!isNew) val._id = website._id;
    onSubmit(val, !website);
  };

  const setActiveTab = async (key) => {
    const curentSelectedTab = key;
    const previouslySelectedTab = tabSelected;
    if (curentSelectedTab !== previouslySelectedTab) {
      if (key === '1') {
        history.push(`/admin/edit-website/${website._id}/list-top-list`);
      } else if (key === '2') {
        history.push(`/admin/edit-website/${website._id}/list-eat-top-list`);
      } else if (key === '3') {
        history.push(`/admin/edit-website/${website._id}/list-career-widgets`);
      } else if (key === '4') {
        history.push(`/admin/edit-website/${website._id}/list-degree-widgets`);
      }
      setTabSelected(key);
    }
  };

  // const numberedListEdit = !isNew ? numberedList.find((item) => item._id === numberedListId) : null;
  // const topListView = !isNew ? topList.find((item) => item._id === topListId) : null;
  const style = {};
  isNew ? (style['float'] = 'right') : (style['textAlign'] = 'center');

  const Status = () => {
    let status = <LoadingOutlined spin />;
    if (connectionStatus) {
      if (connectionStatus === 'Connection OK') {
        status = (
          <Tag title='Connection with WP is OK' color='green'>
            CONNECTION OK
          </Tag>
        );
      }
      if (connectionStatus.toLowerCase().includes('timeout')) {
        status = (
          <Tag title='Timeout Error connecting to WP. The Website might be down or very slow.' color='red'>
            CONNECTION ERROR
          </Tag>
        );
      }
      if (connectionStatus.toLowerCase().includes('username')) {
        status = (
          <Tag title='Admin username error, unable to establish connection.' color='red'>
            ADMIN USERNAME ERROR
          </Tag>
        );
      }
      if (connectionStatus.toLowerCase().includes('password')) {
        status = (
          <Tag title='Admin password error, unable to establish connection.' color='red'>
            ADMIN PASSWORD ERROR
          </Tag>
        );
      }
      if (connectionStatus.toLowerCase().includes('enotfound')) {
        status = (
          <Tag title='Internet error. Url might be wrong.' color='red'>
            INTERNET ERROR
          </Tag>
        );
      }
    }
    return status;
  };

  return (
    <>
      <Sider className='site-layout-background' width={isNew ? 1200 : 300} theme='light'>
        <Content style={{ padding: '15px', height: '100%' }}>
          {!isNew && (
            <Typography.Paragraph>
              <Tooltip placement='top' title={<span>Shows if CMS is able to connect to Wordpress website.</span>}>
                <QuestionCircleFilled style={{ marginRight: '6px' }} />
              </Tooltip>
              <strong style={{ marginRight: '12px' }}>Status:</strong>
              <Status />
            </Typography.Paragraph>
          )}
          <Form
            className='form-horizontal'
            layout='vertical'
            initialValues={initialValues}
            onFinish={(values) => {
              onSubmitPage(values);
            }}
            form={form}
          >
            <Form.Item label='Name:' name='name' rules={[{ required: true, message: 'Please enter Name' }]}>
              <Input placeholder='Add Name' />
            </Form.Item>

            <Form.Item label='Url:' name='url' rules={[{ required: true, message: 'Please enter URL' }]}>
              <Input placeholder='Add Url' />
            </Form.Item>

            <Form.Item
              label='Admin username:'
              name='username'
              rules={[{ required: true, message: 'Please enter username' }]}
            >
              <Input placeholder='Add Admin username' />
            </Form.Item>

            <Form.Item
              label='Admin password:'
              name='password'
              rules={[{ required: true, message: 'Please enter password' }]}
            >
              <Input.Password
                placeholder='Add Admin password'
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              />
            </Form.Item>

            <Form.Item style={style}>
              <Button type='primary' htmlType='submit'>
                {isNew ? 'Add' : 'update'} website
              </Button>
            </Form.Item>
          </Form>
        </Content>
      </Sider>

      {!isNew && (
        <Content style={{ padding: '0 0 0 15px', minHeight: 280 }}>
          <Tabs onChange={setActiveTab} defaultActiveKey={tabSelected}>
            {/* COMMERCIAL LISTS TAB */}
            {/* <TabPane tab='COMMERCIAL LISTS' key='1'> */}
            {/* <div className='actions-block flex'>
                <Link
                  to={
                    newList || editList
                      ? `/admin/edit-website/${website._id}/list-top-list`
                      : `/admin/edit-website/${website._id}/new-top-list`
                  }
                >
                  <Button type='primary'>{newList || editList || view ? 'All Lists' : 'Add New List'}</Button>
                </Link>

                <h2 style={{ margin: 0 }}>Total commercial lists: {topListTableData?.length || 0}</h2>

                <Tooltip title='Synchronize all lists to the WordPress website'>
                  <Button
                    onClick={() => syncTopList('commercial')}
                    disabled={!topListTableData || topListTableData.length === 0}
                  >
                    Synchronize
                  </Button>
                </Tooltip>
              </div> */}

            {/* {view && tabSelected === '1' && (
                <ViewTopList
                  schools={schools}
                  programs={programs}
                  topList={topListView}
                  website={website}
                  SERVER_URL={SERVER_URL}
                  token={token}
                />
              )} */}

            {/* {!viewTable &&
                !view &&
                (newList || (editList && topListEdit.data && topListEdit.data._id)) &&
                tabSelected === '1' && (
                  <TopListForm
                    topList={topListEdit.data}
                    isNew={newList}
                    onSubmit={addTopList}
                    categories={categories}
                    profiles={profiles}
                    type='commercial'
                    // website={website}
                    // SERVER_URL={SERVER_URL}
                    // token={token}
                  />
                )} */}

            {/* {viewTable && !view && !newList && !editList && topList && topList.length > 0 && tabSelected === '1' && (
                <TopListTable
                  type='commercial'
                  setWorking={setWorking}
                  connectionStatus={connectionStatus}
                  data={topListTableData}
                  columnKeys={columnKeysTopLists}
                  deleteHandler={deleteTopList}
                  editPath={`/admin/edit-website/${website._id}/edit-top-list/`}
                  viewPath={`/admin/edit-website/${website._id}/view-top-list/`}
                  title='Commercial List'
                  SERVER_URL={SERVER_URL}
                  token={token}
                  website={website}
                />
              )} */}

            {/* {viewTable &&
                !view &&
                !newList &&
                !editList &&
                topList &&
                topList.length === 0 &&
                tabSelected === '1' && (
                  <p style={{ textAlign: 'center', fontSize: '18px', marginTop: '32px' }}>NO LISTS</p>
                )} */}
            {/* </TabPane> */}
            {/* END COMMERCIAL LISTS TAB */}

            {/* E.A.T. LISTS TAB */}
            {/* <TabPane tab='E.A.T. LISTS' key='2'> */}
            {/* <div className='actions-block flex'>
                <Link
                  to={
                    newList || editList
                      ? `/admin/edit-website/${website._id}/list-eat-top-list`
                      : `/admin/edit-website/${website._id}/new-eat-top-list`
                  }
                >
                  <Button type='primary'>{newList || editList || view ? 'All Lists' : 'Add New List'}</Button>
                </Link>

                <h2 style={{ margin: 0 }}>Total E.A.T. lists: {eatTopListTableData?.length || 0}</h2>

                <Tooltip title='Synchronize all lists to the WordPress website'>
                  <Button
                    onClick={() => syncTopList('eat')}
                    disabled={!eatTopListTableData || eatTopListTableData.length === 0}
                  >
                    Synchronize
                  </Button>
                </Tooltip>
              </div> */}

            {/* {!viewTable &&
                !view &&
                (newList || (editList && eatTopListEdit.data && eatTopListEdit.data._id)) &&
                tabSelected === '2' && (
                  <TopListForm
                    topList={eatTopListEdit.data}
                    isNew={newList}
                    onSubmit={addTopList}
                    categories={categories}
                    profiles={profiles}
                    type='eat'
                  />
                )} */}

            {/* {viewTable &&
                !view &&
                !newList &&
                !editList &&
                eatTopLists &&
                eatTopLists.length > 0 &&
                tabSelected === '2' && (
                  <TopListTable
                    type='eat'
                    setWorking={setWorking}
                    connectionStatus={connectionStatus}
                    data={eatTopListTableData}
                    columnKeys={columnKeysEatTopLists}
                    deleteHandler={deleteTopList}
                    editPath={`/admin/edit-website/${website._id}/edit-eat-top-list/`}
                    viewPath={`/admin/edit-website/${website._id}/view-eat-top-list/`}
                    title='E.A.T. List'
                    SERVER_URL={SERVER_URL}
                    token={token}
                    website={website}
                  />
                )} */}

            {/* {viewTable &&
                !view &&
                !newList &&
                !editList &&
                eatTopLists &&
                eatTopLists.length === 0 &&
                tabSelected === '2' && (
                  <p style={{ textAlign: 'center', fontSize: '18px', marginTop: '32px' }}>NO LISTS</p>
                )} */}
            {/* </TabPane> */}
            {/* END E.A.T. LISTS TAB */}

            {/* CAREER WIDGETS TAB */}
            <TabPane tab='CAREER WIDGETS' key='3'>
              <div className='actions-block flex'>
                <Link
                  to={
                    newList || editList
                      ? `/admin/edit-website/${website._id}/list-career-widgets`
                      : `/admin/edit-website/${website._id}/new-career-widgets`
                  }
                >
                  <Button type='primary'>
                    {newList || editList || view ? 'All Career Widgets' : 'Add new widgets'}
                  </Button>
                </Link>

                <h2 style={{ margin: 0 }}>
                  Total careers widgets:{' '}
                  {careerWidgetsTableData?.length > 0
                    ? careerWidgetsTableData.filter((c) => c.widgets?.length > 0).length
                    : 0}
                </h2>

                <Tooltip
                  title={
                    newList
                      ? 'Create widgets for selected careers on the WordPress website'
                      : 'Synchronize selected careers widgets with the WordPress website'
                  }
                >
                  <Button onClick={() => syncCareerWidgets(selectedCareers)} disabled={selectedCareers.length === 0}>
                    {newList ? 'Create' : 'Synchronize'}
                  </Button>
                </Tooltip>
              </div>

              {viewTable && careers && careers.length > 0 && tabSelected === '3' && (
                <CareersWidgetsTable
                  selectedRowKeys={selectedCareers}
                  setSelectedRowKeys={setSelectedCareers}
                  data={
                    careerWidgetsTableData?.length > 0
                      ? careerWidgetsTableData.filter((c) => c.widgets?.length > 0)
                      : []
                  }
                  deleteHandler={syncDeleteCareerWidgets}
                  columnKeys={columnKeysCareerWidgets}
                />
              )}

              {newList && careers && careers.length > 0 && tabSelected === '3' && (
                <CareersWidgetsTable
                  selectedRowKeys={selectedCareers}
                  setSelectedRowKeys={setSelectedCareers}
                  data={
                    careerWidgetsTableData?.length > 0
                      ? careerWidgetsTableData.filter((c) => !c.widgets || c.widgets.length === 0)
                      : 0
                  }
                  columnKeys={columnKeysCareerWidgets}
                />
              )}
            </TabPane>
            {/* END CAREER WIDGETS TAB */}

            {/* DEGREE WIDGETS TAB */}
            <TabPane tab='DEGREE WIDGETS' key='4'>
              <div className='actions-block flex'>
                <Link
                  to={
                    newList || editList
                      ? `/admin/edit-website/${website._id}/list-degree-widgets`
                      : `/admin/edit-website/${website._id}/new-degree-widgets`
                  }
                >
                  <Button type='primary'>
                    {newList || editList || view ? 'All Degree Widgets' : 'Add new widgets'}
                  </Button>
                </Link>

                <h2 style={{ margin: 0 }}>
                  Total degree widgets:{' '}
                  {degreeWidgetsTableData?.length > 0
                    ? degreeWidgetsTableData.filter((c) => c.widgets?.length > 0).length
                    : 0}
                </h2>

                <Tooltip
                  title={
                    newList
                      ? 'Create widgets for selected degrees and programs on the WordPress website'
                      : 'Synchronize selected degree widgets with the WordPress website'
                  }
                >
                  {
                    <Button
                      onClick={() => syncDegreeWidgets(selectedIPEDSTopLists, newList ? 'insert' : 'update')}
                      disabled={selectedIPEDSTopLists.length === 0}
                    >
                      {newList ? 'Create' : 'Synchronize'}
                    </Button>
                  }
                </Tooltip>
              </div>

              {viewTable && ipedsTopLists && ipedsTopLists.length > 0 && tabSelected === '4' && (
                <DegreeWidgetsTable
                  selectedRowKeys={selectedIPEDSTopLists}
                  setSelectedRowKeys={setSelectedIPEDSTopLists}
                  data={
                    degreeWidgetsTableData?.length > 0
                      ? degreeWidgetsTableData.filter((c) => c.widgets?.length > 0)
                      : []
                  }
                  deleteHandler={syncDeleteDegreeWidgets}
                  columnKeys={columnKeysDegreeWidgets}
                />
              )}

              {newList && ipedsTopLists && ipedsTopLists.length > 0 && tabSelected === '4' && (
                <DegreeWidgetsTable
                  selectedRowKeys={selectedIPEDSTopLists}
                  setSelectedRowKeys={setSelectedIPEDSTopLists}
                  data={
                    degreeWidgetsTableData?.length > 0
                      ? degreeWidgetsTableData.filter((c) => !c.widgets || c.widgets.length === 0)
                      : 0
                  }
                  columnKeys={columnKeysDegreeWidgets}
                />
              )}
            </TabPane>
            {/* END DEGREE WIDGETS */}
          </Tabs>
        </Content>
      )}
    </>
  );
};
export default WebsiteForm;
