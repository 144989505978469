import React, { useState, useContext, useEffect } from 'react';
import { Button, notification, Spin, Table, Select } from 'antd';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';
import { ExportToExcel } from '../../components/csv';

const RankingsBySchool = () => {
  const currentuser = useContext(UserContext);
  const [working, setWorking] = useState(false);
  const [unitid, setUnitid] = useState(null);
  const [data, setData] = useState([]);
  const [institutions, setInstitutions] = useState([]);

  useEffect(() => {
    const fetchInstitutions = async () => {
      try {
        setWorking(true);
        const res = await Axios.get(`${SERVER_URL}/all-school-profiles-names`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
        if (res) {
          setInstitutions(res.data);
          setWorking(false);
        }
      } catch (error) {
        setWorking(false);
        console.log('Error on fetch institutions');
      }
    };
    fetchInstitutions();
  }, [currentuser.data.token]);

  const handleFetch = async () => {
    if (!unitid) {
      notification.error({
        message: 'Enter unitid first',
        placement: 'bottomRight',
        duration: 2,
      });
      return;
    }

    try {
      setWorking(true);
      const res = await Axios.get(`${SERVER_URL}/rankings-by-unitid/${unitid}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      setData(res.data);
      setWorking(false);
    } catch (error) {
      setWorking(false);
      notification.error({
        message: 'Something went wrong',
        placement: 'bottomRight',
        duration: 3,
      });
      console.log('Error on fetch', error);
    }
  };

  const columns = [
    {
      title: 'UNITID',
      dataIndex: ['unitid'],
      key: 'unitid',
    },
    {
      title: 'School Name',
      dataIndex: ['schoolName'],
      key: 'schoolName',
    },
    {
      title: 'Ranking Type',
      dataIndex: ['rankingType'],
      key: 'rankingType',
    },
    {
      title: 'Ranking Name',
      dataIndex: ['rankingName'],
      key: 'rankingName',
    },
    {
      title: 'Cipcode',
      dataIndex: ['cipcode'],
      key: 'cipcode',
    },
    {
      title: 'Total Schools on list',
      dataIndex: ['totalSchools'],
      key: 'totalSchools',
    },
    {
      title: 'Ranking Position',
      dataIndex: ['rankingPosition'],
      key: 'rankingPosition',
    },
    {
      title: 'URL',
      dataIndex: ['url'],
      key: 'url',
      render: (text) => (
        <a href={text} alt={text} target='_blank' rel='noopener noreferrer'>
          {text}
        </a>
      ),
    },
  ];

  let filename = '';
  let excelData = [];
  if (data && data.length > 0) {
    filename = 'school_' + data[0].schoolName;
    excelData = data.map((item) => item);
  }

  return (
    <div className='content-wrapper'>
      <Spin spinning={working} tip='Working...'>
        <div className='actions-block flex'>
          <div>
            <label htmlFor='unitid'>
              <strong>SCHOOL: </strong>
            </label>
            {/* <Input
              type='text'
              value={unitid}
              onChange={(e) => setUnitid(e.target.value)}
              style={{ width: '200px', marginRight: '10px' }}
              name='unitid'
            /> */}
            <Select
              allowClear
              showSearch
              value={unitid}
              optionFilterProp='children'
              onChange={(value) => setUnitid(value)}
              style={{ width: '300px', marginLeft: '4px', marginRight: '5px', fontSize: '11px' }}
              placeholder='(unitid) - (name)'
            >
              {institutions.map((inst, index) => (
                <Select.Option
                  key={`${inst.unitid}_${index}`}
                  value={inst.unitid}
                  title={`${inst.unitid} - ${inst.name}`}
                  style={{ fontSize: '11px' }}
                >
                  {inst.unitid} - {inst.name}
                </Select.Option>
              ))}
            </Select>

            <Button type='primary' onClick={handleFetch}>
              FETCH
            </Button>

            <ExportToExcel
              csvData={excelData}
              fileName={filename}
              propsToRemove={[]}
              disabled={!data || (data && data.length === 0)}
            />
          </div>
        </div>

        {data && data.length > 0 ? (
          <Table
            size='middle'
            // onChange={onChangeTable}
            bordered
            dataSource={data}
            columns={columns}
            rowKey={(record) => record._id}
            // rowSelection={rowSelection}
            pagination={{
              pageSize: 20,
              // total: total || 0,
              // current: page,
              // defaultCurrent: 1,
              // position: 'bottom',
              // showSizeChanger: false,
              // onChange: (page) => pageHandler(page),
              // hideOnSinglePage: true,
            }}
          />
        ) : (
          <h3>No Rankings</h3>
        )}
      </Spin>
    </div>
  );
};

export default RankingsBySchool;
