import React from 'react';
import axios from 'axios';
import { Button, Upload, Tooltip } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const ImportExcelFile = ({
  type = 'secondary',
  disabled,
  onChange,
  headers,
  action,
  name,
  toolTip,
  label,
  setWorking,
}) => {
  const uploadFile = async ({ action, file }) => {
    setWorking(true);
    let actions = [];
    if (!Array.isArray(action)) {
      actions.push(action);
    } else {
      actions = action;
    }

    for (const url of actions) {
      const sendData = new FormData();
      sendData.append('main', file);

      const config = {
        method: 'post',
        url,
        headers,
        data: sendData,
      };

      try {
        const resp = await axios(config);
        console.log('resp =', resp?.data?.message);
      } catch (error) {
        console.log('error =', error.response ? error.response.data.message : error.message);
      }
    }
    setWorking(false);
  };

  return (
    <Upload
      disabled={disabled}
      beforeUpload={(file) => {
        if (
          file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' &&
          file.type !== 'text/csv'
        ) {
          return false;
        }
      }}
      customRequest={uploadFile}
      maxCount={1}
      // multiple
      action={action}
      accept='.xlsx,.csv'
      name={name}
      headers={headers}
      fileList={[]}
      onChange={(value) => {
        onChange(value);
      }}
    >
      <Tooltip title={toolTip}>
        <Button disabled={disabled} type={type} icon={<UploadOutlined />}>
          {label}
        </Button>
      </Tooltip>
    </Upload>
  );
};

export default ImportExcelFile;
