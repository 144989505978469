import React, {useContext, useEffect, useRef, useState} from 'react'
import {Formik} from "formik";
import {Form, FormItem, Input, ResetButton, SubmitButton, Select} from "formik-antd";
import * as Yup from "yup";
import {Col, Row} from "antd";
import axios from "axios";
import {SERVER_URL} from "../../config";
import {UserContext} from "../../App";

const Tags = ["ONLINE", "NO_GRE_REQUIREMENT", "NO_GPA_REQUIREMENT", "TOP_RANKED", "FOR_PROFIT"]

const AffiliateInstitutionFrom = ({data, submitData, handleSubmitForm}) => {
    const currentUser = useContext(UserContext);
    const [tagsData, setTagsData] = useState([]);
    const formRef = useRef();

    const fetchData = async () => {
        const response = await axios.get(`${SERVER_URL}/affiliate-institution-tag/all`, {
            withCredentials: false,
            headers: {Authorization: `Bearer ${currentUser.data.token}`},
        });
        setTagsData(response.data);
    }

    useEffect(() => {
        fetchData()
    },[]);


    const validateScheme = Yup.object({
        unitId: Yup.number().required('Unit ID is required'),
        name: Yup.string().required('Name is required'),
        state: Yup.string().required('State is required'),
        city: Yup.string().required('City is required'),
        // country: Yup.string().required('Country is required'),
    })

    return (
        <Formik
            innerRef={formRef}
            enableReinitialize={true}
            initialValues={data}
            onSubmit={async (values, {setSubmitting, resetForm}) => {
                handleSubmitForm(values);
                setSubmitting(false);
                resetForm();
            }} validationSchema={validateScheme}>
            {() => (
                <Form
                    id="affiliate-institution-form"
                    layout={'vertical'}
                >
                    <FormItem
                        name="unitId"
                        label="Unit ID"
                        required={true}
                    >
                        <Input name="unitId" placeholder="Unit ID"/>
                    </FormItem>
                    <FormItem
                        name="name"
                        label="Name"
                        required={true}
                    >
                        <Input name="name" placeholder="Name"/>
                    </FormItem>
                    <Row gutter={12}>
                        <Col span={12}>
                            <FormItem
                                name="state"
                                label="State"
                                required={true}
                            >
                                <Input name="state" placeholder="State"/>
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem
                                name="city"
                                label="City"
                                required={true}
                            >
                                <Input name="city" placeholder="City"/>
                            </FormItem>
                        </Col>
                    </Row>

                    <FormItem
                        name="logoUrl"
                        label="Logo URL"
                        >
                        <Input name="logoUrl" placeholder="Logo URL"/>
                    </FormItem>
                    <FormItem name={'tags'} label={'Tags'}>
                        <Select mode={'tags'} name={'tags'} placeholder={'Tags'}>
                            {tagsData.map(tag => ( <Select.Option key={tag} value={tag.name}>{tag.name.replace(/_/g," ")}</Select.Option> ))}
                        </Select>
                    </FormItem>
                </Form>
            )}
        </Formik>
    )
}

export default AffiliateInstitutionFrom;
