import React, { useEffect, useState, useContext, useRef } from 'react';
import {
  Button,
  Tooltip,
  Spin,
  Table,
  Input,
  Alert,
  Popconfirm,
  notification,
  Select,
  Collapse,
  Modal,
  Switch,
  Space,
  Checkbox,
} from 'antd';
import { PlusCircleOutlined, SearchOutlined, DeleteOutlined, SyncOutlined, EditOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import { UserContext } from '../../../App';
import * as _ from 'lodash';
import Axios from 'axios';
import { SERVER_URL } from '../../../config';
import Highlighter from 'react-highlight-words';
import CKEditor from '../../../components/base/CKEditor';
import BubblesList from '../BubblesList';

const { TextArea } = Input;

const queryClient = new QueryClient();

let filterData = {
  state: undefined,
  page: 1,
  pageSize: 10,
  sort: undefined,
  pagination: true,
  naFilter: false,
  online: false,
};

const OtherRankings = () => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [refresh, setRefresh] = useState(null);
  const [filter, setFilter] = useState(filterData);
  const [fetchTrigger, setFetchTrigger] = useState(false);
  const [working, setWorking] = useState(false);
  const [selectedForRefresh, setSelectedForRefresh] = useState([]);
  const [modal, setModal] = useState({
    visible: false,
    category: '',
    title: '',
    content: '',
    metaTitle: '',
    metaDescription: '',
    bubbleDescription: '',
    metaImage: '',
    metaUrl: '',
    listName: '',
    bubbles: [],
    degree: null,
    spotlight: null,
    shortcode: null,
  });
  const [bubbles, setBubbles] = useState([]);
  const titleInput = useRef();
  const [allSpotlights, setAllSpotlights] = useState([]);
  const [content, setContent] = useState('');

  useEffect(() => {
    filterData = { ...filterData, ..._.pickBy(_.get(history, 'location.state', null), _.identity) };
    setFilter(filterData);
  }, [history]);

  // react query
  async function fetchOther(filter) {
    const params = _.pickBy(filter, _.identity);
    const { data } = await Axios.get(`${SERVER_URL}/rankings-universal?degree=other`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
      params,
    });

    // spotlight
    const spotlightRes = await Axios.get(`${SERVER_URL}/spotlight`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
    });
    if (spotlightRes) {
      setAllSpotlights(spotlightRes.data);
    }

    return data;
  }

  const { data, isError, isLoading } = useQuery({
    queryKey: ['other', filter, fetchTrigger],
    queryFn: () => fetchOther(filter),
    keepPreviousData: true,
  });

  let searchInput;
  const getColumnSearchProps = (dataIndex) => {
    if (Array.isArray(dataIndex)) dataIndex = dataIndex.join('.');
    const prop = dataIndex;
    return {
      filteredValue:
        (_.pickBy(filter, _.identity) && _.pickBy(filter, _.identity)[prop] && [_.pickBy(filter, _.identity)[prop]]) ||
        null,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <Input
              ref={(node) => {
                searchInput = node;
              }}
              placeholder={`Search ${prop}`}
              value={selectedKeys && selectedKeys.length > 0 ? selectedKeys : [_.pickBy(filter, _.identity)[prop]]}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={(e) => handleSearch(e, selectedKeys, confirm, prop)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />

            <Button
              type='primary'
              onClick={(e) => handleSearch(e, selectedKeys, confirm, prop)}
              size='small'
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>

            <Button onClick={(e) => handleReset(e, clearFilters, prop)} size='small' style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        );
      },
      filterIcon: (filtered) => {
        filtered = !!_.pickBy(filter, _.identity)[prop];
        return <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />;
      },
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => {
            searchInput.select();
          });
        }
      },
      // sorter: true,
      // defaultSortOrder: setDefaultSort(prop),
      // sortDirections: ['ascend', 'descend'],
      render: (text) => {
        return _.pickBy(filter, _.identity)[prop] ? (
          <Highlighter
            title={text}
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[_.pickBy(filter, _.identity)[prop]]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          <span title={text}>{text}</span>
        );
      },
    };
  };

  const handleSearch = async (e, selectedKeys, confirm, dataIndex) => {
    e.preventDefault();
    confirm();
    setFilter({ ..._.pickBy(filter, _.identity), [dataIndex]: selectedKeys[0] });
  };

  const handleReset = async (e, clearFilters, prop) => {
    e.preventDefault();
    setFilter({ ...filter, [prop]: undefined, page: 1, pageSize: 10 });
    clearFilters();
  };

  const deleteHandler = async (id) => {
    try {
      setWorking(true);

      await Axios.delete(`${SERVER_URL}/rankings-universal/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      // sitemap
      await Axios.get(`${SERVER_URL}/rankings-sitemap-refresh?type=undergrad`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      setWorking(false);
      setFetchTrigger(!fetchTrigger);

      notification.success({
        message: 'Success',
        description: 'Ranking removed',
        placement: 'bottomRight',
      });
    } catch (error) {
      setWorking(false);
      console.log(error.response.data.message);
      notification.error({
        message: 'Error',
        description: error.response.data.message,
        placement: 'bottomRight',
      });
    }
  };

  const columns = [
    {
      title: 'CIPCODE',
      dataIndex: ['cipcode'],
      key: 'cipcode',
      ...getColumnSearchProps(['cipcode']),
    },
    {
      title: 'DEGREE',
      dataIndex: ['degree'],
      key: 'degree',
      // ...getColumnSearchProps(['degree']),
    },
    {
      title: 'CATEGORY',
      dataIndex: ['category'],
      key: 'category',
      ...getColumnSearchProps(['category']),
      render: (text, record) => (!record.parent ? text : `${record.parent.category} (${record.parent.degree})`),
    },
    {
      title: 'ADDITIONAL',
      dataIndex: 'category',
      key: 'category-additional',
      render: (text, record) => (!record.parent ? null : text),
    },
    {
      title: 'TOTAL SCHOOLS',
      dataIndex: 'count',
      key: 'count',
      align: 'right',
      render: (text, record) => record.count ?? 0,
    },
    {
      title: 'CREATED AT',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'right',
      render: (text, record) => new Date(record.createdAt).toLocaleString('en-US'),
    },
    {
      title: 'UPDATED AT',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      align: 'right',
      render: (text, record) => new Date(record.updatedAt).toLocaleString('en-US'),
    },
  ];

  columns.push(
    {
      title: 'Content',
      render: (text, record) => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '15px',
            }}
          >
            <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
              <Tooltip title='Edit Content' placement='topLeft'>
                <EditOutlined style={{ textDecoration: 'none', color: 'black' }} onClick={() => modalHandler(record)} />
              </Tooltip>
            </div>
          </div>
        );
      },
    },
    {
      title: 'Action',
      render: (text, record) => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '15px',
            }}
          >
            <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='table-actions'>
              <Tooltip>
                <EditOutlined onClick={() => history.push(`/admin/rankings/other/${record._id}`)} />
              </Tooltip>
            </div>

            <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='table-actions'>
              <Popconfirm
                title={`Are you sure you want to delete this ranking?${
                  !record.parent ? ' This will delete all additional rankings under it!' : ''
                }`}
                onConfirm={async () => deleteHandler(record._id)}
                onCancel={() => console.log('Cancel')}
                okText='Yes'
                cancelText='No'
              >
                <DeleteOutlined />
              </Popconfirm>
            </div>
          </div>
        );
      },
    },
    {
      title: 'Active',
      render: (text, record) => {
        return (
          <Switch
            checked={record.active}
            onChange={(checked) => handleActivate(record._id, checked, record.onlineOther)}
            disabled={record.degree === 'Undergraduate Certificate or Diploma' || record?.parent?.active === false}
          />
        );
      },
    },
  );

  const handleActivate = async (id, checked, onlineOther) => {
    try {
      setWorking(true);
      await Axios.patch(
        `${SERVER_URL}/rankings-universal/${id}${onlineOther ? '?online=true' : ''}`,
        {
          active: checked,
        },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );
      setWorking(false);
      setFetchTrigger(!fetchTrigger);

      notification.success({
        message: 'Success',
        description: 'Ranking updated',
      });
    } catch (error) {
      console.log(error);
      setWorking(false);
      notification.error({
        message: 'Error',
        description: 'Problem with activating ranking. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  const modalHandler = (record) => {
    setModal({
      visible: true,
      id: record._id,
      category: record.category,
      title: record.content ? record.content.title : '',
      listName: record.content ? record.content.listName : '',
      metaTitle: record.content ? record.content.metaTitle : '',
      metaDescription: record.content ? record.content.metaDescription : '',
      bubbleDescription: record.content ? record.content.bobbleDescription : '',
      metaImage: record.content ? record.content.metaImage : '',
      metaUrl: record.content ? record.content.metaUrl : '',
      degree: record.degree,
      spotlight: record.spotlight,
      shortcode: record.shortcode,
    });
    setContent(record.content && record.content.content ? record.content.content : '');
    if (record.content && record.content.bubbles && record.content.bubbles.length > 0) {
      setBubbles(record.content.bubbles);
    } else setBubbles([]);
  };

  const onOk = async () => {
    if (!modal.title || modal.title === '') {
      titleInput.current.focus();
      notification.error({
        message: '<h1> title is required.',
        placement: 'topRight',
        duration: 2,
      });
      return;
    }

    try {
      setWorking(true);
      const body = {
        title: modal.title,
        content,
        listName: modal.listName,
        metaTitle: modal.metaTitle,
        metaDescription: modal.metaDescription,
        bobbleDescription: modal.bubbleDescription,
        metaUrl: modal.metaUrl,
        metaImage: modal.metaImage,
        bubbles: bubbles,
        spotlight: modal.spotlight,
        shortcode: modal.shortcode,
      };

      await Axios.patch(`${SERVER_URL}/rankings-programs/${modal.id}`, body, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      setWorking(false);
      setFetchTrigger(!fetchTrigger);
      setRefresh(Math.random());
      setModal({ visible: false });
      notification.success({
        message: 'Content (other) saved',
        placement: 'bottomRight',
        duration: 2,
      });
    } catch (error) {
      console.log('Error on save content', error);
      setWorking(false);
      setModal({ visible: false });
      notification.error({
        message: 'Error',
        description: 'Problem with saving content',
        placement: 'bottomRight',
        duration: 2,
      });
    }
  };

  const handleSitemap = async () => {
    try {
      setWorking(true);

      // sitemap
      await Axios.get(`${SERVER_URL}/rankings-sitemap-refresh?type=undergrad`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: 'BC Sitemap updated',
        placement: 'bottomRight',
      });

      setWorking(false);
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Problem with refreshing best-colleges sitemap',
        placement: 'bottomRight',
      });
    }
  };

  const handleSPLinks = async () => {
    try {
      setWorking(true);

      // replace school links in rankings
      await Axios.get(`${SERVER_URL}/rankings-update-sp-link?degree=other`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: 'SP links updated on other pages',
        placement: 'bottomRight',
      });

      setWorking(false);
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Problem with refreshing school profile links on other pages',
        placement: 'bottomRight',
      });
    }
  };

  const handleRefreshAll = async () => {
    try {
      const cipcodesBody = data.map((item) => ({
        cipcode: item.cipcode,
        category: item.category,
        categoryUrl: item.categoryUrl,
        degree: item.degree,
        parent: item.parent,
      }));

      setWorking(true);

      // refresh ranking items
      await Axios.patch(
        `${SERVER_URL}/rankings?type=other&isProgramLevel=true`,
        { cipcodes: cipcodesBody },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );

      // STEP 2: refresh sitemap and empty pages
      await Axios.get(`${SERVER_URL}/rankings-sitemap-refresh?type=undergrad`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      // STEP 3: replace school links in rankings
      await Axios.get(`${SERVER_URL}/rankings-update-sp-link?degree=other`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      setWorking(false);
      setFetchTrigger(!fetchTrigger);

      notification.success({
        message: 'Other rankings saved',
        placement: 'bottomRight',
      });
    } catch (error) {
      setWorking(false);
      console.log(error.response);
      notification.error({
        message: 'Error',
        description: 'Problem with refreshing other rankings. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  const handleRefresh = async () => {
    if (!selectedForRefresh || selectedForRefresh.length === 0) return;

    try {
      setWorking(true);
      for (const selectedID of selectedForRefresh) {
        const foundRanking = data.find((item) => item._id === selectedID);

        if (foundRanking) {
          const newMethodRes = await Axios.get(
            `${SERVER_URL}/rankings-new-methodology-creation?degree=${foundRanking.degree}&cipcode=${foundRanking.cipcode}&isProgramLevel=true`,
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${currentuser.data.token}` },
            },
          );

          await Axios.patch(
            `${SERVER_URL}/rankings-universal/${selectedID}`,
            {
              items: newMethodRes.data.ranking.rankingItems,
              // weightedAveragesNational: newMethodRes.data.ranking.weightedAveragesNational,
            },
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${currentuser.data.token}` },
            },
          );
        } else return;

        // replace school links in rankings
        await Axios.get(`${SERVER_URL}/rankings-update-sp-link?id=${selectedID}`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
      }

      // SITEMAP
      await Axios.get(`${SERVER_URL}/rankings-sitemap-refresh?type=undergrad`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: 'Other rankings refreshed',
        placement: 'bottomRight',
      });

      setFetchTrigger(!fetchTrigger);

      setWorking(false);
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Error',
        description: 'Problem with refreshing other rankings. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  const onChangeTable = (pagination, filters, sorter) => {
    // eslint-disable-next-line array-callback-return
    Object.keys(filters).map((prop) => {
      filter[prop] = Array.isArray(filters[prop]) ? filters[prop][0] : filters[prop];
    });

    setFilter({ ...filter, ...{ page: pagination.current, pageSize: pagination.pageSize } });
  };

  const onSelectChange = (selectedRowKeys) => {
    setSelectedForRefresh([...selectedRowKeys]);
  };

  const rowSelection = {
    selectedForRefresh,
    onChange: onSelectChange,
  };

  return (
    <div className='content-wrapper'>
      {isError && <Alert message={'Loading data problem...'} type='error' showIcon closable />}
      <Spin spinning={working} tip='Working...'>
        <div className='actions-block flex'>
          <Link to={'/admin/rankings/new/other'}>
            <Tooltip title='Create new other ranking'>
              <Button type='primary' style={{ marginRight: '8px', float: 'left' }}>
                <PlusCircleOutlined />
                NEW RANKING
              </Button>
            </Tooltip>
          </Link>

          <Tooltip title='/best-colleges'>
            <Button type='danger' style={{ marginRight: '5px' }} onClick={handleSitemap}>
              <SyncOutlined spin={working} />
              Refresh Sitemap
            </Button>
          </Tooltip>

          <Button
            type='danger'
            style={{ marginRight: '5px' }}
            onClick={handleSPLinks}
            disabled={!data || data.length === 0}
          >
            <SyncOutlined spin={working} />
            Refresh SP Links
          </Button>

          <Button
            type='danger'
            style={{ marginRight: '8px' }}
            onClick={handleRefreshAll}
            disabled={!data || data.length === 0}
          >
            <SyncOutlined spin={working} />
            Refresh All
          </Button>

          <Button
            type='danger'
            style={{ marginRight: '8px' }}
            onClick={handleRefresh}
            disabled={!selectedForRefresh || selectedForRefresh.length === 0}
          >
            Refresh Selected Ranking(s)
          </Button>

          <p>Total rankings: {data?.length}</p>
        </div>

        {!isLoading && !isError && (
          <div>
            <Table
              size='middle'
              title={() => (
                <Space>
                  <Tooltip title='Filter for online rankings'>
                    <Checkbox
                      name='online'
                      checked={filter.online}
                      onChange={(e) => setFilter({ ...filter, ...{ online: e.target.checked } })}
                    >
                      Online
                    </Checkbox>
                  </Tooltip>
                </Space>
              )}
              onChange={onChangeTable}
              bordered
              dataSource={data}
              columns={columns}
              rowKey={(record) => record._id}
              rowSelection={rowSelection}
              pagination={{
                pageSize: filter.pageSize || 10,
                total: data.length,
                current: filter.page || 1,
              }}
            />

            <Modal
              centered
              width='70%'
              visible={modal.visible}
              title={
                <span>
                  EDIT - <strong>{modal.category} (OTHER)</strong>
                </span>
              }
              okText='SAVE'
              cancelText='CANCEL'
              maskClosable={false}
              onOk={onOk}
              onCancel={() => {
                setModal({ visible: false });
                setFetchTrigger(!fetchTrigger);
              }}
              confirmLoading={working}
            >
              <div>
                <p style={{ fontSize: 15, marginTop: 20, marginRight: 10 }}>{'<h1> '}</p>
                <Input
                  name='title'
                  value={modal.title}
                  placeholder={'Title for <h1>...'}
                  onChange={(e) => setModal({ ...modal, title: e.target.value })}
                  ref={titleInput}
                />
              </div>
              <br></br>
              <div>
                <p style={{ fontSize: 15, marginTop: 20, marginRight: 10 }}>Content </p>
                <CKEditor content={content} setContent={setContent} />
              </div>
              <br></br>
              {modal.category !== 'Search' && (
                <>
                  <Collapse>
                    <Collapse.Panel key='1' header='Bubbles'>
                      <BubblesList data={bubbles} handleBubbles={setBubbles} refresh={refresh} />
                    </Collapse.Panel>
                  </Collapse>
                  <br></br>
                </>
              )}
              <br></br>
              {modal.category !== 'Search' && (
                <div>
                  <p style={{ fontSize: 15, marginTop: 20, marginRight: 10 }}>List Name </p>
                  <Input
                    name='listName'
                    value={modal.listName}
                    placeholder={'Example List Name'}
                    onChange={(e) => setModal({ ...modal, listName: e.target.value })}
                  />
                </div>
              )}
              <br></br>
              <div>
                <p style={{ fontSize: 15, marginTop: 20, marginRight: 10 }}>Meta Title</p>
                <Input
                  name='metaTitle'
                  value={modal.metaTitle}
                  onChange={(e) => setModal({ ...modal, metaTitle: e.target.value })}
                  maxLength={70}
                />
              </div>
              <br></br>
              <div>
                <p style={{ fontSize: 15, marginTop: 20, marginRight: 10 }}>Meta Description</p>
                <TextArea
                  name='metaDescription'
                  value={modal.metaDescription}
                  maxLength={180}
                  rows={3}
                  onChange={(e) => setModal({ ...modal, metaDescription: e.target.value })}
                />
              </div>
              <br></br>
              <div>
                <p style={{ fontSize: 15, marginTop: 20, marginRight: 10 }}>Meta Url</p>
                <Input
                  name='metaUrl'
                  value={modal.metaUrl}
                  onChange={(e) => setModal({ ...modal, metaUrl: e.target.value })}
                />
              </div>
              <br></br>
              <div>
                <p style={{ fontSize: 15, marginTop: 20, marginRight: 10 }}>Meta Image</p>
                <Input
                  name='metaImage'
                  value={modal.metaImage}
                  onChange={(e) => setModal({ ...modal, metaImage: e.target.value })}
                />
              </div>
              <br></br>
              <div>
                {modal.metaImage && (
                  <img
                    src={modal.metaImage}
                    style={{
                      width: '200px',
                      border: '0.5px solid #bbb',
                      background: '#eee',
                      borderRadius: '5px',
                      cursor: 'pointer',
                    }}
                    alt='metaImage'
                  />
                )}
              </div>

              <div>
                <p style={{ fontSize: 15, marginTop: 20, marginRight: 10 }}>Bubble Description</p>
                <TextArea
                  name='bubbleDescription'
                  value={modal.bubbleDescription}
                  maxLength={180}
                  rows={3}
                  onChange={(e) => setModal({ ...modal, bubbleDescription: e.target.value })}
                />
              </div>

              {/* SPOTLIGHT */}
              <div style={{ marginTop: '20px', marginBottom: '10px' }}>
                <label htmlFor='spotlight'>Spotlight: </label>
                <Select
                  allowClear
                  showSearch
                  name='spotlight'
                  value={modal.spotlight}
                  onChange={(value) => setModal({ ...modal, spotlight: value })}
                  style={{ width: '400px', marginLeft: '4px', fontSize: '15px' }}
                  placeholder='Select spotlight...'
                >
                  {allSpotlights.map((item) => (
                    <Select.Option key={`${item._id}`} value={item._id}>
                      {item.CMSDisplayName}
                    </Select.Option>
                  ))}
                </Select>
              </div>

              {/* SHORTCODE */}
              <div style={{ marginTop: '20px', marginBottom: '10px' }}>
                <label htmlFor='shortcode'>Shortcode </label>
                <TextArea
                  name='shortcode'
                  value={modal.shortcode}
                  rows={2}
                  onChange={(e) => setModal({ ...modal, shortcode: e.target.value })}
                />
              </div>
            </Modal>
          </div>
        )}
      </Spin>
    </div>
  );
};

export default function other() {
  return (
    <QueryClientProvider client={queryClient}>
      <OtherRankings />
    </QueryClientProvider>
  );
}
