import React, {useEffect, useContext, useState} from 'react';
import {Link} from 'react-router-dom';
import Axios from 'axios';
import {LoadingOutlined} from '@ant-design/icons';
import {Button, Col, Divider, notification, Row, Spin, Typography} from 'antd';
import {FieldArray, Formik} from "formik";
import {Form, FormItem, Input, ResetButton, SubmitButton, Select} from "formik-antd";
import axios from "axios";
import * as Yup from "yup";

import DEGREES from '../../lib/ASDdegrees.json';
import {UserContext} from '../../App';
import {SERVER_URL} from '../../config';

const {Text} = Typography;

const EditASD = (props) => {
    const currentuser = useContext(UserContext);
    const [touData, setTouData] = useState(undefined);
    const [isWorking, setWorking] = useState(false);
    const [specializations, setSpecializations] = useState([]);
    const [marketContexts, setMarketContexts] = useState([]);

    const {id} = props.match.params;

    const getDataForCodes = async (type) => {
        const specData = await Axios.get(`${SERVER_URL}/affiliate-institution-code/${type}/all`, {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
        return specData.data;
    }

    useEffect(() => {
        const fetchSpecializations = async () => {
            try {
                setWorking(true);
                const specData = await getDataForCodes('SPECIALIZATION');
                if (specData) {
                    setSpecializations(specData);
                }
                setWorking(false);
            } catch (error) {
                setWorking(false);
                console.log('Error: ', error);
            }
        };
        const fetchMarketContext = async () => {
            try {
                setWorking(true);
                const specData = await getDataForCodes('MARKET_CONTEXT');
                if (specData) {
                    setMarketContexts(specData);
                }
                setWorking(false);
            } catch (error) {
                setWorking(false);
                console.log('Error: ', error);
            }
        };
        fetchMarketContext();
        fetchSpecializations();
    }, [currentuser.data.token]);

    useEffect(() => {
        const fetchTOUByID = async () => {
            try {
                const response = await Axios.get(`${SERVER_URL}/asd/${id}`, {
                    withCredentials: false,
                    headers: {Authorization: `Bearer ${currentuser.data.token}`},
                });
                setTouData(response.data);
            } catch (error) {
                console.log(error);
            }
        }
        if (id !== 'new') {
            fetchTOUByID();
        } else {
            setTouData({
                name: undefined,
                urlSegment: undefined,
                marketContext: undefined,
                programs: [],
            });
        }
    }, [currentuser.data.token, id]);


    async function handleSubmit(fields, {setSubmitting}) {
        fields.marketContext = fields.marketContext || null;
        try {
            if (id === 'new') {
                await axios.post(`${SERVER_URL}/asd`, fields, {
                    withCredentials: false,
                    headers: {Authorization: `Bearer ${currentuser.data.token}`},
                });
            } else {
                await axios.put(`${SERVER_URL}/asd/${id}`, fields, {
                    withCredentials: false,
                    headers: {Authorization: `Bearer ${currentuser.data.token}`},
                });
            }
            notification.success({
                message: 'Success',
                description: 'ASD updated successfully',
                duration: 5,
            });
        } catch (e) {
            notification.error({
                message: 'Error',
                description: e.message,
                duration: 0,
            });
        }
        setSubmitting(false);
    }

    const touSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        // marketContext: Yup.string().required('market context is required').nullable(),
        degree: Yup.string().required('Degree is required').nullable(),
        programName: Yup.string().required('Name is required'),
        programId: Yup.string().nullable(),
        specialization: Yup.string().required('Specialization is required').nullable(),
        affLink: Yup.string().url('Must be a valid URL').required('Affiliate Link is required').nullable(),
    });

    const newProgram = {
        degree: undefined,
        name: undefined,
        programName: undefined,
        programId: undefined,
        specialization: undefined,
        affLink: undefined,
    }


    if (!touData) {
        return (
            <div style={{textAlign: 'center'}}>
                <LoadingOutlined spin style={{fontSize: '3rem', marginTop: '5rem'}}/>
            </div>
        )
    }
    return (
        <div style={{padding: '8px'}}>
            <Spin spinning={isWorking} tip='Working...'>
                <div className='actions-block'>
                    <Link to='/admin/affiliate-links'>
                        <Button type='primary'>All ASD</Button>
                    </Link>
                </div>

                <div>
                    <Formik initialValues={touData} validationSchema={touSchema} onSubmit={handleSubmit}>
                        {({values, errors}) => (

                            <Form layout="vertical">
                                <Row gutter={16}>
                                    <Col span={6}>
                                        <FormItem
                                            name="unitId"
                                            label="Unit ID"
                                            required={true}
                                        >
                                            <Input name="unitId"/>
                                        </FormItem>
                                    </Col>
                                    <Col span={6}>
                                        <FormItem
                                            name="programId"
                                            label="Program ID"
                                        >
                                            <Input name="programId"/>
                                        </FormItem>
                                    </Col>
                                    <Col span={12}>
                                        <FormItem
                                            name="name"
                                            label="Institution Name"
                                            required={true}
                                        >
                                            <Input name="name"/>
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                        <Col span={24}>
                                            <FormItem
                                                name="programName"
                                                label="Program Name"
                                                required={true}
                                            >
                                                <Input name="programName"/>
                                            </FormItem>
                                        </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <FormItem
                                            name="marketContext"
                                            label="Market Context"
                                        >
                                            <Select name="marketContext" showSearch allowClear  filterOption={
                                                (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }>
                                                {marketContexts.map((item, index) => <Select.Option key={index} value={item.value}>{item.label}</Select.Option>)}
                                            </Select>
                                        </FormItem>
                                    </Col>
                                    <Col span={12}>
                                        <FormItem
                                            name="degree"
                                            label="Degree"
                                            required={true}
                                        >
                                            <Select name="degree">
                                                {DEGREES.map((degree, index) => (
                                                    <Select.Option key={index} value={degree.title}>{degree.title}</Select.Option>
                                                ))}
                                            </Select>
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <FormItem
                                            name="specialization"
                                            label="Specialization"
                                        >
                                            <Select name="specialization" showSearch allowClear  filterOption={
                                                (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }>
                                                {specializations.map((specialization, index) => <Select.Option key={index} value={specialization.value}>{specialization.label}</Select.Option>)}
                                            </Select>
                                        </FormItem>
                                    </Col>
                                    <Col span={12}>
                                        <FormItem
                                            name="specializationSecondary"
                                            label="Specialization Secondary"
                                        >
                                            <Select name="specializationSecondary" showSearch allowClear  filterOption={
                                                (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }>
                                                {specializations.map((specialization, index) => <Select.Option key={index} value={specialization.value}>{specialization.label}</Select.Option>)}
                                            </Select>
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={24}>
                                        <FormItem
                                            name="affLink"
                                            label="Affiliate Link"
                                            required={true}
                                        >
                                            <Input name="affLink"/>
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                    </Col>
                                    <Col span={12}></Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        {typeof errors.programs === 'string' &&
                                            <Text type="danger">{errors.programs} </Text>}
                                    </Col>
                                </Row>

                                <SubmitButton type="primary" disabled={false}>
                                    Submit
                                </SubmitButton>
                                <ResetButton>Reset</ResetButton>
                            </Form>
                        )}
                    </Formik>

                </div>
            </Spin>
        </div>
    );
};

export default EditASD;
