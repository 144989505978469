import React, {useState} from 'react';
import {Table, Row, Col, Divider, Button, Tooltip, Switch, notification, Input, Typography} from 'antd';
import {CopyFilled, EditOutlined, PushpinOutlined, SearchOutlined} from '@ant-design/icons';
import moment from "moment/moment";
import {Link} from "react-router-dom";
import Highlighter from "react-highlight-words";

const AffiliateLinksTable = ({asd, setAsd, tableData, title, editPath, handleChangeStatus, OnPageChanged, handleOnChangeSearch}) => {
        const [filter, setFilter] = useState(null);
        const [search, setSearch] = useState({});

        let searchInput;
        const getColumnSearchProps = (dataIndex, name) => {
            if (Array.isArray(dataIndex)) dataIndex = dataIndex.join('.');
            const prop = dataIndex;
            return {
                filteredValue: (filter && filter[prop] && [filter[prop]]) || null,
                filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => {
                    return (
                        <div style={{padding: 8}}>
                            <Input
                                ref={(node) => {
                                    searchInput = node;
                                }}
                                placeholder={`Search ${prop}`}
                                value={selectedKeys && selectedKeys.length > 0 ? selectedKeys : [search[prop]]}
                                onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                                onPressEnter={() => handleSearch(selectedKeys, confirm, prop)}
                                style={{width: 188, marginBottom: 8, display: 'block'}}
                            />

                            <Button
                                type='primary'
                                onClick={() => handleSearch(selectedKeys, confirm, prop)}
                                size='small'
                                style={{width: 90, marginRight: 8}}
                            >
                                Search
                            </Button>

                            <Button onClick={() => handleReset(clearFilters, prop)} size='small' style={{width: 90}}>
                                Reset
                            </Button>
                        </div>
                    );
                },
                filterIcon: (filtered) => {
                    if (search && search[prop]) {
                        filtered = true;
                    } else {
                        filtered = false;
                    }
                    return <SearchOutlined style={{color: filtered ? 'red' : '#bbb', fontSize: '1rem'}}/>;
                },
                onFilterDropdownVisibleChange: (visible) => {
                    if (visible) {
                        setTimeout(() => {
                            searchInput.select();
                        });
                    }
                },
                sorter: true,
                sortDirections: ['ascend', 'descend'],
                render: (text) =>
                    search[prop] ? (
                        <Highlighter
                            title={text}
                            highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
                            searchWords={[search[prop]]}
                            autoEscape
                            textToHighlight={text ? text.toString() : ''}
                        />
                    ) : (
                        <span title={text}>{text}</span>
                    ),
            };
        };

        const handleSearch = (selectedKeys, confirm, dataIndex) => {
            if (Array.isArray(dataIndex)) dataIndex = dataIndex.join('.');
            confirm();
            const newSearch = {...search};
            newSearch[dataIndex] = selectedKeys[0];
            setSearch(newSearch);
            filterHandler(dataIndex, selectedKeys[0]);
            if(handleOnChangeSearch) {
                handleOnChangeSearch(selectedKeys[0]);
            }
        };

        const handleReset = (clearFilters, prop) => {
            if (Array.isArray(prop)) prop = prop.join('.');
            clearFilters();
            const newSearch = {...search};
            setSearch(newSearch);
            delete newSearch[prop];
            filterHandler(prop, null);
            if(handleOnChangeSearch) {
                handleOnChangeSearch('');
            }

        };

        const filterHandler = (prop, value) => {
            if (Array.isArray(prop)) prop = prop.join('.');

            let filterQuery = null;
            let prevFilter = {};
            if (filter) {
                prevFilter = JSON.parse(filter);
            }


            if (value) {
                prevFilter[prop] = value;
                filterQuery = `${JSON.stringify({[prop]: !isNaN(value) ? +value : [value.toString()], ...prevFilter})}`;
            } else {
                delete prevFilter[prop];
                filterQuery = `${JSON.stringify({...prevFilter})}`;
            }

            setFilter(filterQuery);
        };

        const columns = [
            {
                title: 'UNIT ID',
                dataIndex: 'unitId',
                key: 'unitId',
                width: 100,
            },
            {
                title: 'PROGRAM ID',
                dataIndex: 'programId',
                ...getColumnSearchProps(['programId']),
            },
            {
                title: 'NAME',
                width: 150,
                dataIndex: ['name'],
                key: 'name',
                ...getColumnSearchProps(['name']),
            },
            {
                title: 'PROGRAM NAME',
                dataIndex: 'programName',
                key: 'programName',
                ...getColumnSearchProps(['programName']),
            },
            {
                title: 'SPECIALIZATION',
                dataIndex: 'specialization',
                key: 'specialization',
            },
            {
                title: 'SEC. SPECIALIZATION',
                dataIndex: 'specializationSecondary',
                key: 'specializationSecondary',
            },
            {
                title: 'MARKET CONTEXT',
                dataIndex: 'marketContext',
                key: 'marketContext',
            },

            {
                title: 'DEGREE',
                dataIndex: 'degree',
                key: 'degree',
                align: 'left',
            },
            {
                title: 'PROVIDER',
                dataIndex: 'provider',
                key: 'provider',
            },
            {
                title: 'CREATED AT',
                dataIndex: 'createdAt',
                key: 'createdAt',
                align: 'right',
                render: (text, record) => {
                    return moment(record.createdAt).format('MM/DD/YYYY');
                }
            },
        ];


        async function handleStatusChange(record, checked) {
            const res = await handleChangeStatus(record._id, checked, record.provider);
            if (res.message === 'success') {
                notification.success({
                    message: 'Success',
                    description: `Status for program ${record.programName} updated successfully`,
                });
                console.log('asd: ', asd);
                const newAsd = asd.docs.map(item => {
                    if (item._id === record._id) {
                        item.status = checked ? 1 : 0;
                    }
                    return item;
                });
                setAsd(asd => ({...asd, docs: newAsd}));
            }
        }

        async function handlePaginationChange(page, pageSize) {
            if (OnPageChanged) {
                OnPageChanged(page, pageSize);
            }
        }

        columns.push(
            {
                title: 'Active',
                align: 'center',
                dataIndex: 'status',
                render: (text, record) => {
                    return (
                        <Tooltip title={`Activate / Deactivate Program`} placement='topLeft'>
                            <Switch size="small" onChange={(checked) => handleStatusChange(record, checked)}
                                    checked={record.status == 1}/>
                        </Tooltip>
                    );
                }
            },
            {
                title: 'Action',
                render: (text, record) => (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '15px',
                        }}
                    >
                        <Tooltip title={`Edit ${title}`} placement='topLeft'>
                            <div style={{margin: '2px', padding: '4px', cursor: 'pointer'}} className='table-actions'>
                                <Link
                                    to={
                                        `/admin/edit/provider/${record.provider}/${record._id}`
                                    }
                                >
                                    <EditOutlined style={{textDecoration: 'none', color: 'black'}}/>
                                </Link>
                            </div>
                        </Tooltip>
                    </div>
                ),
            });

        return (
            <Table
                size='middle'
                bordered
                dataSource={asd.docs ? asd.docs : []}
                columns={columns}
                rowKey={(record) => record._id}
                pagination={{
                    pageSize: asd.limit ? asd.limit : 20,
                    hideOnSinglePage: true,
                    total: asd.totalDocs ? asd.totalDocs : 0,
                    current: asd.page ? asd.page : 1,
                    onChange: handlePaginationChange
                }}
            />
        );
    }
;

export default AffiliateLinksTable;
