import React, { useState, useEffect, useContext } from 'react';
import { Table, Tooltip, notification, Spin, Input, Button, Alert } from 'antd';
import { SearchOutlined, InfoCircleFilled } from '@ant-design/icons';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';
import Highlighter from 'react-highlight-words';
import Axios from 'axios';
import * as _ from 'lodash';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

const queryClient = new QueryClient();

let filterData = { name: undefined };

const MedicalAdmissions = () => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [working, setWorking] = useState(false);
  const [filter, setFilter] = useState(filterData);

  useEffect(() => {
    filterData = { ...filterData, ..._.pickBy(_.get(history, 'location.state', null), _.identity) };
    setFilter(filterData);
  }, [history]);

  // react query
  async function fetchMedAdm(filter) {
    const params = _.pickBy(filter, _.identity);
    setWorking(true);
    const { data } = await Axios.get(`${SERVER_URL}/medical-admissions`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
      params,
    });
    setWorking(false);
    return data;
  }

  const { data, isError, isLoading } = useQuery({
    queryKey: ['crimes', filter],
    queryFn: () => fetchMedAdm(filter),
    keepPreviousData: true,
  });
  if (isError) {
    notification.error({
      message: 'Error',
      description: 'Problem with fetching medical admissions.',
      placement: 'bottomRight',
    });
  }

  // columns
  const setDefaultSort = (column) => {
    const data = _.get(filter, `sort.["${column}"]`, undefined);
    if (data === undefined) return null;
    return data === 1 ? 'ascend' : 'descend';
  };
  let searchInput;
  const getColumnSearchProps = (dataIndex) => {
    if (Array.isArray(dataIndex)) dataIndex = dataIndex.join('.');
    const prop = dataIndex;
    return {
      filteredValue:
        (_.pickBy(filter, _.identity) && _.pickBy(filter, _.identity)[prop] && [_.pickBy(filter, _.identity)[prop]]) ||
        null,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <Input
              ref={(node) => {
                searchInput = node;
              }}
              placeholder={`Search ${prop}`}
              value={selectedKeys && selectedKeys.length > 0 ? selectedKeys : [_.pickBy(filter, _.identity)[prop]]}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={(e) => handleSearch(e, selectedKeys, confirm, prop)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />

            <Button
              type='primary'
              onClick={(e) => handleSearch(e, selectedKeys, confirm, prop)}
              size='small'
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>

            <Button onClick={(e) => handleReset(e, clearFilters, prop)} size='small' style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        );
      },
      filterIcon: (filtered) => {
        filtered = !!_.pickBy(filter, _.identity)[prop];
        return <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />;
      },
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => {
            searchInput.select();
          });
        }
      },
      sorter: true,
      defaultSortOrder: setDefaultSort(prop),
      sortDirections: ['ascend', 'descend'],
      render: (text) => {
        return _.pickBy(filter, _.identity)[prop] ? (
          <Highlighter
            title={text}
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[_.pickBy(filter, _.identity)[prop]]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          <span title={text}>{text}</span>
        );
      },
    };
  };
  const handleSearch = async (e, selectedKeys, confirm, dataIndex) => {
    e.preventDefault();
    confirm();
    setFilter({ ..._.pickBy(filter, _.identity), [dataIndex]: selectedKeys[0] });
  };
  const handleReset = async (e, clearFilters, prop) => {
    e.preventDefault();
    setFilter({ ...filter, [prop]: undefined, page: 1, pageSize: 10 });
    clearFilters();
  };
  let onChangeTable = (pagination, filters, sorter) => {
    Object.keys(filters).map((prop) => {
      filter[prop] = Array.isArray(filters[prop]) ? filters[prop][0] : filters[prop];
    });
    let sort;
    if (sorter.field && sorter.field.length > 0) {
      if (sorter.order) {
        const name = sorter.field.join('.');
        sort = { [name]: sorter.order === 'ascend' ? 1 : -1 };
      } else {
        sort = undefined;
      }
    }
    setFilter({ ...filter, ...{ page: pagination.current, pageSize: pagination.pageSize, sort } });
  };

  let columns = [
    {
      title: 'UNITID',
      dataIndex: ['data', 'UnitID'],
      key: 'UnitID',
    },
    {
      title: 'Name',
      dataIndex: ['name'],
      key: 'name',
      ...getColumnSearchProps(['name']),
      width: 220,
    },
  ];

  if (data && data[0]) {
    const dataColumns = Object.entries(data[0].data)
      .map(([key, value]) => {
        if (key !== 'UnitID') {
          return { title: key.toUpperCase(), dataIndex: ['data', key], key };
        } else return null;
      })
      .filter((item) => item);
    columns = [...columns, ...dataColumns];
  }

  // render
  return (
    <div className='content-wrapper'>
      {isError && !isLoading && <Alert message={'Loading data problem...'} type='error' showIcon closable />}
      <Spin spinning={isLoading || working} tip='Working...'>
        <div className='actions-block flex'>
          <p>
            <Tooltip
              title={
                <a
                  href='https://odeonventurescom.sharepoint.com/:x:/r/sites/Odeon-Tech/_layouts/15/Doc.aspx?sourcedoc=%7Bd138ced5-3778-4009-8817-737fdef1f7d6%7D&action=editnew'
                  target='_blank'
                >
                  https://odeonventurescom.sharepoint.com/:x:/r/sites/Odeon-Tech/_layouts/15/Doc.aspx?sourcedoc=%7Bd138ced5-3778-4009-8817-737fdef1f7d6%7D&action=editnew
                </a>
              }
            >
              <InfoCircleFilled />
            </Tooltip>
            Total documents: {data?.length || 0}
          </p>
        </div>

        <div>
          <Table
            size='middle'
            onChange={onChangeTable}
            bordered
            dataSource={data}
            columns={columns}
            rowKey={(record) => record._id}
          />
        </div>
      </Spin>
    </div>
  );
};

export default function () {
  return (
    <QueryClientProvider client={queryClient}>
      <MedicalAdmissions />
    </QueryClientProvider>
  );
}
