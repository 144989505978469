import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { Button } from 'antd';
import ReactJson from 'react-json-view';

const ViewBls = (props) => {
  const currentuser = useContext(UserContext);
  const [bls, fetchBls] = useAxios('', {}, currentuser.data.token, 'get');
  const { id, year } = props.match.params;

  useEffect(() => {
    if (id) fetchBls(`${SERVER_URL}/bls-data-occcode/${id}?year=${year}`);
  }, [id, fetchBls, year]);

  const bls1 = bls.data?.blsData?.[0];
  const occ = {
    code: bls1?.OCC_CODE,
    year: bls1?.Year,
    area: bls1?.AREA_TITLE,
    title: bls1?.OCC_TITLE,
  };

  return (
    <div className='content-wrapper'>
      <div className='panel'>
        <div className='actions-block'>
          <Link to='/admin/bls'>
            <Button type='primary'>All Data</Button>
          </Link>
        </div>

        <div className='panel-body'>
          {bls.data && (
            <>
              <h2>
                <strong>Year:&nbsp;</strong>
                <span>{occ.year}</span>
              </h2>

              <h2>
                <strong>Area:&nbsp;</strong>
                <span>{occ.area}</span>
              </h2>

              <h2>
                <strong>OCC code:&nbsp;</strong>
                <span>{occ.code}</span>
              </h2>

              <h2>
                <strong>OCC title:&nbsp;</strong>
                <span>{occ.title}</span>
              </h2>

              <h2>
                <strong>BLS Data:</strong>
              </h2>

              <div style={{ margin: '12px 0 20px' }}>
                {bls.data?.blsData && (
                  <ReactJson
                    name={null}
                    src={bls.data.blsData}
                    collapsed={0}
                    displayDataTypes={false}
                    enableClipboard={false}
                    style={{ fontSize: '14px' }}
                  />
                )}
              </div>

              <h2>
                <strong>Projections:</strong>
              </h2>

              <div style={{ margin: '12px 0 20px' }}>
                {bls.data?.projections && (
                  <ReactJson
                    name={null}
                    src={bls.data.projections}
                    collapsed={0}
                    displayDataTypes={false}
                    enableClipboard={false}
                    style={{ fontSize: '14px' }}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewBls;
