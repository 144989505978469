import React, { useEffect, useState, useContext } from 'react';
import { UserContext } from '../../App';
import Axios from 'axios';
import { Spin, notification, Table, Select, Checkbox, Input, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { SERVER_URL } from '../../config';
import { ExportToExcel } from '../../components/csv';
import * as _ from 'lodash';

const StateWAs = () => {
  const currentuser = useContext(UserContext);
  const [data, setData] = useState([]);
  const [working, setWorking] = useState(false);
  const [online, setOnline] = useState(false);
  const [degree, setDegree] = useState('Bachelors Degree');
  const [search, setSearch] = useState(null);
  const [avgTuitions, setAvgTuitions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setWorking(true);

        const res = await Axios.get(
          `${SERVER_URL}/dp-state-averages?degree=${degree}&online=${online}${search ? '&search=' + search : ''}`,
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` },
          },
        );
        if (res && res.data) {
          setData(res.data.data);
        }

        // avg tuition
        const avgTuitionRes = await Axios.get(`${SERVER_URL}/avg-tuition`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
        if (avgTuitionRes && avgTuitionRes.data) {
          setAvgTuitions(avgTuitionRes.data);
        }

        setWorking(false);
      } catch (error) {
        setWorking(false);
        console.log(error.message);
        notification.error({
          message: 'Error',
          description: 'Fetching state WA data failed.',
        });
      }
    };

    fetchData();
  }, [degree, online, search, currentuser.data.token]);

  // assign avg tuition
  if (data && data.length > 0) {
    for (let item of data) {
      const foundAvgTuition = avgTuitions.find((el) => el.state === item.state);
      if (foundAvgTuition) {
        item.avgInStateTuition = foundAvgTuition.data?.avgInStateTuition;
        item.avgOutOfStateTuition = foundAvgTuition.data?.avgOutOfStateTuition;
      }
    }
  }

  let searchInput;
  const getColumnSearchProps = (dataIndex) => {
    if (Array.isArray(dataIndex)) dataIndex = dataIndex.join('.');
    const prop = dataIndex;
    return {
      filteredValue: (search && [search]) || null,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <Input
              ref={(node) => {
                searchInput = node;
              }}
              placeholder={`Search ${prop}`}
              value={selectedKeys && selectedKeys.length > 0 ? selectedKeys : [search]}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={(e) => handleSearch(e, selectedKeys, confirm, prop)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />

            <Button
              type='primary'
              onClick={(e) => handleSearch(e, selectedKeys, confirm, prop)}
              size='small'
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>

            <Button onClick={(e) => handleReset(e, clearFilters, prop)} size='small' style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        );
      },
      filterIcon: (filtered) => {
        filtered = !!_.pickBy(search, _.identity)[prop];
        return <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />;
      },
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => {
            searchInput.select();
          });
        }
      },
      render: (text) => {
        return search ? (
          <Highlighter
            title={text}
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[search]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          <span title={text}>{text}</span>
        );
      },
    };
  };
  const handleSearch = async (e, selectedKeys, confirm, dataIndex) => {
    e.preventDefault();
    confirm();
    setSearch(selectedKeys[0]);
  };
  const handleReset = async (e, clearFilters, prop) => {
    e.preventDefault();
    setSearch(null);
    clearFilters();
  };

  let columns = [
    {
      title: 'STATE',
      dataIndex: ['state'],
      width: 170,
      key: 'state',
      ...getColumnSearchProps('state'),
    },
    {
      title: 'High School Earnings',
      dataIndex: ['hsSalary'],
      key: 'hsSalary',
      render: (text) => (text ? text.toLocaleString('us', { maximumFractionDigits: 0 }) : text ?? 'N/A'),
    },
    {
      title: 'Earnings',
      dataIndex: ['medianEarning'],
      key: 'medianEarning',
      render: (text) => (text ? text.toLocaleString('us', { maximumFractionDigits: 0 }) : text ?? 'N/A'),
    },
    {
      title: 'Payback',
      dataIndex: ['medianPayback'],
      key: 'medianPayback',
      render: (text) => (text ? text.toLocaleString('us', { maximumFractionDigits: 2 }) : text ?? 'N/A'),
    },
    {
      title: 'Debt',
      dataIndex: ['medianDebt'],
      key: 'medianDebt',
      render: (text) => (text ? text.toLocaleString('us', { maximumFractionDigits: 0 }) : text ?? 'N/A'),
    },
    {
      title: 'Debt to earn ratio',
      dataIndex: ['medianEarnDebtRatio'],
      key: 'medianEarnDebtRatio',
      render: (text) => (text ? text.toLocaleString('us', { maximumFractionDigits: 2 }) : text ?? 'N/A'),
    },
    {
      title: 'Conferrals',
      dataIndex: ['medianConferral'],
      key: 'medianConferral',
      render: (text) =>
        text ? text.toLocaleString('us', { maximumFractionDigits: 1, minimumFractionDigits: 0 }) : text ?? 'N/A',
    },
    {
      title: 'Cost',
      dataIndex: ['medianCost'],
      key: 'medianCost',
      render: (text) => (text ? text.toLocaleString('us') : text ?? 'N/A'),
    },
    {
      title: 'In-state tuition',
      dataIndex: ['avgInStateTuition'],
      key: 'avgInStateTuition',
      render: (text) => (text ? text.toLocaleString('us') : text ?? 'N/A'),
    },
    {
      title: 'Out-of-state tuition',
      dataIndex: ['avgOutOfStateTuition'],
      key: 'avgOutOfStateTuition',
      render: (text) => (text ? text.toLocaleString('us') : text ?? 'N/A'),
    },
    {
      title: 'Earning Plus',
      dataIndex: ['medianEarningPlus'],
      key: 'medianEarningPlus',
      render: (text) => (text ? text.toLocaleString('us') : text ?? 'N/A'),
    },
    {
      title: 'Earning Plus %',
      dataIndex: ['medianEarningPlusPerc'],
      key: 'medianEarningPlusPerc',
      render: (text) => (text ? text.toLocaleString('us', { maximumFractionDigits: 2 }) : text ?? 'N/A'),
    },
    {
      title: 'Economic Score',
      dataIndex: ['medianES'],
      key: 'medianES',
      render: (text) => (text ? text.toLocaleString('us', { maximumFractionDigits: 2 }) : text ?? 'N/A'),
    },
  ];

  if (degree === "Master's Degree") {
    columns = columns.filter(
      (col) =>
        col.key !== 'medianPayback' &&
        col.key !== 'hsSalary' &&
        col.key !== 'medianConferral' &&
        col.key !== 'medianCost',
    );
  } else {
    columns = columns.filter((col) => col.key !== 'medianDebt' && col.key !== 'medianEarnDebtRatio');
  }

  // export to excel
  let filename = '';
  let excelData = [];
  if (data && data.length > 0) {
    filename = `state_WAs_${degree.toLowerCase().split(' ').join('_')}${online ? '_online' : ''}`;
    if (degree !== "Master's Degree") {
      excelData = data.map((item) => ({
        state: item.state,
        Earnings: item.medianEarning,
        Payback: item.medianPayback,
        Debt: item.medianDebt,
        Cost: item.medianCost,
        'Debt to Earnings ratio': item.medianEarnDebtRatio,
        Conferrals: item.medianConferral,
        'Earning Plus': item.medianEarningPlus,
        'Earning Plus %': item.medianEarningPlusPerc,
        'Economic Score': item.medianES,
        'High School Salary': item.hsSalary,
        'In-state tuition': item.avgInStateTuition,
        'Out-of-state tuition': item.avgOutOfStateTuition,
      }));
    } else {
      excelData = data.map((item) => ({
        state: item.state,
        Earnings: item.medianEarning,
        Debt: item.medianDebt,
        'Debt to Earnings ratio': item.medianEarnDebtRatio,
        'Earning Plus': item.medianEarningPlus,
        'Earning Plus %': item.medianEarningPlusPerc,
        'Economic Score': item.medianES,
        'In-state tuition': item.avgInStateTuition,
        'Out-of-state tuition': item.avgOutOfStateTuition,
      }));
    }
  }

  return (
    <div className='content-wrapper'>
      <Spin spinning={working} tip='Working...'>
        <div className='actions-block flex'>
          {!currentuser.data.role.includes('guest') && (
            <ExportToExcel
              csvData={excelData}
              fileName={filename}
              propsToRemove={['_id']}
              disabled={currentuser.data.role.includes('viewer')}
            />
          )}

          <Select
            // allowClear
            value={degree}
            onChange={(value) => setDegree(value)}
            style={{ width: '200px', marginLeft: '4px' }}
            placeholder='Degree...'
          >
            <Select.Option key='Undergraduate Certificate or Diploma' value='Undergraduate Certificate or Diploma'>
              Certificate
            </Select.Option>
            <Select.Option key="Associate's Degree" value="Associate's Degree">
              Associate's
            </Select.Option>
            <Select.Option key='Bachelors Degree' value='Bachelors Degree'>
              Bachelor's
            </Select.Option>
            <Select.Option key="Master's Degree" value="Master's Degree">
              Master's
            </Select.Option>
          </Select>

          <Checkbox
            checked={online}
            onChange={(e) => setOnline(e.target.checked)}
            disabled={degree === "Master's Degree"}
          >
            Online
          </Checkbox>
        </div>

        <div>
          <Table
            columns={columns}
            dataSource={data}
            rowKey={(record) => record.state}
            // pagination={{
            //   pageSize: 20,
            //   total: ipeds.length,
            //   defaultCurrent: 1,
            //   position: 'bottom',
            //   showSizeChanger: false,
            //   hideOnSinglePage: true,
            // }}
          />
        </div>
      </Spin>
    </div>
  );
};

export default StateWAs;
