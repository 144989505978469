import React, { useState } from 'react';
import { Table, Tooltip, Tabs } from 'antd';

const { TabPane } = Tabs;

const Majors = ({
  onlineBach,
  onlineMasters,
  onlineAssoc,
  totalBachelorsOnline,
  totalMastersOnline,
  exclusiveOnlineBachelors,
  exclusiveOnlineMasters,
  hybridBachelors,
  hybridMasters,
}) => {
  const [activeTabOnline, setActiveTabOnline] = useState('BACHELORS');

  const columns = [
    {
      title: 'CIPCODE',
      dataIndex: 'cipcode',
      key: 'cipcode',
      // ...getColumnSearchProps(['cipcode']),
    },
    {
      title: 'PROGRAM NAME',
      dataIndex: ['title'],
      key: 'title',
      // ...getColumnSearchProps(['name']),
    },
    {
      title: 'CONFERRALS',
      dataIndex: ['conferrals'],
      key: 'conferrals',
      render: (value) =>
        value || value === 0 ? (
          <Tooltip title={`Only programs with 10+ conferrals`} color='blue' placement='top'>
            {value}
          </Tooltip>
        ) : (
          'N/A'
        ),
      // sorter: true,
      // sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'EARNINGS',
      dataIndex: ['EARN'],
      key: 'EARN',
      render: (value, record) => {
        if (record.EARN_USED) {
          return (
            <Tooltip title={`${record.EARN_USED} earnings`} color='blue' placement='left'>
              {value ? value.toFixed(0) : 'N/A'}
            </Tooltip>
          );
        }
        return value ? value.toFixed(0) : 'N/A';
      },
      // sorter: true,
      // sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'EARN COUNT',
      dataIndex: ['EARN_COUNT'],
      key: 'EARN_COUNT',
      render: (value) => (value ? value : 'N/A'),
      // sorter: true,
      // sortDirections: ['ascend', 'descend'],
    },
    // {
    //   title: 'PAYBACK',
    //   dataIndex: ['Payback'],
    //   key: 'Payback',
    //   render: (value) => (value && typeof value === 'number' ? value.toFixed(2) : 'N/A'),
    //   // sorter: true,
    //   // sortDirections: ['ascend', 'descend'],
    // },
    {
      title: 'EARNINGPLUS',
      dataIndex: ['degreePremium', 'premiumAmount'],
      key: 'premiumAmount',
      render: (value) => (value ? value.toFixed(0) : 'N/A'),
      // sorter: true,
      // sortDirections: ['ascend', 'descend'],
    },
    // {
    //   title: 'EARNINGPLUS %',
    //   dataIndex: ['degreePremium', 'premiumPercentage'],
    //   key: 'premiumPercentage',
    //   render: (value) => (value ? value.toFixed(2) : 'N/A'),
    //   // sorter: true,
    //   // sortDirections: ['ascend', 'descend'],
    // },
    // {
    //   title: 'ECONOMIC SCORE',
    //   dataIndex: ['degreePremium', 'rankingScore'],
    //   key: 'rankingScore',
    //   render: (value) => (value ? value.toFixed(2) : 'N/A'),
    //   // sorter: true,
    //   // sortDirections: ['ascend', 'descend'],
    // },
    {
      title: 'ONLINE/HYBRID',
      dataIndex: ['distance'],
      key: 'distance',
      render: (value) => {
        return value['All programs'] > 0 && value['Some programs'] === 0
          ? 'Online'
          : value['All programs'] === 0 && value['Some programs'] > 0
          ? 'Hybrid'
          : value['All programs'] > 0 && value['Some programs'] > 0
          ? 'Online/Hybrid'
          : 'N/A';
      },
    },
  ];

  const columnsMasters = [
    ...columns,
    {
      title: 'DEBT',
      dataIndex: ['DEBT_ALL_STGP_EVAL_MDN'],
      key: 'DEBT_ALL_STGP_EVAL_MDN',
      render: (value) => (value ? value.toFixed(0) : 'N/A'),
    },
  ];

  return (
    <div className='panel-body'>
      <div style={{ display: 'flex' }}>
        <Tooltip title='Total numbers (including programs with less then 10 conferrals)'>
          <div>
            <p>
              <b>Total bachelors online:</b> {totalBachelorsOnline}
            </p>
            <p>
              <b>Exclusive online bachelors:</b> {exclusiveOnlineBachelors}
            </p>
            <p>
              <b>Hybrid bachelors:</b> {hybridBachelors}
            </p>
          </div>
        </Tooltip>

        <Tooltip title='Total numbers (including programs with less then 10 conferrals)'>
          <div style={{ marginLeft: '70px' }}>
            <p>
              <b>Total masters online:</b> {totalMastersOnline}
            </p>
            <p>
              <b>Exclusive online masters:</b> {exclusiveOnlineMasters}
            </p>
            <p>
              <b>Hybrid masters:</b> {hybridMasters}
            </p>
          </div>
        </Tooltip>
      </div>

      <br></br>

      <Tabs onChange={(value) => setActiveTabOnline(value)} defaultActiveKey={activeTabOnline}>
        <TabPane tab='ASSOCIATES' key='ASSOCIATES'>
          <div>
            <Table
              size='middle'
              // onChange={onChangeTable}
              bordered
              dataSource={onlineAssoc}
              columns={columns}
              rowKey={(record) => record.cipcode + record.credential}
              // pagination={{
              //   pageSize: 20,
              //   total: total || 0,
              //   current: page,
              //   defaultCurrent: 1,
              //   position: 'bottom',
              //   showSizeChanger: false,
              //   onChange: (page) => pageHandler(page),
              //   hideOnSinglePage: true,
              // }}
            />
          </div>
        </TabPane>

        <TabPane tab='BACHELORS' key='BACHELORS'>
          <div>
            <Table
              size='middle'
              // onChange={onChangeTable}
              bordered
              dataSource={onlineBach}
              columns={columns}
              rowKey={(record) => record.cipcode + record.credential}
              // pagination={{
              //   pageSize: 20,
              //   total: total || 0,
              //   current: page,
              //   defaultCurrent: 1,
              //   position: 'bottom',
              //   showSizeChanger: false,
              //   onChange: (page) => pageHandler(page),
              //   hideOnSinglePage: true,
              // }}
            />
          </div>
        </TabPane>

        <TabPane tab='MASTERS' key='MASTERS'>
          <div>
            <Table
              size='middle'
              // onChange={onChangeTable}
              bordered
              dataSource={onlineMasters}
              columns={columnsMasters}
              rowKey={(record) => record.cipcode + record.credential}
              // pagination={{
              //   pageSize: 20,
              //   total: total || 0,
              //   current: page,
              //   defaultCurrent: 1,
              //   position: 'bottom',
              //   showSizeChanger: false,
              //   onChange: (page) => pageHandler(page),
              //   hideOnSinglePage: true,
              // }}
            />
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Majors;
