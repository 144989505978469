import React, { useEffect, useState } from 'react';
import { Form, Select, Row, Col, Input, Button, Layout, notification } from 'antd';
import useAxios from '../../hooks/useAxios';
import Axios from 'axios';

const { Content } = Layout;
const formInit = {
  _id: null,
  name: '',
  link: [],
  type: 'affiliate',
  ctaLink: '',
};

const LargeButtonsForm = ({ largeButtonEdit, isNew, onSubmit, schools, website, SERVER_URL, token }) => {
  let type = true;
  if (!isNew) {
    type = largeButtonEdit.data.type === 'affiliate' ? true : false;
  }
  const [form] = Form.useForm();
  const [submited, setSubmited] = useState(false);
  const [linkType, setLinkType] = useState(type);
  const [links, fetchLinks] = useAxios('', [], token, 'get');
  let initialValues = { ...formInit, ...largeButtonEdit.data };

  const onSubmitPage = async (val) => {
    let largeButtonExists = [];
    if (!submited) {
      if (isNew) {
        largeButtonExists = await Axios.get(`${SERVER_URL}/large-buttons?filter=${JSON.stringify({ $and: [{ name: val.name }, { website: website._id }] })}`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${token}` },
        });
        if (largeButtonExists.data && largeButtonExists.data.length > 0) {
          setSubmited(false);
          notification.error({
            message: 'Duplicate large button name',
            placement: 'bottomRight',
          });
        } else {
          if (val.type === 'affiliate') {
            val.ctaLink = '';
          } else {
            val.link = null;
          }
          onSubmit(val, isNew);
        }
      } else {
        val._id = largeButtonEdit.data._id;
        onSubmit(val, isNew);
      }
    }
  };

  useEffect(() => {
    if (website && website._id) fetchLinks(`${SERVER_URL}/affiliate?filter=${JSON.stringify({ website: website._id })}`, []);
  }, [website, fetchLinks, isNew, SERVER_URL]);

  const handleLinksType = (val) => {
    if (val === 'affiliate') {
      setLinkType(true);
      form.setFieldsValue({ link: [] });
    } else if (val === 'custom') {
      setLinkType(false);
      form.setFieldsValue({ ctaLink: '' });
    }
  };

  const linkFilterOptionSearch = (input, option) => {
    const searchVal = option.children.props.children;
    return searchVal.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  return (
    <div className='panel'>
      <div className='panel-body'>
        <Content style={{ padding: '15px', height: '100%' }}>
          <Form
            name='form'
            className='form-horizontal'
            layout='vertical'
            initialValues={initialValues}
            onFinish={(values) => {
              onSubmitPage(values, !schools);
            }}
            form={form}
          >
            <Row type='flex' gutter={16}>
              <Col xs={24} md={14}>
                <Form.Item label='Large Button Name:' name='name' rules={[{ required: true, message: 'Please enter large button name!' }]}>
                  <Input disabled={!isNew} placeholder='Add name' />
                </Form.Item>

                <Form.Item label='Type:' name='type' rules={[{ required: true, message: 'Please select type!' }]}>
                  <Select onSelect={(value) => handleLinksType(value)} style={{ textAlign: 'left' }}>
                    <Select.Option value='affiliate'>Affiliate link</Select.Option>
                    <Select.Option value='custom'>Custom URL</Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item label='Link:' name='link' hidden={!linkType} rules={[{ required: linkType, message: 'Please select link!' }]}>
                  <Select showSearch optionFilterProp='children' placeholder='Select link' filterOption={(input, option) => linkFilterOptionSearch(input, option)}>
                    {links.data &&
                      links.data.length > 0 &&
                      links.data.map((link) => (
                        <Select.Option key={link._id} value={link._id}>
                          <span style={{ color: link.type !== 'CTA' ? (link.school ? 'inherit' : 'red') : 'inherit' }}>
                            {link.type === 'Affiliate' ? (link.school ? link.school.name + ' - ' + link.partner : 'UNKNOWN') : link.name}
                          </span>
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>

                <Form.Item label='Link:' hidden={linkType} name='ctaLink' rules={[{ required: !linkType, message: 'Please enter large button link!' }]}>
                  <Input placeholder='Add name' />
                </Form.Item>
              </Col>
            </Row>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button style={{ margin: '0 10px' }} type='primary' htmlType='submit' title='Update'>
                {isNew ? 'Add' : 'update'} button
              </Button>
            </div>
          </Form>
        </Content>
      </div>
    </div>
  );
};
export default LargeButtonsForm;
