import React from 'react';
import { Divider } from 'antd';

const Cost = ({
  control,
  TUITIONFEE_IN,
  TUITIONFEE_OUT,
  BOOKSUPPLY,
  ROOMBOARD_ON,
  ROOMBOARD_OFF,
  OTHEREXPENSE_ON,
  OTHEREXPENSE_OFF,
  averageNetPrice,
  averageNetPriceLowIncome_0_to_30k,
  averageNetPriceLowIncome_30k_to_48k,
  averageNetPriceLowIncome_48k_to_75k,
  averageNetPriceLowIncome_75k_to_110k,
  averageNetPriceLowIncome_110k_plus,
  grants,
  cost_number_of_students,
  tuitionReciprocity,
}) => {
  return (
    <div className='panel-body'>
      <div style={{ display: 'flex' }}>
        <div style={{ marginRight: '80px' }}>
          <p>
            <b>In state tuition/fees:</b> {TUITIONFEE_IN ? TUITIONFEE_IN : 'N/A'}
          </p>

          <p>
            <b>Out of state tuition/fees:</b> {TUITIONFEE_OUT ? TUITIONFEE_OUT : 'N/A'}
          </p>

          <p>
            <b>Books and supplies:</b> {BOOKSUPPLY ? BOOKSUPPLY : 'N/A'}
          </p>

          <p>
            <b>Campus housing:</b> {ROOMBOARD_ON ? ROOMBOARD_ON : 'N/A'}
          </p>

          <p>
            <b>Other expenses:</b> {OTHEREXPENSE_ON ? OTHEREXPENSE_ON : 'N/A'}
          </p>

          <p>
            <b>Off-campus housing:</b> {ROOMBOARD_OFF ? ROOMBOARD_OFF : 'N/A'}
          </p>

          <p>
            <b>Off-campus other expenses:</b> {OTHEREXPENSE_OFF ? OTHEREXPENSE_OFF : 'N/A'}
          </p>

          <p>
            <b>Tuition reciprocity:</b> {tuitionReciprocity ? tuitionReciprocity : 'No'}
          </p>
        </div>

        <div style={{ marginRight: '150px' }}>
          <p>
            <b>Average net price:</b> {averageNetPrice ? `$${averageNetPrice}` : 'N/A'}
          </p>
          <Divider />
          <h3 style={{ color: 'red' }}>Different family income levels</h3>
          <p>
            <b>Average net price for $0 - $30.000:</b>{' '}
            {averageNetPriceLowIncome_0_to_30k ? `$${averageNetPriceLowIncome_0_to_30k}` : 'N/A'}
          </p>

          <p>
            <b>Number of students for $0 - $30.000:</b>{' '}
            {cost_number_of_students && control === 'Public'
              ? cost_number_of_students.NUM41_PUB
              : cost_number_of_students
              ? cost_number_of_students.NUM41_PRIV
              : 'N/A'}
          </p>
          <Divider />
          <p>
            <b>Average net price for $30.001 - $48.000:</b>{' '}
            {averageNetPriceLowIncome_30k_to_48k ? `$${averageNetPriceLowIncome_30k_to_48k}` : 'N/A'}
          </p>

          <p>
            <b>Number of students for $30.001 - $48.000:</b>{' '}
            {cost_number_of_students && control === 'Public'
              ? cost_number_of_students.NUM42_PUB
              : cost_number_of_students
              ? cost_number_of_students.NUM42_PRIV
              : 'N/A'}
          </p>
          <Divider />
          <p>
            <b>Average net price for $48.001 - $75.000:</b>{' '}
            {averageNetPriceLowIncome_48k_to_75k ? `$${averageNetPriceLowIncome_48k_to_75k}` : 'N/A'}
          </p>

          <p>
            <b>Number of students for $48.001 - $75.000:</b>{' '}
            {cost_number_of_students && control === 'Public'
              ? cost_number_of_students.NUM43_PUB
              : cost_number_of_students
              ? cost_number_of_students.NUM43_PRIV
              : 'N/A'}
          </p>
          <Divider />
          <p>
            <b>Average net price for $75.001 - $110.000:</b>{' '}
            {averageNetPriceLowIncome_75k_to_110k ? `$${averageNetPriceLowIncome_75k_to_110k}` : 'N/A'}
          </p>

          <p>
            <b>Number of students for $75.001 - $110.000:</b>{' '}
            {cost_number_of_students && control === 'Public'
              ? cost_number_of_students.NUM44_PUB
              : cost_number_of_students
              ? cost_number_of_students.NUM44_PRIV
              : 'N/A'}
          </p>
          <Divider />
          <p>
            <b>Average net price for $110.000+ :</b>{' '}
            {averageNetPriceLowIncome_110k_plus ? `$${averageNetPriceLowIncome_110k_plus}` : 'N/A'}
          </p>

          <p>
            <b>Number of students for $110.000+:</b>{' '}
            {cost_number_of_students && control === 'Public'
              ? cost_number_of_students.NUM45_PUB
              : cost_number_of_students
              ? cost_number_of_students.NUM45_PRIV
              : 'N/A'}
          </p>
          <Divider />
          <p>
            <b>Pell grant amount average all undergrad:</b> {grants && grants.UPGRNTA ? `$${grants.UPGRNTA}` : 'N/A'}
          </p>
        </div>

        <div>
          <h3 style={{ color: 'red' }}>Freshmen</h3>
          <p>
            <b>Average total grant amount:</b> {grants && grants.AGRNT_A ? grants.AGRNT_A : 'N/A'}
          </p>

          <p>
            <b>Percentage awarded grant:</b> {grants && grants.AGRNT_P ? grants.AGRNT_P + '%' : 'N/A'}
          </p>
          <Divider />
          <p>
            <b>Average federal loan amount: </b> {grants && grants.FLOAN_A ? grants.FLOAN_A : 'N/A'}
          </p>

          <p>
            <b>Percentage undergrads that receive federal loan: </b>{' '}
            {grants && grants.FLOAN_P ? grants.FLOAN_P + '%' : 'N/A'}
          </p>
          <Divider />
          <p>
            <b>Federal grant average: </b> {grants && grants.FGRNT_A ? grants.FGRNT_A : 'N/A'}
          </p>

          <p>
            <b>Federal grant percentage: </b> {grants && grants.FGRNT_P ? grants.FGRNT_P + '%' : 'N/A'}
          </p>
          <Divider />
          <p>
            <b>State grant average: </b> {grants && grants.SGRNT_A ? grants.SGRNT_A : 'N/A'}
          </p>

          <p>
            <b>State grant percentage: </b> {grants && grants.SGRNT_P ? grants.SGRNT_P + '%' : 'N/A'}
          </p>
          <Divider />
          <p>
            <b>Other grant average: </b> {grants && grants.OFGRT_A ? grants.OFGRT_A : 'N/A'}
          </p>

          <p>
            <b>Other grant percentage: </b> {grants && grants.OFGRT_P ? grants.OFGRT_P + '%' : 'N/A'}
          </p>
          <Divider />
          <p>
            <b>Institutional grant average: </b> {grants && grants.IGRNT_A ? grants.IGRNT_A : 'N/A'}
          </p>

          <p>
            <b>Institutional grant percentage: </b> {grants && grants.IGRNT_P ? grants.IGRNT_P + '%' : 'N/A'}
          </p>
          <Divider />
          <p>
            <b>Total loan average: </b> {grants && grants.LOAN_A ? grants.LOAN_A : 'N/A'}
          </p>

          <p>
            <b>Total loan percentage: </b> {grants && grants.LOAN_P ? grants.LOAN_P + '%' : 'N/A'}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Cost;
