import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const CKEditorComponent = ({ content, setContent }) => {
  return (
    <CKEditor
      name='content'
      editor={ClassicEditor}
      data={content}
      onReady={(editor) => {
        if (content) {
          editor.setData(content);
        }
      }}
      onChange={(event, editor) => {
        const text = editor.getData();
        setContent(text);
        // setFormData({ ...formData, description: text });
      }}
      config={{
        plugins: ['Paragraph', 'Bold', 'Italic', 'Essentials', 'Table', 'TableToolbar', 'List', 'Link'],
        link: {
          decorators: {
            addTargetToExternalLinks: {
              mode: 'manual',
              label: 'Open in a new tab',
              defaultValue: true,
              // callback: (url) => /^(https?:)?\/\//.test(url),
              attributes: {
                target: '_blank',
                // rel: 'noopener noreferrer',
              },
            },
          },
        },
        toolbar: [
          'bold',
          'italic',
          '|',
          'bulletedList',
          'numberedList',
          '|',
          'insertTable',
          '|',
          'link',
          '|',
          'undo',
          'redo',
        ],
      }}
      style={{
        margin: '2px 0px',
        minHeight: '350px',
      }}
    />
  );
};

export default CKEditorComponent;
