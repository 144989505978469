import React, { useState, useContext, useEffect } from 'react';
import Axios from 'axios';
import * as _ from 'lodash';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import { UserContext } from '../../App';
import { notification, Spin, Table, Tooltip, Button, Select, Checkbox, Alert, Input } from 'antd';
import { EyeOutlined, SearchOutlined, FilterFilled } from '@ant-design/icons';
import { SERVER_URL } from '../../config';
import { useHistory } from 'react-router-dom';
import STATES from '../../lib/states.json';
import { ExportToExcel } from '../../components/csv';
import Highlighter from 'react-highlight-words';

const queryClient = new QueryClient();

let filterData = {
  cipcode: undefined,
  degree: 'Bachelors Degree',
  online: false,
  state: undefined,
  page: 1,
  pageSize: 10,
  sort: undefined,
  pagination: true,
  naFilter: true,
  category: undefined,
};

const displayPerc = (perc) => {
  let percText = 'N/A';
  if (perc === 5) percText = `Outstanding - Top 5%`;
  if (perc === 20) percText = `Very good - Top 20%`;
  if (perc === 40) percText = `Average - Top 40%`;
  if (perc === 60) percText = `Below average - Bottom 60%`;
  if (perc === 80) percText = `Poor - Bottom 40%`;
  if (perc === 100) percText = `Terrible - Bottom 20%`;

  return percText;
};

const Cipcodes = () => {
  const history = useHistory();

  const currentuser = useContext(UserContext);
  const [fetchTrigger, setFetchTrigger] = useState(false);
  const [filter, setFilter] = useState(filterData);
  const [working, setWorking] = useState(false);
  const [cipCats, setCipCats] = useState([]);

  useEffect(() => {
    const fetchCipCategories = async () => {
      try {
        const cipCatsRes = await Axios.get(`${SERVER_URL}/cip-categories`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });

        if (cipCatsRes && cipCatsRes.data) {
          setCipCats(cipCatsRes.data);
        }
      } catch (error) {
        notification.error({
          message: 'Problem with loading cip categories',
          placement: 'bottomRight',
        });
      }
    };

    fetchCipCategories();
  }, []);

  useEffect(() => {
    filterData = { ...filterData, ..._.pickBy(_.get(history, 'location.state', null), _.identity) };
    setFilter(filterData);
  }, [history]);

  // react query
  async function fetchCipcodes(filter) {
    const params = _.pickBy(filter, _.identity);
    const { data } = await Axios.get(`${SERVER_URL}/dp-all-cipcodes`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
      params,
    });
    return data;
  }

  const { data, isError, isLoading } = useQuery({
    queryKey: ['cipcodes', filter],
    queryFn: () => fetchCipcodes(filter),
    keepPreviousData: true,
  });

  const setDefaultSort = (column) => {
    const data = _.get(filter, `sort.["${column}"]`, undefined);
    if (data === undefined) return null;
    return data === 1 ? 'ascend' : 'descend';
  };

  let searchInput;
  const getColumnSearchProps = (dataIndex) => {
    if (Array.isArray(dataIndex)) dataIndex = dataIndex.join('.');
    const prop = dataIndex;
    return {
      filteredValue:
        (_.pickBy(filter, _.identity) && _.pickBy(filter, _.identity)[prop] && [_.pickBy(filter, _.identity)[prop]]) ||
        null,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <Input
              ref={(node) => {
                searchInput = node;
              }}
              placeholder={`Search ${prop}`}
              value={selectedKeys && selectedKeys.length > 0 ? selectedKeys : [_.pickBy(filter, _.identity)[prop]]}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={(e) => handleSearch(e, selectedKeys, confirm, prop)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />

            <Button
              type='primary'
              onClick={(e) => handleSearch(e, selectedKeys, confirm, prop)}
              size='small'
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>

            <Button onClick={(e) => handleReset(e, clearFilters, prop)} size='small' style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        );
      },
      filterIcon: (filtered) => {
        filtered = !!_.pickBy(filter, _.identity)[prop];
        return <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />;
      },
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => {
            searchInput.select();
          });
        }
      },
      sorter: true,
      defaultSortOrder: setDefaultSort(prop),
      sortDirections: ['ascend', 'descend'],
      render: (text) => {
        return _.pickBy(filter, _.identity)[prop] ? (
          <Highlighter
            title={text}
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[_.pickBy(filter, _.identity)[prop]]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          <span title={text}>{text}</span>
        );
      },
    };
  };

  const handleSearch = async (e, selectedKeys, confirm, dataIndex) => {
    e.preventDefault();
    confirm();
    setFilter({ ..._.pickBy(filter, _.identity), [dataIndex]: selectedKeys[0] });
  };

  const handleReset = async (e, clearFilters, prop) => {
    e.preventDefault();
    setFilter({ ...filter, [prop]: undefined, page: 1, pageSize: 10 });
    clearFilters();
  };

  const columns = [
    {
      title: 'CIPCODE',
      dataIndex: ['cipcode'],
      key: 'cipcode',
      sorter: (a, b) => a.cipcode - b.cipcode,
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps(['cipcode']),
    },
    {
      title: 'TITLE',
      dataIndex: ['title'],
      key: 'title',
      ...getColumnSearchProps(['title']),
    },
    {
      title: 'NUMBER OF RANKED SCHOOLS',
      dataIndex: ['numberOfSchools'],
      key: 'numberOfSchools',
      render: (value) => (value ? value.toLocaleString('en-US') : 0),
      sorter: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'TOTAL CONFERRALS',
      dataIndex: ['totalConferrals'],
      key: 'totalConferrals',
      render: (value) => (value ? value.toLocaleString('en-US') : 0),
      sorter: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'WA EARNINGS',
      dataIndex: ['WAs', 'waEarnings'],
      key: 'waEarnings',
      render: (value) => (value ? value.toLocaleString('en-US') : 'N/A'),
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      defaultSortOrder: setDefaultSort('WAs.waEarnings'),
    },
    {
      title: 'WA COST',
      dataIndex: ['WAs', 'waCost'],
      key: 'waCost',
      render: (value) => (value ? value.toLocaleString('en-US') : 'N/A'),
      defaultSortOrder: setDefaultSort('WAs.waCost'),
      sorter: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'WA DEBT',
      dataIndex: ['WAs', 'waDebt'],
      key: 'waDebt',
      render: (value) => (value ? value.toLocaleString('en-US') : 'N/A'),
      defaultSortOrder: setDefaultSort('WAs.waDebt'),
      sorter: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: filter.degree !== "Master's Degree" ? 'WA PAYBACK' : 'WA DEBT TO EARNINGS',
      dataIndex: ['WAs', 'waPayback'],
      key: 'waPayback',
      render: (value) => (value && typeof value === 'number' ? value.toFixed(2) : 'N/A'),
      defaultSortOrder: setDefaultSort('WAs.waPayback'),
      sorter: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'WA EARNING PLUS',
      dataIndex: ['WAs', 'waEarningPlus'],
      key: 'waEarningPlus',
      render: (value) => (value ? value.toLocaleString('en-US') : 'N/A'),
      defaultSortOrder: setDefaultSort('WAs.waEarningPlus'),
      sorter: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'WA EARNING PLUS %',
      dataIndex: ['WAs', 'waEarningPlusPerc'],
      key: 'waEarningPlusPerc',
      render: (value) => (value ? value.toFixed(2) : 'N/A'),
      defaultSortOrder: setDefaultSort('WAs.waEarningPlusPerc'),
      sorter: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'WA ECONOMIC SCORE',
      dataIndex: ['WAs', 'waES'],
      key: 'waES',
      filters: [
        {
          text: 'Non N/A',
          value: 'Non N/A',
        },
      ],
      filteredValue: filter.naFilter ? ['Non N/A'] : null,
      filterIcon: (filtered) => <FilterFilled style={{ color: filtered ? 'red' : 'white', fontSize: '1rem' }} />,
      render: (value) => (value ? value.toFixed(2) : 'N/A'),
      defaultSortOrder: setDefaultSort('WAs.waES'),
      sorter: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'CAREERS',
      key: 'CAREERS',
      align: 'center',
      render: (text, record) => (
        <>
          {record.careers && record.careers.length > 0 ? (
            <Tooltip title={`View all careers`}>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => history.push(`/admin/cipcode-cip-soc/${record.cipcode}`)}
              >
                <EyeOutlined style={{ textDecoration: 'none', color: 'black', fontSize: '15px' }} />
              </div>
            </Tooltip>
          ) : (
            'N/A'
          )}
        </>
      ),
    },
  ];

  if (!filter.state && !filter.online) {
    columns.push({
      title: 'PERCENTAGE AGAINST OTHER CIPS',
      dataIndex: ['WAs', 'perc'],
      key: 'perc',
      render: (value) => displayPerc(value),
    });
  }

  columns.push({
    title: 'Action',
    render: (text, record) => (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '15px',
        }}
      >
        <Tooltip title='View school ranks' placement='top'>
          <div style={{ margin: '2px', padding: '3px' }}>
            <EyeOutlined onClick={() => history.push(`/admin/degree-profiles/cipcodes/${record.cipcode}`, filter)} />
          </div>
        </Tooltip>
      </div>
    ),
  });

  const handleRefresh = async () => {
    try {
      setWorking(true);

      await Axios.get(`${SERVER_URL}/degree-profiles-combine`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      await Axios.get(`${SERVER_URL}/degree-profiles-add-wa-2-digit-profile`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      setWorking(false);
      setFetchTrigger(!fetchTrigger);

      notification.success({
        message: 'All school ranks updated',
        placement: 'bottomRight',
        duration: 3,
      });
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Error refreshing cipcodes',
        placement: 'bottomRight',
        duration: 3,
      });
    }
  };

  const handleRefreshSlugs = async () => {
    try {
      setWorking(true);

      await Axios.get(`${SERVER_URL}/degree-profiles-refresh-slugs`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      setWorking(false);
      setFetchTrigger(!fetchTrigger);

      notification.success({
        message: 'All cipcode slugs generated',
        placement: 'bottomRight',
        duration: 3,
      });
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Error refreshing cipcode slugs',
        placement: 'bottomRight',
        duration: 3,
      });
    }
  };

  // export to excel
  let filename = '';
  // let excelData = [];
  if (data?.docs?.length > 0) {
    filename = `degree_profiles${filter.online ? '_online' : ''}${'_' + filter.degree}`;
  }

  let onChangeTable = (pagination, filters, sorter) => {
    let naFilter = false;
    if (filters.waES && filters.waES.length > 0) {
      if ('Non N/A' === filters.waES[0]) {
        naFilter = true;
      }
    }
    // eslint-disable-next-line array-callback-return
    Object.keys(filters).map((prop) => {
      if (prop !== 'waES') {
        filter[prop] = Array.isArray(filters[prop]) ? filters[prop][0] : filters[prop];
      }
    });
    let sort;
    if (sorter.field && sorter.field.length > 0) {
      if (sorter.order) {
        const name = sorter.field.join('.');
        sort = { [name]: sorter.order === 'ascend' ? 1 : -1 };
      } else {
        sort = undefined;
      }
    }

    setFilter({ ...filter, ...{ page: pagination.current, pageSize: pagination.pageSize, sort, naFilter } });
  };

  const excelServerData = async () => {
    const params = _.pickBy(filter, _.identity);
    params.pagination = false;
    const { data } = await Axios.get(`${SERVER_URL}/dp-all-cipcodes`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
      params,
    });
    const docs = data.docs.map((cip) => ({
      Cipcode: cip.cipcode,
      'Original Title': cip.oldTitle,
      'New Title': cip.newTitle,
      'WA Earnings': cip.WAs.waEarnings,
      'WA Payback': cip.WAs.waPayback,
      'WA Debt': cip.WAs.waDebt,
      'WA Debt to Earnings': cip.WAs.waPayback,
      'WA Cost': cip.WAs.waCost,
      'WA Earningplus': cip.WAs.waEarningPlus,
      'WA Earningplus %': cip.WAs.waEarningPlusPerc,
      'WA Economic Score': cip.WAs.waES,
      'Percentage against other cipcodes': cip.WAs.perc,
    }));

    if (filter.degree === "Master's Degree") {
      docs.forEach((item) => {
        delete item['WA Payback'];
      });
    }

    return docs;
  };

  return (
    <div className='content-wrapper'>
      {isError && <Alert message={'Loading data problem...'} type='error' showIcon closable />}
      <Spin spinning={isLoading || working} tip='Working...'>
        <div className='actions-block flex'>
          {!currentuser.data.role.includes('guest') && (
            <ExportToExcel
              csvData={excelServerData}
              fileName={filename}
              propsToRemove={['_id']}
              disabled={currentuser.data.role.includes('viewer')}
            />
          )}

          {!currentuser.data.role.includes('guest') && (
            <>
              <Tooltip title='Refresh all school ranks inside all cipcodes (NOTE: this can take up to 30min to complete)'>
                <Button type='danger' style={{ marginRight: '8px', float: 'left' }} onClick={handleRefresh}>
                  REFRESH ALL
                </Button>
              </Tooltip>

              <Tooltip title='Generate cipocde slugs to use for compare majors tool (NOTE: if already created this button will still refresh the slugs)'>
                <Button type='danger' style={{ marginRight: '8px', float: 'left' }} onClick={handleRefreshSlugs}>
                  REFRESH CIP SLUGS
                </Button>
              </Tooltip>
            </>
          )}

          <Select
            // allowClear
            value={filter.degree}
            onChange={(value) => setFilter({ ...filter, degree: value })}
            style={{ width: '140px' }}
            placeholder='Degree...'
          >
            <Select.Option key='Undergraduate Certificate or Diploma' value='Undergraduate Certificate or Diploma'>
              Certificate
            </Select.Option>
            <Select.Option key="Associate's Degree" value="Associate's Degree">
              Associate's
            </Select.Option>
            <Select.Option key='Bachelors Degree' value='Bachelors Degree'>
              Bachelor's
            </Select.Option>
            <Select.Option key="Master's Degree" value="Master's Degree">
              Master's
            </Select.Option>
          </Select>

          <Select
            allowClear
            value={filter.category}
            onChange={(value) => setFilter({ ...filter, category: value })}
            style={{ width: '300px', marginLeft: '4px' }}
            placeholder='Category...'
          >
            {cipCats.map((item) => (
              <Select.Option key={item} value={item}>
                {item}
              </Select.Option>
            ))}
          </Select>

          <Select
            allowClear
            value={filter.state}
            onChange={(value) => setFilter({ ...filter, ...{ state: value } })}
            style={{ width: '200px', marginLeft: '4px' }}
            placeholder='State...'
          >
            {STATES.map(({ name }) => (
              <Select.Option key={`${name}`} value={name}>
                {name}
              </Select.Option>
            ))}
          </Select>

          <Checkbox
            name='online'
            checked={filter.online}
            onChange={(e) => setFilter({ ...filter, ...{ online: e.target.checked } })}
          >
            Online/Hybrid
          </Checkbox>
        </div>

        {!isLoading && !isError && (
          <div>
            <p>Total cip codes: {data.totalDocs}</p>
            <Table
              size='middle'
              onChange={onChangeTable}
              bordered
              dataSource={data.docs}
              columns={columns}
              rowKey={(record) => record._id}
              pagination={{
                pageSize: filter.pageSize || 10,
                total: data.totalDocs,
                current: filter.page || 1,
              }}
            />
          </div>
        )}
      </Spin>
    </div>
  );
};

export default function () {
  return (
    <QueryClientProvider client={queryClient}>
      <Cipcodes />
    </QueryClientProvider>
  );
}
