import React, { useState, useEffect, useContext } from 'react';
import { Table, Tooltip, notification, Spin, Input, Button, Alert, Select } from 'antd';
import { SearchOutlined, InfoCircleFilled } from '@ant-design/icons';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';
import Highlighter from 'react-highlight-words';
import Axios from 'axios';
import * as _ from 'lodash';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import CRIME_FILES from '../../lib/crimeFiles.json';

const queryClient = new QueryClient();

let filterData = {
  unitid: undefined,
  name: undefined,
  campusID: undefined,
  year: undefined,
  file: 'Criminal_Offenses_On_campus', // default
};

const Crimes = () => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [working, setWorking] = useState(false);
  const [filter, setFilter] = useState(filterData);

  useEffect(() => {
    filterData = { ...filterData, ..._.pickBy(_.get(history, 'location.state', null), _.identity) };
    setFilter(filterData);
  }, [history]);

  // react query
  async function fetchCrimes(filter) {
    const params = _.pickBy(filter, _.identity);
    setWorking(true);
    const { data } = await Axios.get(`${SERVER_URL}/crimes-custom-full`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
      params,
    });
    setWorking(false);
    return data;
  }

  const { data, isError, isLoading } = useQuery({
    queryKey: ['crimes', filter],
    queryFn: () => fetchCrimes(filter),
    keepPreviousData: true,
  });
  if (isError) {
    notification.error({
      message: 'Error',
      description: 'Problem with fetching all crimes.',
      placement: 'bottomRight',
    });
  }

  // columns
  const setDefaultSort = (column) => {
    const data = _.get(filter, `sort.["${column}"]`, undefined);
    if (data === undefined) return null;
    return data === 1 ? 'ascend' : 'descend';
  };
  let searchInput;
  const getColumnSearchProps = (dataIndex) => {
    if (Array.isArray(dataIndex)) dataIndex = dataIndex.join('.');
    const prop = dataIndex;
    return {
      filteredValue:
        (_.pickBy(filter, _.identity) && _.pickBy(filter, _.identity)[prop] && [_.pickBy(filter, _.identity)[prop]]) ||
        null,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <Input
              ref={(node) => {
                searchInput = node;
              }}
              placeholder={`Search ${prop}`}
              value={selectedKeys && selectedKeys.length > 0 ? selectedKeys : [_.pickBy(filter, _.identity)[prop]]}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={(e) => handleSearch(e, selectedKeys, confirm, prop)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />

            <Button
              type='primary'
              onClick={(e) => handleSearch(e, selectedKeys, confirm, prop)}
              size='small'
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>

            <Button onClick={(e) => handleReset(e, clearFilters, prop)} size='small' style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        );
      },
      filterIcon: (filtered) => {
        filtered = !!_.pickBy(filter, _.identity)[prop];
        return <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />;
      },
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => {
            searchInput.select();
          });
        }
      },
      sorter: true,
      defaultSortOrder: setDefaultSort(prop),
      sortDirections: ['ascend', 'descend'],
      render: (text) => {
        return _.pickBy(filter, _.identity)[prop] ? (
          <Highlighter
            title={text}
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[_.pickBy(filter, _.identity)[prop]]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          <span title={text}>{text}</span>
        );
      },
    };
  };

  const handleSearch = async (e, selectedKeys, confirm, dataIndex) => {
    e.preventDefault();
    confirm();
    setFilter({ ..._.pickBy(filter, _.identity), [dataIndex]: selectedKeys[0] });
  };

  const handleReset = async (e, clearFilters, prop) => {
    e.preventDefault();
    setFilter({ ...filter, [prop]: undefined, page: 1, pageSize: 10 });
    clearFilters();
  };

  let onChangeTable = (pagination, filters, sorter) => {
    Object.keys(filters).map((prop) => {
      filter[prop] = Array.isArray(filters[prop]) ? filters[prop][0] : filters[prop];
    });
    let sort;
    if (sorter.field && sorter.field.length > 0) {
      if (sorter.order) {
        const name = sorter.field.join('.');
        sort = { [name]: sorter.order === 'ascend' ? 1 : -1 };
      } else {
        sort = undefined;
      }
    }
    setFilter({ ...filter, ...{ page: pagination.current, pageSize: pagination.pageSize, sort } });
  };

  let columns = [
    {
      title: 'UNITID',
      dataIndex: ['unitid'],
      key: 'unitid',
      ...getColumnSearchProps(['unitid']),
      fixed: 'left',
      width: 100,
    },
    {
      title: 'YEAR',
      dataIndex: ['year'],
      key: 'year',
      ...getColumnSearchProps(['year']),
      fixed: 'left',
      width: 100,
    },
    {
      title: 'CAMPUS ID',
      dataIndex: ['campusID'],
      key: 'campusID',
      ...getColumnSearchProps(['campusID']),
      fixed: 'left',
      width: 120,
    },
    {
      title: 'SCHOOL',
      dataIndex: ['name'],
      key: 'name',
      ...getColumnSearchProps(['name']),
      width: 120,
    },
  ];

  if (data && data[0]) {
    const dataColumns = Object.entries(data[0].data).map(([key, value]) => {
      return {
        title: key.toUpperCase(),
        dataIndex: ['data', key],
        key,
        width: 200,
        sorter: true,
        defaultSortOrder: setDefaultSort(['data', key]),
        sortDirections: ['ascend', 'descend'],
      };
    });
    columns = [...columns, ...dataColumns];
  }

  // render
  return (
    <div className='content-wrapper'>
      {isError && !isLoading && <Alert message={'Loading data problem...'} type='error' showIcon closable />}
      <Spin spinning={isLoading || working} tip='Working...'>
        <div className='actions-block flex'>
          <div>
            <label htmlFor='crimeFiles'>
              <Tooltip
                title={`Currently on /college-crime-rates page we are displaying only data from 2 files ("Criminal_Offenses_On_campus" and "VAWA_Offenses_On_campus") and only data from main campuses (CAMPUS ID = 1)`}
              >
                <InfoCircleFilled />
              </Tooltip>{' '}
              File:{' '}
            </label>
            <Select
              showSearch
              optionFilterProp='children'
              value={filter.file}
              onChange={(value) => setFilter({ ...filter, file: value })}
              style={{ width: '500px', marginLeft: '4px' }}
              placeholder='File...'
              name='crimeFiles'
            >
              {CRIME_FILES.map((item) => (
                <Select.Option key={item} value={item}>
                  {item}
                </Select.Option>
              ))}
            </Select>
          </div>

          <p>Total documents: {data?.length || 0}</p>
        </div>

        <div>
          <Table
            size='middle'
            onChange={onChangeTable}
            bordered
            dataSource={data}
            columns={columns}
            rowKey={(record) => record._id}
            scroll={{
              x: 1600,
            }}
          />
        </div>
      </Spin>
    </div>
  );
};

export default function () {
  return (
    <QueryClientProvider client={queryClient}>
      <Crimes />
    </QueryClientProvider>
  );
}
