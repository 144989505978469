import React from 'react';
import { Tooltip } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';

const SourceTooltip = ({ link, description, linkLabel }) => {
  return (
    <Tooltip
      title={
        <div>
          {description && <p>{description}</p>}
          <a href={link} target='_blank' rel='noopener noreferrer'>
            {linkLabel}
          </a>
        </div>
      }
      color='black'
    >
      <InfoCircleFilled style={{ fontSize: '17px' }} />
    </Tooltip>
  );
};

export default SourceTooltip;
