import React, { useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import AutocompleteDropdown from './AutocompleteDropdown';
import { Tooltip, Modal, Button } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';

const EditorComponent = ({ content, setContent, constants, elemID }) => {
  const [suggestions, setSuggestions] = useState([]);
  const [selectedSugg, setSelectedSugg] = useState(null);
  const [editorInstance, setEditorInstance] = useState(null);
  const [selectedConst, setSelectedConst] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleAutocomplete = (editor, text) => {
    // remove html tags
    const sanitizedText = text.replace(/<\/?[^>]+(>|$)/g, '').replace(/&nbsp;/g, ' ');

    // Get the current selection and position of cursor
    const selection = editor.model.document.selection;
    const position = selection.getFirstPosition();
    let currentRowOffset = position.offset;
    const row = position.path[0];

    // const textToRead =
    //   position.nodeBefore && position.nodeBefore._data
    //     ? position.nodeBefore._data
    //     : position.textNode && position.textNode._data
    //     ? position.textNode._data
    //     : sanitizedText;

    const sumOffsetBefore =
      position.root._children && position.root._children._nodes
        ? position.root._children._nodes
            .filter((node, index) => index < position.path[0])
            .reduce((sum, item) => (sum += item.maxOffset), 0)
        : 0;

    // Extract the last word being typed before the cursor position
    const wordsBeforeCursor = sanitizedText.slice(0, currentRowOffset + sumOffsetBefore).split(/\s+/);
    let wordBeforeCursor = wordsBeforeCursor[wordsBeforeCursor.length - 1];

    const lastCharPrevRow = wordBeforeCursor.slice(-1);
    if (row !== 0 && currentRowOffset === 1 && lastCharPrevRow === '#') {
      wordBeforeCursor = lastCharPrevRow;
    }

    // Provide suggestions based on the word before the cursor
    const filterSuggestions = constants.filter((suggestion) => {
      if (wordBeforeCursor) {
        if (suggestion.isCip) {
          if (
            suggestion.key.toLowerCase().includes(wordBeforeCursor.toLowerCase().replace('#', '')) &&
            wordBeforeCursor.includes('#')
          ) {
            return suggestion;
          }
        } else {
          if (suggestion.key.toLowerCase().startsWith(wordBeforeCursor.toLowerCase())) {
            return suggestion;
          }
        }
      } else {
        return;
      }
    });
    const matchingSuggestions = filterSuggestions.map((suggestion) => suggestion.key);

    // Update the state with matching suggestions
    if (!constants.find((item) => item.key.toLowerCase() === wordBeforeCursor.toLowerCase())) {
      setSuggestions(matchingSuggestions);
      setSelectedSugg(matchingSuggestions[0]);
      setSelectedConst(filterSuggestions[0]);
    }
  };

  const handleDropdownSelect = (selectedSuggestion) => {
    setSelectedSugg(selectedSuggestion);
    const findConstant = constants.find((el) => el.key === selectedSuggestion);
    setSelectedConst(findConstant);
  };

  const handleInsertIntoText = (selectedSuggestion) => {
    if (editorInstance) {
      // Get the current editor content
      const currentContent = editorInstance.getData();

      // remove html tags
      const sanitizedText = currentContent.replace(/<\/?[^>]+(>|$)/g, '').replace(/&nbsp;/g, ' ');

      // find cursor position
      const selection = editorInstance.model.document.selection;
      const position = selection.getFirstPosition();
      const currentRowOffset = position.offset;

      // const textToRead =
      //   position.nodeBefore && position.nodeBefore._data
      //     ? position.nodeBefore._data
      //     : position.textNode && position.textNode._data
      //     ? position.textNode._data
      //     : sanitizedText;

      const sumOffsetBefore =
        position.root._children && position.root._children._nodes
          ? position.root._children._nodes
              .filter((node, index) => index < position.path[0])
              .reduce((sum, item) => (sum += item.maxOffset), 0)
          : 0;

      // Extract the last word being typed before the cursor position
      const wordsBeforeCursor = sanitizedText.slice(0, currentRowOffset + sumOffsetBefore).split(/\s+/);
      const wordBeforeCursor = wordsBeforeCursor[wordsBeforeCursor.length - 1];

      // add suggestion at position and add space before the typed word
      editorInstance.model.change((writer) => {
        const positionBefore = writer.createPositionAt(position, 'before');
        writer.insertText(selectedSuggestion, position);

        writer.insertText(' ', positionBefore);
      });

      // Remove typed word
      const refetchContent = editorInstance.getData();
      const regex = wordBeforeCursor !== '#' ? new RegExp(`${wordBeforeCursor}\\b`) : /(^|\s)#(\s|$)/g;
      const updatedText =
        wordBeforeCursor !== '#' ? refetchContent.replace(regex, '') : refetchContent.replace(regex, ' ');
      editorInstance.setData(updatedText);

      // reset dropdown value
      setSelectedSugg(null);

      // keep focus on editor
      editorInstance.model.change((writer) => {
        writer.setSelection(writer.createPositionAt(editorInstance.model.document.getRoot(), 'end'));
      });
      editorInstance.focus();
    }
  };

  const extractContentInsideHashes = (inputString) => {
    const regex = /#(?!\s)(\S*?)#/g;
    const matches = [];
    let match;

    while ((match = regex.exec(inputString)) !== null) {
      matches.push(match[1]);
    }

    return matches;
  };

  const extractCipContent = (inputString) => {
    const regexCip = /#\[(.*?)\]#/g;
    const matches = [];
    let match;

    while ((match = regexCip.exec(inputString)) !== null) {
      matches.push(match[1]);
    }

    return matches;
  };

  const handleDynamicText = () => {
    const vars = extractContentInsideHashes(content);
    const varsCips = extractCipContent(content);

    let text = content;
    vars.forEach((el) => {
      const foundConst = constants.find((item) => item.key === `#${el}#`);
      if (foundConst) {
        text = text.replace(`#${el}#`, foundConst.value);
      }
    });
    varsCips.forEach((el) => {
      const foundConst = constants.find((item) => item.key === `#[${el}]#`);
      if (foundConst) {
        text = text.replace(`#[${el}]#`, foundConst.value);
      }
    });

    return text;
  };

  return (
    <div>
      <div style={{ display: 'flex' }}>
        <div style={{ display: 'inline-block' }}>
          <Tooltip
            title="Type '#' in the text field and suggestions for variables will be populated in the dropdown above this input. If you continue writing something after '#' ('#na...') it will further match the selection in the dropdown. You can click on the INSERT INTO TEXT button at any time (as long as you have the wanted variable selected)."
            placement='top'
          >
            <p>
              <InfoCircleFilled /> How to use dynamic variable insert?
            </p>
          </Tooltip>
        </div>
      </div>

      <AutocompleteDropdown
        suggestions={suggestions}
        onSelect={handleDropdownSelect}
        selectedSugg={selectedSugg}
        onInsert={handleInsertIntoText}
        selectedConstant={selectedConst}
      />

      <Button type='dashed' onClick={() => setIsModalOpen(true)} style={{ marginBottom: '15px' }}>
        Preview
      </Button>
      <Modal
        title='Preview description'
        visible={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        cancelText='CLOSE'
        centered
        getContainer={document.getElementById(elemID)}
        okButtonProps={{ style: { display: 'none' } }}
      >
        <div dangerouslySetInnerHTML={{ __html: handleDynamicText(content) }} />
      </Modal>

      <CKEditor
        name='content'
        id='editor'
        editor={ClassicEditor}
        data={content}
        onReady={(editor) => {
          if (content) {
            editor.setData(content);
          }
          setEditorInstance(editor);
        }}
        onChange={(event, editor) => {
          const text = editor.getData();
          setContent(text);
          handleAutocomplete(editor, text);
        }}
        config={{
          plugins: ['Heading', 'Paragraph', 'Bold', 'Italic', 'Essentials', 'List', 'Link'],
          heading: {
            options: [
              { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
              { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
              { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
              { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
            ],
          },
          link: {
            decorators: {
              addTargetToExternalLinks: {
                mode: 'manual',
                label: 'Open in a new tab',
                defaultValue: true,
                attributes: {
                  target: '_blank',
                },
              },
            },
          },
          toolbar: ['heading', 'bold', 'italic', '|', 'bulletedList', 'numberedList', '|', 'link', '|', 'undo', 'redo'],
        }}
        style={{
          margin: '2px 0px',
          minHeight: '350px',
        }}
      />
    </div>
  );
};

export default EditorComponent;
