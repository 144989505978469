import React, {useState, useEffect, useContext, useRef} from 'react';
import axios from "axios";
import {SERVER_URL} from "../../config";
import {UserContext} from "../../App";
import {useHistory} from "react-router-dom";
import ReactDragListView from 'react-drag-listview';
import {EditOutlined, MenuOutlined, SearchOutlined} from '@ant-design/icons'
import {
    Button, Card, Col,
    Collapse,
    Descriptions, Form,
    Image, Input,
    List, message, Modal,
    Row,
    Select, Space,
    Spin,
    Switch,
    Table, Tag, Tooltip,
    Typography
} from "antd";
import Axios from "axios";
import AffiliateInstitutionFrom from "../../components/forms/AffiliateInstitutionFrom";
import Highlighter from "react-highlight-words";

const {Title, Link} = Typography;

const DEGREE = [
    {value: 'bachelors', label: 'Bachelor\'s'},
    {value: 'masters', label: 'Master\'s'},
    {value: 'doctoral', label: 'Doctorate'},
    {value: 'associates', label: 'Associate\'s'},
    {value: 'undergraduate', label: 'Certificates and Diplomas'},
]


let filterInit = {
    degree: 'All',
    specialization: undefined,
    marketContext: undefined,
    name: undefined,
    page: 1,
    limit: 10,
}


const CommercialSpace = () => {
    const [form] = Form.useForm();
    const currentuser = useContext(UserContext);
    const history = useHistory();
    const [working, setWorking] = useState(false);
    const [listData, setListData] = useState([]);
    const [specializations, setSpecializations] = useState([]);
    const [marketContexts, setMarketContexts] = useState([]);
    const [isModalEditInstitutionVisible, setIsModalEditInstitutionVisible] = useState(false);
    const [affiliateInstitutionData, setAffiliateInstitutionData] = useState(null);
    const [isModalEditProgramNameVisible, setIsModalEditProgramNameVisible] = useState(false);
    const [programNameData, setProgramNameData] = useState(null);
    const [filter, setFilter] = useState(filterInit);
    const [search, setSearch] = useState({});


    const fetchData = async () => {
        try {
            console.log('fetchData', filter);
            const { page, degree, specialization, marketContext, name, limit } = filter;
            setWorking(true);
            let url = `${SERVER_URL}/affiliate-institution-cms?page=${page}&degree=${degree}&specialization=${specialization}&marketContext=${marketContext}`;
            // filter
            if(filter){
                // const filterData = JSON.parse(filter);
                // const { name } = filterData;
                url = `${SERVER_URL}/affiliate-institution-cms?page=${page}&degree=${degree}&specialization=${specialization}&marketContext=${marketContext}&name=${name}`
            }
            const dataRes = await axios.get(url, {
                withCredentials: false,
                headers: {Authorization: `Bearer ${currentuser.data.token}`},
            });
            if (dataRes) {
                setListData(dataRes.data);
            }
            setWorking(false);
        } catch (error) {
            setWorking(false);
            console.log('Error: ', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [filter]);

    useEffect(() => {
        fetchData().catch(console.error);
    }, []);

    const fetchSpecializations = async () => {
        try {
            const specData = await Axios.get(`${SERVER_URL}/affiliate-institution-code/SPECIALIZATION/all`, {
                withCredentials: false,
                headers: {Authorization: `Bearer ${currentuser.data.token}`},
            });
            if (specData) {
                return specData.data;
            }
            return [];
        } catch (error) {
            console.log('Error: ', error);
        }
    };

    const fetchMarketContexts = async () => {
        try {
            const specData = await Axios.get(`${SERVER_URL}/affiliate-institution-code/MARKET_CONTEXT/all`, {
                withCredentials: false,
                headers: {Authorization: `Bearer ${currentuser.data.token}`},
            });
            if (specData) {
                return specData.data;
            }
            return [];
        } catch (error) {
            console.log('Error: ', error);
        }
    };

    const fetchDataFilter = async () => {
        Promise.all([fetchSpecializations(), fetchMarketContexts()]).then((values) => {
            setSpecializations(values[0]);
            setMarketContexts(values[1]);
        }).catch((error) => {
            console.log(error);
            setWorking(false);
        });
    }

    useEffect(() => {
        fetchDataFilter().catch(console.error);
    }, [currentuser.data.token]);

    useEffect(() => form.resetFields(), [programNameData]);

    let searchInput;
    const getColumnSearchProps = (dataIndex, name) => {
        if (Array.isArray(dataIndex)) dataIndex = dataIndex.join('.');
        const prop = dataIndex;
        return {
            filteredValue: (filter && filter[prop] && [filter[prop]]) || null,
            filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters, close}) => {
                return (
                    <div style={{padding: 8}}>
                        <Input
                            ref={(node) => {
                                searchInput = node;
                            }}
                            placeholder={`Search ${prop}`}
                            value={selectedKeys && selectedKeys.length > 0 ? selectedKeys : [search[prop]]}
                            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                            onPressEnter={(event) => handleSearch(event, selectedKeys, confirm, prop, close)}
                            style={{width: 188, marginBottom: 8, display: 'block'}}
                        />

                        <Button
                            type='primary'
                            onClick={(event) => handleSearch(event, selectedKeys, confirm, prop, close)}
                            size='small'
                            style={{width: 90, marginRight: 8}}
                        >
                            Search
                        </Button>

                        <Button onClick={(event) => handleReset(event, clearFilters, prop, close)} size='small' style={{width: 90}}>
                            Reset
                        </Button>
                    </div>
                );
            },
            filterIcon: (filtered) => {
                if (search && search[prop]) {
                    filtered = true;
                } else {
                    filtered = false;
                }
                return <SearchOutlined style={{color: filtered ? 'red' : '#bbb', fontSize: '1rem'}}/>;
            },
            onFilterDropdownVisibleChange: (visible) => {
                if (visible) {
                    setTimeout(() => {
                        searchInput.select();
                    });
                }
            },
            sorter: true,
            sortDirections: ['ascend', 'descend'],
            render: (text, record) =>
                search[prop] ? (

                        <Space size="middle">
                            <Typography.Text> <Highlighter
                                title={text}
                                highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
                                searchWords={[search[prop]]}
                                autoEscape
                                textToHighlight={text ? text.toString() : ''}
                            /><Tooltip title="Number of specializations">({record?.specializations?.length})</Tooltip></Typography.Text>
                            <Typography.Text type="secondary">{record.city}</Typography.Text>
                            <Typography.Text type="secondary">{record.state}</Typography.Text>
                        </Space>
                ) : (
                    <Space size="middle">
                        <Typography.Text> {text} <Tooltip title="Number of specializations">({record?.specializations?.length})</Tooltip></Typography.Text>
                        <Typography.Text type="secondary">{record.city}</Typography.Text>
                        <Typography.Text type="secondary">{record.state}</Typography.Text>
                    </Space>
                ),
        };
    };

    const handleSearch = (event, selectedKeys, confirm, dataIndex) => {
        event.preventDefault();
        // if (Array.isArray(dataIndex)) dataIndex = dataIndex.join('.');
        confirm();
        const newSearch = {...search};
        newSearch[dataIndex] = selectedKeys[0];
        setSearch(newSearch);
        filterHandler(dataIndex, selectedKeys[0]);
    };

    const handleReset = (event, clearFilters, prop) => {
        event.preventDefault();
        clearFilters();
        // if (Array.isArray(prop)) prop = prop.join('.');
        const newSearch = {...search};
        setSearch(newSearch);
        delete newSearch[prop];
        filterHandler(prop, null);
        setFilter({...filter, [prop]: undefined});
    };

    const filterHandler = async (prop, value) => {
        setFilter({...filter, [prop]: value});
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'institutionName',
            key: 'institutionName',
            ...getColumnSearchProps('name'),
        },
        {
            title: 'Unit ID',
            dataIndex: 'unitId',
            key: 'unitId',
        },
        {
            title: 'Active Programs',
            dataIndex: 'sumOfActivePrograms',
            key: 'sumOfActivePrograms',
            align: 'center',
            render: (text, record) => (
                <Space size="middle">
                    {record.sumOfActivePrograms > 0 ? <Tag color="green">{record.sumOfActivePrograms}</Tag> :
                        <Tag color="red">{record.sumOfActivePrograms}</Tag>}
                </Space>),
        },
        {
            title: 'Inactive Programs',
            dataIndex: 'sumOfInactivePrograms',
            key: 'sumOfInactivePrograms',
            align: 'center',
        }
    ];

    async function handleTableChange(page, limit) {
        const filterData = {...filter};
        setFilter({...filterData, page: page, limit: limit});
        //await fetchData(page, limit);
    }

    const handleReorderDataSave = async (data) => {
        try {
            setWorking(true);
            const specData = await Axios.post(`${SERVER_URL}/affiliate-reorder-programs`, data, {
                withCredentials: false,
                headers: {Authorization: `Bearer ${currentuser.data.token}`},
            });
            setWorking(false);
        } catch (error) {
            setWorking(false);
            console.log('Error: ', error);
        }
    }

    const handleStatusDataSave = async (data) => {
        try {
            setWorking(true);

            const specData = await Axios.post(`${SERVER_URL}/affiliate-update-status-programs`, data, {
                withCredentials: false,
                headers: {Authorization: `Bearer ${currentuser.data.token}`},
            });

            setWorking(false);
        } catch (error) {
            setWorking(false);
            console.log('Error: ', error);
        }
    }


    async function onDragEnd(fromIndex, toIndex, unitId, specialization) {
        const data = {...listData};
        const dataIndex = data.docs.findIndex(v => v.unitId == unitId);
        console.log(data.docs[dataIndex]);
        const specIndex = data.docs[dataIndex].specializations.findIndex(v => v.specialization == specialization);
        console.log(data.docs[dataIndex].specializations[specIndex]);
        const dataForReorder = data.docs[dataIndex].specializations[specIndex];
        const item = dataForReorder.data.splice(fromIndex, 1)[0];
        dataForReorder.data.splice(toIndex, 0, item);

        for (let i = 0; i < dataForReorder.data.length; i++) {
            dataForReorder.data[i].order = i;
        }

        data.docs[dataIndex].specializations[specIndex] = dataForReorder;
        // console.log(data.docs[dataIndex].specializations[specIndex]);
        setListData(data);
        // save data
        const saveData = dataForReorder.data.map(
            (item, index) => {
                return {id: item.institutionProgramId, provider: item.provider, order: index}
            }
        )
        await handleReorderDataSave(saveData);
        // console.log(fromIndex, toIndex, unitId, specialization);
    }

    async function handleActiveProgram(institutionProgramId, provider, status, unitId, specialization) {
        console.log(institutionProgramId, provider, status, unitId, specialization);
        const data = {...listData};
        const dataIndex = data.docs.findIndex(v => v.unitId == unitId);
        const specIndex = data.docs[dataIndex].specializations.findIndex(v => v.specialization == specialization);
        const dataForReorder = data.docs[dataIndex].specializations[specIndex];
        const item = dataForReorder.data.find(v => v.institutionProgramId == institutionProgramId);
        item.status = !status;
        data.docs[dataIndex].specializations[specIndex] = dataForReorder;
        data.docs[dataIndex].sumOfActivePrograms = data.docs[dataIndex].sumOfActivePrograms + (status ? -1 : 1);
        data.docs[dataIndex].sumOfInactivePrograms = data.docs[dataIndex].sumOfInactivePrograms + (status ? 1 : -1);
        setListData(data);
        await handleStatusDataSave({id: institutionProgramId, provider: provider, status: !status});
    }

    async function handleSubmitForm(values) {

        // console.log(values);
        try {
            setWorking(true);
            const config = {
                withCredentials: false,
                headers: {Authorization: `Bearer ${currentuser.data.token}`},
            };
            let touRes;
            if (values._id) {
                touRes = await Axios.put(`${SERVER_URL}/affiliate-institution/${values._id}`, values, config);
            }
            if (touRes) {
                message.success('Affiliate Institution updated successfully');
                setIsModalEditInstitutionVisible(false);
                setWorking(false);
                const data = {...listData};
                const dataIndex = data.docs.findIndex(v => v.institutionId == values._id);
                data.docs[dataIndex].city = values.city;
                data.docs[dataIndex].institutionName = values.name;
                data.docs[dataIndex].state = values.state;
                data.docs[dataIndex].logoUrl = values.logoUrl;
                data.docs[dataIndex].tags = values.tags;
                setListData(data);
            }
        } catch (error) {
            setWorking(false);
            console.log('Error: ', error);
        }
    }

    function handleOpenEditInst(record) {
        console.log(record);
        setIsModalEditInstitutionVisible(true);
        setAffiliateInstitutionData({
            unitId: record.unitId,
            logoUrl: record.logoUrl,
            name: record.institutionName,
            city: record.city,
            state: record.state,
            tags: record.tags,
            _id: record.institutionId,
        });
    }

    async function handleEditProgram(value) {
        const {_id, provider, unitId, specialization} = programNameData;
        const data = {id: _id, provider, programName: value.programName};
        try {
            setWorking(true);
            const config = {
                withCredentials: false,
                headers: {Authorization: `Bearer ${currentuser.data.token}`},
            };
            let touRes;
            if (_id) {
                touRes = await Axios.post(`${SERVER_URL}/affiliate-update-program-name`, data, config);
            }
            if (touRes) {
                message.success('Affiliate Program updated successfully');
                setIsModalEditProgramNameVisible(false);
                const data = {...listData};
                const dataIndex = data.docs.findIndex(v => v.unitId == unitId);
                const specIndex = data.docs[dataIndex].specializations.findIndex(v => v.specialization == specialization);
                const dataForReorder = data.docs[dataIndex].specializations[specIndex];
                const item = dataForReorder.data.find(v => v.institutionProgramId == _id);
                item.programName = value.programName;
                data.docs[dataIndex].specializations[specIndex] = dataForReorder;
                setListData(data);
            }
            setWorking(false);
        } catch (error) {
            setWorking(false);
            console.log('Error: ', error);
        }
    }

    return (
        <div className='content-wrapper'>
            <Spin spinning={working} tip='Working...'>
                <div className='actions-block'>
                    <Row gutter={[16, 16]}>
                        <Col span={8}>
                            <Select
                                showSearch
                                allowClear
                                value={filter.degree}
                                onChange={(value) => setFilter({...filter, degree: value, page: 1})}
                                style={{width: '100%', marginLeft: '4px'}}
                                placeholder='Select Degree...'
                            >
                                {DEGREE.map((item) => (
                                    <Select.Option value={item.value} key={item.value}>
                                        {item.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Col>
                        <Col span={8}>
                            <Select
                                showSearch
                                allowClear
                                value={filter.marketContext}
                                onChange={(value) => setFilter({...filter, marketContext: value, page: 1})}
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                style={{width: '100%'}}
                                placeholder='Select Market Context...'
                            >
                                {marketContexts && marketContexts.map((item, index) => (
                                    <Select.Option value={item.value} key={index}>
                                        {item.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Col>
                        <Col span={8}>
                            <Select
                                showSearch
                                allowClear
                                value={filter.specialization}
                                onChange={(value) => setFilter({...filter, specialization: value, page: 1})}
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                style={{width: '100%'}}
                                placeholder='Select Specialization...'
                            >
                                {specializations && specializations.map((item, index) => (
                                    <Select.Option value={item.value} key={index}>
                                        {item.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Col>
                    </Row>
                </div>
                <Table
                    pagination={{
                        pageSize: 10,
                        total: listData.totalDocs,
                        current: filter.page,
                        onChange: handleTableChange
                    }}
                    columns={columns}
                    dataSource={listData.docs ? [...listData.docs] : []}
                    rowKey={record => record.institutionId}
                    expandable={{
                        expandedRowRender: (record) => (
                            <Card type="inner" title={
                                <Row>
                                    <Col span={3}>
                                        <Image
                                            style={{width: '75px', height: '75px', marginRight: '10px', float: 'left'}}
                                            src={record.logoUrl}/>
                                    </Col>
                                    <Col span={21}>
                                        <Typography.Title level={4}>{record.institutionName} <Button type='link'
                                                                                                     onClick={() => handleOpenEditInst(record)}>Edit</Button></Typography.Title>
                                        <Typography.Text>{record.city}, {record.state}</Typography.Text>
                                        <br/>
                                        <Typography.Text>Unit ID: {record.unitId}</Typography.Text>
                                        <br/>
                                        <>{record.tags && record.tags.map(
                                            (item, index) => (
                                                <Tag key={index}>{item.replace(/_/g, " ")}</Tag>
                                            )
                                        )}</>
                                    </Col>
                                </Row>
                            }>

                                <List
                                    dataSource={record.specializations}
                                    rowKey={item => item.specialization}
                                    renderItem={item => (
                                        <List.Item>
                                            <Collapse style={{width: "100%"}} accordion>
                                                <Collapse.Panel
                                                    header={`${item.specialization.replace(/-/g, ' ')} (${item.data.length})`}
                                                    key={item.specialization}>
                                                    <ReactDragListView onDragEnd={(fromIndex, toIndex) =>
                                                        onDragEnd(fromIndex, toIndex, record.unitId, item.specialization)
                                                    }>
                                                        <Table dataSource={[...item.data]} pagination={false}
                                                               rowKey={(record) => record.institutionProgramId}>
                                                            <Table.Column title="Sort" align="center"
                                                                          render={(text, record) => (
                                                                              <a style={{cursor: "move!important"}}
                                                                                 href="#"><MenuOutlined
                                                                                  style={{
                                                                                      cursor: 'grab',
                                                                                      color: '#999',
                                                                                  }}
                                                                              /></a>)}/>
                                                            <Table.Column title="Program Name" render={
                                                                (data, itemData) => (
                                                                    <Space>{data.programName} <EditOutlined onClick={
                                                                        () => {
                                                                            setIsModalEditProgramNameVisible(true);
                                                                            setProgramNameData({
                                                                                _id: itemData.institutionProgramId,
                                                                                provider: itemData.provider,
                                                                                programName: itemData.programName,
                                                                                unitId: record.unitId,
                                                                                specialization: item.specialization,
                                                                            });
                                                                        }
                                                                    }/>
                                                                    </Space>)
                                                            }/>
                                                            <Table.Column title="Degree" dataIndex="degree"/>
                                                            <Table.Column title="Market Context"
                                                                          dataIndex="marketContext"/>
                                                            <Table.Column title="Aff. Link" align="center"
                                                                          dataIndex="affLink"
                                                                          render={(text, record) => (<Link href={text}
                                                                                                           target="_blank">Link</Link>)}/>
                                                            <Table.Column title="Provider" align="center"
                                                                          dataIndex="provider"/>
                                                            <Table.Column title="Active" align="center"
                                                                          dataIndex="active"
                                                                          render={(text, data) => (<Switch
                                                                              onChange={() => handleActiveProgram(data.institutionProgramId, data.provider, data.status, record.unitId, item.specialization)}
                                                                              checked={data.status}/>)}/>
                                                            />
                                                        </Table>
                                                    </ReactDragListView>
                                                </Collapse.Panel>
                                            </Collapse>


                                        </List.Item>
                                    )
                                    }
                                />
                            </Card>
                        ),
                        rowExpandable: (record) => record.specializations.length > 0,
                    }}
                />
                <Modal forceRender
                       okButtonProps={{form: 'affiliate-institution-form', key: 'submit', htmlType: 'submit'}}
                       style={{top: 20}} title="Add / Edit Affiliate Institution"
                       visible={isModalEditInstitutionVisible}
                       onCancel={() => setIsModalEditInstitutionVisible(false)}>
                    <AffiliateInstitutionFrom data={affiliateInstitutionData} handleSubmitForm={handleSubmitForm}/>
                </Modal>
                <Modal forceRender centered width={800}
                       okButtonProps={{form: 'program-name-edit', key: 'submit', htmlType: 'submit'}}
                       style={{top: 20}} title="Edit Program Name" visible={isModalEditProgramNameVisible}
                       onCancel={() => setIsModalEditProgramNameVisible(false)}>
                    <Form form={form} id="program-name-edit" initialValues={programNameData}
                          onFinish={handleEditProgram}>
                        <Form.Item label="Program Name" name="programName" rules={[{required: true}]}>
                            <Input/>
                        </Form.Item>
                    </Form>
                </Modal>
            </Spin>
        </div>
    )
}

export default CommercialSpace;
