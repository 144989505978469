import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../../App';
import NavMenu from './NavMenu';
import Routes from '../routes/Routes';
// import useMessage from '../../hooks/useMessage';
// import { SERVER_URL } from '../../config';
import { Layout, Menu, Dropdown /* , notification */ } from 'antd';
import { DownOutlined } from '@ant-design/icons';

const { Header, Content } = Layout;

const AdminLayout = () => {
  const user = useContext(UserContext);
  const [isCollapsed, setCollapsed] = useState(false);

  const handleSignOutSubmit = async (event) => {
    event.preventDefault();
    sessionStorage.removeItem('user');
    window.location.href = '/login';
  };

  const userMenu = (
    <Menu>
      {user.data && ['editor'].includes(user.data.role[0]) && (
        <Menu.Item key='1'>
          <Link to={`/admin/edit-user/${user.data ? user.data.id : ''}`}>My account</Link>
        </Menu.Item>
      )}
      <Menu.Item key='2'>
        <span onClick={handleSignOutSubmit}>Log out</span>
      </Menu.Item>
    </Menu>
  );

  // const data = useMessage(`${SERVER_URL}/scrape-notifications`);
  // if (data) {
  //   console.log('\nmessage =', data);
  //   // notification.info({ message: data.message, placement: 'topRight' });
  // }

  return (
    <Layout
      style={{
        minHeight: '100vh',
      }}
    >
      <NavMenu isCollapsed={isCollapsed} setCollapsed={setCollapsed} user={user.data} />
      <Layout>
        <Header className='admin_header' style={{ color: '#fff', position: 'relative' }}>
          <Dropdown overlay={userMenu} trigger={['click']}>
            <span className='ant-dropdown-link' style={{ color: '#fff', cursor: 'pointer' }}>
              <span>{user.data ? `${user.data.firstName} ${user.data.lastName} ` : 'user '}</span>
              <DownOutlined />
            </span>
          </Dropdown>
        </Header>

        <Content>
          <Routes />
        </Content>
      </Layout>
    </Layout>
  );
};

export default AdminLayout;
