import React, { useContext } from 'react';
import Axios from 'axios';
import { notification, Spin, Input, Button, Checkbox, Tooltip, Image, Divider, Select } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import { SERVER_URL } from '../../../config';
import { UserContext } from '../../../App';
import UPDATED_RV_OPTIONS from '../../../lib/updated_rvOptions.json';

const { TextArea } = Input;

const DP_Admin = ({
  fetchTrigger,
  setFetchTrigger,
  title,
  newTitle,
  slug,
  setSlug,
  setNewTitle,
  setWorking,
  working,
  cipcode,
  loanForgiveness,
  setLoanForgiveness,
  wpProfileLink,
  setWpProfileLink,
  featureImage,
  setFeatureImage,
  shortcodes,
  setShortcodes,
  rvSubject,
  setRVSubject,
  rvCategory,
  setRVCategory,
}) => {
  const currentuser = useContext(UserContext);

  const editHandler = async (cipcode, newTitle, loan, wpLink, imageLink, slug, shortcodes, rvSubject, rvCategory) => {
    try {
      setWorking(true);

      await Axios.patch(
        `${SERVER_URL}/degree-profiles-edit-cipcode-name`,
        {
          cipcode,
          newTitle,
          loanForgiveness: loan,
          wpLink,
          featureImage: imageLink,
          manualSlug: slug,
          shortcodes,
          rvSubject,
          rvCategory,
        },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );

      setWorking(false);
      setFetchTrigger(!fetchTrigger);
      notification.success({
        message: 'Cipcode updated',
        placement: 'bottomRight',
        duration: 3,
      });
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Error editing cipcode',
        placement: 'bottomRight',
        duration: 3,
      });
    }
  };

  return (
    <Spin spinning={working} tip='Working...'>
      <div>
        <Tooltip title='Title from IPEDS.'>
          <label htmlFor='oldTitle'>
            <InfoCircleFilled /> Original title:{' '}
          </label>
        </Tooltip>
        <Input type='text' value={title} disabled style={{ width: '800px' }} name='oldTitle' />

        <br></br>
        <br></br>

        <Tooltip title='Custom title shown on frontend. If nothing is entered, original title will be shown.'>
          <label htmlFor='newTitle'>
            <InfoCircleFilled /> New title:{' '}
          </label>
        </Tooltip>
        <Input
          type='text'
          value={newTitle}
          name='newTitle'
          onChange={(e) => setNewTitle(e.target.value)}
          style={{ width: '800px' }}
        />

        <br></br>
        <br></br>

        <Tooltip title='Slug in URL of compare majors tool. If this is empty, the default is taken to be the same as the new title (if present, if not than the original is used) all lowercase and with dashes inbetween.'>
          <label htmlFor='slug'>
            <InfoCircleFilled /> Slug:{' '}
          </label>
        </Tooltip>
        <Input
          type='text'
          value={slug}
          name='slug'
          onChange={(e) => setSlug(e.target.value)}
          style={{ width: '800px' }}
        />

        <br></br>
        <br></br>

        <Tooltip title='Used in key information widget on degree profile pages.'>
          <Checkbox checked={loanForgiveness} onChange={(e) => setLoanForgiveness(e.target.checked)}>
            <InfoCircleFilled /> Loan Forgiveness Eligibility
          </Checkbox>
        </Tooltip>

        <br></br>
        <br></br>

        <Tooltip title='Link to WP degree profile page.'>
          <label htmlFor='wpProfileLink'>
            <InfoCircleFilled /> Associated Profile:{' '}
          </label>
        </Tooltip>
        <Input
          type='text'
          value={wpProfileLink}
          name='wpProfileLink'
          onChange={(e) => setWpProfileLink(e.target.value)}
          style={{ width: '500px' }}
        />

        <br></br>
        <br></br>

        <Tooltip title='Image link that will be shown in degree compare tool on /rankings pages.'>
          <label htmlFor='featureImage'>
            <InfoCircleFilled /> Feature Image:{' '}
          </label>
        </Tooltip>
        <Input
          type='text'
          value={featureImage}
          name='featureImage'
          onChange={(e) => setFeatureImage(e.target.value)}
          style={{ width: '800px' }}
        />
        <br></br>
        <br></br>
        <div>
          {featureImage && (
            <Image
              src={featureImage}
              style={{
                width: '300px',
                border: '0.5px solid #bbb',
                background: '#eee',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
            />
          )}
        </div>

        <br></br>
        <br></br>

        <div>
          <label htmlFor='rvCategory'>RV Category: </label>
          <Select
            showSearch
            allowClear
            optionFilterProp='children'
            value={rvCategory}
            onChange={(value) => setRVCategory(value)}
            style={{ width: '400px', marginRight: '30px' }}
            placeholder='RV category...'
            name='rvCategory'
          >
            {UPDATED_RV_OPTIONS.rv_data_categories.map((item) => (
              <Select.Option key={item} value={item}>
                {item}
              </Select.Option>
            ))}
          </Select>

          <label htmlFor='rvSubject'>RV Subject: </label>
          <Select
            showSearch
            allowClear
            optionFilterProp='children'
            value={rvSubject}
            onChange={(value) => setRVSubject(value)}
            style={{ width: '400px' }}
            placeholder='RV subject...'
            name='rvSubject'
          >
            {UPDATED_RV_OPTIONS.rv_data_subject.map((item) => (
              <Select.Option key={item} value={item}>
                {item}
              </Select.Option>
            ))}
          </Select>
        </div>

        <Divider>Shortcodes for field of study</Divider>

        <label htmlFor='certificate'>Undergraduate Certificate or Diploma: </label>
        <TextArea
          value={shortcodes.certificate}
          name='certificate'
          onChange={(e) => setShortcodes({ ...shortcodes, certificate: e.target.value })}
          rows={2}
        />

        <br></br>
        <br></br>

        <label htmlFor='associate'>Associate's Degree: </label>
        <TextArea
          value={shortcodes.associate}
          name='associate'
          onChange={(e) => setShortcodes({ ...shortcodes, associate: e.target.value })}
          rows={2}
        />

        <br></br>
        <br></br>

        <label htmlFor='bachelors'>Bachelors's Degree: </label>
        <TextArea
          value={shortcodes.bachelors}
          name='bachelors'
          onChange={(e) => setShortcodes({ ...shortcodes, bachelors: e.target.value })}
          rows={2}
        />

        <br></br>
        <br></br>

        <label htmlFor='baccalaureate'>Post-baccalaureate Certificate: </label>
        <TextArea
          value={shortcodes.baccalaureate}
          name='baccalaureate'
          onChange={(e) => setShortcodes({ ...shortcodes, baccalaureate: e.target.value })}
          rows={2}
        />

        <br></br>
        <br></br>

        <label htmlFor='masters'>Masters's Degree: </label>
        <TextArea
          value={shortcodes.masters}
          name='masters'
          onChange={(e) => setShortcodes({ ...shortcodes, masters: e.target.value })}
          rows={2}
        />

        <br></br>
        <br></br>

        <label htmlFor='gradCertificate'>Graduate/Professional Certificate: </label>
        <TextArea
          value={shortcodes.gradCertificate}
          name='gradCertificate'
          onChange={(e) => setShortcodes({ ...shortcodes, gradCertificate: e.target.value })}
          rows={2}
        />

        <br></br>
        <br></br>

        <label htmlFor='doctoral'>Doctoral Degree: </label>
        <TextArea
          value={shortcodes.doctoral}
          name='doctoral'
          onChange={(e) => setShortcodes({ ...shortcodes, doctoral: e.target.value })}
          rows={2}
        />

        <br></br>
        <br></br>

        <label htmlFor='firstPro'>First Professional Degree: </label>
        <TextArea
          value={shortcodes.firstPro}
          name='firstPro'
          onChange={(e) => setShortcodes({ ...shortcodes, firstPro: e.target.value })}
          rows={2}
        />

        <br></br>
        <br></br>

        <Divider />

        <Button
          type='primary'
          onClick={() =>
            editHandler(
              cipcode,
              newTitle,
              loanForgiveness,
              wpProfileLink,
              featureImage,
              slug,
              shortcodes,
              rvSubject,
              rvCategory,
            )
          }
        >
          SAVE
        </Button>
      </div>
    </Spin>
  );
};

export default DP_Admin;
