import React from 'react';
import { Link } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import { Button, Input, Table, Tooltip, Tag } from 'antd';
import { EyeOutlined, SearchOutlined } from '@ant-design/icons';

const BlsTable = ({
  data,
  isLoading,
  search,
  setSearch,
  filterHandler,
  pageHandler,
  sortHandler,
  viewPath,
  page,
  total,
  filter,
}) => {
  let searchInput;

  const getColumnSearchProps = (dataIndex) => {
    if (Array.isArray(dataIndex)) dataIndex = dataIndex.join('.');
    const prop = dataIndex;
    return {
      filteredValue: (filter && filter[prop] && [filter[prop]]) || null,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              ref={(node) => {
                searchInput = node;
              }}
              placeholder={`Search ${prop}`}
              value={selectedKeys}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, prop)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />

            <Button
              type='primary'
              onClick={() => handleSearch(selectedKeys, confirm, prop)}
              size='small'
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>

            <Button onClick={() => handleReset(clearFilters, prop)} size='small' style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        );
      },
      filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />,
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => {
            searchInput.select();
          });
        }
      },
      sorter: true,
      defaultSortOrder: prop === 'areaTitle' ? 'ascend' : undefined,
      sortDirections: ['ascend', 'descend'],
      render: (text) =>
        search[prop] ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[search[prop]]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          text
        ),
    };
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    if (Array.isArray(dataIndex)) dataIndex = dataIndex.join('.');
    const newSearch = { ...search };
    newSearch[dataIndex] = selectedKeys[0];
    setSearch(newSearch);
    filterHandler(dataIndex, selectedKeys[0]);
    confirm();
  };

  const handleReset = (clearFilters, prop) => {
    if (Array.isArray(prop)) prop = prop.join('.');
    const newSearch = { ...search };
    setSearch(newSearch);
    delete newSearch[prop];
    filterHandler(prop, null);
    clearFilters();
  };

  const columns = [
    { title: 'YEAR', dataIndex: ['year'], key: 'year', ...getColumnSearchProps('year') },
    { title: 'STATE', dataIndex: ['areaTitle'], key: 'areaTitle', ...getColumnSearchProps('areaTitle') },
    {
      title: 'OCC CODE',
      dataIndex: ['occCode'],
      key: 'occCode',
      ...getColumnSearchProps('occCode'),
    },
    {
      title: 'OCC GROUP',
      dataIndex: ['data', 'OCC_GROUP'],
      key: 'occGroup',
      ...getColumnSearchProps(['data', 'OCC_GROUP']),
    },
    {
      title: 'TITLE',
      dataIndex: ['data', 'OCC_TITLE'],
      key: 'title',
      ...getColumnSearchProps(['data', 'OCC_TITLE']),
    },
    {
      title: 'PROJECTIONS',
      dataIndex: 'ltCount',
      key: 'ltCount',
      render: (text) => <Tag color={text === 1 ? 'green' : 'red'}>{text === 1 ? 'YES' : 'NO'}</Tag>,
      // ...getColumnSearchProps(['data', 'OCC_TITLE']),
    },
  ];

  columns.push({
    title: 'Action',
    render: (text, record) => (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '15px',
        }}
      >
        {viewPath && (
          <Tooltip title='View Details' placement='topLeft'>
            <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='table-actions'>
              <Link to={`${viewPath}${record.occCode}/${record.year}`}>
                <EyeOutlined style={{ textDecoration: 'none', color: 'black' }} />
              </Link>
            </div>
          </Tooltip>
        )}
      </div>
    ),
  });

  const onChangeTable = (addPagination, addFilters, addSorter) => {
    pageHandler(addPagination.current);
    sortHandler(addSorter.field, addSorter.order);
  };

  return (
    <div>
      <Table
        size='middle'
        loading={isLoading}
        onChange={onChangeTable}
        bordered
        dataSource={data}
        columns={columns}
        rowKey={(record) => record._id}
        pagination={{
          pageSize: 20,
          total: total || 0,
          current: page,
          defaultCurrent: 1,
          position: 'bottom',
          showSizeChanger: false,
          onChange: (page) => pageHandler(page),
          hideOnSinglePage: true,
        }}
      />
    </div>
  );
};

export default BlsTable;
