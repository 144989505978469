import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card, Form, Input, Result, Select } from 'antd';
import UploadBox from '../base/UploadBox';
import GalleryModal from './GalleryModal';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 24 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 24 },
};

const initialValues = {
  email: undefined,
  password: undefined,
  firstName: undefined,
  lastName: undefined,
  role: [],
  avatar: undefined,
};

const UserForm = ({
  id,
  data,
  avatar,
  updateHandler,
  createHandler,
  token,
  result,
  setResult,
  loggedInUserId,
  userId,
  SERVER_URL,
}) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [modal, setModal] = useState({ visible: false, formKey: null, /* limit: 1, */ id: null });
  const [currentAvatar, setCurrentAvatar] = useState(data?.avatar ? data.avatar : null);
  const isNew = data ? false : true;
  if (data) ['createdAt', 'updatedAt', '__v'].forEach((key) => delete data[key]);
  let user = data ? { ...data } : initialValues;

  // const prevForm = sessionStorage.getItem('userForm');
  // if (prevForm) user = JSON.parse(prevForm);

  const onFinish = (values, isNew) => {
    if (isNew) {
      if (avatar) {
        values.avatar = avatar._id;
      }
      createHandler(values);
      window.location.replace('/admin/users');
    } else {
      if (avatar || data.avatar) {
        values.avatar = avatar ? avatar._id : currentAvatar._id;
      }
      updateHandler(values);
      history.push('/admin/users');
    }
  };

  const onInsert = (values) => {
    form.setFieldsValue({ avatar: values });
    setCurrentAvatar(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const avatarHandler = () => {
    // const values = form.getFieldsValue();
    // sessionStorage.setItem('userForm', JSON.stringify(values));
    // history.push(`/admin/gallery/edit-content/${isNew ? 'new-user' : 'edit-user'}/${isNew ? 'img' : userId}`);
    setModal({ visible: true, formKey: 'userForm' });
  };

  return (
    <div style={{ marginBottom: '20px' }}>
      <div className='dashboard'>
        <div className='card-wrapper'>
          {/* eslint-disable-next-line no-useless-concat */}
          <Card title={`${isNew ? 'NEW' : 'EDIT'} USER`} bordered={false}>
            {!result && (
              <Form
                {...layout}
                name='basic'
                initialValues={user}
                onFinish={(values) => onFinish(values, isNew)}
                onFinishFailed={onFinishFailed}
                layout='vertical'
                form={form}
              >
                <Form.Item label='Email' name='email' rules={[{ required: true, message: 'Please enter email!' }]}>
                  <Input placeholder='Email' />
                </Form.Item>

                <Form.Item
                  label='First name'
                  name='firstName'
                  rules={[{ required: true, message: 'Please enter first name!' }]}
                >
                  <Input placeholder='First name' />
                </Form.Item>

                <Form.Item
                  label='Last name'
                  name='lastName'
                  rules={[{ required: true, message: 'Please enter last name!' }]}
                >
                  <Input placeholder='Last name' />
                </Form.Item>

                {isNew && (
                  <Form.Item
                    label='Password'
                    name='password'
                    rules={[{ required: true, message: 'Please enter your password!' }, { min: 8 }]}
                  >
                    <Input.Password placeholder='password' />
                  </Form.Item>
                )}

                {loggedInUserId !== userId && (
                  <Form.Item label='Role' name='role' rules={[{ required: true, message: 'Please select role!' }]}>
                    <Select style={{ textAlign: 'left' }}>
                      <Select.Option value='admin'>Admin</Select.Option>
                      <Select.Option value='editor'>Editor</Select.Option>
                      <Select.Option value='contributor'>Contributor</Select.Option>
                      <Select.Option value='viewer'>Viewer</Select.Option>
                      <Select.Option value='guest'>Guest</Select.Option>
                    </Select>
                  </Form.Item>
                )}

                {!isNew && loggedInUserId !== userId && (
                  <Form.Item
                    label='Status'
                    name='status'
                    rules={[{ required: true, message: 'Please select status!' }]}
                  >
                    <Select style={{ textAlign: 'left' }}>
                      <Select.Option value='ACTIVE'>Active</Select.Option>
                      <Select.Option value='SUSPENDED'>Suspended</Select.Option>
                      <Select.Option value='DELETED'>Deleted</Select.Option>
                      <Select.Option value='WAITING_FOR_ACTIVATION'>Waiting for activation</Select.Option>
                    </Select>
                  </Form.Item>
                )}

                <Form.Item label='Avatar' className='upload-wrapper right' name='avatar' valuePropName='avatar'>
                  {currentAvatar ? (
                    <img
                      src={currentAvatar.url}
                      style={{
                        width: '100px',
                        border: '0.5px solid #bbb',
                        background: '#eee',
                        borderRadius: '5px',
                        cursor: 'pointer',
                      }}
                      alt='upload'
                      title='Select from gallery or upload'
                      onClick={() => avatarHandler()}
                    />
                  ) : (
                    <UploadBox handler={avatarHandler} />
                  )}
                </Form.Item>

                <Form.Item {...tailLayout}>
                  <Button style={{ float: 'right' }} type='primary' htmlType='submit'>
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            )}

            <GalleryModal
              visible={modal.visible}
              // limit={modal.limit}
              formKey={modal.formKey}
              imageSavePath='public/images/data/'
              imageType='data'
              imageHeight={500}
              SERVER_URL={SERVER_URL}
              token={token}
              form={form}
              onCancel={() => setModal({ visible: false, formKey: null })}
              onInsert={(values) => {
                onInsert(values);
              }}
              imageId={modal.id}
              imageIndex={modal.index}
              currentImage={currentAvatar}
            />

            {result && (
              <Result
                title='User has been created'
                extra={
                  <Button style={{ float: 'right' }} type='primary' key='console' onClick={() => setResult(false)}>
                    Ok
                  </Button>
                }
              />
            )}
          </Card>
        </div>
      </div>
      <Button type='primary' onClick={() => history.push('/admin/users')}>
        ALL USERS
      </Button>
    </div>
  );
};

export default UserForm;
