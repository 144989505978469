import React, { useState, useEffect, useContext } from 'react';
import { Button, Form, Image, Input, Modal, Space, Spin, Table, Tooltip, notification } from 'antd';
import { UserContext } from '../../App';
import axios from 'axios';
import { SERVER_URL } from '../../config';
import { DeleteOutlined, EditOutlined, SyncOutlined } from '@ant-design/icons';

const { confirm } = Modal;

const Tags = () => {
  const [form] = Form.useForm();
  const [tags, setTags] = useState([]);
  const [working, setWorking] = useState(true);
  const [visible, setVisible] = useState(false);
  const [selectedCode, setSelectedCode] = useState(null);
  const [filterTable, setFilterTable] = useState(null);
  const currentUser = useContext(UserContext);

  const fetchData = async () => {
    setWorking(true);
    const response = await axios.get(`${SERVER_URL}/affiliate-institution-tag/all`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentUser.data.token}` },
    });
    setTags(response.data);
    setWorking(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => form.resetFields(), [selectedCode]);

  async function handleDelete(record) {
    confirm({
      title: 'Are you sure delete this tag?',
      content: 'This action cannot be undone.',
      onOk: async () => {
        setWorking(true);
        await axios.delete(`${SERVER_URL}/affiliate-institution-tag/${record._id}`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentUser.data.token}` },
        });
        await fetchData();
        setWorking(false);
      },
    });
  }

  function handleEdit(record) {
    setVisible(true);
    setSelectedCode(record);
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Icon',
      dataIndex: 'iconUrl',
      key: 'iconUrl',
      align: 'center',
      render: (text, record) => (
        <Image fallback='https://via.placeholder.com/50?text=No Image' src={record.iconUrl} alt={record.name} />
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'center',
      render: (text, record) => (
        <Space>
          <DeleteOutlined onClick={() => handleDelete(record)} />
          <EditOutlined onClick={() => handleEdit(record)} />
        </Space>
      ),
    },
  ];

  function handleAdd() {
    setVisible(true);
    setSelectedCode(null);
  }

  async function handleFinish() {
    try {
      const values = await form.validateFields();
      form.resetFields();
      const { _id } = values;
      if (_id) {
        setWorking(true);
        await axios.put(`${SERVER_URL}/affiliate-institution-tag/${_id}`, values, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentUser.data.token}` },
        });
        setWorking(false);
        setVisible(false);
        await fetchData();
      } else {
        setWorking(true);
        await axios.post(`${SERVER_URL}/affiliate-institution-tag`, values, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentUser.data.token}` },
        });
        setWorking(false);
        setVisible(false);
        await fetchData();
      }
    } catch (e) {
      console.log(e);
    }
  }

  function handleSearchTable(value) {
    const baseData = [...tags];
    const filterData = baseData.filter((o) =>
      Object.keys(o).some((k) => String(o[k]).toLowerCase().includes(value.toLowerCase())),
    );
    setFilterTable(filterData);
  }

  async function handleIconsSync() {
    try {
      setWorking(true);

      await axios.get(`${SERVER_URL}/affiliate-institution-tags-sync-wp`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentUser.data.token}` },
      });

      setWorking(false);
      notification.success({
        message: 'Success',
        description: 'Tags (icons) sent to WP',
        placement: 'bottomRight',
      });
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Error',
        description: 'Problem with sending tags (icons) to WP',
        placement: 'bottomRight',
      });
    }
  }

  return (
    <div className='content-wrapper'>
      <Spin spinning={working} tip='Working...'>
        <div className='actions-block flex'>
          <Tooltip title='Send icons to WP'>
            <Button type='danger' onClick={handleIconsSync} style={{ marginRight: '10px' }}>
              <SyncOutlined />
              Sync To WP
            </Button>
          </Tooltip>
          <Button type='primary' onClick={handleAdd}>
            New
          </Button>
          <Input.Search placeholder='Search by...' enterButton onSearch={handleSearchTable} />
        </div>
        <Table columns={columns} dataSource={filterTable ? filterTable : tags} rowKey={(record) => record._id} />

        <Modal title='New Tag' visible={visible} onOk={handleFinish} onCancel={() => setVisible(false)}>
          <Form form={form} initialValues={selectedCode} layout='vertical'>
            <Form.Item label='Tag name' name='name' rules={[{ required: true, message: 'Name is required.' }]}>
              <Input placeholder='Label' />
            </Form.Item>
            <Form.Item label='Icon Url' name='iconUrl' rules={[{ required: true, message: 'Icon url is required.' }]}>
              <Input placeholder='Value' />
            </Form.Item>
            <Form.Item name='_id' hidden>
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      </Spin>
    </div>
  );
};

export default Tags;
