import React, { useEffect, useContext } from 'react';
import Axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { notification, Button, Modal } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { WebsiteTable } from '../../components/tables';
import { SERVER_URL } from '../../config';

const TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'largeButtons',
  'numberedLists',
  'topLists',
  'eatTopLists',
  'password',
  'schools',
  'createdBy',
  'modifiedBy',
  'numberedList',
];

const Websites = () => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [data, fetchData] = useAxios('', [], currentuser.data.token, 'get');

  useEffect(() => {
    fetchData(`${SERVER_URL}/websites?testConnection=true`, []);
  }, [fetchData]);

  const deleteHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/websites/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Item is deleted.',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/websites');
    } catch (err) {
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  const deleteDataHandler = async (website) => {
    let message = 'Please delete all ';
    let validate = false;

    if (website.numberedLists && website.numberedLists.length > 0) {
      message = message + 'numbered lists, ';
      validate = true;
    }

    if (website.largeButtons && website.largeButtons.length > 0) {
      message = message + 'large buttons, ';
      validate = true;
    }

    if (website.topLists && website.topLists.length > 0) {
      message = message + 'top lists';
      validate = true;
    }

    if (validate) {
      Modal.confirm({
        width: 700,
        icon: <ExclamationCircleOutlined style={{ display: 'block', fontSize: '2rem' }} />,
        content: <div style={{ marginLeft: '3.5rem' }}>{<p>{message}!</p>}</div>,
        onOk() {
          Modal.destroyAll();
        },
        onCancel() {
          Modal.destroyAll();
        },
      });
    } else {
      Modal.confirm({
        width: 700,
        icon: <ExclamationCircleOutlined style={{ display: 'block', fontSize: '2rem' }} />,
        content: <div style={{ marginLeft: '3.5rem' }}>{<p>This {website.url} website will be deleted!</p>}</div>,
        onOk() {
          Modal.confirm({
            width: 700,
            icon: <ExclamationCircleOutlined style={{ display: 'block', fontSize: '2rem' }} />,
            content: (
              <div style={{ marginLeft: '3.5rem' }}>Are you sure you want to delete this {website.url} website ?</div>
            ),
            onOk() {
              deleteHandler(website._id);
              Modal.destroyAll();
            },
            onCancel() {
              Modal.destroyAll();
            },
          });
        },
        onCancel() {
          Modal.destroyAll();
        },
      });
    }
  };

  let columnKeys;
  if (data && data.data && data.data.length > 0) {
    const keys = Object.keys(data.data[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
  }

  let tableData = [];
  if (data && data.data && data.data.length > 0) {
    tableData = data.data.map((item) => {
      item.createdAt = new Date(item.createdAt).toLocaleString('en-US');
      item.updatedAt = new Date(item.updatedAt).toLocaleString('en-US');
      return item;
    });
  }

  return (
    <div style={{ padding: '8px' }}>
      <div className='actions-block'>
        <Link to='/admin/new-website'>
          <Button type='primary'>New website</Button>
        </Link>
      </div>

      <div style={{ textAlign: 'center' }}>
        {data && data.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {data && !data.isLoading && !data.isError && data.data.length > 0 && (
          <WebsiteTable
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
            data={tableData}
            deleteHandler={deleteDataHandler}
            columnKeys={columnKeys}
            title='Website'
            editPath='/admin/edit-website/'
          />
        )}
        {data && !data.isLoading && !data.isError && data.data.length === 0 && <h2>NO WEBSITES</h2>}
        {data && !data.isLoading && data.isError && <h2>ERROR FETCHING WEBSITES</h2>}
      </div>
    </div>
  );
};

export default Websites;
