import React, { useEffect, useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, notification, Layout, Spin } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { WebsiteForm } from '../../components/forms';

const EditWebsite = (props) => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [isWorking, setWorking] = useState(false);
  const [careers, fetchCareers] = useAxios('', [], currentuser.data.token, 'get');
  const [ipedsTopLists, fetchIpedsTopLists] = useAxios('', [], currentuser.data.token, 'get');
  const [website, fetchWebsite] = useAxios('', {}, currentuser.data.token, 'get');
  const [topListEdit, fetchTopListEdit] = useAxios('', [], currentuser.data.token, 'get');
  const { id, topListId, eatTopListId } = props.match.params;

  useEffect(() => {
    fetchCareers(`${SERVER_URL}/careers-slim${id ? '?website=' + id : ''}`, []);
    fetchIpedsTopLists(`${SERVER_URL}/ipeds-toplists-slim${id ? '?website=' + id : ''}`, []);
    if (id) fetchWebsite(`${SERVER_URL}/websites/${id}`, {});
    if (topListId) fetchTopListEdit(`${SERVER_URL}/top-list/${topListId}`, {});
  }, [fetchWebsite, fetchCareers, fetchIpedsTopLists, id, topListId, fetchTopListEdit, eatTopListId]);

  const onSubmit = async (formData, isNew) => {
    const data = { ...formData };
    const method = isNew ? 'post' : 'put';
    const route = isNew ? `${SERVER_URL}/websites` : `${SERVER_URL}/websites/${data._id}`;

    try {
      await Axios[method](route, data, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: `Website ${isNew ? 'created.' : 'updated.'}`,
        placement: 'bottomRight',
      });

      history.push('/admin/websites');
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message;
      console.log(msg);
      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  /**
   * top list WP sync handler
   *
   * @param {String} listId - top list mongo id
   * @param {String} type - top list type - eat|commercial
   */
  const handleSyncTopList = async (listId, type) => {
    let route = '';
    if (type === 'commercial') {
      route = `${SERVER_URL}/top-list-sync/${listId}`;
    }
    if (type === 'eat') {
      route = `${SERVER_URL}/eat-top-list-sync/${listId}`;
    }
    try {
      await Axios.post(
        route,
        { website: { url: website.data.url, username: website.data.username, password: website.data.password } },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );

      return true;
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message ? error.message : error;
      console.log(msg);
      throw new Error(msg);
    }
  };

  /**
   * save/update top list to CMS db
   *
   * @param {Object} formData - top list data
   * @param {Boolean} isNew
   * @param {String} type - top list type - eat|commercial
   */
  const addTopList = async (formData, isNew, type) => {
    setWorking(true);
    const data = { ...formData };
    if (website && website.data) data.website = website.data._id;

    const method = isNew ? 'post' : 'put';
    let route = '';
    if (type === 'commercial') {
      route = isNew ? `${SERVER_URL}/top-list?websiteId=${id}` : `${SERVER_URL}/top-list/${data._id}`;
    }
    if (type === 'eat') {
      route = isNew ? `${SERVER_URL}/eat-top-list?websiteId=${id}` : `${SERVER_URL}/eat-top-list/${data._id}`;
    }

    try {
      const resp = await Axios[method](route, data, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: `List ${isNew ? 'created.' : 'updated.'}`,
        placement: 'bottomRight',
      });

      // after Top list is saved to CMS db synchronize with WP websites
      if ([200, 201].includes(resp.status)) {
        const listId = resp.data.id ? resp.data.id : data._id;

        const sync = await handleSyncTopList(listId, type);

        if (sync) {
          notification.success({
            message: 'List synced.',
            placement: 'bottomRight',
          });
        } else {
          throw new Error('Sync failed');
        }
      } else {
        throw new Error(resp.statusText);
      }

      let returnRoute = '';
      if (type === 'commercial') {
        returnRoute = `/admin/edit-website/${id}/list-top-list`;
      }
      if (type === 'eat') {
        returnRoute = `/admin/edit-website/${id}/list-eat-top-list`;
      }
      history.push('/admin');
      history.push(returnRoute);
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message;
      console.log(msg);
      notification.error({
        message: msg,
        placement: 'bottomRight',
        duration: 8,
      });
    }
    setWorking(false);
  };

  /**
   * delete top list from CMS db
   *
   * @param {String} listId - top list mongo Id
   * @param {Boolean} wpSync - WP sync required
   * @param {String} type - top list type - eat|commercial
   */
  const deleteTopList = async (listId, wpSync, type) => {
    setWorking(true);
    let route = '';
    let returnRoute = '';
    if (type === 'commercial') {
      route = `${SERVER_URL}/top-list/${listId}?websiteId=${id}`;
      returnRoute = `/admin/edit-website/${id}/list-top-list`;
    }
    if (type === 'eat') {
      route = `${SERVER_URL}/eat-top-list/${listId}?websiteId=${id}`;
      returnRoute = `/admin/edit-website/${id}/list-eat-top-list`;
    }

    try {
      const resp = await Axios.delete(route, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: resp.data ? resp.data.message : 'List deleted from CMS.',
        placement: 'bottomRight',
      });

      if (!wpSync) {
        history.push('/admin');
        history.push(returnRoute);
      }
    } catch (err) {
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
      });
    }

    // delete top list from WP website
    if (wpSync) {
      let deleteRoute = '';
      if (type === 'commercial') {
        deleteRoute = `${SERVER_URL}/top-list-sync/${listId}?websiteId=${id}`;
      }
      if (type === 'eat') {
        deleteRoute = `${SERVER_URL}/eat-top-list-sync/${listId}?websiteId=${id}`;
      }

      try {
        const resp = await Axios.delete(deleteRoute, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });

        notification.success({
          message: resp.data ? resp.data.message : 'List deleted from website.',
          placement: 'bottomRight',
        });

        history.push('/admin');
        history.push(returnRoute);
      } catch (err) {
        notification.error({
          message: 'Problem with delete. Please try later.',
          placement: 'bottomRight',
        });
      }
    }
    setWorking(false);
  };

  /**
   * Synchronize all top lists
   *
   * @param {String} type list type - eat|commercial
   */
  const syncTopList = async (type) => {
    setWorking(true);
    let syncRoute = '';
    if (type === 'commercial') {
      syncRoute = `${SERVER_URL}/top-list-sync-all?websiteId=${id}`;
    }

    if (type === 'eat') {
      syncRoute = `${SERVER_URL}/eat-top-list-sync-all?websiteId=${id}`;
    }

    try {
      const resp = await Axios.post(
        syncRoute,
        {
          website: {
            _id: website.data._id,
            url: website.data.url,
            username: website.data.username,
            password: website.data.password,
          },
        },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );
      console.log('syncTopList :: response =', resp);
      notification.success({
        message: 'Lists synchronized.',
        placement: 'bottomRight',
      });
    } catch (error) {
      console.log('syncTopList :: ', error);
      notification.error({
        message: 'Problem with synchronization. Please try later.',
        placement: 'bottomRight',
      });
    }
    setWorking(false);
  };

  /**
   * careers widgets WP sync handler
   *
   * @param {[String]} careers - list of careers ids to sync
   */
  const syncCareerWidgets = async (careers) => {
    setWorking(true);
    try {
      await Axios.post(
        `${SERVER_URL}/career-sync`,
        {
          careers,
          website: { url: website.data.url, username: website.data.username, password: website.data.password },
        },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );

      setWorking(false);
      notification.success({
        message: 'Careers widgets synchronized.',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push(`/admin/edit-website/${id}/list-career-widgets`);
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message ? error.message : error;
      console.log(msg);
      setWorking(false);
      notification.error({
        message: 'Problem with synchronization. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  /**
   * delete careers widgets WP handler
   *
   * @param {[String]} careers - list of careers ids to sync
   */
  const syncDeleteCareerWidgets = async (career) => {
    setWorking(true);
    // console.log([career]);
    // setWorking(false);

    try {
      await Axios.post(
        `${SERVER_URL}/career-delete-sync`,
        {
          careers: [career],
          website: { url: website.data.url, username: website.data.username, password: website.data.password },
        },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );

      setWorking(false);
      notification.success({
        message: 'Careers widgets deleted.',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push(`/admin/edit-website/${id}/list-career-widgets`);
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message ? error.message : error;
      console.log(msg || error);
      setWorking(false);
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  /**
   * degree widgets WP sync handler
   *
   * @param {[String]} toplists - list of toplist (with other degree widgets) ids to sync
   */
  const syncDegreeWidgets = async (toplists, method) => {
    setWorking(true);
    try {
      await Axios.post(
        `${SERVER_URL}/degree-widgets-sync?method=${method}`,
        {
          IPEDSTopLists: toplists,
          website: { url: website.data.url, username: website.data.username, password: website.data.password },
        },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );

      setWorking(false);
      notification.success({
        message: 'Degree widgets synchronized.',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push(`/admin/edit-website/${id}/list-degree-widgets`);
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message ? error.message : error;
      console.log(msg);
      setWorking(false);
      notification.error({
        message: 'Problem with synchronization. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  /**
   * delete degree widgets WP handler
   *
   * @param {[String]} toplistid - list of IPEDS top list ids to sync
   */
  const syncDeleteDegreeWidgets = async (toplistid) => {
    setWorking(true);

    try {
      await Axios.post(
        `${SERVER_URL}/degree-widgets-delete-sync`,
        {
          topListIds: [toplistid],
          website: { url: website.data.url, username: website.data.username, password: website.data.password },
        },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );

      setWorking(false);
      notification.success({
        message: 'Degree widgets deleted.',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push(`/admin/edit-website/${id}/list-degree-widgets`);
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message ? error.message : error;
      console.log(msg || error);
      setWorking(false);
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  const isDataFetched = !website.isLoading && website.data && !careers.isLoading && careers.data && careers.data.items;

  return (
    <Spin spinning={isWorking} tip='Working...' size='large'>
      <div style={{ padding: '8px' }}>
        <div className='actions-block'>
          <Link to='/admin/websites'>
            <Button type='primary'>All websites</Button>
          </Link>
        </div>

        <div className='content-wrapper'>
          <div className='content'>
            <Layout className='site-layout-background'>
              {!isDataFetched && (
                <div style={{ textAlign: 'center' }}>
                  <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />
                </div>
              )}

              {!id && isDataFetched && (
                <WebsiteForm
                  isNew={true}
                  careers={careers.data}
                  onSubmit={onSubmit}
                  setWorking={setWorking}
                  SERVER_URL={SERVER_URL}
                  token={currentuser.data.token}
                />
              )}

              {id && isDataFetched && (
                <WebsiteForm
                  isNew={false}
                  newList={props.location.pathname.includes('new-')}
                  editList={topListId !== undefined || eatTopListId !== undefined}
                  website={website.data}
                  careers={careers.data?.items}
                  ipedsTopLists={ipedsTopLists?.data?.items}
                  syncCareerWidgets={syncCareerWidgets}
                  syncDeleteCareerWidgets={syncDeleteCareerWidgets}
                  syncDegreeWidgets={syncDegreeWidgets}
                  syncDeleteDegreeWidgets={syncDeleteDegreeWidgets}
                  topListId={topListId}
                  topList={website.data.topLists}
                  eatTopLists={website.data.eatTopLists}
                  topListEdit={topListEdit}
                  addTopList={addTopList}
                  syncTopList={syncTopList}
                  onSubmit={onSubmit}
                  deleteTopList={deleteTopList}
                  setWorking={setWorking}
                  SERVER_URL={SERVER_URL}
                  token={currentuser.data.token}
                />
              )}
            </Layout>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default EditWebsite;
