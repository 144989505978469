import React, { useState, useEffect } from 'react';
import { Button, Input, Switch, notification, Form, Divider, Spin, Select, Tooltip, Checkbox, message } from 'antd';
import Axios from 'axios';
import { SERVER_URL } from '../../../config';
import { QuestionCircleFilled, InfoCircleFilled } from '@ant-design/icons';
import CKEditor from '../../../components/base/CKEditor';
import SCHOOLS_WITH_DUPLICATE_NAMES from '../../../lib/duplicateNamesSchools.json';

const getCarnegieName = (carnegieClass) => {
  if (carnegieClass === 15 || carnegieClass === 16 || carnegieClass === 17) return 'national university.';
  if (carnegieClass === 18 || carnegieClass === 19 || carnegieClass === 20 || carnegieClass === 22)
    return 'regional college.';
  if (carnegieClass === 21) return 'liberal arts college.';
};

const addNumberSuffix = (number) => {
  const j = number % 10,
    k = number % 100;
  if (j === 1 && k !== 11) {
    return number + 'st';
  }
  if (j === 2 && k !== 12) {
    return number + 'nd';
  }
  if (j === 3 && k !== 13) {
    return number + 'rd';
  }
  return number + 'th';
};

const Admin = ({
  otherData,
  name,
  website,
  id,
  token,
  unitid,
  carnegieClass,
  rank,
  isMain,
  branches,
  degreePremium,
  isBranch,
  websiteURL,
  slug,
  earnLessThenHS,
  earnings,
  hsSalary,
}) => {
  const [form] = Form.useForm();
  const [schoolName, setSchoolName] = useState(otherData && otherData.name);
  const [schoolLogo, setSchoolLogo] = useState(otherData && otherData.logo);
  const [schoolLink, setSchoolLink] = useState(otherData && otherData.link);
  const [schoolNickname, setSchoolNickname] = useState(otherData && otherData.nickname);
  const [metaTitle, setMetaTitle] = useState(otherData && otherData.metaTitle);
  const [metaDescription, setMetaDescription] = useState(otherData && otherData.metaDescription);
  const [metaImage, setMetaImage] = useState(otherData && otherData.metaImage);
  const [h1, setH1] = useState(otherData && otherData.h1);
  const [schoolOpen, setSchoolClosed] = useState(otherData ? !otherData.closed : true);
  const [disabledProfile, setDisabledProfile] = useState(otherData && otherData.disabledProfile);
  const [fetchTrigger, setFetchTrigger] = useState(false);
  const [working, setWorking] = useState(false);
  const [institutions, setInstitutions] = useState([]);
  const [schoolBranches, setSchoolBranches] = useState(branches);
  const [tags, setTags] = useState(otherData && otherData.tags ? otherData.tags.map((tag) => tag._id) : []);
  const [allTags, setAllTags] = useState([]);
  const [content, setContent] = useState(otherData && otherData.widgetDesc ? otherData.widgetDesc : '');
  const [dashboardHeading, setDashboardHeading] = useState(
    otherData && otherData.dashboard && otherData.dashboard.dashboardHeading,
  );
  const [dashboardText, setDashboardText] = useState(
    otherData && otherData.dashboard && otherData.dashboard.dashboardText ? otherData.dashboard.dashboardText : '',
  );
  const [featureImage, setFeatureImage] = useState(otherData && otherData.featureImage);

  isMain = isMain === 1 ? true : false;

  // const urlSlug = name
  //   .split(' ')
  //   .join('-')
  //   .replace(/-campus/gi, '')
  //   .replace(/&/gi, 'and')
  //   .replace(/-main$/gi, '')
  //   .replace('.', '')
  //   .replace('/', '-')
  //   .replace('-Anschutz-Medical', '')
  //   .replace("'", '')
  //   .toLowerCase();

  const formData = {
    name: schoolName ? schoolName : name,
    nickname: schoolNickname ? schoolNickname : schoolName ? schoolName : name,
    link: schoolLink ? schoolLink : website,
    logo: schoolLogo,
    open: schoolOpen,
    metaTitle: metaTitle ? metaTitle : schoolName ? `${schoolName} rankings` : `${name} rankings`,
    metaDescription: metaDescription
      ? metaDescription
      : `Want to go to ${schoolName ? schoolName : name}? We reveal earnings data of ${
          schoolNickname ? schoolNickname : schoolName ? schoolName : name
        } grads to help you make a smarter investment. ${
          rank
            ? schoolName
              ? schoolName + ' is the ' + addNumberSuffix(rank) + ' ranked ' + getCarnegieName(carnegieClass)
              : name + ' is the ' + addNumberSuffix(rank) + ' ranked ' + getCarnegieName(carnegieClass)
            : ''
        }`,
    metaImage: metaImage ? metaImage : schoolLogo,
    h1: h1 ? h1 : schoolName ? schoolName : name,
    branches: schoolBranches,
    tags,
    disabledProfile,
    widgetDesc: content,
    featureImage,
    dashboardText,
    dashboardHeading,
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setWorking(true);
        if (isMain) {
          const res = await Axios.get(`${SERVER_URL}/all-branch-school-profiles-names`, {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` },
          });

          if (res) {
            setInstitutions(res.data);
          }
        }

        const resTags = await Axios.get(`${SERVER_URL}/rankings-tags`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${token}` },
        });

        if (resTags) {
          setAllTags(resTags.data);
        }

        setWorking(false);
      } catch (error) {
        setWorking(false);
        console.log('Error on fetch institutions');
        notification.error({
          message: 'Error',
          placement: 'bottomRight',
          description: 'Failed to load school names',
        });
      }
    };
    fetchData();
  }, [token]);

  useEffect(() => {
    form.setFieldsValue({
      ...formData,
    });
  }, [
    fetchTrigger,
    schoolName,
    schoolNickname,
    schoolLogo,
    featureImage,
    schoolLink,
    schoolOpen,
    disabledProfile,
    metaTitle,
    metaDescription,
    metaImage,
    h1,
    tags,
    schoolBranches,
    content,
    dashboardHeading,
    dashboardText,
    form,
  ]);

  const handleSave = async (values) => {
    let tagsToSend = [];
    values.tags.forEach((tag) => {
      const foundTag = allTags.find((el) => el._id === tag);
      if (foundTag) tagsToSend.push({ name: foundTag.name, iconUrl: foundTag.iconUrl, _id: foundTag._id });
    });

    if (values.dashboardHeading || values.dashboardText) {
      values.dashboard = { dashboardHeading: values.dashboardHeading, dashboardText: values.dashboardText };
    }

    values = { ...values, unitid, tags: tagsToSend };
    try {
      setWorking(true);
      await Axios.patch(
        `${SERVER_URL}/all-school-profiles/${id}`,
        { values },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      setWorking(false);
      setFetchTrigger(!fetchTrigger);

      notification.success({
        message: 'School data updated',
        placement: 'bottomRight',
      });
    } catch (error) {
      setWorking(false);
      notification.error({
        message: 'Failed to update the data',
        placement: 'bottomRight',
      });
    }
  };

  const handleCopySPLink = (textToCopy) => {
    if (textToCopy) {
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          message.success('Text copied to clipboard');
        })
        .catch((err) => {
          message.error('Failed to copy text to clipboard');
          console.error(err);
        });
    } else {
      message.error('No text to copy');
    }
  };

  return (
    <div className='panel-body'>
      <Spin spinning={working} tip='Working...'>
        <Form
          form={form}
          name='TopListItemModal'
          layout='horizontal'
          initialValues={formData}
          onFinish={(values) => handleSave(values)}
        >
          {unitid === 217873 && (
            <p>
              <InfoCircleFilled style={{ fontSize: '17px' }} />
              <strong>This school is removed from rankings and dashboard (hardcoded)!</strong>
            </p>
          )}

          <Form.Item label='Name' name='name'>
            <Input onChange={(e) => setSchoolName(e.target.value)} />
          </Form.Item>

          <Form.Item label='Nickname' name='nickname'>
            <Input onChange={(e) => setSchoolNickname(e.target.value)} />
          </Form.Item>

          <Form.Item label='Logo' name='logo'>
            <Input onChange={(e) => setSchoolLogo(e.target.value)} />
          </Form.Item>

          <Form.Item
            label={
              <>
                <Tooltip title='Image link shown on school profile comparison tool and most popular schools in state on school profile page'>
                  <QuestionCircleFilled /> Feature Image
                </Tooltip>
              </>
            }
            name='featureImage'
          >
            <Input onChange={(e) => setFeatureImage(e.target.value)} />
          </Form.Item>

          <Form.Item label='External School Link' name='link'>
            <Input onChange={(e) => setSchoolLink(e.target.value)} />
          </Form.Item>

          <Form.Item
            label={
              <>
                <Tooltip title='Indicates wheather or not a school is open (working) or closed (shut down). If its closed, the school will not appear on any ranking pages. After this option is set to closed, you must refresh sitemap on all ranking pages (or just the ones you know school appears in). If its set to open, you must refresh all rankings.'>
                  <QuestionCircleFilled /> Open
                </Tooltip>
              </>
            }
            name='open'
          >
            <Switch defaultChecked={schoolOpen} checked={schoolOpen} onChange={(checked) => setSchoolClosed(checked)} />
          </Form.Item>

          <Form.Item
            label={
              <>
                <Tooltip title='Select tags for this school to appear on ranking cards'>
                  <QuestionCircleFilled /> Tags
                </Tooltip>
              </>
            }
            name='tags'
          >
            <Select
              mode='multiple'
              placeholder='Select tags for this school to appear on ranking cards...'
              allowClear
              showSearch
              onChange={(value) => setTags(value)}
            >
              {allTags.map((tag) => (
                <Select.Option key={tag._id} value={tag._id} title={tag.name}>
                  {tag.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <div style={{ display: 'flex' }}>
            <Form.Item
              label={
                <>
                  <Tooltip
                    title={
                      !degreePremium
                        ? "Can't enable a school with no economic score."
                        : isBranch || !isMain
                        ? `Can't enable branch schools.`
                        : SCHOOLS_WITH_DUPLICATE_NAMES.includes(name)
                        ? 'School has the same name as at least one other school (duplicate URL slugs).'
                        : 'Disable school profile. The school will no longer have a profile page.'
                    }
                  >
                    <QuestionCircleFilled /> Disable Profile
                  </Tooltip>
                </>
              }
              name='disabledProfile'
              valuePropName='checked'
            >
              <Checkbox
                onChange={(e) => setDisabledProfile(e.target.checked)}
                disabled={
                  !degreePremium ||
                  (!degreePremium.rankingScore && !earnLessThenHS) ||
                  isBranch ||
                  !isMain ||
                  SCHOOLS_WITH_DUPLICATE_NAMES.includes(name)
                }
              />
            </Form.Item>
            {!disabledProfile && (
              <>
                <Tooltip title='Click to copy'>
                  <Button
                    style={{ marginLeft: '10px', textTransform: 'lowercase' }}
                    type='dashed'
                    onClick={() => handleCopySPLink(`${websiteURL}/${slug}/`)}
                  >{`${websiteURL}/${slug}/`}</Button>
                </Tooltip>
              </>
            )}

            {earnLessThenHS && earnings && (
              <Tooltip
                title={`This school has its earnings (${earnings}) less then high school earnings (${hsSalary}).`}
              >
                <InfoCircleFilled style={{ fontSize: '17px' }} />
              </Tooltip>
            )}
          </div>

          <Form.Item
            label={
              <Tooltip title='This text will show under the first (info) box widget on school profile page.'>
                <QuestionCircleFilled /> Widget text
              </Tooltip>
            }
            name='widgetDesc'
          >
            <CKEditor content={content} setContent={setContent} />
          </Form.Item>

          {/* <Divider>performance dashboard</Divider>

          <Form.Item
            label={
              <Tooltip title='This heading will show under the most compared schools section on school performance dashboard.'>
                <QuestionCircleFilled /> Dashboard heading
              </Tooltip>
            }
            name='dashboardHeading'
          >
            <Input onChange={(e) => setDashboardHeading(e.target.value)} />
          </Form.Item>

          <Form.Item
            label={
              <Tooltip title='This text will show under the most compared schools section on school performance dashboard.'>
                <QuestionCircleFilled /> Dashboard text
              </Tooltip>
            }
            name='dashboardText'
          >
            <CKEditor content={dashboardText} setContent={setDashboardText} />
          </Form.Item> */}

          <Divider>meta data</Divider>

          <Form.Item label='Meta Title' name='metaTitle'>
            <Input onChange={(e) => setMetaTitle(e.target.value)} />
          </Form.Item>

          <Form.Item label='Meta Description' name='metaDescription'>
            <Input onChange={(e) => setMetaDescription(e.target.value)} />
          </Form.Item>

          <Form.Item label='Meta Image' name='metaImage'>
            <Input onChange={(e) => setMetaImage(e.target.value)} />
          </Form.Item>

          <Form.Item label='H1' name='h1'>
            <Input onChange={(e) => setH1(e.target.value)} />
          </Form.Item>

          {isMain && (
            <>
              <Divider>branches</Divider>

              <Form.Item label='Branches' name='branches'>
                <Select
                  mode='multiple'
                  placeholder='Select branches for this main campus'
                  allowClear
                  showSearch
                  onChange={(value) => setSchoolBranches(value)}
                >
                  {institutions.map((inst, index) => (
                    <Select.Option
                      key={`${inst.unitid}_${index}`}
                      value={inst.unitid}
                      title={`${inst.unitid} - ${inst.name}`}
                      style={{ fontSize: '13px' }}
                    >
                      {inst.unitid} - {inst.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          )}

          <Form.Item>
            <Button type='primary' htmlType='submit'>
              SAVE
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

export default Admin;
