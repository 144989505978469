import React, { useState, useEffect, useContext, useRef } from 'react';
import { Table, Button, notification, Tooltip, Input, Modal, Spin, Tabs, Select, List, Divider, Space } from 'antd';
import Axios from 'axios';
import { UserContext } from '../../App';
import { SERVER_URL } from '../../config';
import STATES from '../../lib/states.json';
import { useHistory, useLocation } from 'react-router-dom';
import { EditOutlined, CheckOutlined, DeleteOutlined, SearchOutlined, SyncOutlined } from '@ant-design/icons';
import CKEditor from '../../components/base/CKEditor';

const { TextArea } = Input;
const { TabPane } = Tabs;
const { confirm } = Modal;

const REGIONALS = ['south', 'northeast', 'west', 'midwest'];

const formInit = {
  title: '',
  content: '',
  listName: '',
  metaTitle: '',
  metaDescription: '',
  metaUrl: '',
  metaImage: null,
};

const StateRankingsContent = () => {
  const currentuser = useContext(UserContext);
  const search = useLocation().search;
  const pageUrl = new URLSearchParams(search).get('page');
  const history = useHistory();
  const [title, setTitle] = useState('');
  const [listName, setListName] = useState('');
  const [page, setPage] = useState(1);
  const [content, setContent] = useState('');
  const [metaTitle, setMetaTitle] = useState('');
  const [metaDescription, setMetaDescription] = useState('');
  const [metaUrl, setMetaUrl] = useState('');
  const [metaImage, setMetaImage] = useState(null);
  const [comboH1, setComboH1] = useState('');
  const [comboTitle, setComboTitle] = useState('');
  const [rankingStateContents, setRankingStateContents] = useState([]);
  const [modal, setModal] = useState({ visible: false, state: '', abbr: '', id: '', rankingCombinations: [] });
  const [working, setWorking] = useState(false);
  const [fetchTrigger, setFetchTrigger] = useState(false);
  const [ranking, setRanking] = useState(null);
  const [rankingDropdown, setRankingDropdown] = useState([]);
  const titleInput = useRef();

  let searchInput;
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size='small'
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  useEffect(() => {
    const fetchContents = async () => {
      setWorking(true);
      const data = await Axios.get(`${SERVER_URL}/rankings-contents?type=state`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      if (data) {
        setRankingStateContents(data.data);
        setPage(pageUrl ? pageUrl : 1);
      }
      setWorking(false);
    };
    fetchContents();
  }, [fetchTrigger, currentuser.data.token]);

  let tableData = [];
  if (rankingStateContents && rankingStateContents?.length > 0) {
    tableData = rankingStateContents
      .filter(
        (item) =>
          item.state !== 'Marshall Islands' &&
          item.state !== 'Guam' &&
          item.state !== 'Northern Mariana Islands' &&
          item.state !== 'Palau' &&
          item.state !== 'Puerto Rico' &&
          item.state !== 'Virgin Islands',
      )
      .map((item) => item);
  }

  const columns = [
    {
      title: 'STATE',
      dataIndex: 'state',
      key: 'state',
      ...getColumnSearchProps('state'),
    },
    {
      title: 'ABBREVIATION',
      dataIndex: 'abbr',
      key: 'abbr',
      ...getColumnSearchProps('abbr'),
    },
    {
      title: 'TITLE',
      dataIndex: 'title',
      key: 'title',
      ...getColumnSearchProps('title'),
    },
    {
      title: 'NUMBER OF SCHOOLS',
      dataIndex: 'numberOfSchools',
      key: 'numberOfSchools',
    },
    {
      title: 'UPDATED AT',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text, record) => new Date(record.updatedAt).toLocaleString('en-US'),
    },
  ];

  columns.push(
    {
      title: 'Content',
      render: (text, record) => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '15px',
            }}
          >
            <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
              <div
                style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}
                className='table-actions'
                onClick={async () => editHandler(record)}
              >
                <Tooltip title='Edit Content' placement='topLeft'>
                  <EditOutlined style={{ textDecoration: 'none', color: 'black' }} />
                </Tooltip>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: 'New Content',
      render: (text, record) => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '15px',
            }}
          >
            <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
              <Tooltip title='Edit new design content'>
                <EditOutlined
                  style={{ textDecoration: 'none', color: 'black' }}
                  onClick={() => history.push(`/admin/rankings-state-content/${record.state}`)}
                />
              </Tooltip>

              {record.useNewTemplate && (
                <Tooltip title='Using new design'>
                  <CheckOutlined style={{ color: 'green', marginLeft: '10px' }} />
                </Tooltip>
              )}
            </div>
          </div>
        );
      },
    },
    {
      title: 'School List',
      render: (text, record) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '15px',
          }}
        >
          <Tooltip title={`View ${record.state} schools`} placement='topRight'>
            <div
              style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}
              className='table-actions'
              onClick={() => history.push(`/admin/rankings/view-state-items/${record.state}`)}
            >
              <EditOutlined style={{ textDecoration: 'none', color: 'black' }} />
            </div>
          </Tooltip>
        </div>
      ),
    },
  );

  const editHandler = async (record) => {
    setWorking(true);

    setModal({
      visible: true,
      state: record.state,
      abbr: record.abbr,
      id: record._id,
      rankingCombinations: record.rankingCombinations,
    });
    setTitle(record.title);
    setListName(record.listName !== '' ? record.listName : record.state);
    setContent(record.content);
    setMetaTitle(record.metaTitle);
    setMetaDescription(record.metaDescription);
    setMetaUrl(record.metaUrl);
    setMetaImage(record.metaImage);

    try {
      const rankingDropdownRes = await Axios.get(`${SERVER_URL}/get-dropdown-bubble?state=${record.state}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      if (rankingDropdownRes?.data && rankingDropdownRes?.data?.length > 0) {
        const filterRes = rankingDropdownRes.data.filter(
          (item) => !REGIONALS.includes(item.category) && item.additional !== 'starting-pay',
        );
        setRankingDropdown(filterRes);
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: 'Error',
        description: 'Failed to load ranking dropdown data',
        placement: 'bottomRight',
      });
      setWorking(false);
    }

    setWorking(false);
  };

  const handleSave = async () => {
    if (rankingStateContents && rankingStateContents.length > 0) return;
    let body = [];
    STATES.forEach((state) => {
      body.push({
        state: state.name,
        abbr: state.abbreviation,
        title: formInit.title,
        content: formInit.content,
        listName: state.name,
        metaTitle: formInit.metaTitle,
        metaDescription: formInit.metaDescription,
        metaUrl: formInit.metaUrl,
        metaImage: formInit.metaImage,
      });
    });
    try {
      await Axios.post(`${SERVER_URL}/rankings-contents?type=state`, body, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: 'Rankings contents saved.',
        placement: 'bottomRight',
      });

      history.push('/admin');
      history.push('/admin/rankings-contents');
    } catch (error) {
      console.log(error);
      notification.error({
        message: 'Problem with creating.',
        placement: 'bottomRight',
      });
    }
  };

  const onCancel = () => {
    setModal({ visible: false });
    window.location.reload();
  };

  const onOk = async () => {
    if (!title || title === '') {
      titleInput.current.focus();
      notification.error({
        message: '<h1> title is required.',
        placement: 'topRight',
      });
      return;
    }

    const body = {
      state: modal.state,
      abbr: modal.abbr,
      title,
      content,
      listName,
      metaTitle,
      metaDescription,
      metaUrl,
      metaImage,
      rankingCombinations: modal.rankingCombinations,
    };
    try {
      setWorking(true);
      await Axios.patch(`${SERVER_URL}/rankings-state-content-old/${modal.state}`, body, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: 'Rankings contents saved.',
        placement: 'bottomRight',
      });

      // setFetchTrigger(!fetchTrigger);
      setWorking(false);
      history.push('/admin');
      history.push(`/admin/rankings/undergraduate?tab=STATES`);
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Problem with creating.',
        placement: 'bottomRight',
      });
    }
    setModal({ visible: false });
  };

  const handleAddCombo = () => {
    const foundRanking = rankingDropdown.find((item) => item.name === ranking);

    if (foundRanking) {
      let arr = modal?.rankingCombinations?.length > 0 ? [...modal.rankingCombinations] : [];

      if (
        arr.find((item) => {
          if (item.degree && item.cipcode) {
            return (
              item.degree === foundRanking.degree &&
              item.cipcode === foundRanking.cipCode &&
              item.online === foundRanking.isOnline
            );
          } else if (!item.degree && !item.cipcode) {
            return item.additional === foundRanking.additional && item.category === foundRanking.category;
          }
          return;
        })
      ) {
        notification.error({
          message: 'Error',
          description: 'That ranking combination already created',
          placement: 'bottomRight',
        });
        return;
      }

      setModal({
        ...modal,
        rankingCombinations: [
          ...arr,
          {
            cipcode: foundRanking.cipCode,
            degree: foundRanking.degree,
            online: foundRanking.isOnline,
            parent: foundRanking.parent,
            category: foundRanking.category,
            additional: foundRanking.additional,
            name: foundRanking.name,
            comboH1,
            comboTitle,
          },
        ],
      });

      setRanking(null);
      setComboH1('');
      setComboTitle('');
    } else {
      notification.error({
        message: 'Error',
        description: 'Problem with the selected ranking',
        placement: 'bottomRight',
      });
      return;
    }
  };

  const handleRemove = (name) => {
    confirm({
      title: `Are you sure you want to remove this ranking + state cmbination (${name})?`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        const oldCombinations = [...modal.rankingCombinations];
        const newCombinations = oldCombinations.filter((item) => item.name !== name);
        setModal({ ...modal, rankingCombinations: [...newCombinations] });
      },
    });
  };

  const handleRefresh = async () => {
    try {
      setWorking(true);

      await Axios.get(`${SERVER_URL}/rankings-create-state-pages`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      setFetchTrigger(!fetchTrigger);
      setWorking(false);
      notification.success({
        message: 'State Rankings refreshed',
        placement: 'bottomRight',
      });
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Error',
        description: 'Problem with refreshing rankings. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  return (
    <div className='content-wrapper'>
      <Spin spinning={working}>
        <div className='actions-block flex'>
          {!rankingStateContents ||
            (rankingStateContents.length === 0 && (
              <Tooltip title='Create initial state contents for rankings'>
                <Button type='primary' style={{ marginRight: '8px', float: 'right' }} onClick={handleSave}>
                  CREATE INITIAL CONTENTS
                </Button>
              </Tooltip>
            ))}

          <Button type='danger' onClick={handleRefresh}>
            <SyncOutlined spin={working} />
            Refresh State Rankings
          </Button>
        </div>
        <Table
          size='middle'
          // onChange={onChangeTable}
          bordered
          dataSource={tableData}
          columns={columns}
          rowKey={(record) => record._id}
          pagination={{
            pageSize: 20,
            current: page,
            onChange: (page) => setPage(page),
          }}
        />
        <Modal
          centered
          width='70%'
          height='100%'
          visible={modal.visible}
          title={<strong>EDIT {modal.state}</strong>}
          okText='SAVE'
          cancelText='CANCEL'
          maskClosable={false}
          onOk={onOk}
          onCancel={onCancel}
          confirmLoading={working}
        >
          <Tabs>
            <TabPane tab='DEFAULT' key='DEFAULT'>
              <div>
                <p style={{ fontSize: 15, marginTop: 20, marginRight: 10 }}>{'<h1> '}</p>
                <Input
                  name='title'
                  value={title}
                  placeholder={'Title for <h1>...'}
                  onChange={(e) => setTitle(e.target.value)}
                  ref={titleInput}
                />
              </div>
              <br></br>
              <div>
                <p style={{ fontSize: 15, marginTop: 20, marginRight: 10 }}>Content </p>
                <CKEditor content={content} setContent={setContent} />
              </div>
              <br></br>
              <div>
                <p style={{ fontSize: 15, marginTop: 20, marginRight: 10 }}>List Name </p>
                <Input
                  name='listName'
                  value={listName}
                  placeholder={'Example List Name'}
                  onChange={(e) => setListName(e.target.value)}
                />
              </div>
              <br></br>
              <div>
                <p style={{ fontSize: 15, marginTop: 20, marginRight: 10 }}>Meta Title</p>
                <Input
                  name='metaTitle'
                  value={metaTitle}
                  onChange={(e) => setMetaTitle(e.target.value)}
                  maxLength={70}
                />
              </div>
              <br></br>
              <div>
                <p style={{ fontSize: 15, marginTop: 20, marginRight: 10 }}>Meta Description</p>
                <TextArea
                  name='metaDescription'
                  value={metaDescription}
                  maxLength={155}
                  rows={3}
                  onChange={(e) => setMetaDescription(e.target.value)}
                />
              </div>
              <br></br>
              <div>
                <p style={{ fontSize: 15, marginTop: 20, marginRight: 10 }}>Meta Url</p>
                <Input name='metaUrl' value={metaUrl} onChange={(e) => setMetaUrl(e.target.value)} />
              </div>
              <br></br>
              <div>
                <p style={{ fontSize: 15, marginTop: 20, marginRight: 10 }}>Meta Image</p>
                <Input name='metaImage' value={metaImage} onChange={(e) => setMetaImage(e.target.value)} />
              </div>
              <br></br>
              <div>
                {metaImage && (
                  <img
                    src={metaImage}
                    style={{
                      width: '200px',
                      border: '0.5px solid #bbb',
                      background: '#eee',
                      borderRadius: '5px',
                      cursor: 'pointer',
                    }}
                    alt='meta'
                    // title='Select from gallery or upload'
                    // onClick={() => logoHandler()}
                  />
                )}
              </div>
            </TabPane>

            <TabPane tab='RANKING COMBINATIONS' key='RANKING COMBINATIONS'>
              <div style={{ marginBottom: '10px' }}>
                <label htmlFor='ranking'>Ranking: </label>
                <Select
                  allowClear
                  showSearch
                  name='ranking'
                  value={ranking}
                  onChange={(value) => setRanking(value)}
                  style={{ width: '500px', marginLeft: '4px', fontSize: '15px' }}
                  placeholder='Select ranking...'
                >
                  {rankingDropdown.map((item) => (
                    <Select.Option key={`${item.name}`} value={item.name}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>

                {ranking ? (
                  <>
                    <br></br>
                    <br></br>

                    <label htmlFor='comboH1'>H1: </label>
                    <Input name='comboH1' value={comboH1} onChange={(e) => setComboH1(e.target.value)} />

                    <br></br>
                    <br></br>

                    <label htmlFor='comboTitle'>Meta Title: </label>
                    <Input name='comboTitle' value={comboTitle} onChange={(e) => setComboTitle(e.target.value)} />

                    <br></br>
                    <br></br>

                    <Button
                      type='default'
                      style={{ backgroundColor: 'black', color: 'white' }}
                      onClick={handleAddCombo}
                    >
                      Add
                    </Button>
                  </>
                ) : (
                  ''
                )}

                <br></br>
                <br></br>

                {modal?.rankingCombinations?.length > 0 ? (
                  <div>
                    <Divider>Created combinations:</Divider>
                    <List
                      itemLayout='horizontal'
                      dataSource={modal.rankingCombinations}
                      renderItem={(item) => (
                        <div>
                          <List.Item
                            actions={[
                              <Button danger type='link' size='small' onClick={() => handleRemove(item.name)}>
                                <DeleteOutlined /> Remove
                              </Button>,
                            ]}
                          >
                            <List.Item.Meta
                              title={item.name}
                              description={
                                <>
                                  <p>H1: {item.comboH1}</p>
                                  <p>Meta Title: {item.comboTitle}</p>
                                </>
                              }
                            />
                          </List.Item>
                        </div>
                      )}
                    />
                  </div>
                ) : (
                  'No ranking combinations set yet...'
                )}
              </div>
            </TabPane>
          </Tabs>
        </Modal>
      </Spin>
    </div>
  );
};

export default StateRankingsContent;
