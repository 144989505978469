import React from 'react';
import { EditOutlined } from '@ant-design/icons';

const UploadBox = ({ handler }) => {
  return (
    <div
      style={{
        width: '100px',
        height: '100px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        border: '0.5px solid #bbb',
        background: '#eee',
        borderRadius: '5px',
        cursor: 'pointer',
      }}
      title='Select from gallery or upload'
      onClick={() => handler()}
    >
      <EditOutlined />
      <div>Edit</div>
    </div>
  );
};

export default UploadBox;
