import React, { useEffect } from 'react';
import { Modal, Form, Input, InputNumber, Button } from 'antd';

const IPEDSTopListItemModal = ({
  visible,
  name,
  link,
  unitid,
  index,
  onSubmit,
  onCancel,
  position,
  handleDeleteSponsored,
  max,
  limit,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    const formData = { link, position };
    form.setFieldsValue({ ...formData });
  }, [link, position, form]);

  return (
    <Modal
      centered
      width={800}
      visible={visible}
      title={
        !position ? (
          <span>
            Edit link for&nbsp;
            <strong>{name}</strong>
          </span>
        ) : (
          <span>
            Edit link and position for&nbsp;
            <strong>{name}</strong> sponsored school
          </span>
        )
      }
      okText='SAVE'
      cancelText='CANCEL'
      onOk={() => {
        const formData = form.getFieldsValue();
        onSubmit({ ...formData }, index);
        form.resetFields();
        onCancel();
        Modal.destroyAll();
      }}
      onCancel={() => {
        form.resetFields();
        onCancel();
        Modal.destroyAll();
      }}
    >
      <Form form={form} name='TopListItemModal' layout='horizontal'>
        {!position ? (
          <Form.Item label='Website link' name='link'>
            <Input />
          </Form.Item>
        ) : (
          <>
            <Form.Item label='Website link' name='link'>
              <Input />
            </Form.Item>
            <Form.Item label='Position' name='position'>
              <InputNumber min={1} max={max ? max : limit} />
            </Form.Item>
            <Button type='secondary' onClick={() => handleDeleteSponsored(unitid)}>
              Delete Sponsored school
            </Button>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default IPEDSTopListItemModal;
