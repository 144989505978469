import React, { useContext, useEffect } from 'react';
import useAxios from '../hooks/useAxios';
import { UserContext } from '../App';
import { SERVER_URL } from '../config';
import { LatestActivityTable } from '../components/tables';

const Home = () => {
  const currentuser = useContext(UserContext);
  const [logs, fetchLogs] = useAxios('', [], currentuser.data.token, 'get');

  useEffect(() => {
    fetchLogs(`${SERVER_URL}/logs`, []);
  });

  return (
    <div className='board'>
      {currentuser.data.role[0] === 'guest' && currentuser.data.role.length === 1 ? (
        <>
          <div>
            <h1>Welcome to Odeon CMS</h1>
          </div>
        </>
      ) : (
        <>
          <div className='actions-block text-center'>
            <span>
              <strong>LATEST ACTIVITIES</strong>
            </span>
          </div>

          <LatestActivityTable activities={logs.data} loading={logs.isLoading} />
        </>
      )}
    </div>
  );
};

export default Home;
