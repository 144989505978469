import React from 'react';
import { Table } from 'antd';

const ValueTable = ({ activities, loading }) => {
  const columns = [
    {
      title: 'Time',
      dataIndex: ['createdAt'],
      key: 'createdAt',
      render: (date) => new Date(date).toLocaleString('en-US'),
    },
    {
      title: 'Activity',
      dataIndex: ['description'],
      key: 'description',
      render: (text) => (text ? text.replace(/_/gi, ' ') : ''),
    },
    {
      title: 'User',
      dataIndex: ['userId', 'email'],
      key: 'userId',
    },
    {
      title: 'User role',
      dataIndex: ['userId', 'role'],
      key: 'role',
      render: (roles) => {
        if (!roles) {
          roles = ['NN'];
        }
        return roles.join(', ');
      },
    },
    {
      title: 'Browser',
      dataIndex: 'browser',
      key: 'browser',
    },
    {
      title: 'OS',
      dataIndex: 'os',
      key: 'os',
    },
  ];

  return (
    <Table
      className='table-striped-rows'
      pagination={false}
      dataSource={activities}
      columns={columns}
      rowKey='_id'
      loading={loading}
    />
  );
};

export default ValueTable;
