import React from 'react';
import { Tooltip, Button, Table, Modal, Select, Input, Collapse } from 'antd';
import { Link } from 'react-router-dom';
import CKEditor from '../../../components/base/CKEditor';
import { PlusCircleOutlined, SyncOutlined } from '@ant-design/icons';
import BubblesList from '../BubblesList';

const { TextArea } = Input;

const OnlineList = ({
  tab,
  tableData,
  columns,
  handleRefresh,
  handleRefreshAll,
  rowSelection,
  selectedForRefresh,
  modal,
  setModal,
  bubbles,
  refresh,
  setBubbles,
  content,
  setContent,
  working,
  fetchTrigger,
  setFetchTrigger,
  onOk,
  titleInput,
  allSpotlights,
  handleSitemap,
  handleSPLinks,
}) => {
  return (
    <div className='content-wrapper'>
      <div className='actions-block flex'>
        <Link to={`/admin/rankings/new/${tab === 'BACHELORS' ? 'undergraduate' : 'graduate'}?online=${tab}`}>
          <Tooltip title='Create new ranking'>
            <Button type='primary' style={{ marginRight: '8px', float: 'left' }}>
              <PlusCircleOutlined />
              NEW RANKING
            </Button>
          </Tooltip>
        </Link>

        <Tooltip title={tab === 'BACHELORS' ? '/best-online-colleges' : '/best-online-graduate-programs'}>
          <Button type='danger' style={{ marginRight: '8px' }} onClick={handleSitemap}>
            <SyncOutlined spin={working} />
            Refresh Sitemap
          </Button>
        </Tooltip>

        <Button type='danger' style={{ marginRight: '8px' }} onClick={handleSPLinks}>
          <SyncOutlined spin={working} />
          Refresh SP Links
        </Button>

        <Button type='danger' style={{ marginRight: '8px' }} onClick={handleRefreshAll}>
          <SyncOutlined spin={working} />
          Refresh All
        </Button>

        <Button
          type='danger'
          style={{ marginRight: '8px' }}
          onClick={handleRefresh}
          disabled={!selectedForRefresh || selectedForRefresh.length === 0}
        >
          Refresh Selected Ranking(s)
        </Button>
      </div>

      <Table
        size='middle'
        // onChange={onChangeTable}
        bordered
        dataSource={tableData}
        columns={columns}
        rowKey={(record) => record._id}
        rowSelection={rowSelection}
        pagination={{
          pageSize: 20,
          // total: total || 0,
          // current: page,
          // defaultCurrent: 1,
          // position: 'bottom',
          // showSizeChanger: false,
          // onChange: (page) => pageHandler(page),
          // hideOnSinglePage: true,
        }}
      />

      <Modal
        centered
        width='70%'
        visible={modal.visible}
        title={
          <span>
            EDIT -{' '}
            <strong>
              {modal.category} (ONLINE - {tab})
            </strong>
          </span>
        }
        okText='SAVE'
        cancelText='CANCEL'
        maskClosable={false}
        onOk={onOk}
        onCancel={() => {
          setModal({ visible: false });
          setFetchTrigger(!fetchTrigger);
        }}
        confirmLoading={working}
      >
        <div>
          <p style={{ fontSize: 15, marginTop: 20, marginRight: 10 }}>{'<h1> '}</p>
          <Input
            name='title'
            value={modal.title}
            placeholder={'Title for <h1>...'}
            onChange={(e) => setModal({ ...modal, title: e.target.value })}
            ref={titleInput}
          />
        </div>
        <br></br>
        <div>
          <p style={{ fontSize: 15, marginTop: 20, marginRight: 10 }}>Content </p>
          <CKEditor content={content} setContent={setContent} />
        </div>
        <br></br>
        {modal.category !== 'Search' && (
          <>
            <Collapse>
              <Collapse.Panel key='1' header='Bubbles'>
                <BubblesList data={bubbles} handleBubbles={setBubbles} refresh={refresh} />
              </Collapse.Panel>
            </Collapse>
          </>
        )}
        <br></br>
        {modal.category !== 'Search' && (
          <div>
            <p style={{ fontSize: 15, marginTop: 20, marginRight: 10 }}>List Name </p>
            <Input
              name='listName'
              value={modal.listName}
              placeholder={'Example List Name'}
              onChange={(e) => setModal({ ...modal, listName: e.target.value })}
            />
          </div>
        )}
        <br></br>
        <div>
          <p style={{ fontSize: 15, marginTop: 20, marginRight: 10 }}>Meta Title</p>
          <Input
            name='metaTitle'
            value={modal.metaTitle}
            onChange={(e) => setModal({ ...modal, metaTitle: e.target.value })}
            maxLength={70}
          />
        </div>
        <br></br>
        <div>
          <p style={{ fontSize: 15, marginTop: 20, marginRight: 10 }}>Meta Description</p>
          <TextArea
            name='metaDescription'
            value={modal.metaDescription}
            maxLength={180}
            rows={3}
            onChange={(e) => setModal({ ...modal, metaDescription: e.target.value })}
          />
        </div>
        <br></br>
        <div>
          <p style={{ fontSize: 15, marginTop: 20, marginRight: 10 }}>Meta Url</p>
          <Input
            name='metaUrl'
            value={modal.metaUrl}
            onChange={(e) => setModal({ ...modal, metaUrl: e.target.value })}
          />
        </div>
        <br></br>
        <div>
          <p style={{ fontSize: 15, marginTop: 20, marginRight: 10 }}>Meta Image</p>
          <Input
            name='metaImage'
            value={modal.metaImage}
            onChange={(e) => setModal({ ...modal, metaImage: e.target.value })}
          />
        </div>
        <br></br>
        <div>
          {modal.metaImage && (
            <img
              src={modal.metaImage}
              style={{
                width: '200px',
                border: '0.5px solid #bbb',
                background: '#eee',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
              alt='metaImage'
              // title='Select from gallery or upload'
              // onClick={() => logoHandler()}
            />
          )}
        </div>
        <div>
          <p style={{ fontSize: 15, marginTop: 20, marginRight: 10 }}>Bubble Description</p>
          <TextArea
            name='bobbleDescription'
            value={modal.bobbleDescription}
            maxLength={180}
            rows={3}
            onChange={(e) => setModal({ ...modal, bobbleDescription: e.target.value })}
          />
        </div>

        {/* SPOTLIGHT */}
        <div style={{ marginTop: '20px', marginBottom: '10px' }}>
          <label htmlFor='spotlight'>Spotlight: </label>
          <Select
            allowClear
            showSearch
            name='spotlight'
            value={modal.spotlight}
            onChange={(value) => setModal({ ...modal, spotlight: value })}
            style={{ width: '400px', marginLeft: '4px', fontSize: '15px' }}
            placeholder='Select spotlight...'
          >
            {allSpotlights.map((item) => (
              <Select.Option key={`${item._id}`} value={item._id}>
                {item.CMSDisplayName}
              </Select.Option>
            ))}
          </Select>
        </div>

        {/* SHORTCODE */}
        <div style={{ marginTop: '20px', marginBottom: '10px' }}>
          <label htmlFor='shortcode'>Shortcode </label>
          <TextArea
            name='shortcode'
            value={modal.shortcode}
            rows={2}
            onChange={(e) => setModal({ ...modal, shortcode: e.target.value })}
          />
        </div>
      </Modal>
    </div>
  );
};

export default OnlineList;
