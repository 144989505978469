import React, { useState, useEffect } from 'react';
import { Select } from 'antd';

const EMI = ({
  earnings,
  earnings4yr,
  cost,
  ACT,
  payback,
  degreePremium,
  earnings10y,
  costPriv,
  costPub,
  ACT_pell,
  paybackEMI,
  degreePremiumEMI,
  EMI_number,
  EMI_percentile,
  EMI_rank,
  countLow1,
  countLow2,
  countLow3,
  earningsLow2,
  earningsLow3,
  pellPerc,
  costIncome,
}) => {
  const [incomeLevel, setIncomeLevel] = useState(null);
  const [costState, setCostState] = useState(cost);
  const [paybackState, setPaybackState] = useState(payback);
  const [earningsState, setEarningsState] = useState(earnings);
  const [esState, setEsState] = useState(
    degreePremium && degreePremium.rankingScore ? degreePremium.rankingScore : null,
  );

  useEffect(() => {
    const determineValues = () => {
      if (incomeLevel) {
        setCostState(costIncome[incomeLevel].cost);
        setPaybackState(costIncome[incomeLevel].payback);
        setEarningsState(costIncome[incomeLevel].earnings);
        setEsState(costIncome[incomeLevel].es);
      } else {
        setCostState(cost);
        setPaybackState(payback);
        setEarningsState(earnings);
        setEsState(degreePremium && degreePremium.rankingScore ? degreePremium.rankingScore : null);
      }
    };

    determineValues();
  }, [incomeLevel]);

  return (
    <div className='panel-body'>
      <div style={{ display: 'flex' }}>
        <div style={{ marginRight: '150px' }}>
          <Select
            allowClear
            style={{ width: '230px' }}
            placeholder='Different income levels...'
            onChange={(value) => setIncomeLevel(value)}
          >
            <Select.Option key='0k-30k' value='0-30'>
              0k-30k
            </Select.Option>
            <Select.Option key='30k-48k' value='30-48'>
              30k-48k
            </Select.Option>
            <Select.Option key='48k-75k' value='48-75'>
              48k-75k
            </Select.Option>
            <Select.Option key='75k-110k' value='75-110'>
              75k-110k
            </Select.Option>
            <Select.Option key='110k+' value='110-plus'>
              110k+
            </Select.Option>
          </Select>
          <br></br>
          <br></br>

          <h3 style={{ color: 'red' }}>KPI</h3>

          <p>
            <b>Earnings:</b> {earningsState ? earningsState : 'N/A'}
          </p>

          <p>
            <b>Earnings 4 years after completion:</b> {earnings4yr ? earnings4yr : 'N/A'}
          </p>

          <p>
            <b>Cost:</b> {costState ? costState : 'N/A'}
          </p>

          <p>
            <b>Total cost:</b> {costState && ACT ? (costState * ACT).toFixed(2) : 'N/A'}
          </p>

          <p>
            <b>Payback:</b> {paybackState && typeof paybackState === 'number' ? paybackState.toFixed(2) : 'N/A'}
          </p>

          <p>
            <b>Earning plus:</b>{' '}
            {degreePremium && degreePremium.premiumAmount ? degreePremium.premiumAmount.toFixed(2) : 'N/A'}
          </p>

          <p>
            <b>Earning plus %:</b>{' '}
            {degreePremium && degreePremium.premiumPercentage
              ? degreePremium.premiumPercentage.toFixed(2) + '%'
              : 'N/A'}
          </p>

          <p>
            <b>Economic score:</b> {esState ? esState.toFixed(4) : 'N/A'}
          </p>
        </div>

        <div style={{ marginRight: '100px' }}>
          <h3 style={{ color: 'red' }}>EMI</h3>
          <p>
            <b>10 year earnings (lowest tercile):</b> {earnings10y ? earnings10y : 'N/A'}
          </p>

          <p>
            <b>10 year earnings count (lowest tercile):</b> {countLow1 ? countLow1 : 'N/A'}
          </p>

          <p>
            <b>Cost (public):</b> {costPub ? costPub : 'N/A'}
          </p>

          <p>
            <b>Cost (private):</b> {costPriv ? costPriv : 'N/A'}
          </p>

          <p>
            <b>Pell total cost:</b>{' '}
            {costPub && ACT_pell
              ? (costPub * ACT_pell).toFixed(2)
              : costPriv && ACT_pell
              ? (costPriv * ACT_pell).toFixed(2)
              : 'N/A'}
          </p>

          <p>
            <b>Payback:</b> {paybackEMI && typeof paybackEMI === 'number' ? paybackEMI.toFixed(2) : 'N/A'}
          </p>

          <p>
            <b>Earning plus:</b>{' '}
            {degreePremiumEMI && degreePremiumEMI.premiumAmountEMI ? degreePremiumEMI.premiumAmountEMI : 'N/A'}
          </p>

          <p>
            <b>Earning plus %:</b>{' '}
            {degreePremiumEMI && degreePremiumEMI.premiumPercentageEMI
              ? degreePremiumEMI.premiumPercentageEMI.toFixed(2) + '%'
              : 'N/A'}
          </p>

          <p>
            <b>Economic score:</b>{' '}
            {degreePremiumEMI && degreePremiumEMI.rankingScoreEMI ? degreePremiumEMI.rankingScoreEMI.toFixed(4) : 'N/A'}
          </p>

          <p>
            <b>EMI percentile:</b> {EMI_percentile ? EMI_percentile.toFixed(2) + '%' : 'N/A'}
          </p>

          <p>
            <b>Pell grant percentage:</b> {pellPerc + '%'}
          </p>

          <p>
            <b>EMI:</b> {EMI_number ? EMI_number.toFixed(2) : 'N/A'}
          </p>

          <p>
            <b>Rank:</b> {EMI_rank ? EMI_rank : 'N/A'}
          </p>
        </div>

        <div>
          <p>
            <b>10 year earnings (middle tercile):</b> {earningsLow2 ? earningsLow2 : 'N/A'}
          </p>

          <p>
            <b>10 year earnings count (middle tercile):</b> {countLow2 ? countLow2 : 'N/A'}
          </p>

          <p>
            <b>10 year earnings (highest tercile):</b> {earningsLow3 ? earningsLow3 : 'N/A'}
          </p>

          <p>
            <b>10 year earnings count (highest tercile):</b> {countLow3 ? countLow3 : 'N/A'}
          </p>
        </div>
      </div>
    </div>
  );
};

export default EMI;
