import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import {SERVER_URL} from "../../config";
import {UserContext} from "../../App";
import {Card, Spin, Table} from "antd";
import ReactDragListView from 'react-drag-listview';
import {MenuOutlined} from "@ant-design/icons";

const StateOrder = () => {
    const [working, setWorking] = useState(false);
    const [dataForOder, setDataForOder] = useState([]);
    const currentUser = useContext(UserContext);

    const loadData = async () => {
        try {
            setWorking(true);
            const {data} = await axios.get(`${SERVER_URL}/affiliate-institution-for-sort`, {
                withCredentials: false,
                headers: {Authorization: `Bearer ${currentUser.data.token}`},
            });
            setDataForOder(data);
            setWorking(false);
        } catch (e) {
            console.log(e);
            setWorking(false);
        }

    }

    useEffect(() => {
        loadData().catch(e => console.log(e));
    }, []);

    async function onDragEnd(fromIndex, toIndex, institutions, stateCode) {
        const data = [...dataForOder];
        const dataIndex = data.findIndex(item => item._id === stateCode);
        const item = institutions.splice(fromIndex, 1)[0];

        institutions.splice(toIndex, 0, item);

// send institutions for save
        try {
            setWorking(true);
            for (let i = 0; i < institutions.length; i++) {
                institutions[i].sortState = i;
            }
            const orderData = institutions.map(item => {
                return {id: item.institution_id, sortState: item.sortState}
            });
            const {data} = await axios.post(`${SERVER_URL}/affiliate-institution-sort-by-state`, [...orderData], {
                withCredentials: false,
                headers: {Authorization: `Bearer ${currentUser.data.token}`},
            });
            setWorking(false);
        } catch (e) {
            setWorking(false);
            console.log(e);
        }
        data[dataIndex].institutions = institutions;
        setDataForOder(data);
        console.log(fromIndex, toIndex, institutions, stateCode);
    }


    return (
        <div className='content-wrapper'>
            <Spin spinning={working} tip='Working...'>
                <Table pagination={false} rowKey={'_id'} dataSource={[...dataForOder] || []}
                       expandable={{
                           expandedRowRender: record => (
                               <Card>
                                   <ReactDragListView onDragEnd={(fromIndex, toIndex) =>
                                       onDragEnd(fromIndex, toIndex, record.institutions, record._id)
                                   }>
                                       <Table rowKey={record => record._id}
                                              pagination={false}
                                              dataSource={[...record.institutions] || []}
                                              expandable={{
                                                  expandedRowRender: record => (
                                                      <Card>
                                                          <Table rowKey={record => record._id} pagination={{
                                                              pageSize: 20,
                                                          }}
                                                                 dataSource={[...record.programs] || []}>
                                                              <Table.Column title='Program Name'
                                                                            dataIndex='programName'/>
                                                              <Table.Column title='Degree' dataIndex='degree'/>
                                                              <Table.Column title='Provider' dataIndex='provider'/>
                                                              <Table.Column align="center" title='Active'
                                                                            dataIndex='status'/>
                                                          </Table>
                                                      </Card>
                                                  )
                                              }}
                                       >

                                           <Table.Column title="Sort" align="center"
                                                         render={(text, record) => (
                                                             <a style={{cursor: "move!important"}}
                                                                href="#"><MenuOutlined
                                                                 style={{
                                                                     cursor: 'grab',
                                                                     color: '#999',
                                                                 }}
                                                             /></a>)}/>
                                           <Table.Column title='Name' dataIndex='name'/>
                                           <Table.Column title='State' dataIndex='state'/>
                                           <Table.Column title='City' dataIndex='city'/>
                                           <Table.Column align="center" title='Number of Programs' dataIndex='count'/>
                                           <Table.Column title='Active Programs' align='center'
                                                         dataIndex='countActive'/>
                                       </Table>
                                   </ReactDragListView>
                               </Card>
                           ),
                       }}
                >
                    <Table.Column title='State' render={(value, record) => `${value} (${record.numOfInstitutions})`}
                                  dataIndex='state'/>
                    <Table.Column title='Code' dataIndex='_id'/>
                </Table>
            </Spin>
        </div>
    )
}

export default StateOrder;
