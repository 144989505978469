import React, { useEffect, useState } from 'react';
import { Form, Select, Row, Col, Input, Button, Layout, Checkbox, TreeSelect, notification } from 'antd';
import { getTreeFromFlatData } from 'react-sortable-tree';
import useAxios from '../../hooks/useAxios';
import DNDList from '../base/DNDList';
import Axios from 'axios';

const { Content } = Layout;
const formInit = {
  _id: null,
  listName: '',
  program: undefined,
  schoolProps: ['name', 'state', 'city'],
  links: [],
};

const checkboxOptions = [
  { label: 'Name', value: 'name' },
  { label: 'State', value: 'state' },
  { label: 'City', value: 'city' },
];

const NumberedListForm = ({ numberedList, isNew, onSubmit, schools, programs, website, SERVER_URL, token }) => {
  const [form] = Form.useForm();
  const [selectedProgram, setSelectedProgram] = useState();
  const [selectedLinks, setSelectedLinks] = useState([]);
  const [links, fetchLinks] = useAxios('', [], token, 'get');
  let initialValues = { ...formInit, ...numberedList };

  const onSubmitPage = async (val) => {
    if (isNew) {
      const numberListExists = await Axios.get(`${SERVER_URL}/numbered-list?filter=${JSON.stringify({ $and: [{ listName: val.listName }, { website: website._id }] })}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` },
      });

      if (numberListExists.data && numberListExists.data.length > 0) {
        notification.error({
          message: 'Duplicate numbered list name',
          placement: 'bottomRight',
        });
      } else {
        onSubmit(val, !numberedList);
      }
    } else {
      val._id = numberedList._id;
      onSubmit(val, !numberedList);
    }
  };

  const getLinks = (programId) => {
    if (website._id && website._id) fetchLinks(`${SERVER_URL}/affiliate?filter=${JSON.stringify({ website: website._id, program: programId })}`, []);
  };

  useEffect(() => {
    setSelectedProgram(form.getFieldValue('program'));
    setSelectedLinks(form.getFieldValue('links'));
  }, [form, setSelectedLinks, setSelectedProgram]);

  useEffect(() => {
    if (!isNew && website && website._id) fetchLinks(`${SERVER_URL}/affiliate?filter=${JSON.stringify({ website: website._id, program: selectedProgram })}`, []);
  }, [website, selectedProgram, fetchLinks, isNew, SERVER_URL]);

  const treeData = getTreeFromFlatData({
    flatData: programs.map((node) => ({
      title: node.parent ? `${programs.find((p) => p._id === node.parent).name} - ${node.name}` : node.name,
      value: node._id,
      key: node._id,
      parent: node.parent,
      _id: node._id,
    })),
    getKey: (node) => node._id, // resolve a node's key
    getParentKey: (node) => node.parent, // resolve a node's parent's key
    rootKey: null, // The value of the parent key when there is no parent (i.e., at root level)
  });

  const handleLinks = (value, remove) => {
    let newValues = [...selectedLinks];
    if (!remove) {
      const oldLinks = links.data.filter((oldLink) => newValues.includes(oldLink._id));
      const newLink = links.data.find((link) => link._id === value);
      if (newLink && oldLinks.some((oldLink) => oldLink.school._id === newLink.school._id)) {
        const oldLink = oldLinks.find((oldLink) => oldLink.school._id === newLink.school._id);
        newValues = [...newValues.filter((link) => link !== oldLink._id), value];
      } else {
        newValues = [...newValues, value];
      }
    } else {
      newValues = newValues.filter((l) => l !== value);
    }
    setSelectedLinks(newValues);
    form.setFieldsValue({ links: newValues });
  };

  const linkFilterOptionSearch = (input, option) => {
    const searchVal = option.children.props.children;
    return searchVal.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  return (
    <div className='panel'>
      <div className='panel-body'>
        <Content style={{ padding: '15px', height: '100%' }}>
          <Form
            name='form'
            className='form-horizontal'
            layout='vertical'
            initialValues={initialValues}
            onFinish={(values) => {
              onSubmitPage(values, !schools);
            }}
            form={form}
          >
            <Row type='flex' gutter={16}>
              <Col xs={24} md={14}>
                <Form.Item label='List Name:' name='listName' rules={[{ required: true, message: 'Please enter numbered list name!' }]}>
                  <Input disabled={!isNew} placeholder='Add name' />
                </Form.Item>

                <Form.Item label='Program:' name='program' rules={[{ required: true, message: 'Please select program!' }]}>
                  <TreeSelect
                    showSearch
                    filterTreeNode={(search, item) => {
                      return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
                    }}
                    style={{ width: '100%', height: 30 }}
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    treeData={treeData}
                    placeholder='Select Program'
                    onChange={(value) => {
                      setSelectedProgram(value);
                      setSelectedLinks([]);
                      form.setFieldsValue({ program: value, links: [] });
                      getLinks(value);
                    }}
                    allowClear={true}
                  />
                </Form.Item>

                <Form.Item label='Links:' name='links' rules={[{ required: true, message: 'Please select links!' }]}>
                  <Select
                    showSearch
                    optionFilterProp='children'
                    mode='multiple'
                    placeholder='Select links'
                    onSelect={(value) => handleLinks(value, false)}
                    onDeselect={(value) => handleLinks(value, true)}
                    filterOption={(input, option) => linkFilterOptionSearch(input, option)}
                  >
                    {links.data &&
                      links.data.length > 0 &&
                      links.data.map((link) => (
                        <Select.Option key={link._id} value={link._id}>
                          <span style={{ color: link.school ? 'inherit' : 'red' }}>{link.school ? link.school.name + ' - ' + link.partner : 'UNKNOWN'}</span>
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>

                <Form.Item hidden={true} label='Attributes:' name='schoolProps'>
                  <Checkbox.Group hidden={true} options={checkboxOptions} disabled />
                </Form.Item>
              </Col>

              <Col xs={24} md={10} style={{ minHeight: 600 }}>
                <div className='panel panel-primary'>
                  <div className='panel-heading'>
                    <h4 className='panel-title'>List of Links</h4>
                  </div>
                  <div className='panel-body' style={{ padding: 0 }}>
                    {selectedLinks.length > 0 && links && links.data && links.data.length > 0 && (
                      <DNDList list={selectedLinks} links={[...links.data]} setFieldValue={form.setFieldsValue} setSelectedLinks={setSelectedLinks} />
                    )}
                  </div>
                </div>
              </Col>
            </Row>

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button style={{ margin: '0 10px' }} type='primary' htmlType='submit' title='Update'>
                {isNew ? 'Add' : 'Update'} numbered list
              </Button>
            </div>
          </Form>
        </Content>
      </div>
    </div>
  );
};
export default NumberedListForm;
