import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  Button,
  Tooltip,
  Select,
  Input,
  notification,
  Spin,
  Table,
  Tag,
  Radio,
  Checkbox,
  Popconfirm,
  Modal,
} from 'antd';
import { QuestionCircleFilled, EditOutlined } from '@ant-design/icons';
import Axios from 'axios';
import { UserContext } from '../../App';
import { SERVER_URL } from '../../config';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { ExportToExcel } from '../../components/csv';
import CKEditor from '../../components/base/CKEditor';

const DEGREES = [
  { label: "Associate's", value: "Associate's Degree" },
  { label: "Bachelor's", value: 'Bachelors Degree' },
  { label: "Master's", value: "Master's Degree" },
  { label: 'First Professional', value: 'First Professional Degree' },
  { label: 'Certificate or Diploma', value: 'Undergraduate Certificate or Diploma' },
];

const ViewPLRanking = () => {
  // grad vs undergrad
  const path = window.location.pathname.split('/');
  const type = path[path.length - 2];

  const currentuser = useContext(UserContext);
  const history = useHistory();
  const location = useLocation();
  const [cipcode, setCipcode] = useState(null);
  const [title, setTitle] = useState(null);
  const [url, setUrl] = useState(null);
  const [degree, setDegree] = useState(
    type === 'undergraduate'
      ? 'Bachelors Degree'
      : type === 'graduate'
      ? "Master's Degree"
      : type === 'associate'
      ? "Associate's Degree"
      : 'other',
  );
  const [working, setWorking] = useState(false);
  const [data, setData] = useState([]);
  const [level, setLevel] = useState('subject');
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [allSubjects, setAllSubjects] = useState([]);
  const [threshold, setThreshold] = useState(null); // graduation rate threshold
  const [pathID, setPathID] = useState(null);
  const [showAdditional, setShowAdditional] = useState();
  const [dontShowStates, setDontShowStates] = useState(false);
  const [parent, setParent] = useState(null);
  const [includeAll, setIncludeAll] = useState(false);
  const [includeIC, setIncludeIC] = useState(false);
  const [modal, setModal] = useState({ visible: false, unitid: null, name: null });
  const [description, setDescription] = useState('');
  const [onlineOther, setOnlineOther] = useState(false);

  const selectSubjectRef = useRef();
  const nameForWP = useRef();
  const urlForWP = useRef();

  const online = location.search && location.search.includes('?online=') ? location.search.split('=')[1] : null;
  const isOnline = location.search.includes('?online=') ? true : false;

  useEffect(() => {
    const viewData = async () => {
      try {
        setWorking(true);
        const pathArr = location.pathname.split('/');
        const id = pathArr[pathArr.length - 1];
        setPathID(id);

        // get one
        const dataView = await Axios.get(
          `${SERVER_URL}/rankings-universal/${id}?online=${isOnline ? true : false}&type=${type}`,
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` },
          },
        );
        if (dataView) {
          setData(dataView.data.items);
          setCipcode(dataView.data.cipcode);
          setDegree(dataView.data.degree);
          setTitle(dataView.data.category);
          setUrl(dataView.data.categoryUrl);
          setShowAdditional(dataView.data.showAdditional);
          setDontShowStates(dataView.data.dontShowStates);
          setParent(dataView.data.parent);
          setIncludeAll(dataView.data.includeAll);
          setIncludeIC(dataView.data.includeIC);
          setSelectedSubject(dataView.data.parent);
          setLevel(dataView.data.parent ? 'additional' : 'subject');
          setOnlineOther(dataView.data.onlineOther ? dataView.data.onlineOther : false);

          // get all subjects for additional select
          const resAdditional = await Axios.get(
            `${SERVER_URL}/rankings-programs-parent?parent=false&degree=${dataView.data.degree}&type=${type}`,
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${currentuser.data.token}` },
            },
          );
          if (resAdditional) {
            setAllSubjects(resAdditional.data);
          }
        }

        setWorking(false);
      } catch (error) {
        setWorking(false);
        console.log('Error', error);
      }
    };
    viewData();
  }, [currentuser.data.token]);

  const handleFetch = async () => {
    if (!degree || !cipcode) {
      notification.error({
        message: 'Missing degree or cipcode',
        placement: 'bottomRight',
        duration: 3,
      });
      setData([]);
      return;
    }
    try {
      setWorking(true);

      const res = await Axios.get(
        `${SERVER_URL}/rankings-new-methodology-creation?degree=${degree}&cipcode=${cipcode}&isProgramLevel=true&online=${
          degree !== "Associate's Degree" && !onlineOther
            ? isOnline
            : degree !== "Associate's Degree" && onlineOther
            ? true
            : true
        }&includeAll=${degree === "Associate's Degree" ? includeAll : false}&includeIC=${
          degree === "Associate's Degree" ? includeIC : false
        }`,
        { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
      );

      setData(res.data.ranking.rankingItems);
      setThreshold(res.data.ranking.threshold);
      setSelectedSubject(null);

      setWorking(false);
    } catch (error) {
      setWorking(false);
      console.log(error);
    }
  };

  const handleSave = async () => {
    // validation
    if (!title) {
      nameForWP.current.focus();
      notification.error({
        message: 'Missing name of the ranking',
        placement: 'bottomRight',
        duration: 3,
      });
      return;
    }

    if (!url) {
      urlForWP.current.focus();
      notification.error({
        message: 'Missing url of the ranking for WP',
        placement: 'bottomRight',
        duration: 3,
      });
      return;
    }

    if (!pathID) {
      notification.error({
        message: 'Missing program id',
        placement: 'bottomRight',
        duration: 3,
      });
      return;
    }

    if ((!degree || !cipcode) && !isOnline) {
      notification.error({
        message: 'Missing degree or cipcode',
        placement: 'bottomRight',
        duration: 3,
      });
      return;
    }

    if (level === 'additional' && !selectedSubject) {
      selectSubjectRef.current.focus();
      notification.error({
        message: 'Missing subject for additional ranking',
        placement: 'bottomRight',
        duration: 3,
      });
      return;
    }

    try {
      setWorking(true);

      const body = {
        category: title,
        categoryUrl: url,
        items: data,
        cipcode,
        showAdditional,
        dontShowStates,
        includeAll,
        includeIC,
        parent: level !== 'additional' ? null : selectedSubject,
      };

      await Axios.patch(`${SERVER_URL}/rankings-programs-url/${pathID}?online=${isOnline || onlineOther}`, body, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: 'Ranking updated',
        placement: 'bottomRight',
        duration: 3,
      });

      history.push('/admin');
      history.push(
        !online
          ? type === 'undergraduate'
            ? '/admin/rankings/undergraduate'
            : type === 'graduate'
            ? '/admin/rankings/graduate'
            : type === 'associate'
            ? '/admin/rankings/associate'
            : '/admin/rankings/other'
          : `/admin/rankings/online?tab=${online}`,
      );
      setWorking(false);
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Problem with updating ranking url',
        placement: 'bottomRight',
        duration: 3,
      });
    }
  };

  let columns = [];

  if (degree !== `Master's Degree` && degree !== 'First Professional Degree') {
    columns = [
      {
        title: 'RANK',
        dataIndex: 'rank',
        key: 'rank',
        // ...getColumnSearchProps('rank'),
      },
      {
        title: 'NAME',
        dataIndex: 'name',
        key: 'name',
        // ...getColumnSearchProps('name'),
      },
      {
        title: 'UNITID',
        dataIndex: !isOnline || degree ? '_id' : 'unitid',
        key: !isOnline ? '_id' : 'unitid',
        // ...getColumnSearchProps('name'),
      },
      {
        title: 'STATE',
        dataIndex: 'state',
        key: 'state',
        // ...getColumnSearchProps('state'),
      },
      {
        title: 'CITY',
        dataIndex: 'city',
        key: 'city',
        // ...getColumnSearchProps('city'),
      },
      {
        title: 'ECONOMIC SCORE',
        dataIndex: 'valueRank',
        key: 'valueRank',
        align: 'right',
        render: (text) =>
          text && text === 9999999
            ? 'N/A'
            : text
            ? text.toLocaleString('us', { maximumFractionDigits: 2 })
            : text ?? 'N/A',
        // ...getColumnSearchProps('city'),
      },
      {
        title: 'NET COST',
        dataIndex: 'netCost',
        key: 'netCost',
        align: 'right',
        render: (text) => (text && text === 9999999 ? 'N/A' : text ?? 'N/A'),
        // ...getColumnSearchProps('netCost'),
      },
      {
        title: 'EARNINGS',
        dataIndex: 'earnings',
        key: 'earnings',
        align: 'right',
        render: (text) =>
          text && text === -9999999
            ? 'N/A'
            : text
            ? text.toLocaleString('us', { maximumFractionDigits: 0 })
            : text ?? 'N/A',
        // ...getColumnSearchProps('earnings'),
      },
      {
        title: 'PAYBACK',
        dataIndex: 'payback',
        key: 'payback',
        align: 'right',
        render: (text) =>
          text && text === 9999999
            ? 'N/A'
            : text
            ? text.toLocaleString('us', { maximumFractionDigits: 2 })
            : text ?? 'N/A',
        // ...getColumnSearchProps('payback'),
      },
      {
        title: 'EARNINGPLUS',
        dataIndex: 'degreePremium',
        key: 'degreePremium',
        align: 'right',
        render: (text) =>
          text && text === -9999999
            ? 'N/A'
            : text
            ? text.toLocaleString('us', { maximumFractionDigits: 0 })
            : text ?? 'N/A',
        // ...getColumnSearchProps('degreePremium'),
      },
      {
        title: 'GRADUATION RATE',
        dataIndex: 'gradRate',
        key: 'gradRate',
        align: 'right',
        render: (text, record) => (
          <Tag color={record.graduationRate < threshold ? 'red' : 'green'}>{text ?? 'N/A'}</Tag>
        ),
        // text ?? 'N/A',
        // ...getColumnSearchProps('gradRate'),
      },
    ];
  } else {
    columns = [
      {
        title: 'RANK',
        dataIndex: 'rank',
        key: 'rank',
        // ...getColumnSearchProps('rank'),
      },
      {
        title: 'NAME',
        dataIndex: 'name',
        key: 'name',
        // ...getColumnSearchProps('name'),
      },
      {
        title: 'UNITID',
        dataIndex: '_id',
        key: '_id',
        // ...getColumnSearchProps('name'),
      },
      {
        title: 'STATE',
        dataIndex: 'state',
        key: 'state',
        // ...getColumnSearchProps('state'),
      },
      {
        title: 'CITY',
        dataIndex: 'city',
        key: 'city',
        // ...getColumnSearchProps('city'),
      },
      {
        title: 'ECONOMIC SCORE',
        dataIndex: 'valueRank',
        key: 'valueRank',
        align: 'right',
        render: (text) =>
          text && text === 9999999
            ? 'N/A'
            : text
            ? text.toLocaleString('us', { maximumFractionDigits: 2 })
            : text ?? 'N/A',
        // ...getColumnSearchProps('city'),
      },
      {
        title: 'EARNINGS',
        dataIndex: 'earnings',
        key: 'earnings',
        align: 'right',
        render: (text) =>
          text && text === -9999999
            ? 'N/A'
            : text
            ? text.toLocaleString('us', { maximumFractionDigits: 0 })
            : text ?? 'N/A',
        // ...getColumnSearchProps('earnings'),
      },
      {
        title: 'DEBT',
        dataIndex: 'debt',
        key: 'debt',
        align: 'right',
        render: (text) =>
          text && text === 9999999
            ? 'N/A'
            : text
            ? text.toLocaleString('us', { maximumFractionDigits: 0 })
            : text ?? 'N/A',
        // ...getColumnSearchProps('payback'),
      },
      {
        title: 'DEBT TO EARN RATIO',
        dataIndex: 'debtToEarnRatio',
        key: 'debtToEarnRatio',
        align: 'right',
        render: (text) => (text ? text.toLocaleString('us', { maximumFractionDigits: 2 }) : 'N/A'),
        // ...getColumnSearchProps('payback'),
      },
      {
        title: 'DEGREE PREMIUM',
        dataIndex: 'degreePremium',
        key: 'degreePremium',
        align: 'right',
        render: (text) =>
          text && text === -9999999
            ? 'N/A'
            : text
            ? text.toLocaleString('us', { maximumFractionDigits: 0 })
            : text ?? 'N/A',
        // ...getColumnSearchProps('degreePremium'),
      },
    ];
  }

  // item description
  columns.push({
    title: 'Description',
    key: 'description',
    render: (text, record) => {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '15px',
          }}
        >
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
            <Tooltip title='Edit description' placement='topLeft'>
              <EditOutlined
                style={{ textDecoration: 'none', color: 'black' }}
                onClick={() => {
                  setModal({
                    visible: true,
                    unitid: record.unitid ? record.unitid : record._id,
                    name: record.name,
                  });
                  setDescription(record.description ? record.description : '');
                }}
              />
            </Tooltip>
          </div>
        </div>
      );
    },
  });

  const onOk = () => {
    const newData = data.map((item) => {
      if (item.unitid === modal.unitid || item._id === modal.unitid) {
        item.description = description;
      }
      return item;
    });
    setData([...newData]);
    setModal({ visible: false });
  };

  let tableData = [];
  if (data && data?.length > 0) {
    tableData = data.map((item) => {
      if (item.graduationRate) {
        item.gradRate = item.graduationRate.toFixed(2);
      }
      // item.createdAt = new Date(item.createdAt).toLocaleString('en-US');
      // item.updatedAt = new Date(item.updatedAt).toLocaleString('en-US');
      return item;
    });
  }

  let filename = '';
  let excelData = [];
  if (data.length > 0) {
    filename = 'cipcode_' + cipcode;
    excelData = data.map((item) => {
      return { unitid: item._id, ...item };
    });
  }

  return (
    <div className='content-wrapper'>
      <Spin spinning={working} tip='Working...'>
        <div className='actions-block flex'>
          <ExportToExcel
            csvData={excelData}
            fileName={filename}
            propsToRemove={['_id', 'fullOnline']}
            disabled={data.length === 0}
          />

          <Link
            to={
              !isOnline
                ? type === 'undergraduate'
                  ? '/admin/rankings/undergraduate'
                  : type === 'graduate'
                  ? '/admin/rankings/graduate'
                  : type === 'associate'
                  ? '/admin/rankings/associate'
                  : '/admin/rankings/other'
                : `/admin/rankings/online?tab=${online}`
            }
          >
            <Tooltip title={!online ? 'View all rankings (program level)' : 'View all online rankings'}>
              <Button type='primary' style={{ marginRight: '8px', float: 'left' }}>
                ALL RANKINGS
              </Button>
            </Tooltip>
          </Link>

          <Select
            allowClear
            showSearch
            value={degree}
            onChange={(value) => setDegree(value)}
            style={{ width: '200px', marginLeft: '4px', fontSize: '15px' }}
            placeholder='Select degree...'
            disabled
          >
            {DEGREES.map(({ value, label }, index) => (
              <Select.Option key={`${value}_${index}`} value={value}>
                {label}
              </Select.Option>
            ))}
          </Select>

          <Input
            type={'text'}
            value={cipcode}
            onChange={(e) => setCipcode(e.target.value)}
            placeholder={'Enter cipcode (eg. 14 | 14.07 | 14.10)'}
            style={{ width: '200px' }}
            disabled={!cipcode}
          />

          {degree === "Associate's Degree" && (
            <>
              <Tooltip title='Include non-online associate programs as well'>
                <Checkbox checked={includeAll} onChange={(e) => setIncludeAll(e.target.checked)}>
                  <QuestionCircleFilled />
                  Include non-online
                </Checkbox>
              </Tooltip>

              <Tooltip title='Include schools with ICLEVEL of 1 (level of institution)'>
                <Checkbox checked={includeIC} onChange={(e) => setIncludeIC(e.target.checked)}>
                  <QuestionCircleFilled />
                  Include all levels
                </Checkbox>
              </Tooltip>
            </>
          )}

          {type === 'other' && (
            <Checkbox checked={onlineOther} onChange={(e) => setOnlineOther(e.target.checked)}>
              Online
            </Checkbox>
          )}

          <p>Total Results: {data.length}</p>

          <Button style={{ width: '100px' }} type={'primary'} onClick={handleFetch} disabled={!cipcode}>
            Fetch
          </Button>
        </div>

        {!working && data && data.length > 0 && (
          <>
            <div>
              <label htmlFor={'title'}>Name: </label>
              <Input
                type={'text'}
                name='title'
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                style={{ width: '310px', marginRight: '50px' }}
                disabled={!cipcode}
                ref={nameForWP}
              />

              <label htmlFor={'url'}>Url: </label>
              <Tooltip title='URL segment for WP'>
                <Input
                  type={'text'}
                  value={url}
                  name='url'
                  onChange={(e) => setUrl(e.target.value)}
                  style={{ width: '310px' }}
                  disabled={!cipcode}
                  ref={urlForWP}
                />
              </Tooltip>

              <div style={{ float: 'right' }}>
                <Radio.Group
                  defaultValue='subject'
                  buttonStyle='solid'
                  value={level}
                  style={{ float: 'right' }}
                  onChange={(e) => setLevel(e.target.value)}
                >
                  <Radio.Button value='subject'>Main Ranking</Radio.Button>
                  <Radio.Button value='additional'>Additional Ranking</Radio.Button>
                </Radio.Group>
                <br></br>
                <br></br>
                {!parent && (
                  <div>
                    <label htmlFor='showAdditional'>Show Additional: </label>
                    <Checkbox
                      style={{ marginLeft: '5px' }}
                      name='showAdditional'
                      checked={showAdditional}
                      onChange={() => setShowAdditional(!showAdditional)}
                      disabled={!cipcode}
                    ></Checkbox>
                  </div>
                )}

                {degree !== "Associate's Degree" && !isOnline && (
                  <div>
                    <label htmlFor='dontShowStates'>Don't Create State Pages: </label>
                    <Checkbox
                      style={{ marginLeft: '5px' }}
                      name='dontShowStates'
                      checked={dontShowStates}
                      onChange={() => setDontShowStates(!dontShowStates)}
                    ></Checkbox>
                  </div>
                )}

                <br></br>
              </div>
            </div>
            <br></br>

            {level === 'additional' && (
              <>
                <br></br>
                <div>
                  <label htmlFor='parent'>Parent Subject: </label>
                  <Select
                    name='parent'
                    allowClear
                    showSearch
                    optionFilterProp='children'
                    value={selectedSubject}
                    onChange={(value) => setSelectedSubject(value)}
                    style={{ width: '400px', marginLeft: '4px', fontSize: '15px' }}
                    ref={selectSubjectRef}
                  >
                    {allSubjects.length > 0
                      ? allSubjects.map((item, index) => (
                          <Select.Option key={`${item._id}_${index}`} value={item._id}>
                            {item.category} - {item.degree} - {item.cipcode}
                          </Select.Option>
                        ))
                      : null}
                  </Select>
                </div>
              </>
            )}

            <br></br>
            <Table
              size='middle'
              // onChange={onChangeTable}
              bordered
              dataSource={tableData}
              columns={columns}
              rowKey={(record) => record._id}
              pagination={{
                pageSize: 20,
                // total: total || 0,
                // current: page,
                // defaultCurrent: 1,
                // position: 'bottom',
                // showSizeChanger: false,
                // onChange: (page) => pageHandler(page),
                // hideOnSinglePage: true,
              }}
              id='plItemsTable'
            />

            <Modal
              centered
              width='70%'
              visible={modal.visible}
              title={
                <p>
                  Description for<strong> {modal.name}</strong>
                </p>
              }
              okText='SAVE'
              cancelText='CANCEL'
              maskClosable={false}
              onCancel={() => {
                setModal({ visible: false });
              }}
              onOk={onOk}
              confirmLoading={working}
              getContainer={document.getElementById('plItemsTable')}
            >
              <div>
                <p style={{ fontSize: 15, marginTop: 20, marginRight: 10 }}>Description: </p>
                <CKEditor content={description} setContent={setDescription} />
              </div>
            </Modal>

            <br></br>

            <Popconfirm
              title={`Are you sure you want to save this ranking?`}
              onConfirm={handleSave}
              onCancel={() => console.log('Cancel')}
              okText='Yes'
              cancelText='No'
            >
              <Button style={{ width: '90px', float: 'right' }} type={'primary'}>
                Save
              </Button>
            </Popconfirm>
          </>
        )}
        {!data ||
          (data.length === 0 && (
            <div style={{ textAlign: 'center', marginTop: '1rem' }}>
              <h3>Click on 'FETCH' to (re)fetch the data</h3>
            </div>
          ))}
      </Spin>
    </div>
  );
};

export default ViewPLRanking;
