import React from 'react';
import { Button, Input, Table, Tooltip, Modal } from 'antd';
import { SearchOutlined, ExclamationCircleOutlined, DeleteOutlined } from '@ant-design/icons';

const CareerWidgetsTable = ({ data, columnKeys, selectedRowKeys, setSelectedRowKeys, deleteHandler }) => {
  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  let searchInput;

  const getColumnSearchProps = (dataIndex) => {
    if (!['createdAt', 'updatedAt', 'widgets'].includes(dataIndex)) {
      return {
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
          return (
            <div style={{ padding: 8 }}>
              <Input
                ref={(node) => {
                  searchInput = node;
                }}
                placeholder={`Search ${dataIndex}`}
                value={selectedKeys[0]}
                onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
              />
              <Button
                type='primary'
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                size='small'
                style={{ width: 90, marginRight: 8 }}
              >
                Search
              </Button>
              <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
                Reset
              </Button>
            </div>
          );
        },
        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />,
        onFilter: (value, record) => {
          const filterRecord = Array.isArray(dataIndex) ? record[dataIndex[0]][dataIndex[1]] : record[dataIndex];
          if (!filterRecord || !value) return false;
          return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
        },
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => {
              searchInput.select();
            });
          }
        },
        sorter: (a, b, sortDirection) => {
          const stringA = a[dataIndex].toUpperCase();
          const stringB = b[dataIndex].toUpperCase();
          if (stringA < stringB) {
            return -1;
          }
          if (stringA > stringB) {
            return 1;
          }
          return 0;
        },
        sortDirections: ['ascend', 'descend'],
      };
    } else if (['createdAt', 'updatedAt'].includes(dataIndex)) {
      return {
        sorter: (a, b) => new Date(a[dataIndex]) - new Date(b[dataIndex]),
        sortDirections: ['ascend', 'descend'],
      };
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const columns = columnKeys.map((item) => ({
    key: item,
    title: item.toUpperCase(),
    dataIndex: item,
    render: (text) => {
      if (item !== 'widgets') return text;
      const tooltip =
        text?.length === 0 ? (
          <span>No widgets</span>
        ) : (
          <>
            <span>
              <strong>WIDGETS:</strong>
            </span>
            <ul>
              {text?.length > 0 &&
                text.map((w, i) => (
                  <li key={`${w}_${i}`} style={{ listStyle: 'inside', fontSize: '0.825rem' }}>
                    {w}
                  </li>
                ))}
            </ul>
          </>
        );
      return <Tooltip title={tooltip}>{text?.length || 'NN'}</Tooltip>;
    },
    ...getColumnSearchProps(item),
  }));

  const handleDelete = async (id) => {
    Modal.confirm({
      width: 700,
      icon: <ExclamationCircleOutlined style={{ display: 'block', fontSize: '2rem' }} />,
      content: <div style={{ marginLeft: '3.5rem' }}>Are you sure you want to delete widgets for this career?</div>,
      onOk() {
        deleteHandler(id);
        Modal.destroyAll();
      },
      onCancel() {
        Modal.destroyAll();
      },
    });
  };

  if (deleteHandler) {
    columns.push({
      title: 'Action',
      render: (text, record) => (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
          <Tooltip title={`Delete widgets for ${record.careerName}`}>
            <div
              style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}
              onClick={() => handleDelete(record._id)}
              title='Delete'
            >
              <DeleteOutlined />
            </div>
          </Tooltip>
        </div>
      ),
    });
  }

  return (
    <div>
      <Table
        size='middle'
        bordered
        dataSource={data}
        columns={columns}
        rowSelection={rowSelection}
        rowKey='_id'
        pagination={{
          defaultPageSize: 20,
          position: 'bottom',
          showSizeChanger: false,
          pageSizeOptions: ['10', '20', '50', '100'],
          hideOnSinglePage: true,
        }}
      />
    </div>
  );
};

export default CareerWidgetsTable;
