import React, { useState, useEffect, useContext, useRef } from 'react';
import { Button, Tooltip, Table, Input, Modal, notification, Spin, Collapse, Select, Switch } from 'antd';
import {
  SearchOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  EditOutlined,
  PlusCircleOutlined,
  SyncOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import Axios from 'axios';
import { UserContext } from '../../App';
import { SERVER_URL } from '../../config';
import { Link, useHistory } from 'react-router-dom';
import CKEditor from '../../components/base/CKEditor';
import BubblesList from './BubblesList';

const { TextArea } = Input;

const Graduate = () => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(null);
  const [filter, setFilter] = useState(null);
  const [search, setSearch] = useState({});
  const [modal, setModal] = useState({
    visible: false,
    category: '',
    title: '',
    content: '',
    metaTitle: '',
    metaDescription: '',
    bobbleDescription: '',
    metaImage: '',
    metaUrl: '',
    listName: '',
    bubbles: [],
    degree: null,
    spotlight: null,
    shortcode: null,
  });
  const [fetchTrigger, setFetchTrigger] = useState(false);
  const [working, setWorking] = useState(false);
  const [content, setContent] = useState('');
  const [selectedForRefresh, setSelectedForRefresh] = useState([]); // use to refresh
  const [bubbles, setBubbles] = useState([]);
  const titleInput = useRef();
  const [allSpotlights, setAllSpotlights] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setWorking(true);
        const res = await Axios.get(`${SERVER_URL}/rankings?type=graduate`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });

        if (res) {
          setData(res.data);
        }

        const spotlightRes = await Axios.get(`${SERVER_URL}/spotlight`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });

        if (spotlightRes) {
          setAllSpotlights(spotlightRes.data);
        }

        setWorking(false);
      } catch (error) {
        setWorking(false);
        console.log(error);
      }
    };
    fetchData();
  }, [filter, fetchTrigger, currentuser.data.token]);

  // Coulmn search
  let searchInput;
  const getColumnSearchProps = (dataIndex) => {
    if (Array.isArray(dataIndex)) dataIndex = dataIndex.join('.');
    const prop = dataIndex;
    return {
      filteredValue: (filter && filter[prop] && [filter[prop]]) || null,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              ref={(node) => {
                searchInput = node;
              }}
              placeholder={`Search ${prop}`}
              value={selectedKeys}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, prop)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />

            <Button
              type='primary'
              onClick={() => handleSearch(selectedKeys, confirm, prop)}
              size='small'
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>

            <Button onClick={() => handleReset(clearFilters, prop)} size='small' style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        );
      },
      filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />,
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => {
            searchInput.select();
          });
        }
      },
      // sorter: true,
      // defaultSortOrder: prop === 'title' ? 'ascend' : undefined,
      // sortDirections: ['ascend', 'descend'],
      render: (text) =>
        search[prop] ? (
          <Highlighter
            title={text}
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[search[prop]]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          <span title={text}>{text}</span>
        ),
    };
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    if (Array.isArray(dataIndex)) dataIndex = dataIndex.join('.');
    const newSearch = { ...search };
    newSearch[dataIndex] = selectedKeys[0];
    setSearch(newSearch);
    filterHandler(dataIndex, selectedKeys[0]);
    confirm();
  };

  const handleReset = (clearFilters, prop) => {
    if (Array.isArray(prop)) prop = prop.join('.');
    const newSearch = { ...search };
    setSearch(newSearch);
    delete newSearch[prop];
    filterHandler(prop, null);
    clearFilters();
  };

  const filterHandler = (prop, value) => {
    if (Array.isArray(prop)) prop = prop.join('.');

    let filterQuery = null;
    let prevFilter = {};
    if (filter) {
      prevFilter = JSON.parse(filter);
    }

    if (value !== null) {
      prevFilter[prop] = value;
      filterQuery = `${JSON.stringify({ [prop]: !isNaN(value) ? +value : [value.toString()], ...prevFilter })}`;
    } else {
      delete prevFilter[prop];
      filterQuery = `${JSON.stringify({ ...prevFilter })}`;
    }

    setFilter(filterQuery);
    setFetchTrigger(!fetchTrigger);
  };

  const columns = [
    {
      title: 'CIPCODE',
      dataIndex: ['cipcode'],
      key: 'cipcode',
      ...getColumnSearchProps(['cipcode']),
    },
    {
      title: 'CATEGORY',
      dataIndex: ['category'],
      key: 'category',
      ...getColumnSearchProps(['category']),
      render: (text, record) => (!record.parent ? text : record.parent.category),
    },
    {
      title: 'ADDITIONAL',
      dataIndex: 'category',
      key: 'category-additional',
      render: (text, record) => (!record.parent ? null : text),
      // ...getColumnSearchProps('name'),
    },
    // {
    //   title: 'DEGREE',
    //   dataIndex: 'degree',
    //   key: 'degree',
    //   ...getColumnSearchProps('degree'),
    // },
    {
      title: 'TOTAL SCHOOLS',
      dataIndex: 'items',
      key: 'items',
      align: 'right',
      render: (text, record) => record.items ?? 0,
      // ...getColumnSearchProps(['createdBy', 'email']),
    },
    {
      title: 'CREATED AT',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'right',
      // ...getColumnSearchProps('name'),
    },
    {
      title: 'UPDATED AT',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      align: 'right',
      // ...getColumnSearchProps('name'),
    },
  ];

  const deleteHandler = async (record) => {
    Modal.confirm({
      width: 700,
      icon: <ExclamationCircleOutlined style={{ display: 'block', fontSize: '2rem' }} />,
      content: (
        <div style={{ marginLeft: '3.5rem' }}>
          {record.parent ? (
            'Are you sure you want to delete this Ranking?'
          ) : (
            <span>
              Are you sure you want to delete this Ranking? This will {<b>delete all additional rankings</b>} related to
              this main ranking.{' '}
            </span>
          )}
        </div>
      ),
      async onOk() {
        await Axios.delete(`${SERVER_URL}/rankings-universal/${record._id}`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });

        notification.success({
          message: 'Ranking is deleted',
          placement: 'bottomRight',
        });

        // history.push('/admin');
        // history.push('/admin/rankings-programs');
        setFetchTrigger(!fetchTrigger);
        Modal.destroyAll();
      },
      onCancel() {
        Modal.destroyAll();
      },
    });
  };

  const modalHandler = (record) => {
    setModal({
      visible: true,
      id: record._id,
      category: record.category,
      title: record.content ? record.content.title : '',
      listName: record.content ? record.content.listName : '',
      metaTitle: record.content ? record.content.metaTitle : '',
      metaDescription: record.content ? record.content.metaDescription : '',
      bobbleDescription: record.content ? record.content.bobbleDescription : '',
      metaImage: record.content ? record.content.metaImage : '',
      metaUrl: record.content ? record.content.metaUrl : '',
      degree: record.degree,
      spotlight: record.spotlight,
      shortcode: record.shortcode,
    });
    setContent(record.content && record.content.content ? record.content.content : '');
    if (record.content && record.content.bubbles && record.content.bubbles.length > 0) {
      setBubbles(record.content.bubbles);
    } else setBubbles([]);
  };

  const handleActivate = async (id, checked) => {
    try {
      setWorking(true);
      await Axios.patch(
        `${SERVER_URL}/rankings-universal/${id}`,
        {
          active: checked,
        },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );

      setWorking(false);
      setFetchTrigger(!fetchTrigger);

      notification.success({
        message: 'Success',
        description: 'Ranking updated',
      });
    } catch (error) {
      console.log(error);
      setWorking(false);
      notification.error({
        message: 'Error',
        description: 'Problem with (de)activating ranking. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  columns.push(
    {
      title: 'Content',
      render: (text, record) => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '15px',
            }}
          >
            <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
              <Tooltip title='Edit Content' placement='topLeft'>
                <EditOutlined style={{ textDecoration: 'none', color: 'black' }} onClick={() => modalHandler(record)} />
              </Tooltip>
            </div>
          </div>
        );
      },
    },
    // {
    //   title: 'New Content',
    //   render: (text, record) => {
    //     return (
    //       <div
    //         style={{
    //           display: 'flex',
    //           justifyContent: 'center',
    //           alignItems: 'center',
    //           fontSize: '15px',
    //         }}
    //       >
    //         {record.isProgramLevel && (
    //           <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
    //             <Tooltip title='Edit new design content'>
    //               <EditOutlined
    //                 style={{ textDecoration: 'none', color: 'black' }}
    //                 onClick={() => history.push(`/admin/rankings-content/${record._id}`)}
    //               />
    //             </Tooltip>

    //             {record.useNewTemplate && (
    //               <Tooltip title='Using new design'>
    //                 <CheckOutlined style={{ color: 'green', marginLeft: '10px' }} />
    //               </Tooltip>
    //             )}
    //           </div>
    //         )}
    //       </div>
    //     );
    //   },
    // },
    {
      title: 'Action',
      render: (text, record) => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '15px',
            }}
          >
            {record.category !== 'Search' && (
              <Tooltip title='Edit Ranking' placement='topLeft'>
                <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='table-actions'>
                  <EditOutlined onClick={() => history.push(`/admin/rankings/graduate/${record._id}`)} />
                </div>
              </Tooltip>
            )}

            {deleteHandler && (
              <Tooltip title='Delete Raking' placement='topLeft'>
                {record.category !== 'Search' && (
                  <div
                    style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}
                    className='table-actions'
                    onClick={async () => {
                      deleteHandler(record);
                    }}
                  >
                    <DeleteOutlined />
                  </div>
                )}
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      title: 'Active',
      render: (text, record) => {
        return (
          <Switch
            checked={record.active}
            onChange={(checked) => handleActivate(record._id, checked)}
            disabled={record.degree === 'Undergraduate Certificate or Diploma' || record?.parent?.active === false}
          />
        );
      },
    },
  );

  let tableData = [];
  if (data && data?.length > 0) {
    tableData = data.map((item) => {
      item.createdAt = new Date(item.createdAt).toLocaleString('en-US');
      item.updatedAt = new Date(item.updatedAt).toLocaleString('en-US');
      return item;
    });
  }

  const onOk = async () => {
    if (!modal.title || modal.title === '') {
      titleInput.current.focus();
      notification.error({
        message: '<h1> title is required.',
        placement: 'topRight',
        duration: 2,
      });
      return;
    }

    try {
      setWorking(true);
      const body = {
        title: modal.title,
        content,
        listName: modal.listName,
        metaTitle: modal.metaTitle,
        metaDescription: modal.metaDescription,
        bobbleDescription: modal.bobbleDescription,
        metaUrl: modal.metaUrl,
        metaImage: modal.metaImage,
        bubbles: bubbles,
        spotlight: modal.spotlight,
        shortcode: modal.shortcode,
      };
      if (modal.category !== 'Search') {
        await Axios.patch(`${SERVER_URL}/rankings-programs/${modal.id}`, body, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
      } else {
        body.grad = true;
        delete body.bubbles;
        delete body.listName;
        await Axios.post(`${SERVER_URL}/rankings-contents?type=search-grad`, body, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
      }

      setWorking(false);

      setModal({ visible: false });
      notification.success({
        message: 'Content saved',
        placement: 'bottomRight',
        duration: 2,
      });

      setFetchTrigger(!fetchTrigger);
      setRefresh(Math.random());
    } catch (error) {
      console.log('Error on save content', error);
      setWorking(false);
      setModal({ visible: false });
      notification.error({
        message: 'Problem with saving content',
        placement: 'bottomRight',
        duration: 2,
      });
    }
  };

  const handleRefresh = async () => {
    if (!selectedForRefresh || selectedForRefresh.length === 0) return;
    setWorking(true);
    try {
      for (const selectedID of selectedForRefresh) {
        const foundRanking = data.find((item) => item._id === selectedID);

        if (foundRanking && foundRanking.category === 'Search') {
          // grad search
          await Axios.post(
            `${SERVER_URL}/rankings-grad-search-save`,
            {},
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${currentuser.data.token}` },
            },
          );
        } else if (foundRanking) {
          const newMethodRes = await Axios.get(
            `${SERVER_URL}/rankings-new-methodology-creation?degree=Master's Degree&cipcode=${foundRanking.cipcode}&isProgramLevel=true`,
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${currentuser.data.token}` },
            },
          );

          await Axios.patch(
            `${SERVER_URL}/rankings-universal/${selectedID}`,
            {
              items: newMethodRes.data.ranking.rankingItems,
              weightedAveragesNational: newMethodRes.data.ranking.weightedAveragesNational,
            },
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${currentuser.data.token}` },
            },
          );
        } else return;

        // replace school links in rankings
        await Axios.get(`${SERVER_URL}/rankings-update-sp-link?id=${selectedID}`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
      }

      // sitemap
      await Axios.get(`${SERVER_URL}/rankings-sitemap-refresh?type=grad`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: 'Graduate rankings refreshed.',
        placement: 'bottomRight',
      });

      setFetchTrigger(!fetchTrigger);

      setWorking(false);
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Problem with refreshing graduate rankings. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  const onSelectChange = (selectedRowKeys) => {
    setSelectedForRefresh([...selectedRowKeys]);
  };

  const rowSelection = {
    selectedForRefresh,
    onChange: onSelectChange,
  };

  const handleSitemap = async () => {
    try {
      setWorking(true);

      // sitemap
      await Axios.get(`${SERVER_URL}/rankings-sitemap-refresh?type=grad`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: 'BGP Sitemap updated',
        placement: 'bottomRight',
      });

      setWorking(false);
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Problem with refreshing BGP sitemap',
        placement: 'bottomRight',
      });
    }
  };

  const handleSPLinks = async () => {
    try {
      setWorking(true);

      // replace school links in rankings
      await Axios.get(`${SERVER_URL}/rankings-update-sp-link?degree=Master's Degree`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: 'BGP SP links updated',
        placement: 'bottomRight',
      });

      setWorking(false);
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Problem with refreshing BGP school profile links',
        placement: 'bottomRight',
      });
    }
  };

  const handleRefreshAll = async () => {
    try {
      setWorking(true);

      // STEP 1: refresh program level
      const cipcodesBody = data.map((item) => ({
        cipcode: item.cipcode,
        category: item.category,
        categoryUrl: item.categoryUrl,
        degree: item.degree,
        parent: item.parent,
      }));

      await Axios.patch(
        `${SERVER_URL}/rankings?type=grad&isProgramLevel=true`,
        { cipcodes: cipcodesBody },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );

      // STEP 2: refresh grad search
      await Axios.post(
        `${SERVER_URL}/rankings-grad-search-save`,
        {},
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );

      // STEP 3: refresh sitemap and empty pages
      await Axios.get(`${SERVER_URL}/rankings-sitemap-refresh?type=grad`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      // STEP 4: replace school links in rankings
      await Axios.get(`${SERVER_URL}/rankings-update-sp-link?degree=Master's Degree`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      setWorking(false);
      setFetchTrigger(!fetchTrigger);

      notification.success({
        message: 'Rankings saved.',
        placement: 'bottomRight',
      });
    } catch (error) {
      setWorking(false);
      console.log(error.response);
      notification.error({
        message: 'Problem with refreshing rankings. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  return (
    <div className='content-wrapper'>
      <Spin spinning={working} tip='Working...'>
        <div className='actions-block flex'>
          <Link to={'/admin/rankings/new/graduate'}>
            <Tooltip title='Create new ranking'>
              <Button type='primary'>
                <PlusCircleOutlined />
                NEW RANKING
              </Button>
            </Tooltip>
          </Link>

          <Tooltip title='/best-graduate-programs'>
            <Button type='danger' onClick={handleSitemap}>
              <SyncOutlined spin={working} />
              Refresh Sitemap
            </Button>
          </Tooltip>

          <Button type='danger' onClick={handleSPLinks}>
            <SyncOutlined spin={working} />
            Refresh SP Links
          </Button>

          <Button type='danger' onClick={handleRefreshAll}>
            <SyncOutlined spin={working} />
            Refresh All
          </Button>

          <Button
            type='danger'
            onClick={handleRefresh}
            disabled={!selectedForRefresh || selectedForRefresh.length === 0}
          >
            Refresh Selected Ranking(s)
          </Button>

          <h2>Total: {data.length}</h2>
        </div>
        <div>
          <Table
            size='middle'
            // onChange={onChangeTable}
            bordered
            dataSource={tableData}
            columns={columns}
            rowKey={(record) => record._id}
            rowSelection={rowSelection}
            pagination={{
              pageSize: 20,
              // total: total || 0,
              // current: page,
              // defaultCurrent: 1,
              // position: 'bottom',
              // showSizeChanger: false,
              // onChange: (page) => pageHandler(page),
              // hideOnSinglePage: true,
            }}
          />
        </div>
        <Modal
          centered
          width='70%'
          visible={modal.visible}
          title={
            <span>
              EDIT - <strong>{modal.category} (GRADUATE)</strong>
            </span>
          }
          okText='SAVE'
          cancelText='CANCEL'
          maskClosable={false}
          onOk={onOk}
          onCancel={() => {
            setModal({ visible: false });
            setFetchTrigger(!fetchTrigger);
          }}
          confirmLoading={working}
        >
          <div>
            <p style={{ fontSize: 15, marginTop: 20, marginRight: 10 }}>{'<h1> '}</p>
            <Input
              name='title'
              value={modal.title}
              placeholder={'Title for <h1>...'}
              onChange={(e) => setModal({ ...modal, title: e.target.value })}
              ref={titleInput}
            />
          </div>
          <br></br>
          <div>
            <p style={{ fontSize: 15, marginTop: 20, marginRight: 10 }}>Content </p>
            <CKEditor content={content} setContent={setContent} />
          </div>
          <br></br>
          {modal.category !== 'Search' && (
            <>
              <Collapse>
                <Collapse.Panel key='1' header='Bubbles'>
                  <BubblesList data={bubbles} handleBubbles={setBubbles} refresh={refresh} />
                </Collapse.Panel>
              </Collapse>
            </>
          )}
          <br></br>
          {modal.category !== 'Search' && (
            <div>
              <p style={{ fontSize: 15, marginTop: 20, marginRight: 10 }}>List Name </p>
              <Input
                name='listName'
                value={modal.listName}
                placeholder={'Example List Name'}
                onChange={(e) => setModal({ ...modal, listName: e.target.value })}
              />
            </div>
          )}
          <br></br>
          <div>
            <p style={{ fontSize: 15, marginTop: 20, marginRight: 10 }}>Meta Title</p>
            <Input
              name='metaTitle'
              value={modal.metaTitle}
              onChange={(e) => setModal({ ...modal, metaTitle: e.target.value })}
              maxLength={70}
            />
          </div>
          <br></br>
          <div>
            <p style={{ fontSize: 15, marginTop: 20, marginRight: 10 }}>Meta Description</p>
            <TextArea
              name='metaDescription'
              value={modal.metaDescription}
              maxLength={180}
              rows={3}
              onChange={(e) => setModal({ ...modal, metaDescription: e.target.value })}
            />
          </div>
          <br></br>
          <div>
            <p style={{ fontSize: 15, marginTop: 20, marginRight: 10 }}>Meta Url</p>
            <Input
              name='metaUrl'
              value={modal.metaUrl}
              onChange={(e) => setModal({ ...modal, metaUrl: e.target.value })}
            />
          </div>
          <br></br>
          <div>
            <p style={{ fontSize: 15, marginTop: 20, marginRight: 10 }}>Meta Image</p>
            <Input
              name='metaImage'
              value={modal.metaImage}
              onChange={(e) => setModal({ ...modal, metaImage: e.target.value })}
            />
          </div>
          <br></br>
          <div>
            {modal.metaImage && (
              <img
                src={modal.metaImage}
                style={{
                  width: '200px',
                  border: '0.5px solid #bbb',
                  background: '#eee',
                  borderRadius: '5px',
                  cursor: 'pointer',
                }}
                alt='meta'
                // title='Select from gallery or upload'
                // onClick={() => logoHandler()}
              />
            )}
          </div>

          <div>
            <p style={{ fontSize: 15, marginTop: 20, marginRight: 10 }}>Bubble Description</p>
            <TextArea
              name='bobbleDescription'
              value={modal.bobbleDescription}
              maxLength={180}
              rows={3}
              onChange={(e) => setModal({ ...modal, bobbleDescription: e.target.value })}
            />
          </div>
          {/* SPOTLIGHT */}
          <div style={{ marginTop: '20px', marginBottom: '10px' }}>
            <label htmlFor='spotlight'>Spotlight: </label>
            <Select
              allowClear
              showSearch
              name='spotlight'
              value={modal.spotlight}
              onChange={(value) => setModal({ ...modal, spotlight: value })}
              style={{ width: '400px', marginLeft: '4px', fontSize: '15px' }}
              placeholder='Select spotlight...'
            >
              {allSpotlights.map((item) => (
                <Select.Option key={`${item._id}`} value={item._id}>
                  {item.CMSDisplayName}
                </Select.Option>
              ))}
            </Select>
          </div>
          {/* SHORTCODE */}
          <div style={{ marginTop: '20px', marginBottom: '10px' }}>
            <label htmlFor='shortcode'>Shortcode </label>
            <TextArea
              name='shortcode'
              value={modal.shortcode}
              rows={2}
              onChange={(e) => setModal({ ...modal, shortcode: e.target.value })}
            />
          </div>
        </Modal>
      </Spin>
    </div>
  );
};

export default Graduate;
