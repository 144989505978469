import React, { useEffect, useContext, useState, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Axios from 'axios';
import queryString from 'query-string';
import {
  Spin,
  Select,
  Table,
  Tag,
  Button,
  Input,
  Tooltip,
  notification,
  Form,
  InputNumber,
  ConfigProvider,
} from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { IPEDSTopListItemModal } from '../../components/forms';
import { UserContext } from '../../App';
import { ExportToExcel } from '../../components/csv';
import { SERVER_URL } from '../../config';
import CIP_CODES from '../../lib/cscCipcodes.json';
import Checkbox from 'antd/lib/checkbox/Checkbox';

const DEGREES = [
  { label: "Associate's", value: "Associate's Degree" },
  { label: "Bachelor's", value: 'Bachelors Degree' },
  { label: "Master's", value: "Master's Degree" },
];

const INITIAL_OPTIONS = { cipcode: null, degree: 'Bachelors Degree', limit: 10 };

const INITIAL_MODAL = { visible: false, name: null, link: null, index: null, position: null, unitid: null };

const EditIPEDSTopList = (props) => {
  const currentuser = useContext(UserContext);
  const history = useHistory();
  const { id } = props.match.params;

  const [form] = Form.useForm();
  const [topList, setTopList] = useState([]);
  const [topListName, setTopListName] = useState(null);
  const [topListTitle, setTopListTitle] = useState(null);
  const [options, setOptions] = useState(INITIAL_OPTIONS);
  const [isWorking, setWorking] = useState(false);
  const [isWorking2, setWorking2] = useState(false);
  const [fetchTrigger, setTrigger] = useState(id ? false : true);
  const [fetchTrigger2, setTrigger2] = useState(false);
  const [types, setTypes] = useState([]);
  const [selectedType, setSelectedType] = useState();
  const [topListByType, setTopListByType] = useState([]);
  const [modal, setModal] = useState(INITIAL_MODAL);
  const [includeWidgets, setIncludeWidgets] = useState(false);
  const [includeWidgetsMajor, setIncludeWidgetsMajor] = useState(false);
  const [widgetsName, setWidgetsName] = useState(null);
  const [widgetTitle, setWidgetTitle] = useState(null);
  const [majors, setMajors] = useState([]);
  const [selectedMajor, setSelectedMajor] = useState(null);
  const majorRef = useRef(null);
  const [allSchools, setAllSchools] = useState([]);
  const [sponsoredSchool, setSponsoredSchool] = useState(null);
  const [sponsoredSchoolData, setSponsoredSchoolData] = useState(null);
  const [position, setPosition] = useState(null);
  const [customize, setCustomize] = useState(false);
  const [sponsoredPositions, setSponsoredPositions] = useState(null);

  const formInit = {
    kpiLongTermMajor: selectedMajor,
  };

  const customizeRenderEmpty = () => (
    <div style={{ textAlign: 'center' }}>
      <p>Fetching data...</p>
    </div>
  );

  useEffect(() => {
    const fetchToplist = async () => {
      setWorking(true);

      let urlQuery = '?year=2020';
      if (options) urlQuery += `&${queryString.stringify(options)}`;

      try {
        const { data } = await Axios.get(`${SERVER_URL}/ipeds-get-toplist${urlQuery}`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });

        if (data.length > 0) {
          setTopList(data);
          setTopListName(data[0].topList);
          // setTopListTitle(data.topListName.substr(6));
          setTypes(data.map(({ type }) => type));
          setSelectedType('National');
          setIncludeWidgetsMajor(false);
        } else {
          setTopList([]);
          setTopListName(null);
          // setTopListTitle(null);
          setTypes([]);
          setSelectedType();
        }
      } catch (error) {
        console.log(error);
      }

      setTrigger(false);
      setWorking(false);
    };

    if (fetchTrigger && options.cipcode) {
      fetchToplist();
    }
  }, [fetchTrigger, options, currentuser.data.token]);

  useEffect(() => {
    if (includeWidgetsMajor && majors.length === 0) {
      const fetchMajors = async () => {
        try {
          const { data } = await Axios.get(`${SERVER_URL}/ipeds-majors`, {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` },
          });

          if (data) {
            setMajors(data);
          }
        } catch (error) {
          console.log(error);
        }
      };
      if (majors.length === 0) {
        fetchMajors();
      }
    }
  }, [includeWidgetsMajor, currentuser.data.token]);

  useEffect(() => {
    if (id) {
      const fetchToplistById = async () => {
        setWorking(true);
        try {
          const { data } = await Axios.get(`${SERVER_URL}/ipeds-toplists/${id}`, {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` },
          });

          if (data) {
            setTopList(data.topListItems);
            setTopListName(data.topListName);
            setTopListTitle(data.topListTitle);
            setOptions({ cipcode: data.cipcode, degree: data.degree, limit: data.limit });
            setTypes(data.topListItems.map(({ type }) => type));
            setSelectedType('National');
            if (data.includeWidgetsMajor) {
              setWidgetsName(data.widgetsName);
              setWidgetTitle(data.widgetTitle);
            }
            if (data.includeWidgetsMajor) {
              setIncludeWidgetsMajor(true);
              setSelectedMajor(data.kpiLongTermMajor);
            }
            form.setFieldsValue({
              kpiLongTermMajor: data.kpiLongTermMajor,
            });
          }
        } catch (error) {
          console.log(error);
        }
        setWorking(false);
      };

      fetchToplistById();
    }
  }, [id, currentuser.data.token]);

  useEffect(() => {
    if (topList.length > 0 && selectedType) {
      const selectedTopList = topList.find((list) => list.type === selectedType);
      if (sponsoredSchoolData && sponsoredSchoolData.length > 0) {
        const sponsored = selectedTopList.items.find((item) => item.sponsored);
        const indexOfSponsored = selectedTopList.items.indexOf(sponsored);
        const removeElement = selectedTopList.items.splice(indexOfSponsored, 1)[0];
        selectedTopList.items.splice(removeElement.position - 1, 0, removeElement);
      }
      const sponsoredArr = selectedTopList.items.filter((item) => item.sponsored);
      const sponsoredPositionsArr = sponsoredArr.map((item) => item.position);

      setSponsoredPositions(sponsoredPositionsArr);
      setTopListByType([...selectedTopList.items]);
    }
  }, [topList, selectedType]);

  useEffect(() => {
    const fetchAllSchools = async () => {
      if (topList && topList.length > 0) {
        setCustomize(!customize);
        setWorking2(true);
        try {
          const data = await Axios.get(
            `${SERVER_URL}/ipeds-institution-list?cipcode=${options.cipcode}&degree=${options.degree}`,
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${currentuser.data.token}` },
            },
          );

          let filteredData = [];
          if (data) {
            if (selectedType === 'National') {
              setAllSchools(data.data);
            } else if (selectedType === 'Online') {
              data.data.forEach((school) => {
                if (school.programs.find((program) => program.distance['All programs'] > 0)) {
                  filteredData.push(school);
                }
              });
              setAllSchools(filteredData);
            } else {
              data.data.forEach((school) => {
                if (school.state === selectedType) {
                  filteredData.push(school);
                }
              });
              setAllSchools(filteredData);
            }
            setSponsoredSchool(null);
          }
        } catch (error) {
          console.log(error);
        }
        setWorking2(false);
        // setCustomize(false);
      }
    };
    fetchAllSchools();
  }, [topList, options.cipcode, options.degree, selectedType, currentuser.data.token]);

  useEffect(() => {
    const fetchSchoolById = async () => {
      setWorking(true);
      try {
        const data = await Axios.get(
          `${SERVER_URL}/ipeds-get-toplist/${sponsoredSchool}?cipcode=${options.cipcode}&degree=${options.degree}`,
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` },
          },
        );
        setSponsoredSchoolData(data.data.filteredData);
      } catch (error) {
        setWorking(false);
        console.log(error);
      }
      setWorking(false);
      // setTrigger2(false);
    };
    fetchSchoolById();
  }, [fetchTrigger2, currentuser.data.token]);

  // const sorter = (a, b, prop) => {
  //   return a[prop] - b[prop];
  // };

  const optionChange = (value, option) => {
    setOptions((prev) => ({ ...prev, [option]: value || null }));
    setTrigger(true);
  };

  const editListItem = (record, index) => {
    setModal({
      visible: true,
      name: record.name,
      link: record.link,
      index,
      position: record.sponsored ? record.position : null,
      unitid: record.unitid ? record.unitid : null,
    });
  };

  const handleSubmit = async (values) => {
    if (includeWidgets && !id) {
      await saveTopList(values);
    } else if (!includeWidgets && id) {
      await saveTopList(values);
    } else if (includeWidgets && id) {
      await saveTopList(values);
    } else if (!includeWidgets && !id) {
      await saveTopList(values);
    } else {
      return;
    }
  };

  const columns = [
    {
      title: 'rank',
      dataIndex: ['rank'],
      width: 20,
      key: 'rank',
      render: (text, record, index) => {
        if (record.sponsored && record.sponsored === true) {
          return <Tag color='#f50'>Sponsored</Tag>;
        } else {
          return <div>{text}</div>;
        }
      },
    },
    {
      title: 'unitid',
      dataIndex: ['unitid'],
      align: 'center',
      width: 100,
      key: 'unitid',
    },
    {
      title: 'name',
      dataIndex: ['name'],
      width: 350,
      key: 'name',
      render: (text, record, index) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Tooltip title={record.link} color='blue' placement='topLeft'>
            <a href={record.link} target='_blank' rel='noreferrer'>
              {text}
            </a>
          </Tooltip>

          <Tooltip title='Edit Link' destroyTooltipOnHide={{ keepParent: false }}>
            <EditOutlined style={{ padding: '4px', cursor: 'pointer' }} onClick={() => editListItem(record, index)} />
          </Tooltip>
        </div>
      ),
    },
    {
      title: 'state',
      dataIndex: ['state'],
      width: 150,
      key: 'state',
    },
    {
      title: 'Earning',
      dataIndex: ['earnings'],
      width: 100,
      align: 'right',
      key: 'earnings',
      render: (text, { earningsUsed }) => {
        if (earningsUsed) {
          return (
            <Tooltip title={`${earningsUsed} earnings`} color='blue' placement='left'>
              {text ? text.toLocaleString('us', { maximumFractionDigits: 0 }) : text ?? 'N/A'}
            </Tooltip>
          );
        }
        return text ? text.toLocaleString('us', { maximumFractionDigits: 0 }) : text ?? 'N/A';
      },
      sorter: true,
    },
    {
      title: ["Master's Degree", 'Doctoral Degree'].includes(options?.degree) ? 'debt' : 'payback',
      dataIndex: ["Master's Degree", 'Doctoral Degree'].includes(options?.degree) ? ['debt'] : ['payback'],
      width: 100,
      align: 'right',
      key: ["Master's Degree", 'Doctoral Degree'].includes(options?.degree) ? 'debt' : 'payback',
      render: (text) => (text ? text.toLocaleString('us', { maximumFractionDigits: 2 }) : text ?? 'N/A'),
      sorter: true,
    },
    {
      title: 'degree premium',
      dataIndex: ['degreePremium'],
      width: 150,
      align: 'right',
      key: 'degreePremium',
      render: (text) => (text ? text.toLocaleString('us', { maximumFractionDigits: 0 }) : text ?? 'N/A'),
      sorter: true,
    },
    {
      title: 'Graduation rate',
      dataIndex: ['graduationRate'],
      width: 150,
      align: 'right',
      key: 'graduationRate',
      render: (text, { belowAverageGR }) => (
        <Tag color={belowAverageGR ? 'red' : 'green'}>
          {text ? text.toLocaleString('us', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : text ?? 'N/A'}
        </Tag>
      ),
      sorter: true,
    },
  ];

  if (["Master's Degree", 'Doctoral Degree'].includes(options?.degree)) {
    columns.splice(columns.length - 1, 1);

    columns.splice(
      5,
      1,
      {
        title: 'Debt',
        dataIndex: ['debt'],
        width: 100,
        align: 'right',
        key: 'debt',
        render: (text) => (text ? text.toLocaleString('us') : text ?? 'N/A'),
        sorter: true,
        // sortDirections: ['ascend', 'descend'],
      },
      {
        title: 'Debt to earning ratio',
        dataIndex: ['debtToEarningRatio'],
        width: 180,
        align: 'right',
        key: 'debtToEarningRatio',
        render: (text) =>
          text ? text.toLocaleString('us', { minimumFractionDigits: 3, maximumFractionDigits: 3 }) : text ?? 'N/A',
        sorter: true,
        // sortDirections: ['ascend', 'descend'],
      },
    );
  }

  let filename = '';
  let excelData = [];
  if (topList.length > 0) {
    filename = topList[0].topList;
    excelData = topList
      .map(({ type, items }) => {
        const list = items?.map((item) => ({ type, ...item }));
        return list;
      })
      .flat(1);
  }

  Array.prototype.insert = function (index, item) {
    this.splice(index, 0, item);
  };

  const handleSponsored = async () => {
    // setWorking(true);
    if (sponsoredPositions.includes(position)) {
      notification.error({
        message: `That position is already taken by another sponsored school.`,
        placement: 'topRight',
        duration: 4,
      });
      return;
    }
    if (!sponsoredSchool || !position) {
      notification.error({
        message: `Please select the sponsored school and it's position`,
        placement: 'topRight',
        duration: 4,
      });
    }
    const newTopListState = [...topList];

    const topListType = newTopListState.find((list) => list.type === selectedType);
    topListType.items.forEach((item, index) => {
      item.sponsored = item.sponsored ? item.sponsored : false;
      item.position = item.position ? item.position : item.rank ?? index + 1;
    });
    if (sponsoredSchoolData.length > 0) {
      sponsoredSchoolData[0].sponsored = true;
      sponsoredSchoolData[0].position = position;
      // const includesSponsored = topListType.items.some((item) => item.sponsored);
      const sponsoredArr = topListType.items.filter((item) => item.sponsored);

      topListType.items.map((item) => {
        if (position && position <= item.position && !item.sponsored) {
          item.position = item.position + 1;
        }
      });

      if (sponsoredArr && sponsoredArr.length > 0) {
        const arr = [];
        sponsoredArr.forEach((item) => arr.push(item.position));
        topListType.items.forEach((item, index) => {
          if (arr.includes(item.position) && !item.sponsored) {
            item.position = item.position + 1;
          }
        });
      }
      topListType.items.insert(position - 1, sponsoredSchoolData[0]);
    }
    topListType.items.sort((a, b) => {
      if (a.position > b.position) {
        return 1;
      } else if (a.position < b.position) {
        return -1;
      } else return 0;
    });

    // topListType.items.forEach((item, index) => {
    //   if (item.position - index > 1 && !item.sponsored) {
    //     // console.log('ITEM', item);
    //     topListType.items.splice(index, 1);
    //     topListType.items.splice(item.position - 1, 0, item);
    //   }
    // });

    const topListTypeIndex = newTopListState.findIndex((list) => list.type === selectedType);
    newTopListState.splice(topListTypeIndex, 1, topListType);

    setPosition(null);
    setSponsoredSchool(null);
    setSponsoredSchoolData([]);
    setTopList([...newTopListState]);

    // setWorking(false);
  };

  const handleDeleteSponsored = (unitid) => {
    const newTopListState = [...topList];
    const topListType = topList.find((item) => item.type === selectedType);
    const newArr = topListType.items.filter((item) => item.unitid !== parseInt(unitid));
    const removeItem = topListType.items.find((item) => item.unitid === parseInt(unitid));
    const sponsoredArr = topListType.items.filter((item) => item.sponsored);
    const sponsoredMap = sponsoredArr.map((item) => item.position);

    // set correct positions after delete
    newArr.forEach((item) => {
      if (removeItem.position < item.position && !item.sponsored && !sponsoredMap.includes(item.position)) {
        item.position = item.position - 1;
      }
      if (removeItem.position < item.position && !item.sponsored && sponsoredMap.includes(item.position)) {
        item.position = item.position - 1;
      }
    });

    newArr.sort((a, b) => {
      if (a.position > b.position) {
        return 1;
      } else if (a.position < b.position) {
        return -1;
      } else return 0;
    });
    topListType.items = [...newArr];
    const topListTypeIndex = newTopListState.findIndex((list) => list.type === selectedType);
    newTopListState.splice(topListTypeIndex, 1, topListType);
    setTopList([...newTopListState]);
    setModal(INITIAL_MODAL);
  };

  const saveTopList = async (values) => {
    setWorking(true);

    // if (!topListTitle) {
    //   notification.error({
    //     message: 'Top list title is required!',
    //     placement: 'topRight',
    //     duration: 0,
    //   });
    //   setWorking(false);
    //   return;
    // }

    // if (includeWidgets) {
    // if (!selectedMajor) {
    //   majorRef.current.focus();
    //   notification.error({
    //     message: 'Major for KPI long term (chart) widget is required!',
    //     placement: 'topRight',
    //     duration: 0,
    //   });
    //   setWorking(false);
    //   return;
    // }

    // if (!selectedAreaOfStudy) {
    //   scholarshipsRef.current.focus();
    //   notification.error({
    //     message: 'Area of study for Scholarship box widget is required!',
    //     placement: 'topRight',
    //     duration: 0,
    //   });
    //   setWorking(false);
    //   return;
    // }

    // if (!widgetsName) {
    //   notification.error({
    //     message: 'Widgets name is required!',
    //     placement: 'topRight',
    //     duration: 0,
    //   });
    //   setWorking(false);
    //   return;
    // }

    // if (!widgetTitle) {
    //   notification.error({
    //     message: 'Widgets title is required!',
    //     placement: 'topRight',
    //     duration: 0,
    //   });
    //   setWorking(false);
    //   return;
    // }
    // }

    const saveData = {
      topListName: topListName,
      topListID: topList[0].topListID,
      //topListTitle,
      // includeWidgets,
      includeWidgetsMajor,
      cipcode: options.cipcode,
      degree: options.degree,
      limit: options.limit,
      //widgetsName,
      //widgetTitle,
      kpiLongTermMajor: values.kpiLongTermMajor,
      topListItems: topList.map(({ type, items, topListID }) => {
        const list = items.map((item, index) => ({
          sponsored: item.sponsored ? item.sponsored : false,
          position: item.rank ?? index + 1,
          ...item,
        }));

        return {
          type,
          topListID,
          items: list,
        };
      }),
    };

    try {
      const method = id ? 'put' : 'post';
      const route = `${SERVER_URL}/${id ? 'ipeds-toplists/' + id : 'ipeds-toplists'}`;

      await Axios[method](route, saveData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: `IPEDS Top List ${id ? 'updated' : 'created.'}`,
        placement: 'topRight',
      });

      setWorking(false);

      history.push('/admin/ipeds-top-lists');
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message;
      console.log(error);
      console.log(msg);
      notification.error({
        message: msg,
        placement: 'topRight',
      });

      setWorking(false);
    }
  };

  const handleTopListName = (event) => {
    setTopListName(event.target.value);
  };

  const modalOnSubmit = (value, index) => {
    const newTopListState = [...topList];

    const topListType = newTopListState.find((list) => list.type === selectedType);
    if (value.link) {
      topListType.items[index].link = value.link;
    }
    if (value.position) {
      if (topListType.items[index].position !== value.position) {
        topListType.items[index].position = value.position;
        const removeElement = topListType.items.splice(index, 1)[0];
        topListType.items.splice(removeElement.position - 1, 0, removeElement);
      }
    }

    topListType.items.forEach((item, index) => {
      if (value.position && value.position >= item.position && !item.sponsored) {
        item.position = index + 1;
      }
    });

    const topListTypeIndex = newTopListState.findIndex((list) => list.type === selectedType);
    newTopListState.splice(topListTypeIndex, 1, topListType);

    setTopList([...newTopListState]);
    setModal(INITIAL_MODAL);
  };

  const onCancel = () => setModal(INITIAL_MODAL);

  const getTopListTypeLength = (type) => {
    const topListType = topList.find((list) => list.type === type);

    if (!topListType) return 0;

    return topListType.items?.length;
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const topListType = topList.find((list) => list.type === selectedType);
    const noSponsoredArr = topListType.items.filter((item) => !item.sponsored);
    const sponsoredArr = topListType.items.filter((item) => item.sponsored);
    if (!sorter.order) {
      setTopListByType([...topListType.items]);
      return;
    }
    if (sorter.order === 'ascend') {
      noSponsoredArr.sort((a, b) => {
        // ?
        return a[sorter.columnKey] - b[sorter.columnKey];
      });
      sponsoredArr.forEach((item) => noSponsoredArr.insert(item.position - 1, item));
      setTopListByType([...noSponsoredArr]);
    } else if (sorter.order === 'descend') {
      noSponsoredArr.sort((a, b) => {
        if (a[sorter.columnKey] > b[sorter.columnKey]) {
          return -1;
        }
      });
      sponsoredArr.forEach((item) => noSponsoredArr.insert(item.position - 1, item));
      setTopListByType([...noSponsoredArr]);
    }
  };

  return (
    <div className='content-wrapper'>
      <Spin spinning={isWorking} tip='Working...'>
        <div className='actions-block'>
          <Link to='/admin/ipeds-top-lists'>
            <Button type='primary'>All IPEDS Top Lists</Button>
          </Link>
        </div>

        <div className='actions-block flex'>
          <ExportToExcel csvData={excelData} fileName={filename} propsToRemove={['_id']} disabled={!options?.cipcode} />

          <Select
            value={options?.limit}
            onChange={(value) => optionChange(value, 'limit')}
            style={{ width: '100px', marginLeft: '4px' }}
            placeholder='Limit...'
          >
            <Select.Option value={10}>10</Select.Option>
            <Select.Option value={20}>20</Select.Option>
            <Select.Option value={50}>50</Select.Option>
          </Select>

          <Select
            value={options?.degree}
            onChange={(value) => {
              form.setFieldsValue({
                kpiLongTermMajor: null,
              });
              return optionChange(value, 'degree');
            }}
            style={{ width: '200px', marginLeft: '4px' }}
            placeholder='Degree...'
          >
            {DEGREES.map(({ value, label }, index) => (
              <Select.Option key={`${value}_${index}`} value={value}>
                {label}
              </Select.Option>
            ))}
          </Select>

          <Select
            allowClear
            showSearch
            value={options?.cipcode}
            optionFilterProp='children'
            onChange={(value) => optionChange(value, 'cipcode')}
            style={{ width: '350px', fontSize: '11px', marginLeft: '4px' }}
            placeholder='Program...'
          >
            {CIP_CODES.map((cip, index) => (
              <Select.Option
                key={`${cip.code}_${index}`}
                value={cip.code}
                style={{ fontSize: '11px' }}
                title={cip.label}
              >
                {cip.code} - {cip.label}
              </Select.Option>
            ))}
          </Select>
        </div>

        {topList.length > 0 ? (
          <>
            <div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Select
                  showSearch
                  optionFilterProp='children'
                  value={selectedType}
                  onChange={(value) => setSelectedType(value)}
                  style={{ width: '250px', marginLeft: '4px', marginBottom: '8px' }}
                  placeholder='Top list by type...'
                  disabled={types.length === 0}
                >
                  {types.map((type) => (
                    <Select.Option key={type} value={type}>
                      {type} ({getTopListTypeLength(type)})
                    </Select.Option>
                  ))}
                </Select>

                <Tooltip title='What You see in WP admin'>
                  <Input
                    placeholder='WP admin Top list name...'
                    value={topListName}
                    onChange={handleTopListName}
                    style={{ maxWidth: '400px', marginLeft: '4px', marginBottom: '8px' }}
                  />
                </Tooltip>

                {/* <Tooltip title='What visitor see in the frontend' color='#1c8380'>
                  <Input
                    placeholder='Frontend Top list title...'
                    value={topListTitle}
                    onChange={(e) => setTopListTitle(e.target.value)}
                    style={{ maxWidth: '400px', marginLeft: '4px', marginBottom: '8px' }}
                  />
                </Tooltip> */}
              </div>

              <Table
                columns={columns}
                dataSource={topListByType}
                rowKey={(record) => record.unitid}
                pagination={false}
                onChange={handleTableChange}
              />
            </div>

            <Form
              className='form-horizontal'
              layout='vertical'
              initialValues={formInit}
              onFinish={(values) => {
                handleSubmit(values);
              }}
              form={form}
            >
              <div style={{ marginTop: '1rem', color: 'black' }}>
                <ConfigProvider renderEmpty={customize && customizeRenderEmpty}>
                  <Select
                    id='sponsoredSchools'
                    showSearch
                    optionFilterProp='children'
                    value={sponsoredSchool || null}
                    onChange={(value) => {
                      setSponsoredSchool(value);
                      setTrigger2(!fetchTrigger2);
                    }}
                    style={{ width: '300px', marginLeft: '16px', marginRight: '10px' }}
                    placeholder='Sponsored school...'
                  >
                    {allSchools.map((item, index) => (
                      <Select.Option key={`${item.unitid}`} title={item.name}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </ConfigProvider>

                <InputNumber
                  min={1}
                  max={topListByType ? topListByType.length : options.limit}
                  value={position || null}
                  placeholder='Position'
                  onChange={(value) => setPosition(value)}
                  style={{ marginRight: '10px' }}
                />

                <Button type='secondary' onClick={handleSponsored}>
                  Add Sponsored School
                </Button>
              </div>

              <div style={{ marginTop: '2rem', color: 'black' }}>
                {/* <div>
                    <Tooltip title='What You see in WP admin'>
                      <Input
                        placeholder='WP admin Widgets name...'
                        value={widgetsName}
                        onChange={(e) => setWidgetsName(e.target.value)}
                        style={{ maxWidth: '400px', marginLeft: '4px', marginBottom: '8px' }}
                      />
                    </Tooltip>

                    <Tooltip title='What visitor see in the frontend' color='#1c8380'>
                      <Input
                        placeholder='Frontend Widget title...'
                        value={widgetTitle}
                        onChange={(e) => setWidgetTitle(e.target.value)}
                        style={{ maxWidth: '400px', marginLeft: '4px', marginBottom: '8px' }}
                      />
                    </Tooltip>
                  </div> */}

                {options.degree !== `Master's Degree` && (
                  <div style={{ marginTop: '1rem', color: 'black' }}>
                    <Checkbox
                      style={{ marginTop: '2rem' }}
                      checked={includeWidgetsMajor}
                      onChange={(e) => {
                        form.setFieldsValue({
                          kpiLongTermMajor: null,
                        });
                        return setIncludeWidgetsMajor(e.target.checked);
                      }}
                    >
                      Major for KPI long term (chart) widget
                    </Checkbox>
                    <Form.Item
                      name='kpiLongTermMajor'
                      rules={[{ required: includeWidgetsMajor ? true : false, message: 'Please select major' }]}
                    >
                      {includeWidgetsMajor && (
                        <Select
                          id='abc'
                          showSearch
                          optionFilterProp='children'
                          // value={selectedMajor}
                          onChange={(value) => setSelectedMajor(value)}
                          style={{ width: '300px', marginLeft: '16px' }}
                          placeholder='Major...'
                          disabled={!includeWidgetsMajor}
                          ref={majorRef}
                        >
                          {majors.map((major) => (
                            <Select.Option key={major} value={major} style={{ fontSize: '0.725rem' }}>
                              {major}
                            </Select.Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>
                  </div>
                )}
              </div>

              <div style={{ marginTop: '1rem', textAlign: 'right' }}>
                <Form.Item>
                  <Button htmlType='submit' type='primary' disabled={topList.length === 0}>
                    SAVE TOP LIST
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </>
        ) : (
          <div style={{ textAlign: 'center', marginTop: '2rem' }}>
            <h2>
              {options.cipcode && !isWorking
                ? `No data for ${options.degree} in ${
                    CIP_CODES.find((program) => program.code === options.cipcode).label
                  }`
                : !isWorking
                ? 'Please select program...'
                : null}
            </h2>
          </div>
        )}
      </Spin>

      <IPEDSTopListItemModal
        {...modal}
        onSubmit={modalOnSubmit}
        onCancel={onCancel}
        handleDeleteSponsored={handleDeleteSponsored}
        max={topListByType.length}
        limit={options.limit}
      />
    </div>
  );
};

export default EditIPEDSTopList;
