import React from 'react';
import SourceTooltip from '../../../components/base/SourceTooltip';
import { Divider } from 'antd';

const iclevelLabel = (value) => {
  let label = '';
  switch (value) {
    case -3:
      label = '{Not available}';
      break;
    case 1:
      label = 'Four or more years';
      break;
    case 2:
      label = 'At least 2 but less than 4 years';
      break;
    case 3:
      label = 'Less than 2 years (below associate)';
      break;
    default:
      label = value;
  }

  return label;
};

const ugofferLabel = (value) => {
  let label = '';
  switch (value) {
    case -3:
      label = '{Not available}';
      break;
    case 1:
      label = 'Yes';
      break;
    case 2:
      label = 'No';
      break;
    default:
      label = value;
  }

  return label;
};

const pset4flgLabel = (value) => {
  let label = '';
  switch (value) {
    case 1:
      label = 'Title IV postsecondary institution';
      break;
    case 2:
      label = 'Non-Title IV postsecondary institution';
      break;
    case 4:
      label = 'Non-Title IV NOT primarily postsecondary institution';
      break;
    case 6:
      label = 'Non-Title IV postsecondary institution that is NOT open to the public';
      break;
    case 9:
      label = 'Institution is not active in current universe';
      break;
    default:
      label = value;
  }

  return label;
};

const Characteristics = ({
  unitid,
  city,
  state,
  region,
  LOCALE,
  control,
  ICLEVEL,
  UGDS,
  totalSize,
  inStateStudentPerc,
  campusStudentPerc,
  foreignStudentPerc,
  carnegieClass,
  FIRST_GEN,
  WOMENONLY,
  MENONLY,
  HSI,
  HBCU,
  RELIGIOUS_AFFILIATION,
  VET1,
  VET2,
  MAIN_CAMPUS,
  PSET4FLG,
  UGOFFER,
  MALE,
  FEMALE,
  RACE_ETHNICITY,
  fullOnline,
  AGE_ENTRY,
  AGEGE24,
  VETERAN,
  MARRIED,
  UG25ABV,
  DEP_STAT_PCT_IND,
  student_faculty_ratio,
  RACE_ETHNICITY_FACULTY,
  PBI,
}) => {
  return (
    <div className='panel-body'>
      <div style={{ display: 'flex', marginLeft: '-20px' }}>
        <Divider type='vertical' className='sp-divider' />

        <div style={{ marginRight: '30px' }}>
          <p>
            <SourceTooltip link={process.env.REACT_APP_HD} linkLabel='IPEDS (HD2021 file)' description='CITY' />
            <b>City:</b> {city} {unitid === 176017 ? '(changed to "Oxford")' : ''}
          </p>

          <p>
            <SourceTooltip link={process.env.REACT_APP_HD} linkLabel='IPEDS (HD2021 file)' description='STABBR' />
            <b>State:</b> {state}
          </p>

          <p>
            <SourceTooltip link={process.env.REACT_APP_HD} linkLabel='IPEDS (HD2021 file)' description='LOCALE' />
            <b>Campus location:</b> {LOCALE?.LOCALE_codelabel}
          </p>

          <p>
            <SourceTooltip link={process.env.REACT_APP_HD} linkLabel='IPEDS (HD2021 file)' description='CONTROL' />
            <b>Control:</b> {control}
          </p>

          <p>
            <SourceTooltip link={process.env.REACT_APP_HD} linkLabel='IPEDS (HD2021 file)' description='ICLEVEL' />
            <b>Level of institution:</b> {iclevelLabel(ICLEVEL)}
          </p>

          <p>
            <SourceTooltip
              link={process.env.REACT_APP_EFFY_DIST}
              linkLabel='IPEDS (EFFY2021_DIST file)'
              description='EFYDETOT where EFFYDLEV = 1'
            />
            <b>Total number of all students:</b> {totalSize}
          </p>

          <p>
            <SourceTooltip link={process.env.REACT_APP_CSC_SITE} linkLabel='CSC API (2023)' />
            <b>Total number of undergraduate students:</b> {UGDS}
          </p>

          <p>
            <SourceTooltip
              link={process.env.REACT_APP_EF_C}
              description='Number of students living on campus divided by total number (EFRES01 find EFCSTATE for the state / EFRES01 where EFCSTATE = 99)'
              linkLabel='IPEDS (EF2020C file)'
            />
            <b>In state student percentage:</b> {inStateStudentPerc ? inStateStudentPerc.toFixed(2) + '%' : 'N/A'}
          </p>

          <p>
            <SourceTooltip
              link={process.env.REACT_APP_SFA}
              linkLabel='IPEDS (SFA2021 file)'
              description='Number of first-time undergraduate students living on-campus divided by total number (GISTON2 / GISTN2)'
            />
            <b>Percentage of students living on campus:</b>{' '}
            {campusStudentPerc ? campusStudentPerc.toFixed(2) + '%' : 'N/A'}
          </p>

          <p>
            <SourceTooltip
              link={process.env.REACT_APP_EF_C}
              description='Number of foreign first-time undergraduate students divided by total number (EFRES01 where EFCSTATE = 90 / EFRES01 where EFCSTATE = 99)'
              linkLabel='IPEDS (EF2020C file)'
            />
            <b>Percentage of foreign students:</b> {foreignStudentPerc ? foreignStudentPerc.toFixed(2) + '%' : 'N/A'}
          </p>

          <p>
            <SourceTooltip link={process.env.REACT_APP_HD} linkLabel='IPEDS (HD2021 file)' description='C21BASIC' />
            <b>Carnegie class:</b> {carnegieClass}
          </p>

          <p>
            <SourceTooltip
              link={process.env.REACT_APP_CMS_CLEAN_UP_DOC}
              linkLabel='CMS clean up document (Regional classification tab)'
            />
            <b>Regional classification:</b> {region}
          </p>
        </div>

        <Divider type='vertical' className='sp-divider' />

        <div style={{ marginRight: '30px' }}>
          <p>
            <SourceTooltip link={process.env.REACT_APP_CSC_SITE} linkLabel='CSC API (2023)' />
            <b>First generation student percentage:</b> {FIRST_GEN ? FIRST_GEN.toFixed(2) + '%' : 'N/A'}
          </p>

          <p>
            <SourceTooltip link={process.env.REACT_APP_CSC_SITE} linkLabel='CSC API (2023)' />
            <b>Women's college:</b> {WOMENONLY === 0 ? 'No' : 'Yes'}
          </p>

          <p>
            <SourceTooltip link={process.env.REACT_APP_CSC_SITE} linkLabel='CSC API (2023)' />
            <b>Men's college:</b> {MENONLY === 0 ? 'No' : 'Yes'}
          </p>

          <p>
            <SourceTooltip link={process.env.REACT_APP_CSC_SITE} linkLabel='CSC API (2023)' />
            <b>HBCU colleges:</b> {HBCU === 0 ? 'No' : 'Yes'}
          </p>

          <p>
            <SourceTooltip link={process.env.REACT_APP_CSC_SITE} linkLabel='CSC API (2023)' />
            <b>HSI colleges:</b> {HSI === 0 ? 'No' : 'Yes'}
          </p>

          <p>
            <SourceTooltip link={process.env.REACT_APP_CSC_SITE} linkLabel='CSC API (2023)' />
            <b>PBI colleges:</b> {PBI === 0 ? 'No' : 'Yes'}
          </p>

          <p>
            <SourceTooltip link={process.env.REACT_APP_CSC_SITE} linkLabel='CSC API (2023)' />
            <b>Religious affiliation:</b> {RELIGIOUS_AFFILIATION ? RELIGIOUS_AFFILIATION : 'None'}
          </p>

          <p>
            <SourceTooltip link={process.env.REACT_APP_IC} linkLabel='IPEDS (IC2021 file)' description='VET1' />
            <b>Yellow ribbon participant:</b> {VET1 === 0 ? 'No' : 'Yes'}
          </p>

          <p>
            <SourceTooltip link={process.env.REACT_APP_IC} linkLabel='IPEDS (IC2021 file)' description='VET2' />
            <b>Credit for military training:</b> {VET2 === 0 ? 'No' : 'Yes'}
          </p>

          <p>
            <SourceTooltip
              link={process.env.REACT_APP_ONLINE_HYBRID_99}
              linkLabel='File'
              description='If column PBACHL is equal to column PBACHLDE'
            />
            <b>Online/Hybrid (entirely):</b> {fullOnline ? 'Yes' : 'No'}
          </p>

          <p>
            <SourceTooltip link={process.env.REACT_APP_CSC_SITE} linkLabel='CSC API (2023)' />
            <b>Average age of entry:</b> {AGE_ENTRY}
          </p>

          <p>
            <SourceTooltip link={process.env.REACT_APP_CSC_SITE} linkLabel='CSC API (2023)' />
            <b>Percent of students over 23 at entry:</b> {AGEGE24 ? (AGEGE24 * 100).toFixed(2) + '%' : 'N/A'}
          </p>

          <p>
            <SourceTooltip link={process.env.REACT_APP_CSC_SITE} linkLabel='CSC API (2023)' />
            <b>Share of veteran students:</b> {VETERAN ? (VETERAN * 100).toFixed(2) + '%' : 'N/A'}
          </p>

          <p>
            <SourceTooltip link={process.env.REACT_APP_CSC_SITE} linkLabel='CSC API (2023)' />
            <b>Share of married students:</b> {MARRIED ? (MARRIED * 100).toFixed(2) + '%' : 'N/A'}
          </p>

          <p>
            <SourceTooltip link={process.env.REACT_APP_CSC_SITE} linkLabel='CSC API (2023)' />
            <b>Percentage of undergraduates aged 25 and above:</b> {UG25ABV ? (UG25ABV * 100).toFixed(2) + '%' : 'N/A'}
          </p>

          <p>
            <SourceTooltip link={process.env.REACT_APP_CSC_SITE} linkLabel='CSC API (2023)' />
            <b>Percentage of students who are financially independent:</b>{' '}
            {DEP_STAT_PCT_IND ? (DEP_STAT_PCT_IND * 100).toFixed(2) + '%' : 'N/A'}
          </p>

          <p>
            <SourceTooltip link={process.env.REACT_APP_CSC_SITE} linkLabel='CSC API (2023)' />
            <b>Student-Faculty ratio:</b> {student_faculty_ratio ? student_faculty_ratio + '%' : 'N/A'}
          </p>
        </div>

        <Divider type='vertical' className='sp-divider' />

        <div>
          <p>
            <SourceTooltip link={process.env.REACT_APP_CSC_SITE} linkLabel='CSC API (2023)' />
            <b>Main or branch campus:</b> {MAIN_CAMPUS === 0 ? 'Branch' : 'Main'}
          </p>

          <p>
            <SourceTooltip link={process.env.REACT_APP_HD} linkLabel='IPEDS (HD2021 file)' description='PSET4FLG' />
            <b>Title IV:</b> {pset4flgLabel(PSET4FLG)}
          </p>

          <p>
            <SourceTooltip link={process.env.REACT_APP_HD} linkLabel='IPEDS (HD2021 file)' description='UGOFFER' />
            <b>Offers degree or certificate:</b> {ugofferLabel(UGOFFER)}
          </p>

          <p>
            <SourceTooltip link={process.env.REACT_APP_CSC_SITE} linkLabel='CSC API (2023)' />
            <b>Male:</b> {MALE ? MALE.toFixed(2) + '%' : 'N/A'}
          </p>

          <p>
            <SourceTooltip link={process.env.REACT_APP_CSC_SITE} linkLabel='CSC API (2023)' />
            <b>Female:</b> {FEMALE ? FEMALE.toFixed(2) + '%' : 'N/A'}
          </p>

          <p>
            <SourceTooltip link={process.env.REACT_APP_CSC_SITE} linkLabel='CSC API (2023)' />
            <b>White:</b> {RACE_ETHNICITY?.UGDS_WHITE?.toFixed(2) + '%'}
          </p>

          <p>
            <SourceTooltip link={process.env.REACT_APP_CSC_SITE} linkLabel='CSC API (2023)' />
            <b>Black:</b> {RACE_ETHNICITY?.UGDS_BLACK?.toFixed(2) + '%'}
          </p>

          <p>
            <SourceTooltip link={process.env.REACT_APP_CSC_SITE} linkLabel='CSC API (2023)' />
            <b>Hispanic:</b> {RACE_ETHNICITY?.UGDS_HISP?.toFixed(2) + '%'}
          </p>

          <p>
            <SourceTooltip link={process.env.REACT_APP_CSC_SITE} linkLabel='CSC API (2023)' />
            <b>Asian:</b> {RACE_ETHNICITY?.UGDS_ASIAN?.toFixed(2) + '%'}
          </p>

          <p>
            <SourceTooltip link={process.env.REACT_APP_CSC_SITE} linkLabel='CSC API (2023)' />
            <b>American indian:</b> {RACE_ETHNICITY?.UGDS_AIAN?.toFixed(2) + '%'}
          </p>

          <p>
            <SourceTooltip link={process.env.REACT_APP_CSC_SITE} linkLabel='CSC API (2023)' />
            <b>Other:</b> {RACE_ETHNICITY?.UGDS_OTHER?.toFixed(2) + '%'}
          </p>

          {RACE_ETHNICITY_FACULTY && (
            <>
              <p>
                <SourceTooltip link={process.env.REACT_APP_CSC_SITE} linkLabel='CSC API (2023)' />
                <b>White (Faculty):</b>{' '}
                {RACE_ETHNICITY_FACULTY.white ? (RACE_ETHNICITY_FACULTY.white * 100).toFixed(2) + '%' : 'N/A'}
              </p>

              <p>
                <SourceTooltip link={process.env.REACT_APP_CSC_SITE} linkLabel='CSC API (2023)' />
                <b>Black (Faculty):</b>{' '}
                {RACE_ETHNICITY_FACULTY.white ? (RACE_ETHNICITY_FACULTY.black * 100).toFixed(2) + '%' : 'N/A'}
              </p>

              <p>
                <SourceTooltip link={process.env.REACT_APP_CSC_SITE} linkLabel='CSC API (2023)' />
                <b>Hispanic (Faculty):</b>{' '}
                {RACE_ETHNICITY_FACULTY.white ? (RACE_ETHNICITY_FACULTY.hispanic * 100).toFixed(2) + '%' : 'N/A'}
              </p>

              <p>
                <SourceTooltip link={process.env.REACT_APP_CSC_SITE} linkLabel='CSC API (2023)' />
                <b>Asian (Faculty):</b>{' '}
                {RACE_ETHNICITY_FACULTY.white ? (RACE_ETHNICITY_FACULTY.asian * 100).toFixed(2) + '%' : 'N/A'}
              </p>

              <p>
                <SourceTooltip link={process.env.REACT_APP_CSC_SITE} linkLabel='CSC API (2023)' />
                <b>American indian (Faculty):</b>{' '}
                {RACE_ETHNICITY_FACULTY.white ? (RACE_ETHNICITY_FACULTY.aian * 100).toFixed(2) + '%' : 'N/A'}
              </p>

              <p>
                <SourceTooltip link={process.env.REACT_APP_CSC_SITE} linkLabel='CSC API (2023)' />
                <b>Unknown (Faculty):</b>{' '}
                {RACE_ETHNICITY_FACULTY.white ? (RACE_ETHNICITY_FACULTY.unknown * 100).toFixed(2) + '%' : 'N/A'}
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Characteristics;
