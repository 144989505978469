import React, { useEffect, useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Axios from 'axios';
import { LoadingOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Tooltip, Modal, notification, Spin } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { IPEDSTopListTable } from '../../components/tables';
import { SERVER_URL } from '../../config';

const SORT = {
  ascend: 1,
  descend: -1,
};

const IPEDSTopLists = () => {
  const currentuser = useContext(UserContext);
  const history = useHistory();
  const [topLists, fetchTopLists] = useAxios('', [], currentuser.data.token, 'get');
  const [search, setSearch] = useState({});
  const [filter, setFilter] = useState(null);
  const [sort, setSort] = useState(JSON.stringify({ careerName: 1 }));
  const [page, setPage] = useState(1);
  const [isWorking, setWorking] = useState(false);
  const [selectedForRefresh, setSelectedForRefresh] = useState([]);

  useEffect(() => {
    let urlQuery = '';
    if (page) urlQuery += `&offset=${(page ? +page - 1 : 0) * 20}&limit=20`;
    if (filter) urlQuery += `&filter=${filter}`;
    if (sort) urlQuery += `&sorter=${sort}`;

    fetchTopLists(`${SERVER_URL}/ipeds-toplists${urlQuery ? '?' + urlQuery : ''}`, [page, filter]);
  }, [fetchTopLists, page, filter, sort]);

  const filterHandler = (prop, value) => {
    if (Array.isArray(prop)) prop = prop.join('.');

    let filterQuery = null;
    let prevFilter = {};
    if (filter) {
      prevFilter = JSON.parse(filter);
    }

    if (value !== null) {
      prevFilter[prop] = value;
      filterQuery = `${JSON.stringify({ [prop]: [value.toString()], ...prevFilter })}`;
    } else {
      delete prevFilter[prop];
      filterQuery = `${JSON.stringify({ ...prevFilter })}`;
    }

    setFilter(filterQuery);
  };

  const sortHandler = (prop, order) => {
    if (Array.isArray(prop)) prop = prop.join('.');

    let sortQuery = null;
    if (order) {
      sortQuery = `${JSON.stringify({ [prop]: SORT[order] })}`;
    }

    setSort(sortQuery);
  };

  const deleteHandler = async (id) => {
    const career = topLists.data?.items.find((item) => item._id === id);

    try {
      Modal.confirm({
        width: 700,
        icon: <ExclamationCircleOutlined style={{ display: 'block', fontSize: '2rem' }} />,
        content: (
          <div style={{ marginLeft: '3.5rem' }}>
            {career.websites?.length > 0 && (
              <p>This IPEDS top list has widgets on {career.websites.map((w) => w.url).join(', ')}</p>
            )}
            Are you sure you want to delete this IPEDS top list?
          </div>
        ),
        async onOk() {
          setWorking(true);

          await Axios.delete(`${SERVER_URL}/ipeds-toplists/${id}`, {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` },
          });

          setWorking(false);

          notification.success({
            message: 'Item is deleted.',
            placement: 'bottomRight',
          });

          history.push('/admin');
          history.push('/admin/ipeds-top-lists');
          Modal.destroyAll();
        },
        onCancel() {
          Modal.destroyAll();
        },
      });
    } catch (err) {
      console.log(err);
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  const refreshAll = async () => {
    if (!selectedForRefresh || selectedForRefresh.length === 0) return;
    setWorking(true);
    try {
      for (const selectedID of selectedForRefresh) {
        await Axios.patch(
          `${SERVER_URL}/ipeds-toplists-refresh-data/${selectedID}`,
          {},
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` },
          },
        );
      }

      notification.success({
        message: 'IPEDS Top Lists refreshed.',
        placement: 'bottomRight',
      });

      history.push('/admin');
      history.push('/admin/ipeds-top-lists');

      setWorking(false);
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Problem with refreshing IPEDS Top Lists. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  let tableData = [];
  if (topLists.data && topLists.data?.items?.length > 0) {
    tableData = topLists.data.items.map((item) => {
      item.createdAt = new Date(item.createdAt).toLocaleString('en-US');
      item.updatedAt = new Date(item.updatedAt).toLocaleString('en-US');
      return item;
    });
  }

  return (
    <div className='content-wrapper'>
      <Spin spinning={isWorking} tip='Working...'>
        <div className='actions-block flex'>
          <div>
            <Link to={isWorking ? '/admin/ipeds-top-lists' : '/admin/new-ipeds-top-list'}>
              <Tooltip title='Create new Top List'>
                <Button type='primary' style={{ marginRight: '8px' }} disabled={isWorking}>
                  NEW TOP LIST
                </Button>
              </Tooltip>
            </Link>

            <Tooltip title='Refresh all Top Lists data'>
              <Button
                type='primary'
                danger
                style={{ marginRight: '8px' }}
                onClick={refreshAll}
                disabled={!selectedForRefresh || selectedForRefresh.length === 0 ? true : false}
              >
                REFRESH TOP LISTS
              </Button>
            </Tooltip>
          </div>

          <h2 style={{ margin: 0 }}>Total Top Lists: {topLists.data?.count || 0}</h2>
        </div>

        <div style={{ textAlign: 'center' }}>
          {topLists.isLoading && !topLists.data && (
            <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />
          )}

          {topLists.data?.items && (
            <IPEDSTopListTable
              data={tableData}
              filterHandler={filterHandler}
              deleteHandler={deleteHandler}
              pageHandler={setPage}
              sortHandler={sortHandler}
              search={search}
              setSearch={setSearch}
              title='IPEDS Top List'
              editPath='/admin/edit-ipeds-top-list/'
              page={page}
              total={topLists.data?.count || 0}
              filter={filter ? JSON.parse(filter) : null}
              sort={sort ? JSON.parse(sort) : null}
              selectedForRefresh={selectedForRefresh}
              setSelectedForRefresh={setSelectedForRefresh}
            />
          )}
        </div>
      </Spin>
    </div>
  );
};

export default IPEDSTopLists;
