import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { Button, Row, Col, Collapse, Spin } from 'antd';
import ReactJson from 'react-json-view';

const { Panel } = Collapse;

const ViewCareer = (props) => {
  const currentuser = useContext(UserContext);
  const [career, fetchCareer] = useAxios('', {}, currentuser.data.token, 'get');
  const { id } = props.match.params;

  useEffect(() => {
    if (id) fetchCareer(`${SERVER_URL}/career/${id}`);
  }, [id, fetchCareer]);

  return (
    <div className='content-wrapper'>
      <div className='panel'>
        <div className='actions-block'>
          <Link to='/admin/careers'>
            <Button type='primary'>All Careers</Button>
          </Link>
        </div>

        <div className='panel-body'>
          <Spin spinning={career.isLoading} tip='Loading...'>
            {career.data && (
              <>
                <Row gutter={[8, 8]}>
                  <Col span={12}>
                    <h2>
                      <strong>Career Title:&nbsp;</strong>
                      <span>{career.data?.careerName}</span>
                    </h2>
                  </Col>

                  <Col span={12}>
                    <h2>
                      <strong>Occupation Code:&nbsp;</strong>
                      <span>{career.data?.occCode}</span>
                    </h2>
                  </Col>
                </Row>

                <h2>
                  <strong>National:</strong>
                </h2>

                <div style={{ margin: '12px 0 20px', paddingLeft: '12px' }}>
                  {career?.data?.national && (
                    <Row gutter={[8, 8]}>
                      <Col span={12}>
                        <p>BLS</p>
                        <ReactJson
                          name={null}
                          src={career.data.national.BLS}
                          collapsed={2}
                          displayDataTypes={false}
                          enableClipboard={false}
                          style={{ fontSize: '14px' }}
                        />
                      </Col>

                      <Col span={12}>
                        <p>PAYSCALE</p>
                        <ReactJson
                          name={null}
                          src={career.data.national.PAYSCALE}
                          collapsed={2}
                          displayDataTypes={false}
                          enableClipboard={false}
                          style={{ fontSize: '14px' }}
                        />
                      </Col>
                    </Row>
                  )}
                </div>

                <h2>
                  <strong>States:</strong>
                </h2>

                <div style={{ margin: '12px 0 20px', paddingLeft: '12px' }}>
                  <Collapse defaultActiveKey={0}>
                    {career?.data?.states &&
                      career.data.states.map((state, index) => (
                        <Panel header={state.state} key={index}>
                          <Row gutter={[8, 8]}>
                            <Col span={12}>
                              <p>
                                <strong>Price parity:</strong> {state.priceParity || '--'}
                              </p>
                              <p>BLS</p>
                              <ReactJson
                                name={null}
                                src={state.BLS}
                                collapsed={2}
                                displayDataTypes={false}
                                enableClipboard={false}
                                style={{ fontSize: '14px' }}
                              />
                            </Col>

                            <Col span={12}>
                              <p style={{ marginTop: '20px' }}>PAYSCALE</p>
                              <ReactJson
                                name={null}
                                src={state.PAYSCALE}
                                collapsed={2}
                                displayDataTypes={false}
                                enableClipboard={false}
                                style={{ fontSize: '14px' }}
                              />
                            </Col>
                          </Row>
                        </Panel>
                      ))}
                  </Collapse>
                </div>

                <h2>
                  <strong>Skills:</strong>
                </h2>

                <div style={{ margin: '12px 0 20px', paddingLeft: '12px' }}>
                  <Collapse defaultActiveKey={0}>
                    {career?.data?.skills &&
                      career.data.skills.map((skill, index) => (
                        <Panel header={skill.skill} key={index}>
                          <Col span={12}>
                            <ReactJson
                              name={null}
                              src={skill}
                              collapsed={2}
                              displayDataTypes={false}
                              enableClipboard={false}
                              style={{ fontSize: '14px' }}
                            />
                          </Col>
                        </Panel>
                      ))}
                  </Collapse>
                </div>

                <h2>
                  <strong>Experience:</strong>
                </h2>

                <div style={{ margin: '12px 0 20px', paddingLeft: '12px' }}>
                  <Collapse defaultActiveKey={0}>
                    {career?.data?.experience &&
                      career.data.experience.map((exp, index) => (
                        <Panel header={`Degree Level: ${exp.degreeLevel}`} key={index}>
                          <Row gutter={[8, 8]}>
                            {exp.data.map((item, i) => (
                              <Col span={8} key={`exp_${i}`}>
                                <ReactJson
                                  name={null}
                                  src={item}
                                  collapsed={2}
                                  displayDataTypes={false}
                                  enableClipboard={false}
                                  style={{ fontSize: '14px' }}
                                />
                              </Col>
                            ))}
                          </Row>
                        </Panel>
                      ))}
                  </Collapse>
                </div>
              </>
            )}
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default ViewCareer;
