import React, { useEffect, useContext } from 'react';
import Axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { notification, Button } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { UserTable } from '../../components/tables';

import { SERVER_URL } from '../../config';

const TABLE_COLUMN_KEYS = ['_id', '__v', 'avatar', 'imgId', 'imgPath'];

const Users = () => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [users, fetchUsers] = useAxios('', [], currentuser.data.token, 'get');

  useEffect(() => {
    fetchUsers(`${SERVER_URL}/users`, []);
  }, [fetchUsers]);

  const filterHandler = (prop, value) => {
    console.log('filterHandler :: prop =', prop);
    console.log('filterHandler :: value =', value);
  };

  const sortHandler = (prop, order) => {
    console.log('sortHandler :: prop =', prop);
    console.log('sortHandler :: order =', order);
  };

  const deleteUserHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/users/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Item is deleted.',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/users');
    } catch (err) {
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  let columnKeys;
  if (users.data && users.data.length > 0) {
    const keys = Object.keys(users.data[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
  }

  let tableData = [];
  if (users.data && users.data.length > 0) {
    tableData = users.data.map((item) => {
      if (Array.isArray(item.role)) item.role = item.role.join(', ');
      item.status = item.status.split('_').join(' ').toLowerCase();
      item.createdAt = new Date(item.createdAt).toLocaleString('en-US');
      item.updatedAt = new Date(item.updatedAt).toLocaleString('en-US');
      return item;
    });
  }

  return (
    <div className='content-wrapper'>
      <div className='actions-block'>
        <Link to='/admin/new-user'>
          <Button type='primary'>New user</Button>
        </Link>
      </div>

      <div style={{ textAlign: 'center' }}>
        {users.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {!users.isLoading && users.data && users.data.length > 0 && (
          <UserTable
            data={tableData}
            filterHandler={filterHandler}
            sortHandler={sortHandler}
            deleteHandler={deleteUserHandler}
            columnKeys={columnKeys}
            title='User'
            editPath='/admin/edit-user/'
            viewPath='/admin/view-user/'
          />
        )}
        {!users.isLoading && users.data && users.data.length === 0 && <h2>NO DATA</h2>}
      </div>
    </div>
  );
};

export default Users;
