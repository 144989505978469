import React, { useEffect, useState } from 'react';
import { Input, Button, Form, Row, Col, Select, Typography } from 'antd';
import STATES from '../../lib/states.json';
import UploadBox from '../base/UploadBox';
import GalleryModal from './GalleryModal';

const { Option } = Select;
const { Text } = Typography;

const formInit = {
  _id: null,
  title: undefined,
  university: undefined,
  program: undefined,
  subprogram: undefined,
  degree: undefined,
  type: undefined,
  logo: null,
  link: undefined,
  universityLink: undefined,
  state: undefined,
  city: undefined,
  restrictedStates: [],
  // second phase
  aid: undefined,
  admissions: undefined,
  earnings: undefined,
  repayment: undefined,
  graudationRates: undefined,
  onlineCost: undefined,
  inStateTuition: undefined,
  outOfStateTuition: undefined,
  programLength: undefined,
  accreditation: undefined,
};

const ProfileForm = ({ isNew, profile, categories, onSubmit, logo, SERVER_URL, token }) => {
  const [form] = Form.useForm();
  const [selectedProgram, setSelectedProgram] = useState({});
  const [modal, setModal] = useState({ visible: false, formKey: null, /* limit: 1, */ id: null });
  const [currentImage, setCurrentImage] = useState(profile?.logo ? profile.logo : null);

  if (profile) ['children', 'path', 'updatedAt', '__v'].forEach((key) => delete profile[key]);
  let initialValues = { ...formInit, ...profile };

  const onFinish = async (values, isNew) => {
    if (!isNew) {
      values._id = profile._id;
    }
    onSubmit(values, isNew);
  };

  const logoHandler = () => {
    setModal({ visible: true, formKey: 'profileForm' });
  };

  const program = categories.find((cat) => cat.name.toLowerCase() === 'programs');
  const university = categories.find((cat) => cat.name.toLowerCase() === 'universities');

  useEffect(() => {
    const currentProg = program.children.find((p) => p._id === form.getFieldValue('program'));
    if (currentProg) setSelectedProgram(currentProg);
  }, [form, program]);

  const onInsert = (values) => {
    form.setFieldsValue({ logo: values });
    setCurrentImage(values);
  };

  return (
    <div className='panel'>
      <div className='panel-body'>
        <Form
          className='form-horizontal'
          layout='vertical'
          initialValues={initialValues}
          onFinish={(values) => {
            onFinish(values, !profile);
          }}
          form={form}
        >
          <Row type='flex' gutter={16}>
            <Col xs={24} md={16}>
              <div className='panel-body'>
                <Form.Item
                  label='Profile Headline:'
                  name='title'
                  rules={[{ required: true, message: 'Please enter Profile Headline!' }]}
                >
                  <Input placeholder='Enter Profile Headline' />
                </Form.Item>

                <Row gutter={8}>
                  <Col xs={24} md={8}>
                    {/* category university */}
                    <Form.Item
                      label='University:'
                      name='university' /*  rules={[{ required: true, message: 'Please select University!' }]} */
                    >
                      <Select showSearch optionFilterProp='children'>
                        {university.children.map((un) => (
                          <Select.Option value={un._id} key={un._id}>
                            {un.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={8}>
                    {/* category program */}
                    <Form.Item
                      label='Program:'
                      name='program' /*  rules={[{ required: true, message: 'Please select University!' }]} */
                    >
                      <Select
                        showSearch
                        optionFilterProp='children'
                        onChange={(val) => {
                          const currentProg = program.children.find((p) => p._id === val);
                          if (currentProg) setSelectedProgram(currentProg);
                          form.setFieldsValue({ program: val, subprogram: undefined });
                        }}
                      >
                        {program.children.map((un) => (
                          <Select.Option value={un._id} key={un._id}>
                            {un.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={8}>
                    {/* category sub program */}
                    <Form.Item
                      label='Discipline:'
                      name='subprogram' /*  rules={[{ required: true, message: 'Please select University!' }]} */
                    >
                      <Select showSearch optionFilterProp='children'>
                        {selectedProgram?.children?.map((un) => (
                          <Select.Option value={un._id} key={un._id}>
                            {un.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={8}>
                  <Col xs={24} md={12}>
                    <Form.Item label='Program Length:' name='programLength'>
                      <Input placeholder='Program Length' />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={12}>
                    <Form.Item label='Accreditation:' name='accreditation'>
                      <Input placeholder='Enter Accreditation' />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={8}>
                  <Col xs={24} md={12}>
                    <Form.Item label='Degree level:' name='degree'>
                      <Select>
                        <Option value='Doctorate'>Doctorate</Option>
                        <Option value="Master's">Master's</Option>
                        <Option value="Bachelor's">Bachelor's</Option>
                        <Option value='Certificate'>Certificate</Option>
                        <Option value='Associate'>Associate</Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={12}>
                    <Form.Item label='Type:' name='type'>
                      <Select mode='multiple'>
                        <Option value='Online'>Online</Option>
                        <Option value='Campus'>Campus</Option>
                        <Option value='Hybrid'>Hybrid</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item label='Affiliate/tracking link:' name='link'>
                  <Input placeholder='Enter Affiliate/tracking link' />
                </Form.Item>

                <Form.Item label='University website:' name='universityLink'>
                  <Input placeholder='Enter University website link' />
                </Form.Item>

                <Row gutter={8}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label='State:'
                      name='state'
                      rules={[{ required: true, message: 'Please select State!' }]}
                    >
                      <Select showSearch optionFilterProp='children'>
                        {STATES.map((state) => (
                          <Select.Option value={state.name} key={state.name}>
                            {state.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={12}>
                    <Form.Item label='City:' name='city'>
                      <Input placeholder='Enter city' />
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item label='Restricted States:' name='restrictedStates'>
                  <Select showSearch optionFilterProp='children' mode='multiple'>
                    {STATES.map((state) => (
                      <Select.Option value={state.name} key={state.name}>
                        {state.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Row gutter={8}>
                  <Col xs={24} md={12}>
                    <Form.Item label='Aid:' name='aid'>
                      <Input placeholder='Enter aid' />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={12}>
                    <Form.Item label='Admissions:' name='admissions'>
                      <Input placeholder='Enter admissions' />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={8}>
                  <Col xs={24} md={12}>
                    <Form.Item label='Earnings:' name='earnings'>
                      <Input placeholder='Enter earnings' />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={12}>
                    <Form.Item label='Repayment:' name='repayment'>
                      <Input placeholder='Enter repayment' />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={8}>
                  <Col xs={24} md={12}>
                    <Form.Item label='Graduation rates:' name='graudationRates'>
                      <Input placeholder='Enter graudation rates' type='number' />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={12}>
                    <Form.Item label='Online Cost:' name='onlineCost'>
                      <Input placeholder='Enter cost' />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={8}>
                  <Col xs={24} md={12}>
                    <Form.Item label='In State Tuition:' name='inStateTuition'>
                      <Input placeholder='Enter in state tuition' />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={12}>
                    <Form.Item label='Out Of State Tuition:' name='outOfStateTuition'>
                      <Input placeholder='Enter out of state tuition' />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col xs={24} md={8}>
              {!isNew ? (
                <div className='panel panel-primary'>
                  <div className='panel-heading'>
                    <h4 className='panel-title'>User information</h4>
                  </div>

                  <div className='panel-body'>
                    <div className='card-wrapper'>
                      <p>
                        <Text strong>Name: </Text>
                        {initialValues.createdby}
                      </p>
                      <p>
                        <Text strong>Created At: </Text>
                        {initialValues.createdAt}
                      </p>
                    </div>
                  </div>
                </div>
              ) : null}

              <div className='panel panel-primary'>
                <div className='panel-heading'>
                  <h4 className='panel-title'>Profile Logo</h4>
                  <span style={{ fontSize: '0.825rem' }}>without logo profile will not be visible in WP list</span>
                </div>

                <Form.Item
                  name='logo'
                  rules={[
                    () => ({
                      validator() {
                        if (currentImage) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('Please upload and/or select logo image!'));
                      },
                    }),
                  ]}
                  valuePropName='logo'
                >
                  <div className='panel-body'>
                    {currentImage ? (
                      <img
                        src={currentImage.url}
                        style={{
                          width: '100px',
                          border: '0.5px solid #bbb',
                          background: '#eee',
                          borderRadius: '5px',
                          cursor: 'pointer',
                        }}
                        alt='upload'
                        title='Select from gallery or upload'
                        onClick={() => logoHandler()}
                      />
                    ) : (
                      <UploadBox handler={logoHandler} />
                    )}
                  </div>
                </Form.Item>
              </div>
            </Col>
          </Row>

          <div className='text-right'>
            <Button style={{ margin: '0 10px' }} type='primary' htmlType='submit'>
              {isNew ? 'Add' : 'update'} Profile
            </Button>
          </div>
        </Form>

        {modal.visible && (
          <GalleryModal
            visible={modal.visible}
            // limit={modal.limit}
            formKey={modal.formKey}
            imageSavePath='public/images/data/'
            imageType='data'
            imageHeight={500}
            SERVER_URL={SERVER_URL}
            token={token}
            form={form}
            onCancel={() => setModal({ visible: false, formKey: null })}
            onInsert={(values) => {
              onInsert(values);
            }}
            imageId={modal.id}
            imageIndex={modal.index}
            currentImage={currentImage}
          />
        )}
      </div>
    </div>
  );
};
export default ProfileForm;
