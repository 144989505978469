import React from 'react';
import { saveAs as fileSaveAs } from 'file-saver';
import { utils as XLSXUtils, write as XSLXWrite } from 'xlsx';
import { Button } from 'antd';
import { ExportOutlined } from '@ant-design/icons';

const ExportToExcel = ({ csvData, fileName, propsToRemove, header, format, disabled }) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const removeProperties = (object, ...keys) =>
    Object.entries(object).reduce(
      (prev, [key, value]) => ({
        ...prev,
        ...(!keys.includes(key) && { [key]: value }),
      }),
      {},
    );

  const dataForExcel = async (data) => {
    let retData = [];
    // Ako zelis da promenis imena polja u xlsx tabeli po imenu format propsa iz glavne komponente
    if (format === 'custom_name') {
      let promises = data.map(async (item) => {
        // item["novo_polje"] = item["staro_polje"];  // dodeli vrednost starog polja novom
        // delete item['staro_polje'];  // obrisi staro polje
        // retData.push(item[i]);  // push to new array
      });
      await Promise.all(promises);
    } else {
      retData = await data.map((item) => removeProperties(item, ...propsToRemove));
    }
    return retData;
  };

  const exportToExcel = async () => {
    let exportData = [];
    if (typeof csvData === 'function') {
      exportData = await csvData();
    } else {
      exportData = csvData;
    }

    const filteredData = await dataForExcel(exportData);
    const workSheet = XLSXUtils.json_to_sheet(filteredData, { header: header, cellDates: true });
    const workBook = { Sheets: { data: workSheet }, SheetNames: ['data'] };
    const excelBuffer = XSLXWrite(workBook, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    fileSaveAs(data, fileName + fileExtension);
  };

  return (
    <Button
      size='small'
      style={{ marginLeft: '15px', marginRight: '0px' }}
      title='Export to excel'
      onClick={exportToExcel}
      disabled={disabled}
    >
      <ExportOutlined /> {'Export to excel'}
    </Button>
  );
};

export default ExportToExcel;
