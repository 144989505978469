import React from 'react';
import SourceTooltip from '../../../components/base/SourceTooltip';
import { Divider } from 'antd';

const Admissions = ({
  admPerc,
  openAdmissions,
  applicantsTotal,
  admissionsTotal,
  enrolled,
  gpaRequired,
  testPolicy,
  percentSubmittingSAT,
  percentSubmittingACT,
  SATVR25,
  SATVR75,
  SATMT25,
  SATMT75,
  SATWR25,
  SATWR75,
  SATVRMID,
  SATMTMID,
  SATWRMID,
  SAT_AVG,
  ACTCOMP25,
  ACTCOMP75,
  ACTENG25,
  ACTENG75,
  ACTMT25,
  ACTMT75,
  ACTWR25,
  ACTWR75,
  ACTCOMPMID,
  ACTENGMID,
  ACTMTMID,
  ACTWRMID,
  AVG_GPA,
  earlyAdmissions,
  acceptance_rate_data_medical,
}) => {
  return (
    <div className='panel-body'>
      <div style={{ display: 'flex' }}>
        <div style={{ marginRight: '80px' }}>
          <p>
            <b>Open admissions:</b> {openAdmissions ? (openAdmissions === 1 ? 'Yes' : 'No') : 'N/A'}
          </p>

          <p>
            <b>Acceptance rate:</b> {admPerc && typeof admPerc === 'number' ? `${admPerc.toFixed(1)}%` : admPerc}
          </p>

          <p>
            <b>Applied:</b> {applicantsTotal}
          </p>

          <p>
            <b>Admitted:</b> {admissionsTotal}
          </p>

          <p>
            <b>Enrolled:</b> {enrolled}
          </p>

          <p>
            <b>GPA required:</b> {gpaRequired}
          </p>

          <p>
            <b>Test policy:</b> {testPolicy}
          </p>

          {AVG_GPA ? (
            <p>
              <SourceTooltip link='https://www.prepscholar.com/' linkLabel='https://www.prepscholar.com/' />
              <b>Average GPA: </b>
              {typeof AVG_GPA === 'number' ? AVG_GPA : 'N/A'}
            </p>
          ) : null}
        </div>

        <div style={{ marginRight: '80px' }}>
          <p>
            <b>Percent submitting SAT:</b> {percentSubmittingSAT}
          </p>
          <p>
            <b>SAT verbal 25:</b> {SATVR25}
          </p>
          <p>
            <b>SAT verbal 75:</b> {SATVR75}
          </p>
          <p>
            <b>SAT math 25:</b> {SATMT25}
          </p>
          <p>
            <b>SAT math 75:</b> {SATMT75}
          </p>
          <p>
            <b>SAT writing 25:</b> {SATWR25}
          </p>
          <p>
            <b>SAT writing 75:</b> {SATWR75}
          </p>
          <p>
            <b>SAT verbal mid:</b> {SATVRMID}
          </p>
          <p>
            <b>SAT math mid:</b> {SATMTMID}
          </p>
          <p>
            <b>SAT writing mid:</b> {SATWRMID}
          </p>
          <p>
            <b>Total average SAT score:</b> {SAT_AVG}
          </p>
        </div>

        <div style={{ marginRight: '80px' }}>
          <p>
            <b>Percent submitting ACT:</b> {percentSubmittingACT}
          </p>
          <p>
            <b>ACT cumulative 25:</b> {ACTCOMP25}
          </p>
          <p>
            <b>ACT cumulative 75:</b> {ACTCOMP75}
          </p>
          <p>
            <b>ACT english 25:</b> {ACTENG25}
          </p>
          <p>
            <b>ACT english 75:</b> {ACTENG75}
          </p>
          <p>
            <b>ACT math 25:</b> {ACTMT25}
          </p>
          <p>
            <b>ACT math 75:</b> {ACTMT75}
          </p>
          <p>
            <b>ACT writing 25:</b> {ACTWR25}
          </p>
          <p>
            <b>ACT writing 75:</b> {ACTWR75}
          </p>
          <p>
            <b>ACT cumulative mid:</b> {ACTCOMPMID}
          </p>
          <p>
            <b>ACT english mid:</b> {ACTENGMID}
          </p>
          <p>
            <b>ACT math mid:</b> {ACTMTMID}
          </p>
          <p>
            <b>ACT writing mid:</b> {ACTWRMID}
          </p>
        </div>

        <div style={{ marginRight: '80px' }}>
          <h3 style={{ color: 'red' }}>Early Action/Decision</h3>
          <p>
            <b>Rolling admissions:</b> {earlyAdmissions ? (earlyAdmissions.rollingPolicy ? 'Yes' : 'No') : 'N/A'}
          </p>
          <p>
            <b>Early Action Deadline:</b>{' '}
            {earlyAdmissions && earlyAdmissions.earlyActionDeadline ? earlyAdmissions.earlyActionDeadline : 'N/A'}
          </p>
          <p>
            <b>Early Action Notification:</b>{' '}
            {earlyAdmissions && earlyAdmissions.earlyActionNotification
              ? earlyAdmissions.earlyActionNotification
              : 'N/A'}
          </p>
          <p>
            <b>Early Decision Deadline:</b>{' '}
            {earlyAdmissions && earlyAdmissions.earlyDecisionDeadline ? earlyAdmissions.earlyDecisionDeadline : 'N/A'}
          </p>
          <p>
            <b>Early Decision II Deadline:</b>{' '}
            {earlyAdmissions && earlyAdmissions.earlyDecision2Deadline ? earlyAdmissions.earlyDecision2Deadline : 'N/A'}
          </p>
          <p>
            <b>Early Decision Notification:</b>{' '}
            {earlyAdmissions && earlyAdmissions.earlyDecisionNotification
              ? earlyAdmissions.earlyDecisionNotification
              : 'N/A'}
          </p>

          {acceptance_rate_data_medical ? (
            <>
              <Divider />
              <h3 style={{ color: 'red' }}>Medical School acceptance rate</h3>
              <p>
                <b>Applications:</b>{' '}
                {acceptance_rate_data_medical.applications
                  ? acceptance_rate_data_medical.applications.toLocaleString('en-US')
                  : 'N/A'}
              </p>
              <p>
                <b>Enrolled:</b>{' '}
                {acceptance_rate_data_medical.enrolled
                  ? acceptance_rate_data_medical.enrolled.toLocaleString('en-US')
                  : 'N/A'}
              </p>
              <p>
                <b>Acceptance rate:</b>{' '}
                {acceptance_rate_data_medical.acceptanceRate
                  ? `${acceptance_rate_data_medical.acceptanceRate}%`
                  : 'N/A'}
              </p>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Admissions;
