import React, { useEffect, useContext, useState } from 'react';
import Axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';
import { notification, Spin, Button, Modal, Row, Col, Divider } from 'antd';
import { UserContext } from '../../App';
import { PayscaleTable } from '../../components/tables';
import { ImportExcelFile } from '../../components/csv';
import { SERVER_URL } from '../../config';

const SORT = {
  ascend: 1,
  descend: -1,
};

const Payscale = () => {
  const currentuser = useContext(UserContext);
  const [payscale, setPayscale] = useState([]);
  const [search, setSearch] = useState({});
  const [filter, setFilter] = useState(null);
  const [sort, setSort] = useState(JSON.stringify({ jobTitle: 1 }));
  const [page, setPage] = useState(1);
  const [fetchTrigger, setFetchTrigger] = useState(false);
  const [isWorking, setWorking] = useState(false);
  const [payscaleUsage, setPayscaleUsage] = useState({});
  const [usageIsFetching, setUsageIsFetching] = useState(true);
  const [modal, setModal] = useState({ visible: false });

  useEffect(() => {
    const fetch = async () => {
      let urlQuery = '';
      if (page) urlQuery += `&offset=${(page ? +page - 1 : 0) * 20}&limit=20`;
      if (filter) urlQuery += `&filter=${filter}`;
      if (sort) urlQuery += `&sorter=${sort}`;
      try {
        const payscaleRes = await Axios.get(
          `${SERVER_URL}/payscale-data?select=${JSON.stringify(['all'])}${urlQuery}`,
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` },
          },
        );
        setPayscale(payscaleRes.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetch();
  }, [fetchTrigger, page, filter, sort, currentuser.data.token]);

  // FETCHING PAYSCALE USAGE
  useEffect(() => {
    const fetchUsage = async () => {
      setUsageIsFetching(true);
      try {
        const usage = await Axios.get(`${SERVER_URL}/payscale-usage`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
        setPayscaleUsage(usage.data.data.usage);
        setUsageIsFetching(false);
      } catch (err) {
        console.log(err);
        setUsageIsFetching(false);
      }
    };
    fetchUsage();
  }, [currentuser.data.token]);

  const filterHandler = (prop, value) => {
    if (Array.isArray(prop)) prop = prop.join('.');

    let filterQuery = null;
    let prevFilter = {};
    if (filter) {
      prevFilter = JSON.parse(filter);
    }

    if (value !== null) {
      prevFilter[prop] = value;
      filterQuery = `${JSON.stringify({ [prop]: [value.toString()], ...prevFilter })}`;
    } else {
      delete prevFilter[prop];
      filterQuery = `${JSON.stringify({ ...prevFilter })}`;
    }

    setFilter(filterQuery);
  };

  const sortHandler = (prop, order) => {
    if (Array.isArray(prop)) prop = prop.join('.');

    let sortQuery = null;
    if (order) {
      sortQuery = `${JSON.stringify({ [prop]: SORT[order] })}`;
    }

    setSort(sortQuery);
  };

  const handleSave = async (record) => {
    setWorking(true);
    if (record.occCode || record.highJobMeaning) {
      try {
        const saveData = {};
        if (record.occCode) saveData.occCode = record.occCode;
        if (record.highJobMeaning) saveData.highJobMeaning = record.highJobMeaning;
        const response = await Axios.put(`${SERVER_URL}/payscale-data/${record._id}`, saveData, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });

        setFetchTrigger(!fetchTrigger);
        setWorking(false);

        notification.success({
          message: response.data.message,
          placement: 'bottomRight',
        });
      } catch (error) {
        const msg = error.response
          ? error.response.data.message
          : error.message
          ? error.message
          : 'Problem with update.';
        console.log(error);
        notification.error({
          message: msg,
          placement: 'bottomRight',
        });
      }
      setWorking(false);
    }
  };

  let tableData = [];
  if (payscale && payscale.items?.length > 0) {
    tableData = payscale.items.map((item) => {
      item.createdAt = new Date(item.createdAt).toLocaleString('en-US');
      item.updatedAt = new Date(item.updatedAt).toLocaleString('en-US');
      return item;
    });
  }

  const uploadExcel = (upload) => {
    try {
      if (upload.file.response) {
        if (upload.file.status === 'done') {
          notification.success({
            message: upload.file.response.message,
            placement: 'bottomRight',
          });
        } else {
          notification.error({
            message: upload.file.response.message,
            placement: 'bottomRight',
          });
        }
      }
    } catch (err) {
      notification.error({
        message: 'Problem with upload.',
        placement: 'bottomRight',
      });
    }
  };

  return (
    <Spin spinning={isWorking} tip='Working...' size='large'>
      <div className='content-wrapper'>
        <div className='actions-block flex'>
          <ImportExcelFile
            onChange={uploadExcel}
            headers={{ Authorization: currentuser.data.token }}
            action={`${SERVER_URL}/payscale-upload-excel`}
            name='excelData'
            toolTip='Import Job Titles to fetch from PayScale'
            label='Import Excel'
            disabled={!currentuser.data.role.includes('admin')}
          />

          <div>
            {usageIsFetching ? (
              <h2 style={{ margin: 0, marginRight: '12px', display: 'inline' }}>Fetching...</h2>
            ) : (
              <h2 style={{ margin: 0, marginRight: '12px', display: 'inline' }}>
                {payscaleUsage && payscaleUsage?.totalBillableReports
                  ? `Payscale requests (this month): ${payscaleUsage.totalBillableReports}`
                  : `Payscale requests (this month): 0`}
              </h2>
            )}
            <Button
              type='primary'
              onClick={() => setModal({ visible: true })}
              disabled={!payscaleUsage || !payscaleUsage?.totalBillableReports ? true : false}
            >
              Details
            </Button>
          </div>

          <h2 style={{ margin: 0 }}>Total data: {payscale.count || 0}</h2>
        </div>

        <div style={{ textAlign: 'center' }}>
          {payscale.isLoading && !payscale && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}

          {payscale?.items && (
            <PayscaleTable
              data={tableData}
              filterHandler={filterHandler}
              pageHandler={setPage}
              sortHandler={sortHandler}
              handleSave={handleSave}
              search={search}
              setSearch={setSearch}
              title='Details'
              viewPath='/admin/view-payscale/'
              page={page}
              total={payscale.count || 0}
              filter={filter ? JSON.parse(filter) : null}
              sort={sort ? JSON.parse(sort) : null}
            />
          )}
        </div>
      </div>

      <Modal
        centered
        visible={modal.visible}
        title={<strong>PAYSCALE USAGE DETAILS</strong>}
        onCancel={() => setModal({ visible: false })}
        footer={[
          <Button key='close' type='primary' onClick={() => setModal({ visible: false })}>
            Close
          </Button>,
        ]}
      >
        <Row gutter={[12]}>
          <Col span={8}>
            <h2>
              <strong>Total:</strong> {payscaleUsage?.totalReports || 0}
            </h2>
          </Col>

          <Col span={8}>
            <h2>
              <strong>Billable:</strong> {payscaleUsage?.totalBillableReports || 0}
            </h2>
          </Col>

          <Col span={8}>
            <h2>
              <strong>Month:</strong> {payscaleUsage?.usageDate || 0}
            </h2>
          </Col>
        </Row>

        <Divider />

        <Row gutter={[12]}>
          <Col span={11} offset={1}>
            <h2>
              <strong>SUCCESSFUL</strong>
            </h2>

            <ul>
              <li>
                <Row gutter={[8]}>
                  <Col span={10}>
                    <strong>Pay:</strong>
                  </Col>

                  <Col span={8} style={{ textAlign: 'right' }}>
                    {payscaleUsage?.successfulReports?.pay || ' 0'}
                  </Col>
                </Row>
              </li>

              <li>
                <Row gutter={[8]}>
                  <Col span={10}>
                    <strong>Skills:</strong>
                  </Col>

                  <Col span={8} style={{ textAlign: 'right' }}>
                    {payscaleUsage?.successfulReports?.skills || ' 0'}
                  </Col>
                </Row>
              </li>

              <li>
                <Row gutter={[8]}>
                  <Col span={10}>
                    <strong>Experience:</strong>
                  </Col>

                  <Col span={8} style={{ textAlign: 'right' }}>
                    {payscaleUsage?.successfulReports?.yoe || ' 0'}
                  </Col>
                </Row>
              </li>
            </ul>
          </Col>

          <Col span={11} offset={1}>
            <h2>
              <strong>UNSUCCESSFUL</strong>
            </h2>

            <ul>
              <li>
                <Row gutter={[8]}>
                  <Col span={10}>
                    <strong>Pay:</strong>
                  </Col>

                  <Col span={8} style={{ textAlign: 'right' }}>
                    {payscaleUsage?.unsuccessfulReports?.pay || ' 0'}
                  </Col>
                </Row>
              </li>

              <li>
                <Row gutter={[8]}>
                  <Col span={10}>
                    <strong>Skills:</strong>
                  </Col>

                  <Col span={8} style={{ textAlign: 'right' }}>
                    {payscaleUsage?.unsuccessfulReports?.skills || ' 0'}
                  </Col>
                </Row>
              </li>

              <li>
                <Row gutter={[8]}>
                  <Col span={10}>
                    <strong>Experience:</strong>
                  </Col>

                  <Col span={8} style={{ textAlign: 'right' }}>
                    {payscaleUsage?.unsuccessfulReports?.yoe || ' 0'}
                  </Col>
                </Row>
              </li>
            </ul>
          </Col>
        </Row>
      </Modal>
    </Spin>
  );
};

export default Payscale;
