import React, { useState, useEffect, useContext, useRef } from 'react';
import { Button, Tooltip, Select, Input, notification, Spin, Table, Tag, Radio, Checkbox, Popconfirm } from 'antd';
import { QuestionCircleFilled } from '@ant-design/icons';
import Axios from 'axios';
import { UserContext } from '../../App';
import { SERVER_URL } from '../../config';
import { Link, useHistory } from 'react-router-dom';
import { ExportToExcel } from '../../components/csv';
import CIP_CODES from '../../lib/programLevelCipcodes.json';
import slugify from 'slugify';

let DEGREES = [
  { label: "Associate's", value: "Associate's Degree" },
  { label: "Bachelor's", value: 'Bachelors Degree' },
  { label: "Master's", value: "Master's Degree" },
];

const defaultDegree = (url) => {
  if (url === 'undergraduate') return 'Bachelors Degree';
  if (url === 'associate') return "Associate's Degree";
  if (url === 'graduate') return "Master's Degree";
  if (url === 'other') return 'First Professional Degree';
  return null;
};

const NewPLRanking = () => {
  // grad vs undergrad
  const path = window.location.pathname.split('/');
  const type = path[path.length - 1];
  const online =
    window.location.search && window.location.search.includes('?online=') ? window.location.search.split('=')[1] : null;
  const isOnline = online ? true : false;

  if (type === 'other') {
    DEGREES = [
      { label: 'First Professional', value: 'First Professional Degree' },
      { label: 'Certificate or Diploma', value: 'Undergraduate Certificate or Diploma' },
    ];
  }

  const currentuser = useContext(UserContext);
  const history = useHistory();
  const [cipcode, setCipcode] = useState(null);
  const [title, setTitle] = useState(null);
  const [url, setUrl] = useState(null);
  const [degree, setDegree] = useState(defaultDegree(type));
  const [working, setWorking] = useState(false);
  const [data, setData] = useState([]);
  const [level, setLevel] = useState('subject');
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [allSubjects, setAllSubjects] = useState([]);
  const [threshold, setThreshold] = useState(null); // graduation rate threshold
  const [showAdditional, setShowAdditional] = useState(false);
  const [dontShowStates, setDontShowStates] = useState(false);
  const [nationalWA, setNationalWA] = useState(null);
  const [includeAll, setIncludeAll] = useState(false);
  const [includeIC, setIncludeIC] = useState(false);
  const [existingRankings, setExistingRankings] = useState([]);
  const [onlineOther, setOnlineOther] = useState(false);

  const selectSubjectRef = useRef();
  const nameForWP = useRef();
  const urlForWP = useRef();

  useEffect(() => {
    const fetchExisting = async () => {
      try {
        setWorking(true);
        const res = await Axios.get(`${SERVER_URL}/rankings-universal?degree=other`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });

        if (res && res.data) {
          setExistingRankings(res.data);
        }
        setWorking(false);
      } catch (error) {
        setWorking(false);
        console.log(error);
        notification.error({
          message: 'Error',
          description: 'Unable to fetch existing other rankings',
          placement: 'bottomRight',
        });
      }
    };
    fetchExisting();
  }, []);

  useEffect(() => {
    const resetState = () => {
      setWorking(true);
      setData([]);
      setWorking(false);
    };
    resetState();
  }, [degree]);

  const handleFetch = async () => {
    if (!degree || !cipcode) {
      notification.error({
        message: 'Missing degree or cipcode',
        placement: 'bottomRight',
        duration: 3,
      });
      setData([]);
      return;
    }
    try {
      setWorking(true);
      // NEW METHODOLOGY
      const res = await Axios.get(
        `${SERVER_URL}/rankings-new-methodology-creation?degree=${degree}&cipcode=${cipcode}&isProgramLevel=true&online=${
          online || onlineOther || degree === "Associate's Degree" ? true : false
        }&includeAll=${degree === "Associate's Degree" ? includeAll : false}&includeIC=${
          degree === "Associate's Degree" ? includeIC : false
        }`,
        { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
      );

      const subject = res.data?.ranking?.cipcode_6_digits
        ? res.data.ranking.cipcode_6_digits
        : CIP_CODES.find((item) => item.code === cipcode);

      setData(res.data.ranking.rankingItems);
      setThreshold(res.data.ranking.threshold);
      setNationalWA(res.data.ranking.weightedAveragesNational);
      if (subject) {
        // Remove dot ('.') from program name
        if (subject.label[subject.label.length - 1] === '.') subject.label = subject.label.slice(0, -1);

        setTitle(subject.label);
        const lowerSubject = subject.label.replace('/', '-').toLowerCase();
        setUrl(slugify(lowerSubject));
      } else {
        setTitle(null);
        setUrl(null);
      }
      setLevel('subject');

      // get all main rankings for additional select
      const resAdditional = await Axios.get(
        `${SERVER_URL}/rankings-programs-parent?parent=false&degree=${degree}&online=${isOnline || onlineOther}`,
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );
      if (resAdditional) {
        setAllSubjects(resAdditional.data);
      }
      setWorking(false);
    } catch (error) {
      setWorking(false);
      notification.error({
        message: error.response && error.response.data ? error.response.data.error : 'Internal server error',
        placement: 'bottomRight',
        duration: 3,
      });
      console.log(error.response);
    }
  };

  const handleSave = async () => {
    // validation
    if (!title) {
      nameForWP.current.focus();
      notification.error({
        message: 'Missing name of the ranking',
        placement: 'bottomRight',
        duration: 3,
      });
      return;
    }

    if (!url) {
      urlForWP.current.focus();
      notification.error({
        message: 'Missing url of the ranking for WP',
        placement: 'bottomRight',
        duration: 3,
      });
      return;
    }

    if (!degree || !cipcode) {
      notification.error({
        message: 'Missing degree or cipcode',
        placement: 'bottomRight',
        duration: 3,
      });
      return;
    }

    if (level === 'additional' && !selectedSubject) {
      selectSubjectRef.current.focus();
      notification.error({
        message: 'Missing subject for additional ranking',
        placement: 'bottomRight',
        duration: 3,
      });
      return;
    }

    if (existingRankings.find((existing) => existing.degree === degree && existing.cipcode === cipcode)) {
      notification.error({
        message: 'Ranking already exists',
        placement: 'bottomRight',
        duration: 3,
      });
      return;
    }

    const withData = data.filter((item) => item.valueRank && item.valueRank !== 9999999);
    if (withData.length <= 2) {
      notification.error({
        message: `Ranking has only ${withData.length} school(s) with data`,
        description: 'Rankings with number of schools with data below or equal to 2 are not allowed',
        placement: 'bottomRight',
        duration: 5,
      });
      return;
    }

    try {
      setWorking(true);

      const body = {
        category: title,
        categoryUrl: url,
        gradRateThreshold: threshold,
        items: data,
        isProgramLevel: true,
        cipcode,
        degree,
        parent: level !== 'additional' ? null : selectedSubject,
        showAdditional,
        dontShowStates,
        weightedAveragesNational: nationalWA,
        includeAll,
        includeIC,
      };

      const route = online || onlineOther ? `${SERVER_URL}/rankings-online` : `${SERVER_URL}/rankings-universal`;

      await Axios.post(route, body, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: 'Ranking created',
        placement: 'bottomRight',
        duration: 3,
      });

      history.push('/admin');
      !online
        ? degree === 'Bachelors Degree'
          ? history.push('/admin/rankings/undergraduate')
          : degree === "Master's Degree"
          ? history.push('/admin/rankings/graduate')
          : degree === "Associate's Degree"
          ? history.push('/admin/rankings/associate')
          : history.push('/admin/rankings/other')
        : history.push(`/admin/rankings/online?tab=${online}`);
      setWorking(false);
    } catch (error) {
      setWorking(false);
      console.log(error);
    }
  };

  let columns;

  if (![`Master's Degree`, 'First Professional Degree'].includes(degree)) {
    columns = [
      {
        title: 'RANK',
        dataIndex: 'rank',
        key: 'rank',
        // ...getColumnSearchProps('rank'),
      },
      {
        title: 'NAME',
        dataIndex: 'name',
        key: 'name',
        // ...getColumnSearchProps('name'),
      },
      {
        title: 'UNITID',
        dataIndex: '_id',
        key: '_id',
        // ...getColumnSearchProps('name'),
      },
      {
        title: 'STATE',
        dataIndex: 'state',
        key: 'state',
        // ...getColumnSearchProps('state'),
      },
      {
        title: 'CITY',
        dataIndex: 'city',
        key: 'city',
        // ...getColumnSearchProps('city'),
      },
      {
        title: 'ECONOMIC SCORE',
        dataIndex: 'valueRank',
        key: 'valueRank',
        align: 'right',
        render: (text) =>
          text && text === 9999999
            ? 'N/A'
            : text
            ? text.toLocaleString('us', { maximumFractionDigits: 2 })
            : text ?? 'N/A',
        // ...getColumnSearchProps('city'),
      },
      {
        title: 'NET COST',
        dataIndex: 'netCost',
        key: 'netCost',
        align: 'right',
        render: (text) => (text && text === 9999999 ? 'N/A' : text ?? 'N/A'),
        // ...getColumnSearchProps('netCost'),
      },
      {
        title: 'EARNINGS',
        dataIndex: 'earnings',
        key: 'earnings',
        align: 'right',
        render: (text) =>
          text && text === -9999999
            ? 'N/A'
            : text
            ? text.toLocaleString('us', { maximumFractionDigits: 0 })
            : text ?? 'N/A',
        // ...getColumnSearchProps('earnings'),
      },
      {
        title: 'PAYBACK',
        dataIndex: 'payback',
        key: 'payback',
        align: 'right',
        render: (text) =>
          text && text === 9999999
            ? 'N/A'
            : text
            ? text.toLocaleString('us', { maximumFractionDigits: 2 })
            : text ?? 'N/A',
        // ...getColumnSearchProps('payback'),
      },
      {
        title: 'EARNING PLUS',
        dataIndex: 'degreePremium',
        key: 'degreePremium',
        align: 'right',
        render: (text) =>
          text && text === -9999999
            ? 'N/A'
            : text
            ? text.toLocaleString('us', { maximumFractionDigits: 0 })
            : text ?? 'N/A',
        // ...getColumnSearchProps('degreePremium'),
      },
      {
        title: 'GRADUATION RATE',
        dataIndex: 'gradRate',
        key: 'gradRate',
        align: 'right',
        render: (text, record) => (
          <Tag color={record.graduationRate < threshold ? 'red' : 'green'}>{text ?? 'N/A'}</Tag>
        ),
        // text ?? 'N/A',
        // ...getColumnSearchProps('gradRate'),
      },
    ];
  } else {
    columns = [
      {
        title: 'RANK',
        dataIndex: 'rank',
        key: 'rank',
        // ...getColumnSearchProps('rank'),
      },
      {
        title: 'NAME',
        dataIndex: 'name',
        key: 'name',
        // ...getColumnSearchProps('name'),
      },
      {
        title: 'UNITID',
        dataIndex: '_id',
        key: '_id',
        // ...getColumnSearchProps('name'),
      },
      {
        title: 'STATE',
        dataIndex: 'state',
        key: 'state',
        // ...getColumnSearchProps('state'),
      },
      {
        title: 'CITY',
        dataIndex: 'city',
        key: 'city',
        // ...getColumnSearchProps('city'),
      },
      {
        title: 'ECONOMIC SCORE',
        dataIndex: 'valueRank',
        key: 'valueRank',
        align: 'right',
        render: (text) =>
          text && text === 9999999
            ? 'N/A'
            : text
            ? text.toLocaleString('us', { maximumFractionDigits: 2 })
            : text ?? 'N/A',
        // ...getColumnSearchProps('city'),
      },
      {
        title: 'EARNINGS',
        dataIndex: 'earnings',
        key: 'earnings',
        align: 'right',
        render: (text) =>
          text && text === -9999999
            ? 'N/A'
            : text
            ? text.toLocaleString('us', { maximumFractionDigits: 0 })
            : text ?? 'N/A',
        // ...getColumnSearchProps('earnings'),
      },
      {
        title: 'DEBT',
        dataIndex: 'debt',
        key: 'debt',
        align: 'right',
        render: (text) =>
          text && text === 9999999
            ? 'N/A'
            : text
            ? text.toLocaleString('us', { maximumFractionDigits: 0 })
            : text ?? 'N/A',
        // ...getColumnSearchProps('payback'),
      },
      {
        title: 'DEBT TO EARN RATIO',
        dataIndex: 'debtToEarnRatio',
        key: 'debtToEarnRatio',
        align: 'right',
        render: (text) => (text ? text.toLocaleString('us', { maximumFractionDigits: 2 }) : 'N/A'),
        // ...getColumnSearchProps('payback'),
      },
      {
        title: 'EARNING PLUS',
        dataIndex: 'degreePremium',
        key: 'degreePremium',
        align: 'right',
        render: (text) =>
          text && text === -9999999
            ? 'N/A'
            : text
            ? text.toLocaleString('us', { maximumFractionDigits: 0 })
            : text ?? 'N/A',
        // ...getColumnSearchProps('degreePremium'),
      },
    ];
  }

  let tableData = [];
  if (data && data?.length > 0) {
    tableData = data.map((item) => {
      if (item.graduationRate) {
        item.gradRate = item.graduationRate.toFixed(2);
      }
      // item.createdAt = new Date(item.createdAt).toLocaleString('en-US');
      // item.updatedAt = new Date(item.updatedAt).toLocaleString('en-US');
      return item;
    });
  }

  let filename = '';
  let excelData = [];
  if (data && data.length > 0) {
    filename = 'cipcode_' + cipcode + `_${degree.split(' ').join('_').toLowerCase()}`;
    excelData = data.map((item) => item);
  }

  return (
    <div className='content-wrapper'>
      <Spin spinning={working} tip='Working...'>
        <div className='actions-block flex'>
          <ExportToExcel
            csvData={excelData}
            fileName={filename}
            propsToRemove={['_id', 'fullOnline']}
            disabled={data?.length === 0}
          />

          <Link
            to={
              !online
                ? type === 'undergraduate'
                  ? '/admin/rankings/undergraduate'
                  : type === 'graduate'
                  ? '/admin/rankings/graduate'
                  : type === 'associate'
                  ? '/admin/rankings/associate'
                  : '/admin/rankings/other'
                : `/admin/rankings/online?tab=${online}`
            }
          >
            <Tooltip title='View all rankings (program level)'>
              <Button type='primary' style={{ marginRight: '8px', float: 'left' }}>
                ALL RANKINGS
              </Button>
            </Tooltip>
          </Link>

          <Select
            showSearch
            value={degree}
            onChange={(value) => setDegree(value)}
            style={{ width: type === 'other' ? '220px' : '130px', marginLeft: '4px', fontSize: '15px' }}
            placeholder='Select degree...'
            disabled={type !== 'other'}
          >
            {DEGREES.map(({ value, label }, index) => (
              <Select.Option key={`${value}_${index}`} value={value}>
                {label}
              </Select.Option>
            ))}
          </Select>

          <Input
            type={'text'}
            value={cipcode}
            onChange={(e) => setCipcode(e.target.value)}
            placeholder={'Cipcode (13 | 13.12 | 13.1202)'}
            style={{ width: degree !== "Associate's Degree" ? '250px' : '200px' }}
          />

          {degree === "Associate's Degree" && (
            <>
              <Tooltip title='Include non-online associate programs as well'>
                <Checkbox checked={includeAll} onChange={(e) => setIncludeAll(e.target.checked)}>
                  <QuestionCircleFilled />
                  Include non-online
                </Checkbox>
              </Tooltip>

              <Tooltip title='Include schools with ICLEVEL of 1 (level of institution)'>
                <Checkbox checked={includeIC} onChange={(e) => setIncludeIC(e.target.checked)}>
                  <QuestionCircleFilled />
                  Include all levels
                </Checkbox>
              </Tooltip>
            </>
          )}

          {type === 'other' && (
            <Checkbox checked={onlineOther} onChange={(e) => setOnlineOther(e.target.checked)}>
              Online
            </Checkbox>
          )}

          <p>Total Results: {data?.length}</p>

          <Button style={{ width: '100px' }} type={'primary'} onClick={handleFetch}>
            Fetch
          </Button>
        </div>

        {!working && data && data.length > 0 && (
          <>
            <div>
              <label htmlFor={'title'}>Name: </label>
              <Input
                type={'text'}
                name='title'
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                style={{ width: '310px', marginRight: '50px' }}
                ref={nameForWP}
              />

              <label htmlFor={'url'}>Url: </label>
              <Tooltip title='URL segment on WP'>
                <Input
                  type={'text'}
                  value={url}
                  name='url'
                  onChange={(e) => setUrl(e.target.value)}
                  style={{ width: '310px' }}
                  ref={urlForWP}
                />
              </Tooltip>

              <div style={{ float: 'right' }}>
                <Radio.Group defaultValue='subject' buttonStyle='solid' onChange={(e) => setLevel(e.target.value)}>
                  <Radio.Button value='subject'>Main Ranking</Radio.Button>
                  <Radio.Button value='additional'>Additional Ranking</Radio.Button>
                </Radio.Group>

                {level !== 'additional' && (
                  <div>
                    <br></br>
                    <label htmlFor='showAdditional'>Show Additional: </label>
                    <Checkbox
                      style={{ marginLeft: '5px' }}
                      name='showAdditional'
                      checked={showAdditional}
                      onChange={() => setShowAdditional(!showAdditional)}
                    ></Checkbox>
                  </div>
                )}

                {degree !== "Associate's Degree" && !isOnline && (
                  <div>
                    <label htmlFor='dontShowStates'>Don't Create State Pages: </label>
                    <Checkbox
                      style={{ marginLeft: '5px' }}
                      name='dontShowStates'
                      checked={dontShowStates}
                      onChange={() => setDontShowStates(!dontShowStates)}
                    ></Checkbox>
                  </div>
                )}

                <br></br>
                <br></br>
              </div>
            </div>
            <br></br>
            <br></br>

            {level === 'additional' ? (
              <div>
                <label htmlFor='parent'>Parent Subject: </label>
                <Select
                  name='parent'
                  allowClear
                  showSearch
                  optionFilterProp='children'
                  value={selectedSubject}
                  onChange={(value) => setSelectedSubject(value)}
                  style={{ width: '400px', marginLeft: '4px', fontSize: '15px' }}
                  ref={selectSubjectRef}
                >
                  {allSubjects.length > 0
                    ? allSubjects.map((item, index) => (
                        <Select.Option key={`${item._id}_${index}`} value={item._id}>
                          {item.category}- {item.degree} - {item.cipcode}
                        </Select.Option>
                      ))
                    : null}
                </Select>
              </div>
            ) : null}

            <br></br>
            <Table
              size='middle'
              // onChange={onChangeTable}
              bordered
              dataSource={tableData}
              columns={columns}
              rowKey={(record) => record._id}
              pagination={{
                pageSize: 20,
                // total: total || 0,
                // current: page,
                // defaultCurrent: 1,
                // position: 'bottom',
                // showSizeChanger: false,
                // onChange: (page) => pageHandler(page),
                // hideOnSinglePage: true,
              }}
            />
            <br></br>

            <Popconfirm
              title={`Are you sure you want to save this ranking?`}
              onConfirm={handleSave}
              onCancel={() => console.log('Cancel')}
              okText='Yes'
              cancelText='No'
            >
              <Button style={{ width: '90px', float: 'right' }} type={'primary'}>
                Save
              </Button>
            </Popconfirm>
          </>
        )}
        {!data ||
          (data.length === 0 && (
            <div style={{ textAlign: 'center', marginTop: '1rem' }}>
              <h3>Click on 'FETCH' to (re)fetch the data</h3>
            </div>
          ))}
      </Spin>
    </div>
  );
};

export default NewPLRanking;
