import React, {useState} from 'react';
import AffiliateCodes from './Codes'
import Tags from './Tags'
import {Tabs} from 'antd';
import {useLocation, useHistory} from 'react-router-dom';
import Institutions from "./institutions";
import StateOrder from "./StateOrder";
import Provider from "./Provider";

const {TabPane} = Tabs;

const CommercialProgramSection = () => {
    const history = useHistory();
    const search = useLocation().search;
    const tab = new URLSearchParams(search).get('tab');
    const [activeTab, setActiveTab] = useState(tab);

    return (
        <div className='content-wrapper'>
            <Tabs
                onChange={(value) => {
                    setActiveTab(value);
                    history.push(`/admin/affiliate-codes?tab=${value}`);
                }}
                defaultActiveKey={tab && tab !== '' ? tab : 'MARKET_CONTEXT'}
            >
                <TabPane tab='MARKET_CONTEXT' key='MARKET_CONTEXT'>
                    <AffiliateCodes type='MARKET_CONTEXT'/>
                </TabPane>

                <TabPane tab='SPECIALIZATIONS' key='SPECIALIZATIONS'>
                    <AffiliateCodes type='SPECIALIZATION'/>
                </TabPane>

                <TabPane tab='DEGREE' key='DEGREE'>
                    <AffiliateCodes type='DEGREE'/>
                </TabPane>

                <TabPane tab='TAGS' key='TAGS'>
                    <Tags/>
                </TabPane>
                <TabPane tab='INSTITUTIONS' key='INSTITUTIONS'>
                    <Institutions />
                </TabPane>
                <TabPane tab="STATE ORDER" key="STATE_ORDER">
                    <StateOrder />
                </TabPane>
                <TabPane tab="PROVIDERS" key="PROVIDERS">
                    <Provider />
                </TabPane>
            </Tabs>
        </div>
    );
};

export default CommercialProgramSection;
