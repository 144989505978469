import React, {useState, useEffect, useContext} from 'react';
import Axios from 'axios';
import {SERVER_URL} from '../../config';
import {UserContext} from '../../App';
import {Spin, Select, Button, notification, Tooltip, Input, Row, Col, Space, Switch, Checkbox} from 'antd';
import AffiliateLinksTable from '../../components/tables/AffiliateLinksTable';
import {useHistory} from 'react-router-dom';
import ASD_DEGREES from '../../lib/ASDdegrees.json';
import axios from "axios";

ASD_DEGREES.unshift({title: 'All'});

const AffiliateList = () => {
    const currentUser = useContext(UserContext);
    const history = useHistory();
    const [asd, setAsd] = useState([]);
    const [working, setWorking] = useState(false);
    const [degree, setDegree] = useState("Bachelor's");
    const [specializations, setSpecializations] = useState([]);
    const [selectedSpec, setSelectedSpec] = useState(null);
    const [provider, setProvider] = useState(null);
    const [search, setSearch] = useState('');
    const [active, setActive] = useState(null);
    const [isRibbon, setIsRibbon] = useState(false);
    const [providers, setProviders] = useState([]);


    const fetchASD = async (page=1,limit=20) => {
        try {
            setWorking(true);
            const {data} = await Axios.get(`${SERVER_URL}/get-asd-data?specialization=${selectedSpec}&degree=${degree}&search=${search}&active=${active}&provider=${provider}&page=${page}&limit=${limit}&isRibbon=${isRibbon}`, {
                withCredentials: false,
                headers: {Authorization: `Bearer ${currentUser.data.token}`},
            });
            if (data) {
                setAsd(data);
            }
            setWorking(false);
        } catch (error) {
            setWorking(false);
            console.log('Error: ', error);
        }
    };

    const fetchSpecializations = async () => {
        setWorking(true);
        const {data: marketContext} = await axios.get(`${SERVER_URL}/affiliate-institution-code/MARKET_CONTEXT/all`, {
            withCredentials: false,
            headers: {Authorization: `Bearer ${currentUser.data.token}`},
        });
        const {data: specializations} = await axios.get(`${SERVER_URL}/affiliate-institution-code/SPECIALIZATION/all`, {
            withCredentials: false,
            headers: {Authorization: `Bearer ${currentUser.data.token}`},
        });
        setSpecializations([...marketContext, ...specializations]);
        setWorking(false);
    }

    const fetchProviders = async () => {
        try {
            setWorking(true);
            const {data: providers} = await axios.get(`${SERVER_URL}/affiliate-institution-provider`,{
                withCredentials: false,
                headers: {Authorization: `Bearer ${currentUser.data.token}`},
            });
            if (providers) {
                setProviders(providers);
            }
            setWorking(false);
        } catch (error) {
            setWorking(false);
            console.log('Error: ', error);
        }
    }

    useEffect(() => {
        fetchASD().catch(console.error);
    }, [degree, selectedSpec, currentUser.data.token,search, active, provider, isRibbon]);

    useEffect(() => {
        fetchSpecializations().catch(console.error);
        fetchProviders().catch(console.error);
    }, [currentUser.data.token]);

    let tableData = [];
    if (asd && asd?.length > 0) {
        tableData = asd.map((item) => {
            item.createdAt = new Date(item.createdAt).toLocaleString('en-US');
            // item.programsLength = item.programs.length;
            return item;
        });
    }

    const handleInitialCreate = async () => {
        // if (asd && asd.length > 0) return;
        const body = {
            landingUrl: 'https://www.degreechoices.com',
            medium: 'part',
            source: 'dgc',
        };
        try {
            setWorking(true);
            await Axios.post(`${SERVER_URL}/create-initial-asd-data`, body, {
                withCredentials: false,
                headers: {Authorization: `Bearer ${currentUser.data.token}`},
            });

            notification.success({
                message: 'ASD data created.',
                placement: 'bottomRight',
            });

            setWorking(false);

            history.push('/admin');
            history.push('/admin/affiliate-links');
        } catch (error) {
            setWorking(false);
            console.log(error);
            notification.error({
                message: 'Problem with creating.',
                placement: 'bottomRight',
            });
        }
    };

    let handleChangeStatus = async (id, status, provider) => {
        // id, status, provider
        const data = {
            id,
            status: status ? 1 : 0,
            provider,
        }
        try {
            setWorking(true);
            const specData = await Axios.post(`${SERVER_URL}/affiliate-update-status-programs`, data, {
                withCredentials: false,
                headers: {Authorization: `Bearer ${currentUser.data.token}`},
            });
            setWorking(false);
            return {message: 'success', data: specData.data};
        } catch (error) {
            setWorking(false);
            console.log('Error: ', error);
            return {message: 'error', data: undefined};
        }
    };

    const handleSearchNameOrProgramName = async (e) => {
        setSearch(e.target.value);
    };


    const OnPageChanged = async (page, limit) => {
        await fetchASD(page, limit);
    };

    function handleChangeFilterIsRibbon(e) {
        setIsRibbon(e.target.checked);
    }

    return (
        <div className='content-wrapper'>
            <Spin spinning={working} tip='Working...'>
                <div className='actions-block'>
                    <Row gutter={12}>
                        <Col span={6}>
                            <Space direction="horizontal">
                            <Button type="primary" onClick={() => history.push(`/admin/edit/provider/TOU/new`)}>New</Button>
                            <Tooltip title={`Fetch new data from ASD API`}>
                                <Button onClick={handleInitialCreate} type='primary'>
                                    Fetch new ASD data
                                </Button>
                            </Tooltip>
                            </Space>
                        </Col>
                    </Row>
                    <br/>
                    <Row gutter={10}>
                        <Col span={3}>
                            <Select
                                style={{width:'100%'}}
                                value={provider}
                                showSearch
                                allowClear
                                onChange={(value) => setProvider(value)}
                                placeholder={'Provider, ASD, 2U ...'}>
                                {providers.map((provider, index) => (
                                    <Select.Option key={index} disabled={!provider.active} value={provider.code}>{provider.name}</Select.Option>
                                ))}
                            </Select>
                        </Col>
                        <Col span={6}>
                            <Select
                                style={{width: '100%'}}
                                showSearch
                                value={degree}
                                onChange={(value) => setDegree(value)}
                                placeholder='Select Degree...'
                            >
                                {ASD_DEGREES.map((item) => (
                                    <Select.Option value={item.title} key={item.title}>
                                        {item.title}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Col>
                        <Col span={6}>
                            <Select
                                style={{width: '100%'}}
                                showSearch
                                allowClear
                                value={selectedSpec}
                                onChange={(value) => setSelectedSpec(value)}
                                placeholder='Select Specialization...'
                            >
                                {specializations.map((item) => (
                                    <Select.Option value={item.value} key={item.value}>
                                        {item.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Col>
                        <Col span={6}>
                            <Select style={{width: '100%'}} value={active} onChange={(value)=>setActive(value)}>
                                <Select.Option value={null} key={1}>All programs</Select.Option>
                                <Select.Option value={1} key={2}>Active Programs</Select.Option>
                                <Select.Option value={0} key={3}>Inactive Programs</Select.Option>
                            </Select>
                        </Col>
                    </Row>
                </div>
                <div>
                    <AffiliateLinksTable
                        asd={asd}
                        setAsd={setAsd}
                        tableData={tableData}
                        handleChangeStatus={handleChangeStatus}
                        OnPageChanged={OnPageChanged}
                        title='ASD'
                        editPath='/admin/edit-asd/'
                        handleOnChangeSearch={setSearch}
                    />
                </div>
            </Spin>
        </div>
    );
};

export default AffiliateList;
