import React, { useContext, useState, useEffect } from 'react';
import { Button, Col, List, Modal, Row, Image, Form, Input, Switch, Select, notification } from 'antd';
import * as _ from 'lodash';
import * as Yup from 'yup';
import { DeleteOutlined, MenuOutlined } from '@ant-design/icons';
import ReactDragListView from 'react-drag-listview';
import Axios from 'axios';
import { UserContext } from '../../App';
import { SERVER_URL } from '../../config';

const { confirm } = Modal;

let bubbleSchema = Yup.object().shape({
  isCustom: Yup.boolean(),
  name: Yup.string().required('Bubble name is required').nullable(),
  link: Yup.string().when('isCustom', (isCustom, schema) => {
    console.log('isCustom', isCustom);
    if (isCustom) {
      return schema.required('Bubble link is required').nullable();
    }
    return schema.nullable();
  }),
  description: Yup.string().nullable(),
  imageUrl: Yup.string().url('Bubble image url is not valid').nullable(),
});

let initialData = {
  isCustom: false,
  name: undefined,
  link: undefined,
  description: undefined,
  imageUrl: undefined,
  category: undefined,
  metaImage: undefined,
  metaTitle: undefined,
  isEdit: false,
  nameForDisplay: undefined,
};

function BubblesList({ data, handleBubbles, refresh }) {
  const currentuser = useContext(UserContext);
  const [visible, setVisible] = useState(false);
  const [bubbles, setBubbles] = useState([]);
  const [customBubble, setCustomBubble] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [metaImage, setMetaImage] = useState(null);
  const [modalTitle, setModalTitle] = useState(null);
  const [form] = Form.useForm();

  // https://stackblitz.com/edit/react-97lr5s?file=index.js
  const yupSync = {
    async validator({ field }, value) {
      await bubbleSchema.validateSyncAt(field, { [field]: value });
    },
  };
  const handleRemoveBubble = (name) => {
    confirm({
      title: `Are you sure you want to remove bubble ${_.upperCase(name.replaceAll('-', ' '))}?`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        removeBubble(_.kebabCase(name.toLowerCase()));
      },
      getContainer: document.getElementById('bubbleCollapse'),
    });
  };

  async function fetchBubbles() {
    const { data } = await Axios.get(`${SERVER_URL}/get-dropdown-bubble`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
    });
    setBubbles(data);
  }

  useEffect(() => {
    fetchBubbles().catch(console.error);
  }, []);

  useEffect(() => {
    fetchBubbles().catch(console.error);
  }, [refresh]);

  const removeBubble = (bubbleToRemove) => {
    const oldBubbles = [...data];
    const newBubbles = oldBubbles.filter((item) => _.kebabCase(item.name.toLowerCase()) !== bubbleToRemove);
    handleBubbles([...newBubbles]);
  };

  function submitBubble(values) {
    console.log(values);

    if (!values.isEdit) {
      const { name } = values;
      const isExist = data.find((item) => item.name === name);
      if (isExist) {
        notification.error({
          message: 'Error',
          description: 'Bubble with same name already exist.',
        });
        return;
      }
      delete values.isEdit;
      delete values.isCustom;
      handleBubbles([...data, values]);
      form.resetFields();
    } else {
      // first find in array item
      const editItemIndex = data.findIndex((item) => item.name === values.name);
      if (editItemIndex > -1) {
        delete values.isEdit;
        delete values.isCustom;
        const oldBubbles = [...data];
        oldBubbles[editItemIndex] = values;
        handleBubbles([...oldBubbles]);
        form.resetFields();
      } else {
        notification.error({
          message: 'Error',
          description: 'Problem with editing bubble. Please try again later.',
        });
      }
    }
    setCustomBubble(false);
    setImageSrc(null);
    setMetaImage(null);
    setVisible(false);
  }

  function openModal(title) {
    setModalTitle(title);
    setVisible(true);
  }

  const handleCancel = () => {
    setCustomBubble(false);
    setVisible(false);
    setImageSrc(null);
    setMetaImage(null);
    form.resetFields();
  };

  function handleFormValuesChanged(values) {
    if (values.isCustom !== undefined) {
      setCustomBubble(values.isCustom);
    }
    if (values.imageUrl !== undefined) {
      setImageSrc(values.imageUrl);
    }
  }

  function handleBubblesChange(value, item) {
    const { key } = item;
    const bubble = bubbles[key];
    const { metaImage, metaTitle, category, additional, degree, cipCode, isOnline, bobbleDescription, parent } = bubble;
    form.setFieldsValue({
      metaImage: metaImage,
      metaTitle: metaTitle,
      category: category,
      additional,
      degree,
      cipCode,
      isOnline,
      description: bobbleDescription,
      parent,
    });
    setMetaImage(metaImage);
  }

  return (
    <>
      <Row gutter={12}>
        <Col span={12}>
          <Button type='primary' size='small' onClick={() => openModal('Add Bubble')}>
            Add bubble
          </Button>
        </Col>
      </Row>
      <br />
      <Row gutter={12}>
        <Col span={24}>
          <ReactDragListView
            onDragEnd={(fromIndex, toIndex) => {
              const oldBubbles = [...data];
              const newBubbles = _.cloneDeep(oldBubbles);
              newBubbles.splice(toIndex, 0, newBubbles.splice(fromIndex, 1)[0]);
              handleBubbles([...newBubbles]);
            }}
            nodeSelector='.ant-list-item.draggable-item'
            lineClassName='dragLine'
          >
            <List
              bordered
              dataSource={data}
              renderItem={(item) => (
                <List.Item
                  className='draggable-item'
                  style={{ cursor: 'grab', color: '#999' }}
                  actions={[
                    <Button danger type='link' size='small' onClick={() => handleRemoveBubble(item.name)}>
                      <DeleteOutlined /> Remove
                    </Button>,
                    <a style={{ cursor: 'move!important' }} href='#'>
                      <MenuOutlined
                        style={{
                          cursor: 'grab',
                          color: '#999',
                        }}
                      />
                    </a>,
                  ]}
                >
                  <List.Item.Meta
                    className='grabbable'
                    avatar={<Image height={100} width={100} src={item.imageUrl ?? item.metaImage} />}
                    title={item.nameForDisplay || item.name}
                    description={item.description}
                  />
                </List.Item>
              )}
            />
          </ReactDragListView>
        </Col>
      </Row>
      <Modal
        forceRender
        style={{ top: 20 }}
        destroyOnClose={true}
        visible={visible}
        title={modalTitle}
        onOk={form.submit}
        onCancel={handleCancel}
        getContainer={document.getElementById('bubbleCollapse')}
      >
        <Form
          onValuesChange={handleFormValuesChanged}
          initialValues={initialData}
          form={form}
          layout='vertical'
          onFinish={submitBubble}
        >
          <Form.Item name='isEdit' hidden={true}>
            <Input />
          </Form.Item>
          <Form.Item name='category' hidden={true}>
            <Input />
          </Form.Item>
          <Form.Item name='additional' hidden={true}>
            <Input />
          </Form.Item>
          <Form.Item name='isOnline' hidden={true}>
            <Input />
          </Form.Item>
          <Form.Item name='cipCode' hidden={true}>
            <Input />
          </Form.Item>
          <Form.Item name='parent' hidden={true}>
            <Input />
          </Form.Item>
          <Form.Item name='degree' hidden={true}>
            <Input />
          </Form.Item>
          <Form.Item name='isCustom' label='Is custom bubble' valuePropName='checked'>
            <Switch
              onChange={(checked) => {
                form.setFieldsValue({ isCustom: checked });
              }}
            />
          </Form.Item>
          {!customBubble && (
            <>
              <Form.Item name='name' label='Ranking' rules={[yupSync]}>
                <Select
                  disabled={form.getFieldValue('isEdit')}
                  showSearch
                  style={{ width: '100%' }}
                  placeholder='Select bubble...'
                  onChange={handleBubblesChange}
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {bubbles.map((bub, index) => (
                    <Select.Option value={bub.name} key={index}>
                      {bub.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item name='nameForDisplay' label='Name'>
                <Input disabled={form.getFieldValue('isEdit')} />
              </Form.Item>
            </>
          )}
          {customBubble && (
            <Form.Item name='name' label='Name' rules={[yupSync]}>
              <Input disabled={form.getFieldValue('isEdit')} />
            </Form.Item>
          )}
          {customBubble && (
            <Form.Item
              name='link'
              label='Link'
              rules={[
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (getFieldValue('isCustom') && !value) {
                      return Promise.reject('Bubble link is required');
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input />
            </Form.Item>
          )}
          <Form.Item name='description' label='Description' rules={[yupSync]}>
            <Input disabled={!customBubble} />
          </Form.Item>
          <Row>
            <Col span={6}>
              <Image src={imageSrc} width={100} height={100} />
            </Col>
            <Col span={18}>
              <Form.Item name='imageUrl' label='Image url' rules={[yupSync]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          {!customBubble && (
            <Row type='flex' align='middle'>
              <Col span={6}>
                <Image src={metaImage} width={100} height={100} />
              </Col>
              <Col span={18}>
                <Form.Item name='metaImage' label='Meta image'>
                  <Input disabled={!customBubble} />
                </Form.Item>
                <Form.Item name='metaTitle' label='Meta title'>
                  <Input disabled={!customBubble} />
                </Form.Item>
              </Col>
            </Row>
          )}
        </Form>
      </Modal>
    </>
  );
}

export default BubblesList;
