import React from 'react';
import { Link } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import { Button, Input, Table, Tooltip } from 'antd';
import { EditOutlined, SearchOutlined, DeleteOutlined } from '@ant-design/icons';

const IPEDSTopListTable = ({
  data,
  search,
  setSearch,
  filterHandler,
  deleteHandler,
  pageHandler,
  sortHandler,
  editPath,
  page,
  total,
  filter,
  selectedForRefresh,
  setSelectedForRefresh,
}) => {
  let searchInput;

  const getColumnSearchProps = (dataIndex) => {
    const prop = dataIndex;
    return {
      filteredValue: (filter && filter[prop] && [filter[prop]]) || null,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              ref={(node) => {
                searchInput = node;
              }}
              placeholder={`Search ${prop}`}
              value={selectedKeys}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, prop)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />

            <Button
              type='primary'
              onClick={() => handleSearch(selectedKeys, confirm, prop)}
              size='small'
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>

            <Button onClick={() => handleReset(clearFilters, prop)} size='small' style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        );
      },
      filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />,
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => {
            searchInput.select();
          });
        }
      },
      sorter: true,
      defaultSortOrder: prop === 'title' ? 'ascend' : undefined,
      sortDirections: ['ascend', 'descend'],
      render: (text) =>
        search[prop] ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[search[prop]]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          text
        ),
    };
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    if (Array.isArray(dataIndex)) dataIndex = dataIndex.join('.');
    const newSearch = { ...search };
    newSearch[dataIndex] = selectedKeys[0];
    setSearch(newSearch);
    filterHandler(dataIndex, selectedKeys);
    confirm();
  };

  const handleReset = (clearFilters, prop) => {
    if (Array.isArray(prop)) prop = prop.join('.');
    const newSearch = { ...search };
    setSearch(newSearch);
    delete newSearch[prop];
    filterHandler(prop, null);
    clearFilters();
  };

  const columns = [
    {
      title: 'TITLE',
      dataIndex: 'topListName',
      key: 'topListName',
      ...getColumnSearchProps('topListName'),
    },
    {
      title: 'CREATEDBY',
      dataIndex: ['createdBy', 'email'],
      key: 'createdBy',
      // ...getColumnSearchProps(['createdBy', 'email']),
    },
    {
      title: 'CREATEDAT',
      dataIndex: 'createdAt',
      key: 'createdAt',
      // ...getColumnSearchProps('createdAt'),
    },
    {
      title: 'UPDATEDAT',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      ...getColumnSearchProps('updatedAt'),
    },
  ];

  columns.push({
    title: 'Action',
    render: (text, record) => (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '15px',
        }}
      >
        {editPath && (
          <Tooltip title='Edit Top List' placement='topLeft'>
            <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='table-actions'>
              <Link to={editPath + record._id}>
                <EditOutlined style={{ textDecoration: 'none', color: 'black' }} />
              </Link>
            </div>
          </Tooltip>
        )}

        {deleteHandler && (
          <Tooltip title='Delete Top List' placement='topLeft'>
            <div
              style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}
              className='table-actions'
              onClick={async () => {
                deleteHandler(record._id);
              }}
            >
              <DeleteOutlined />
            </div>
          </Tooltip>
        )}
      </div>
    ),
  });

  const onChangeTable = (addPagination, addFilters, addSorter) => {
    pageHandler(addPagination.current);
    sortHandler(addSorter.field, addSorter.order);
  };

  const onSelectChange = (selectedRowKeys) => {
    setSelectedForRefresh([...selectedRowKeys]);
  };

  const rowSelection = {
    selectedForRefresh,
    onChange: onSelectChange,
  };

  return (
    <div>
      <Table
        size='middle'
        onChange={onChangeTable}
        bordered
        dataSource={data}
        columns={columns}
        rowKey={(record) => record._id}
        rowSelection={rowSelection}
        pagination={{
          pageSize: 20,
          total: total || 0,
          current: page,
          defaultCurrent: 1,
          position: 'bottom',
          showSizeChanger: false,
          onChange: (page) => pageHandler(page),
          hideOnSinglePage: true,
        }}
      />
    </div>
  );
};

export default IPEDSTopListTable;
