import React, { useState, useEffect, useContext, useRef } from 'react';
import { Table, notification, Spin, Input, Tooltip, Button, Modal } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import Axios from 'axios';
import { UserContext } from '../../../App';
import { SERVER_URL } from '../../../config';
import { Link, useHistory } from 'react-router-dom';
import { ExportToExcel } from '../../../components/csv';
import CKEditor from '../../../components/base/CKEditor';

const EMISchoolsList = () => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [items, setItems] = useState([]);
  const [category, setCategory] = useState(null);
  const [URL, setURL] = useState(null);
  const [working, setWorking] = useState(false);
  const [modal, setModal] = useState({ visible: false, unitid: null, name: null });
  const [description, setDescription] = useState('');
  const nameForWP = useRef();
  const urlForWP = useRef();

  useEffect(() => {
    const fetchEMIItems = async () => {
      try {
        setWorking(true);
        const emiRes = await Axios.get(`${SERVER_URL}/rankings-emi`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });

        if (emiRes && emiRes.data) {
          setItems(emiRes.data.items);
          setCategory(emiRes.data.category);
          setURL(emiRes.data.categoryUrl);
        }

        setWorking(false);
      } catch (error) {
        setWorking(false);
        console.log(error);
        notification.error({
          message: 'Error',
          description: 'Problem on fetching EMI ranking items',
        });
      }
    };

    fetchEMIItems();
  }, [currentuser.data.token]);

  const columns = [
    {
      title: 'RANK',
      dataIndex: 'rank',
      key: 'rank',
    },
    {
      title: 'UNITID',
      dataIndex: 'unitid',
      key: 'unitid',
    },
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'STATE',
      dataIndex: 'state',
      key: 'state',
    },
    {
      title: 'PELL PERC',
      dataIndex: 'pellPerc',
      key: 'pellPerc',
      render: (value, record) => (value ? `${value}%` : 'N/A'),
    },
    {
      title: 'EMI',
      dataIndex: 'EMI',
      key: 'EMI',
      render: (value, record) => (value ? value.toFixed(2) : 'N/A'),
    },
    {
      title: 'EMI ECONOMIC SCORE',
      dataIndex: 'EMI_ES',
      key: 'EMI_ES',
      render: (value, record) => (value ? value.toFixed(2) : 'N/A'),
    },
  ];

  columns.push({
    title: 'Description',
    key: 'description',
    render: (text, record) => {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '15px',
          }}
        >
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
            <Tooltip title='Edit description' placement='topLeft'>
              <EditOutlined
                style={{ textDecoration: 'none', color: 'black' }}
                onClick={() => {
                  setModal({
                    visible: true,
                    unitid: record.unitid,
                    name: record.name,
                  });
                  setDescription(record.description ? record.description : '');
                }}
              />
            </Tooltip>
          </div>
        </div>
      );
    },
  });

  const handleSave = async () => {
    // validation
    if (!category) {
      nameForWP.current.focus();
      notification.error({
        message: 'Missing name of the ranking',
        placement: 'bottomRight',
        duration: 3,
      });
      return;
    }
    if (!URL) {
      urlForWP.current.focus();
      notification.error({
        message: 'Missing url of the ranking for WP',
        placement: 'bottomRight',
        duration: 3,
      });
      return;
    }

    try {
      setWorking(true);

      const body = {
        category,
        categoryUrl: URL,
        items,
      };

      await Axios.patch(`${SERVER_URL}/rankings-emi`, body, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: 'EMI rankings updated',
        placement: 'bottomRight',
        duration: 3,
      });

      history.push('/admin');
      history.push('/admin/rankings/undergraduate');
      setWorking(false);
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Problem with updating ranking url',
        placement: 'bottomRight',
        duration: 3,
      });
    }
  };

  const onOk = () => {
    const newData = items.map((item) => {
      if (item.unitid === modal.unitid) {
        item.description = description;
      }
      return item;
    });
    setItems([...newData]);
    setModal({ visible: false });
  };

  return (
    <div className='content-wrapper'>
      <Spin spinning={working} tip='Working...'>
        <div className='actions-block flex'>
          <ExportToExcel
            csvData={items}
            fileName='EMI_rankings'
            propsToRemove={['_id']}
            disabled={items.length === 0}
          />

          <Link to='/admin/rankings/undergraduate'>
            <Tooltip title='View all rankings'>
              <Button type='primary' style={{ marginRight: '8px', float: 'left' }}>
                ALL RANKINGS
              </Button>
            </Tooltip>
          </Link>

          <h2>Count: {items.length}</h2>

          <div>
            <label htmlFor={'title'}>Name: </label>
            <Input
              type={'text'}
              name='title'
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              style={{ width: '310px', marginRight: '50px' }}
              ref={nameForWP}
            />

            <label htmlFor={'url'}>URL: </label>
            <Tooltip title='URL segment for WP'>
              <Input
                type={'text'}
                value={URL}
                name='url'
                onChange={(e) => setURL(e.target.value)}
                style={{ width: '310px' }}
                ref={urlForWP}
              />
            </Tooltip>
          </div>
        </div>

        <Table
          size='middle'
          // onChange={onChangeTable}
          bordered
          dataSource={items}
          columns={columns}
          rowKey={(record) => record.unitid}
          id='emiItemsTable'
        />

        <Modal
          centered
          width='70%'
          visible={modal.visible}
          title={
            <p>
              Description for<strong> {modal.name}</strong>
            </p>
          }
          okText='SAVE'
          cancelText='CANCEL'
          maskClosable={false}
          onCancel={() => {
            setModal({ visible: false });
          }}
          onOk={onOk}
          confirmLoading={working}
          getContainer={document.getElementById('emiItemsTable')}
        >
          <div>
            <p style={{ fontSize: 15, marginTop: 20, marginRight: 10 }}>Description: </p>
            <CKEditor content={description} setContent={setDescription} />
          </div>
        </Modal>

        <br></br>
        <Button style={{ width: '90px', float: 'right' }} type={'primary'} onClick={handleSave}>
          Save
        </Button>
      </Spin>
    </div>
  );
};

export default EMISchoolsList;
