import React /* , { useState, useEffect } */ from 'react';
import { getTreeFromFlatData } from 'react-sortable-tree';
import { Input, Select, Button, Form, Row, Col, TreeSelect } from 'antd';
const Option = Select.Option;

const formInit = {
  _id: null,
  title: {},
  keywords: {},
  content: {},
  url: '',
  category: '',
  name: '',
  partner: '',
};

const AffiliateFrom = ({ isNew, schools, websites, data, programs, onSubmit }) => {
  const [form] = Form.useForm();
  // const [selectedProgram, setSelectedProgram] = useState();

  // useEffect(() => {
  //   if (data && data.program) {
  //     setSelectedProgram(data.program);
  //   }
  // }, [data]);

  const onSubmitPage = async (val) => {
    if (!isNew) {
      val._id = data._id;
    }
    onSubmit(val, !data);
  };

  let initialValues = { ...formInit, ...data };

  const treeData = getTreeFromFlatData({
    flatData: programs.map((node) => ({
      title: node.parent ? `${programs.find((p) => p._id === node.parent).name} - ${node.name}` : node.name,
      value: node._id,
      key: node._id,
      parent: node.parent,
      _id: node._id,
    })),
    getKey: (node) => node._id, // resolve a node's key
    getParentKey: (node) => node.parent, // resolve a node's parent's key
    rootKey: null, // The value of the parent key when there is no parent (i.e., at root level)
  });

  return (
    <div className='panel'>
      <div className='panel-body'>
        <Form
          className='form-horizontal'
          layout='vertical'
          initialValues={initialValues}
          onFinish={(values) => {
            onSubmitPage(values, !data);
          }}
          form={form}
        >
          <Row type='flex' gutter={16}>
            <Col xs={24} md={16}>
              <div className='panel-body'>
                <Form.Item label='Url:' name='url' rules={[{ required: true, message: 'Please enter URL' }]}>
                  <Input placeholder='Add url' />
                </Form.Item>
              </div>

              <div className='panel-body'>
                <Form.Item label='Name:' name='name' rules={[{ required: true, message: 'Please enter Name' }]}>
                  <Input placeholder='Add name' />
                </Form.Item>
              </div>

              <div className='panel-body'>
                <Form.Item label='Partner:' name='partner'>
                  <Input placeholder='Add partner' />
                </Form.Item>
              </div>
            </Col>

            <Col xs={24} md={8}>
              <div className='panel panel-primary'>
                <div className='panel-heading'>
                  <h4 className='panel-title'>Programs</h4>
                </div>
                <Form.Item label='Programs' name='program' className='panel-body' rules={[{ required: true, message: 'Please select Program' }]}>
                  <TreeSelect
                    showSearch
                    filterTreeNode={(search, item) => {
                      return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
                    }}
                    style={{ width: '100%', height: 30 }}
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    treeData={treeData}
                    placeholder='Please select program'
                    onChange={(value) => {
                      // setSelectedProgram(value);
                      form.setFieldsValue({ program: value });
                    }}
                  />
                </Form.Item>
              </div>

              <div className='panel panel-primary'>
                <div className='panel-heading'>
                  <h4 className='panel-title'>Schools</h4>
                </div>
                <Form.Item label='School' name='school' className='panel-body' rules={[{ required: true, message: 'Please select School' }]}>
                  <Select showSearch style={{ width: '100%' }} placeholder='Please select school' optionFilterProp='children'>
                    {schools.map((schoolItem) => (
                      <Option key={schoolItem._id} value={schoolItem._id}>
                        {schoolItem.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>

              <div className='panel panel-primary'>
                <div className='panel-heading'>
                  <h4 className='panel-title'>Websites</h4>
                </div>
                <Form.Item label='Website' name='website' className='panel-body' rules={[{ required: true, message: 'Please select Website' }]}>
                  <Select showSearch style={{ width: '100%' }} placeholder='Please select website' optionFilterProp='children'>
                    {websites.map((websitesItem) => (
                      <Option key={websitesItem._id} value={websitesItem._id}>
                        {websitesItem.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </Col>
          </Row>
          <div className='text-right'>
            <Button type='primary' htmlType='submit'>
              {isNew ? 'Add' : 'update'} data
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};
export default AffiliateFrom;
