import React, { useEffect, useState } from 'react';
import { Form, Select, Row, Col, Input, Button, Layout } from 'antd';
import { TopListItemFormModal } from '../forms';
import DNDList from '../base/DNDTopList';
import STATES from '../../lib/states.json';

const { Content } = Layout;

const formInit = {
  _id: null,
  listName: '',
  program: undefined,
  listItems: [],
};

const TopListForm = ({ topList, isNew, onSubmit, categories, profiles, type }) => {
  const [form] = Form.useForm();
  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [filterByState, setFilterByState] = useState('');
  const [filterByProgram, setFilterByProgram] = useState('');
  const [filteredProfileList, setFilteredProfileList] = useState(profiles || []);
  const [modal, setModal] = useState({ visible: false, profile: undefined, content: '', index: undefined });
  let initialValues = { ...formInit, ...topList };
  if (topList && topList.listItems) {
    initialValues.profiles = topList.listItems.map((l) => l.profile);
  }
  const program = categories.find((cat) => cat.name.toLowerCase() === 'programs');

  /**
   * save form data to DB
   * @param {Object} val
   */
  const onSubmitPage = async (val) => {
    delete val.profiles;
    if (!isNew) val._id = topList._id;
    onSubmit(val, isNew, type);
  };

  /**
   * if editing existing Top List add temp profiles prop to form
   */
  useEffect(() => {
    if (!isNew && topList.listItems) {
      form.setFieldsValue({ profiles: topList.listItems.map((listItem) => listItem.profile) });
      setSelectedProfiles(topList.listItems || []);
    }
  }, [form, isNew, topList.listItems]);

  /**
   * Handle add/remove profile to listItems array
   * @param {String || [String]} values - profile mongo id
   */
  const handleProfiles = (values) => {
    const oldListItems = form.getFieldValue('listItems');
    const oldListItemIds = Array.isArray(oldListItems) ? oldListItems.map((item) => item.profile) : [];
    let newValues = [...oldListItems.filter((val) => values.includes(val.profile))];
    if (values.length > 0) {
      values.forEach((val) => {
        if (!oldListItemIds.includes(val)) {
          let description = '';
          const selectedProfile = profiles.find((p) => p._id === val);
          if (selectedProfile) {
            description = selectedProfile?.description;
          }
          newValues.push({ profile: val, description });
        }
      });
    }

    form.setFieldsValue({ listItems: newValues });
    setSelectedProfiles(values);
  };

  /**
   * Handles list item description by listItems array index
   * @param {Object} values
   * @param {Number} index
   */
  const handleTopListItem = (values, index) => {
    const newValues = form.getFieldValue('listItems');
    newValues[index] = { ...newValues[index], ...values };
    form.setFieldsValue({ listItems: newValues });
  };

  /**
   * filter Profiles Select element profile list by program and/or state
   */
  useEffect(() => {
    let filtered = [...profiles];
    if (filterByState) {
      filtered = filtered.filter((profile) => profile.state === filterByState);
    }
    if (filterByProgram) {
      filtered = filtered.filter(
        (profile) => profile.program.name.toLowerCase().trim() === filterByProgram.toLowerCase().trim(),
      );
    }
    setFilteredProfileList(filtered);
  }, [filterByState, filterByProgram, profiles, topList]);

  /**
   * filter function for Select elements
   * @param {*} input
   * @param {*} option
   * @returns filtered list by input
   */
  const profileFilterOptionSearch = (input, option) => {
    const searchVal = option.children.props.children;
    return searchVal.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  return (
    <div className='panel'>
      <div className='panel-body'>
        <Content style={{ padding: '15px', height: '100%' }}>
          <Form
            name='form'
            className='form-horizontal'
            layout='vertical'
            initialValues={initialValues}
            onFinish={(values) => {
              onSubmitPage(values);
            }}
            form={form}
          >
            <Row type='flex' gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  label='List Name:'
                  name='listName'
                  rules={[{ required: true, message: 'Please enter numbered list name!' }]}
                >
                  <Input disabled={!isNew} placeholder='Add name' />
                </Form.Item>

                <Form.Item
                  label='Profiles:'
                  name='profiles'
                  rules={[{ required: true, message: 'Please select one or more profiles!' }]}
                >
                  <Select
                    showSearch
                    optionFilterProp='children'
                    mode='multiple'
                    placeholder='Select profile'
                    onChange={handleProfiles}
                    filterOption={(input, option) => profileFilterOptionSearch(input, option)}
                  >
                    {filteredProfileList.map((profile) => (
                      <Select.Option key={profile._id} value={profile._id}>
                        <span>{profile.title ? profile.title : 'UNKNOWN'}</span>
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <p>
                  <strong>Filter profiles</strong>
                </p>

                <Row type='flex' gutter={8}>
                  <Col xs={24} md={12}>
                    <label htmlFor='program'>By program:</label>
                    <Select
                      id='program'
                      style={{ width: '100%', marginBottom: '24px', marginTop: '8px' }}
                      showSearch
                      allowClear
                      optionFilterProp='children'
                      placeholder='Filter profiles by program'
                      onChange={(value) => setFilterByProgram(value)}
                      filterOption={(input, option) => profileFilterOptionSearch(input, option)}
                    >
                      {program?.children &&
                        program.children.map((prog, i) => (
                          <Select.Option key={i} value={prog.name}>
                            <span>{prog.name || 'N/A'}</span>
                          </Select.Option>
                        ))}
                    </Select>
                  </Col>

                  <Col xs={24} md={12}>
                    <label htmlFor='program'>By state:</label>
                    <Select
                      id='state'
                      style={{ width: '100%', marginBottom: '24px', marginTop: '8px' }}
                      showSearch
                      allowClear
                      optionFilterProp='children'
                      placeholder='Select state'
                      onChange={(value) => setFilterByState(value)}
                      filterOption={(input, option) => profileFilterOptionSearch(input, option)}
                    >
                      {STATES.map((state) => (
                        <Select.Option key={state.name} value={state.name}>
                          <span>{state.name}</span>
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                </Row>
              </Col>

              <Col xs={24} md={12} style={{ minHeight: 600 }}>
                <div className='panel panel-primary'>
                  <div className='panel-heading'>
                    <h4 className='panel-title'>Sortable List</h4>
                  </div>

                  <Form.Item name='listItems' noStyle>
                    <div className='panel-body' style={{ padding: 0 }}>
                      <DNDList
                        form={form}
                        profiles={profiles}
                        setSelectedProfiles={setSelectedProfiles}
                        setModal={setModal}
                      />
                    </div>
                  </Form.Item>
                </div>
              </Col>
            </Row>

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button style={{ margin: '0 10px' }} type='primary' htmlType='submit' title='Update'>
                {isNew ? 'Add' : 'update'} list
              </Button>
            </div>
          </Form>

          {modal.visible && (
            <TopListItemFormModal
              visible={modal.visible}
              data={modal.data}
              profile={modal.profile}
              onCancel={() => setModal({ ...modal, visible: false })}
              onSubmit={(values) => handleTopListItem(values, modal.index)}
            />
          )}
        </Content>
      </div>
    </div>
  );
};
export default TopListForm;
