import React from 'react';
import AffiliateList from './AffiliateList';


const AffiliateSection = () => {
    return (
        <div className='content-wrapper'>
            <AffiliateList/>
        </div>
    );
};

export default AffiliateSection;
