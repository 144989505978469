import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
// import { getTreeFromFlatData } from 'react-sortable-tree';
import { Input, Button, Form, Row, Col, /* TreeSelect, */ Select, Typography } from 'antd';
import STATES from '../../lib/states.json';
import UploadBox from '../base/UploadBox';

// const { Option } = Select;
const { Text } = Typography;

const formInit = {
  _id: null,
  name: undefined,
  state: undefined,
  city: undefined,
  address: undefined,
  featureImage: null,
  programs: [],
  // rate: undefined,
  // accreditation: false,
  // typeOfCourses: undefined,
  // type: 'draft',
  // prices: [],
  // affiliates: [],
};

const SchoolForm = ({ isNew, school, programs, onSubmit, logo }) => {
  const [form] = Form.useForm();
  const history = useHistory();

  let image;
  if (school && school.featureImage) image = school.featureImage.url;
  if (logo && logo.url) image = logo.url;

  if (school) ['children', 'path', 'updatedAt', '__v'].forEach((key) => delete school[key]);
  let initialValues = { ...formInit, ...school };

  const prevForm = sessionStorage.getItem('schoolForm');
  if (prevForm) initialValues = JSON.parse(prevForm);

  const onFinish = async (values, isNew) => {
    sessionStorage.removeItem('schoolForm');
    if (!isNew) {
      values._id = school._id;
      if (logo) {
        values.featureImage = logo ? logo._id : school.featureImage ? school.featureImage._id : undefined;
      }
    } else {
      if (logo) {
        values.featureImage = logo ? logo._id : undefined;
      }
    }

    if (values.prices) {
      values.prices.forEach((item) => {
        if (item.price === '') {
          item.price = 0;
        }
      });
    }

    onSubmit(values, isNew);
  };

  // const treeData = getTreeFromFlatData({
  //   flatData: programs.map((node) => ({
  //     title: node.parent ? `${programs.find((p) => p._id === node.parent).name} - ${node.name}` : node.name,
  //     value: node._id,
  //     key: node._id,
  //     parent: node.parent,
  //     _id: node._id,
  //   })),
  //   getKey: (node) => node._id, // resolve a node's key
  //   getParentKey: (node) => node.parent, // resolve a node's parent's key
  //   rootKey: null, // The value of the parent key when there is no parent (i.e., at root level)
  // });

  const avatarHandler = () => {
    const values = form.getFieldsValue();
    sessionStorage.setItem('schoolForm', JSON.stringify(values));
    history.push(`/admin/gallery/edit-content/${isNew ? 'new-school/img' : 'edit-school/' + school._id}`);
  };

  const setFieldVal = (values) => {
    if (values.length > 0) {
      const prevPrices = form.getFieldValue('prices');
      const newPrices =
        prevPrices && prevPrices.length > 0 ? prevPrices.filter((price) => values.includes(price.programId)) : [];

      const prevPricesProgramIDs = newPrices.map((d) => d.programId);
      values.forEach((value) => {
        if (!prevPricesProgramIDs.includes(value) || prevPricesProgramIDs.length === 0) {
          const objPrices = {
            price: '',
            programId: value,
          };
          newPrices.push(objPrices);
        }
      });

      form.setFieldsValue({ prices: newPrices });
    } else {
      form.setFieldsValue({ prices: [] });
    }
  };

  // const getLabel = (index) => {
  //   const formPrices = form.getFieldValue('prices');
  //   let label = '',
  //     program;
  //   if (programs && programs.length > 0 && formPrices) program = programs.find((p) => p._id === formPrices[index].programId);
  //   if (program && !program.parent) label = program.name;
  //   if (program && program.parent) label = programs.find((p) => p._id === program.parent).name + ' - ' + program.name;
  //   return label;
  // };

  useEffect(() => {
    if (school && school.programs && school.programs.length > 0) {
      setFieldVal(school.programs);
    }
  });

  return (
    <div className='panel'>
      <div className='panel-body'>
        <Form
          className='form-horizontal'
          layout='vertical'
          initialValues={initialValues}
          onFinish={(values) => {
            onFinish(values, !school);
          }}
          form={form}
        >
          <Row type='flex' gutter={16}>
            <Col xs={24} md={16}>
              <div className='panel-body'>
                <Form.Item
                  label='School name:'
                  name='name'
                  rules={[{ required: true, message: 'Please enter program name!' }]}
                >
                  <Input placeholder='Add School name' />
                </Form.Item>

                <Form.Item
                  label='State:'
                  name='state'
                  rules={[{ required: true, message: 'Please enter program name!' }]}
                >
                  <Select showSearch optionFilterProp='children'>
                    {STATES.map((state) => (
                      <Select.Option value={state.name} key={state.name}>
                        {state.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item label='City:' name='city'>
                  <Input placeholder='Add City' />
                </Form.Item>

                <Form.Item label='Address:' name='address'>
                  <Input placeholder='Add Address' />
                </Form.Item>

                {/* <Form.Item label='Graduation Rate:' name='rate'>
                  <Input placeholder='Add Graduation Rate' type='number' />
                </Form.Item>

                <Form.Item label='Accreditation:' name='accreditation'>
                  <Input placeholder='Add Accreditation' type='string' />
                </Form.Item>

                <Form.Item label='Type of Courses:' name='typeOfCourses'>
                  <Input placeholder='Add Type of Courses' type='string' />
                </Form.Item>

                <Form.Item label='Type:' name='type'>
                  <Select style={{ width: 120 }}>
                    <Option value='draft'>Draft</Option>
                    <Option value='published'>Published</Option>
                  </Select>
                </Form.Item> */}
              </div>
            </Col>

            <Col xs={24} md={8}>
              {!isNew ? (
                <div className='panel panel-primary'>
                  <div className='panel-heading'>
                    <h4 className='panel-title'>User information</h4>
                  </div>

                  <div className='panel-body'>
                    <div className='card-wrapper'>
                      <p>
                        <Text strong>Name: </Text>
                        {initialValues.createdby}
                      </p>
                      <p>
                        <Text strong>Created At: </Text>
                        {initialValues.createdAt}
                      </p>
                      <p>
                        <Text strong>Status: </Text>
                        {initialValues.type}
                      </p>
                    </div>
                  </div>
                </div>
              ) : null}

              {/* <div className='panel panel-primary'>
                <div className='panel-heading'>
                  <h4 className='panel-title'>Programs</h4>
                </div>

                <Form.Item label='Programs' name='programs' className='panel-body'>
                  <TreeSelect
                    showSearch
                    filterTreeNode={(search, item) => {
                      return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
                    }}
                    style={{ width: '100%' }}
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    treeData={treeData}
                    placeholder='Select Programs'
                    onChange={(value) => setFieldVal(value)}
                    allowClear={true}
                    treeCheckable={true}
                    showCheckedStrategy={TreeSelect.SHOW_CHILD}
                  />
                </Form.Item>
              </div>

              <div className='panel panel-primary'>
                <div className='panel-heading'>
                  <h4 className='panel-title'>Price</h4>
                </div>

                <Form.Item className='panel-body'>
                  <Form.List name='prices'>
                    {(fields) => (
                      <>
                        {fields.map((field) => (
                          <Form.Item
                            {...field}
                            name={[field.name, 'price']}
                            fieldKey={[field.fieldKey, 'price']}
                            label={getLabel(field.fieldKey)}
                          >
                            <Input type='number' placeholder='Enter Price' />
                          </Form.Item>
                        ))}
                      </>
                    )}
                  </Form.List>
                </Form.Item>
              </div> */}

              <div className='panel panel-primary'>
                <div className='panel-heading'>
                  <h4 className='panel-title'>School Logo</h4>
                </div>

                <Form.Item name='featureImage'>
                  <div className='panel-body'>
                    {image ? (
                      <img
                        src={image}
                        style={{
                          width: '100px',
                          border: '0.5px solid #bbb',
                          background: '#eee',
                          borderRadius: '5px',
                          cursor: 'pointer',
                        }}
                        alt='upload'
                        title='Select from gallery or upload'
                        onClick={() => avatarHandler()}
                      />
                    ) : (
                      <UploadBox handler={avatarHandler} />
                    )}
                  </div>
                </Form.Item>

                {/* <p>Affiliate Links:</p>
                <ol>
                  {affiliates && affiliates.length > 0 ? (
                    affiliates.map((aff, i) => (
                      <li key={i} style={{ listStylePosition: 'inside' }}>
                        {aff.url}
                      </li>
                    ))
                  ) : (
                    <span>NO LINKS</span>
                  )}
                </ol> */}
              </div>
            </Col>
          </Row>

          <div className='text-right'>
            <Button style={{ margin: '0 10px' }} type='primary' htmlType='submit'>
              {isNew ? 'Add' : 'update'} school
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};
export default SchoolForm;
