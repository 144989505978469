import React, {useState, useEffect, useContext} from 'react';
import {Button, Form, Input, Modal, Space, Spin, Table, Select, Divider} from "antd";
import {UserContext} from "../../App";
import axios from "axios";
import {SERVER_URL} from "../../config";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import DEGREES from '../../lib/ASDdegrees.json'
import * as _ from 'lodash';

const {confirm} = Modal;

const Codes = ({type}) => {
    const [form] = Form.useForm();
    const [codes, setCodes] = useState([]);
    const [working, setWorking] = useState(true);
    const [visible, setVisible] = useState(false);
    const [selectedCode, setSelectedCode] = useState(null);
    const [filterTable, setFilterTable] = useState(null);
    const [allSpecializations, setAllSpecializations] = useState([]);
    const [allSpotlights, setAllSpotlights] = useState([]);
    const [spotlight, setSpotlight] = useState(null);
    const currentUser = useContext(UserContext);

    const fetchData = async () => {
        setWorking(true);
        const {data} = await axios.get(`${SERVER_URL}/affiliate-institution-code/${type}/all`, {
            withCredentials: false,
            headers: {Authorization: `Bearer ${currentUser.data.token}`},
        });
        setCodes(data);
        setFilterTable(data);

        if(type === 'MARKET_CONTEXT' || type === 'SPECIALIZATION') {
          const spotlightRes = await axios.get(`${SERVER_URL}/spotlight`, {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentUser.data.token}` },
          });
          if (spotlightRes) {
            setAllSpotlights(spotlightRes.data);
          }
        }

        if(type === 'MARKET_CONTEXT') {
           const specializationRes = await axios.get(`${SERVER_URL}/affiliate-institution-code/SPECIALIZATION/all`, {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentUser.data.token}` },
          });
          if (specializationRes) {
            setAllSpecializations(specializationRes.data);
          }
        }

        setWorking(false);
    }

    useEffect(() => {
        fetchData().then(r => console.log(r)).catch(e => console.log(e));
    }, [type])

    useEffect(() => {
        form.resetFields();
        form.setFieldsValue(selectedCode);

        }, [selectedCode]);

    async function handleDelete(record) {
        confirm({
            title: 'Are you sure delete this code?',
            content: 'This action cannot be undone.',
            onOk:
                async () => {
                    setWorking(true);
                    await axios.delete(`${SERVER_URL}/affiliate-institution-code/${record._id}`, {
                        withCredentials: false,
                        headers: {Authorization: `Bearer ${currentUser.data.token}`},
                    });
                    setWorking(false);
                    await fetchData();
                },
        })
    }

    function handleEdit(record) {
        setVisible(true);
        setSelectedCode(record);
        setSpotlight(record.spotlight)
    }

    const columns = [
        {
            title: 'Label',
            dataIndex: 'label',
            key: 'label',
        },
        {
            title: 'Value',
            dataIndex: 'value',
            key: 'value',
        },
        {
            title: 'Actions',
            key: 'actions',
            align: 'center',
            render: (text, record) => (
                <Space>
                    <DeleteOutlined onClick={() => handleDelete(record)}/>
                    <EditOutlined onClick={() => handleEdit(record)}/>
                </Space>
            ),
        }
    ];

    function handleAdd() {
        setVisible(true);
        setSelectedCode(undefined);
    }

    async function handleFinish() {
        try {
            let data = await form.validateFields();

            if(type === 'MARKET_CONTEXT' || type === 'SPECIALIZATION') {
              if(!data.spotlight) {
                data.degrees = []
                data.spotlight = null
              }
            }

            form.resetFields();
            setSpotlight(null)

            const {_id} = data;
            if (_id) {
                setWorking(true);
                await axios.put(`${SERVER_URL}/affiliate-institution-code/${_id}`, data, {
                    withCredentials: false,
                    headers: {Authorization: `Bearer ${currentUser.data.token}`},
                });
                setWorking(false);
                setVisible(false);
                await fetchData();
            } else {
                setWorking(true);
                await axios.post(`${SERVER_URL}/affiliate-institution-code`, data, {
                    withCredentials: false,
                    headers: {Authorization: `Bearer ${currentUser.data.token}`},
                });
                setWorking(false);
                setVisible(false);
                await fetchData();
            }
        } catch (e) {
            console.log(e);
        }
    }

    function handleSearchTable(value) {
        const baseData = [...codes];
        const filterData = baseData.filter(o =>
            Object.keys(o).some(k =>
                String(o[k])
                    .toLowerCase()
                    .includes(value.toLowerCase())
            )
        );
        setFilterTable(filterData);
    }

    function handleOnLabelChange(event) {
        if(type === 'SPECIALIZATION') {
            const {value} = event.target;
            form.setFieldsValue({value: _.kebabCase(value.toLowerCase())});
        }
    }

    return (
        <div className='content-wrapper'>
            <Spin spinning={working} tip='Working...'>
                <div className='actions-block flex'>
                        <Button type='primary' onClick={handleAdd}>New</Button>
                        <Input.Search style={{width: 400}}
                            placeholder="Search by..."
                            enterButton
                            onSearch={handleSearchTable}
                        />
                </div>
                <Table columns={columns} dataSource={filterTable ? filterTable : codes}
                       rowKey={record => record._id}/>

                <Modal forceRender title="New Code" visible={visible} confirmLoading={working} onOk={handleFinish}
                      onCancel={() => {
                        setVisible(false)
                        form.resetFields()
                        setSpotlight(null)
                      }}
                      okText='SAVE'>
                    <Form form={form} initialValues={selectedCode}
                          layout="vertical">
                        <Form.Item label="Label" name="label"  rules={[{required: true, message: 'Label is required.'}]}>
                            <Input placeholder="Label" onChange={handleOnLabelChange} />
                        </Form.Item>
                        <Form.Item label="Value" name="value" rules={[{required: true, message: 'Value is required.'}]}>
                            <Input placeholder="Value"/>
                        </Form.Item>

                        {/* SPECIALIZATION children */}
                        {type === 'MARKET_CONTEXT' && (
                          <Form.Item label="Specializations" name="specializations">
                            <Select
                              mode='multiple'
                              placeholder='Assign specializations to market context'
                            >
                              {allSpecializations.map((item) => (
                                <Select.Option key={item._id} value={item._id}>
                                  {item.label}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        )}

                         {/* SPOTLIGHT */}
                          {(type === 'MARKET_CONTEXT' || type === 'SPECIALIZATION') && (
                            <>
                              <Divider>SPOTLIGHT</Divider>
                              <Form.Item label="Spotlight" name="spotlight">
                                <Select
                                  allowClear
                                  showSearch
                                  placeholder='Select spotlight...'
                                  onChange={(value) => setSpotlight(value)}
                                >
                                  {allSpotlights.map((item) => (
                                    <Select.Option key={item._id} value={item._id}>
                                      {item.CMSDisplayName}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>

                                <Form.Item label="Degrees">
                                  <Form.Item name="degrees" rules={[{required: spotlight ? true : false, message: 'At least 1 degree is required.'}]}>
                                    <Select
                                      mode='multiple'
                                      placeholder='Select degrees for spotlight to appear in'
                                      disabled={!spotlight}
                                    >
                                      {DEGREES.filter((item) => item.title !== 'All').map((item) => (
                                        <Select.Option key={item.title} value={item.title}>
                                          {item.title}
                                        </Select.Option>
                                      ))}
                                    </Select>
                                  </Form.Item>

                                  <Button onClick={() => {
                                    const fillDegrees = DEGREES.filter((item) => item.title !== 'All').map((degree) => degree.title);
                                    form.setFieldsValue({ degrees: fillDegrees });
                                  }} disabled={!spotlight}>SELECT ALL</Button>

                                  <Button onClick={() => {
                                    form.setFieldsValue({ degrees: [] });
                                  }} disabled={!spotlight}>CLEAR ALL</Button>
                                </Form.Item>
                            </>
                          )}

                        <Form.Item name="type" hidden initialValue={type}>
                            <Input/>
                        </Form.Item>
                        <Form.Item name="_id" hidden>
                            <Input/>
                        </Form.Item>
                    </Form>
                </Modal>
            </Spin>
        </div>
    )
}

export default Codes;
