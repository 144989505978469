import React, { useEffect, useState } from 'react';
import { DatePicker, Table, Card, Select, Row, Col } from 'antd';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { ExportToExcel } from '../csv';
import moment from 'moment';

const { RangePicker } = DatePicker;
const { Option } = Select;
const dateFormat = 'M/D/YYYY';

const UserLog = ({ ACTIVITIES, activity, setActivity, range, setRange, userActivities, userName, csvData }) => {
  const [logcount, setLogCount] = useState();

  const handleChangeActivity = async (val) => {
    setActivity(val);
  };

  useEffect(() => {
    if (userActivities.data && userActivities.data.length > 0) {
      const logCount = userActivities.data.reduce((acc, current) => (acc += current.activities.count), 0);
      setLogCount(logCount);
    }
  }, [userActivities]);

  const handleRange = (values) => {
    setRange(values);
    if (userActivities.data && userActivities.data.length > 0) {
      const logCount = userActivities.data.reduce((acc, current) => (acc += current.activities.count), 0);
      setLogCount(logCount);
    }
  };

  function formatXAxis(tickItem) {
    return moment(tickItem).format(dateFormat);
  }

  let title = '';
  switch (activity) {
    case 'AUTH_LOGIN':
      title = 'Logins in selected interval';
      break;
    case 'PROFILE_INSERT':
      title = 'Created profiles in selected interval';
      break;
    case 'PROFILE_UPDATE':
      title = 'Updated profiles in selected interval';
      break;
    case 'CATEGORY_INSERT':
      title = 'Created categories in selected interval';
      break;
    case 'CAREER_INSERT':
      title = 'Created careers in selected interval';
      break;
    case 'CAREER_SYNC':
      title = 'Created synced to WP in selected interval';
      break;
    default:
      title = 'All activities in selected interval';
      break;
  }

  const dataTable = [...userActivities.data]
    .sort((a, b) => new Date(b._id) - new Date(a._id))
    .map((val, index) => {
      const dateId = moment(new Date(val._id)).format(dateFormat);
      const logs = val.activities.logs;
      const count = val.activities.count;
      return {
        _id: dateId,
        key: index,
        logs: logs,
        count: count,
      };
    });

  const expandedRowRender = (record) => {
    const columns = [
      {
        title: 'time',
        key: 'createdAt',
        render: (value) => <span>{moment(value.createdAt).format('DD.MM.YYYY HH:mm')}</span>,
      },
      {
        title: 'description',
        dataIndex: 'description',
        key: 'description',
        render: (text) => text.replace(/_/gi, ' '),
      },
      { title: 'browser', dataIndex: 'browser', key: 'browser' },
      { title: 'os', dataIndex: 'os', key: 'os' },
      { title: 'ip', dataIndex: 'ip', key: 'ip', render: (text) => text.replace(/::ffff:/gi, '') },
    ];

    return (
      <Table
        columns={columns}
        dataSource={record.logs.map((log, index) => {
          log.key = index;
          return log;
        })}
        pagination={{ hideOnSinglePage: true, size: 'small' }}
      />
    );
  };

  const tableColumns = [{ title: 'Date', dataIndex: '_id', key: '_id' }];

  const activityName = () => {
    const selectedActivity = ACTIVITIES.find((a) => a.value === activity);
    return selectedActivity.label;
  };

  return (
    <div className='dashboard'>
      <div style={{ marginBottom: '1.5rem' }}>
        <Row gutter={8}>
          <Col sm={24} md={10}>
            <span style={{ marginRight: '1rem' }}>Select type:</span>
            <Select
              value={activity}
              style={{ width: 180, margin: '0 8px 10px 0' }}
              onChange={(val) => handleChangeActivity(val)}
            >
              {ACTIVITIES.map((activity) => (
                <Option key={activity.value}>{activity.label}</Option>
              ))}
            </Select>
          </Col>

          <Col sm={24} md={14}>
            <span style={{ marginRight: '1rem' }}>Select range:</span>
            <RangePicker
              value={range}
              size='middle'
              format={dateFormat}
              allowClear={false}
              onChange={(value) => handleRange(value)}
            />
          </Col>
        </Row>
      </div>

      <Card title={`${title}: ${logcount}`} bordered={false}>
        <div className='log-graph'>
          <ResponsiveContainer width='100%' aspect={16.0 / 5.0}>
            <LineChart
              data={userActivities.data}
              margin={{
                top: 24,
                left: -24,
                right: 0,
              }}
            >
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis dataKey='_id' tickFormatter={formatXAxis} axisLine={{ stroke: '#4f66a0', strokeWidth: 2 }} />
              <YAxis axisLine={{ stroke: '#4f66a0', strokeWidth: 2 }} />
              <Tooltip />

              {activity === 'all' &&
                ACTIVITIES.map((activity, ind) => {
                  if (activity.value !== 'all') {
                    return (
                      <Line
                        key={ind}
                        type='monotone'
                        dataKey={(item) => {
                          const current = item?.activities?.logs.filter((act) => act.description === activity.value);
                          return current.length;
                        }}
                        name={activity.label}
                        strokeWidth={1}
                        stroke={activity.stroke}
                        activeDot={{ r: 8 }}
                      />
                    );
                  }
                  return null;
                })}

              {activity !== 'all' && (
                <Line
                  type='monotone'
                  dataKey={(item) => {
                    return item.activities.count;
                  }}
                  name={activityName()}
                  strokeWidth={1}
                  stroke='#4f66a0'
                  activeDot={{ r: 8 }}
                />
              )}
            </LineChart>
          </ResponsiveContainer>
        </div>
      </Card>

      <Card
        title='Activity details'
        bordered={false}
        style={{ marginTop: 16 }}
        extra={
          <ExportToExcel
            csvData={csvData != null && csvData.length >= 1 ? csvData : []}
            fileName={`${userName}_logs_${moment(new Date()).format('DD-MM-YYYY:HH-MM')}`} // Ime xlsx fajla
            propsToRemove={['_id', 'objectId', 'userId', 'url', 'updatedAt', '__v']} // Opcija 1 - uklanjaju se polja koje ne treba da se exportuju
            // format="products" // Opcija 2 - custom formatiranje - treba po imenu format polja u exportExcel.js formatirati polja po zelji
          />
        }
      >
        <Table
          showHeader={false}
          columns={tableColumns}
          expandedRowRender={expandedRowRender}
          dataSource={dataTable}
          className='components-table-demo-nested'
        />
      </Card>
    </div>
  );
};

export default UserLog;
