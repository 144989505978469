import React, { useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { Button, Row, Col, Collapse, Spin, Input, notification } from 'antd';
import ReactJson from 'react-json-view';
import axios from 'axios';

const { Panel } = Collapse;

const NewCareer = (props) => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [code, setCode] = useState();
  const [careerData, fetchCareerData] = useAxios('', null, currentuser.data.token, 'get');

  const getData = async () => {
    if (!code) return notification.warning({ message: 'You must enter SOC code!', placement: 'topRight' });

    try {
      const { data } = await axios.get(`${SERVER_URL}/career-by-code/${code}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      if (data)
        return notification.info({ message: `Career with SOC code ${code} already exists!`, placement: 'topRight' });
    } catch (error) {
      console.log(error);
    }

    fetchCareerData(`${SERVER_URL}/career-data/${code.trim()}`, null);
  };

  const saveCareer = async () => {
    try {
      await axios.post(`${SERVER_URL}/careers`, careerData.data, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: 'Career created.',
        placement: 'topRight',
      });

      history.push('/admin/careers');
    } catch (error) {
      console.log(error);
      notification.error({
        message: 'Problem with saving Career.',
        placement: 'topRight',
      });
    }
  };

  return (
    <div className='content-wrapper'>
      <div className='panel'>
        <div className='actions-block'>
          <Link to='/admin/careers'>
            <Button type='primary'>All Careers</Button>
          </Link>
        </div>

        <div className='panel-body'>
          <div style={{ marginBottom: '50px' }}>
            <Input
              style={{ width: '300px', marginRight: '20px' }}
              value={code}
              placeholder='Enter SOC code or full job title...'
              onChange={(e) => setCode(e.target.value)}
            />

            <Button onClick={getData} type='primary'>
              GET DATA
            </Button>

            {careerData.data && (
              <Button onClick={saveCareer} type='primary' danger style={{ marginLeft: '20px' }}>
                SAVE CAREER
              </Button>
            )}
          </div>

          {(careerData.data || careerData.isLoading) && (
            <Spin spinning={careerData.isLoading} tip='Loading...'>
              <Row gutter={[8, 8]}>
                <Col span={12}>
                  <h2>
                    <strong>Career Title:&nbsp;</strong>
                    <span>{careerData.data?.careerName}</span>
                  </h2>
                </Col>

                <Col span={12}>
                  <h2>
                    <strong>Occupation Code:&nbsp;</strong>
                    <span>{careerData.data?.occCode}</span>
                  </h2>
                </Col>
              </Row>

              <h2>
                <strong>National:</strong>
              </h2>

              <div style={{ margin: '12px 0 20px', paddingLeft: '12px' }}>
                {careerData?.data?.national && (
                  <Row gutter={[8, 8]}>
                    <Col span={12}>
                      <p>BLS</p>
                      <ReactJson
                        name={null}
                        src={careerData.data.national.BLS}
                        collapsed={2}
                        displayDataTypes={false}
                        enableClipboard={false}
                        style={{ fontSize: '14px' }}
                      />
                    </Col>

                    <Col span={12}>
                      <p>PAYSCALE</p>
                      <ReactJson
                        name={null}
                        src={careerData.data.national.PAYSCALE}
                        collapsed={2}
                        displayDataTypes={false}
                        enableClipboard={false}
                        style={{ fontSize: '14px' }}
                      />
                    </Col>
                  </Row>
                )}
              </div>

              <h2>
                <strong>States:</strong>
              </h2>

              <div style={{ margin: '12px 0 20px', paddingLeft: '12px' }}>
                <Collapse defaultActiveKey={0}>
                  {careerData?.data?.states &&
                    careerData.data.states.map((state, index) => (
                      <Panel header={state.state} key={index}>
                        <Row gutter={[8, 8]}>
                          <Col span={12}>
                            <p>
                              <strong>Price parity:</strong> {state.priceParity || '--'}
                            </p>
                            <p>BLS</p>
                            <ReactJson
                              name={null}
                              src={state?.BLS}
                              collapsed={1}
                              displayDataTypes={false}
                              enableClipboard={false}
                              style={{ fontSize: '14px' }}
                            />
                          </Col>

                          <Col span={12}>
                            <p style={{ marginTop: '20px' }}>PAYSCALE</p>
                            <ReactJson
                              name={null}
                              src={state?.PAYSCALE}
                              collapsed={1}
                              displayDataTypes={false}
                              enableClipboard={false}
                              style={{ fontSize: '14px' }}
                            />
                          </Col>
                        </Row>
                      </Panel>
                    ))}
                </Collapse>
              </div>

              <h2>
                <strong>Skills:</strong>
              </h2>

              <div style={{ margin: '12px 0 20px', paddingLeft: '12px' }}>
                <Collapse defaultActiveKey={0}>
                  {careerData?.data?.skills &&
                    careerData.data.skills.map((skill, index) => (
                      <Panel header={skill.skill} key={index}>
                        <Col span={12}>
                          <ReactJson
                            name={null}
                            src={skill}
                            collapsed={1}
                            displayDataTypes={false}
                            enableClipboard={false}
                            style={{ fontSize: '14px' }}
                          />
                        </Col>
                      </Panel>
                    ))}
                </Collapse>
              </div>

              <h2>
                <strong>Experience:</strong>
              </h2>

              <div style={{ margin: '12px 0 20px', paddingLeft: '12px' }}>
                <Collapse defaultActiveKey={0}>
                  {careerData?.data?.experience &&
                    !careerData.data.experience.every((d) => !d.data) &&
                    careerData.data.experience.map((exp, index) => (
                      <Panel header={`Degree Level: ${exp.degreeLevel}`} key={index}>
                        <Row gutter={[8, 8]}>
                          {exp.data &&
                            exp.data.map((item, i) => (
                              <Col span={8} key={`exp_${i}`}>
                                <ReactJson
                                  name={null}
                                  src={item}
                                  collapsed={1}
                                  displayDataTypes={false}
                                  enableClipboard={false}
                                  style={{ fontSize: '14px' }}
                                />
                              </Col>
                            ))}
                        </Row>
                      </Panel>
                    ))}
                </Collapse>
              </div>
            </Spin>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewCareer;
