import {useEffect, useState, useContext} from "react";
import axios from "axios";
import {SERVER_URL} from "../../config";
import {Select} from "antd";
import * as _ from "lodash";
import {UserContext} from "../../App";


export default function InstitutionsSearch({value, onChange, placeholder, disabled}) {
    const [search, setSearch] = useState('');
    const [institutions, setInstitutions] = useState([]);
    const [loading, setLoading] = useState(false);
    const currentUser = useContext(UserContext);

    const fetchInstitutions = async (search) => {
        setLoading(true);
        const {data} = await axios.get(`${SERVER_URL}/affiliate-institution-search?search=${search}`,{
            withCredentials: false,
            headers: {Authorization: `Bearer ${currentUser.data.token}`},
        });
        setInstitutions(data);
        setLoading(false);
    };

    const geInitValue = async (unitId) => {
        setLoading(true);
        const {data} = await axios.get(`${SERVER_URL}/affiliate-institution/unitId/${unitId}`,{
            withCredentials: false,
            headers: {Authorization: `Bearer ${currentUser.data.token}`},
        });
        setInstitutions(data);
        setLoading(false);
    }

    const debounceSearch = _.debounce(async (search) => {
        setSearch(value);
        await fetchInstitutions(search);
    },300);

    const handleSearch = (value) => {
        console.log(value);
        debounceSearch(value);
    };

    const handleChange = (value) => {
        const institution = institutions.find(item => item.unitId === value);
        onChange(value, institution);
    };

    useEffect(() => {
        if (value) {
            setSearch(value);
            geInitValue(value).catch(console.error);
        }
    }, [value]);

    return (
        <Select
            showSearch
            value={search}
            onSearch={handleSearch}
            onChange={handleChange}
            placeholder={placeholder}
            loading={loading}
            disabled={disabled}
            filterOption={false}
            notFoundContent={null}
        >
            {institutions.map((item) => (
                <Select.Option value={item.unitId} key={item.unitId}>
                    {`(${item.unitId}) ${item.name}`}
                </Select.Option>
            ))}
        </Select>
    );
}
