import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import {Button, Form, Input, Modal, Spin, Switch, Table, Tooltip} from "antd";
import {SERVER_URL} from "../../config";
import {UserContext} from "../../App";
import {EditOutlined} from "@ant-design/icons";

const Provider = () => {
    const [form] = Form.useForm();
    const [working, setWorking] = useState(false);
    const [data, setData] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [item, setItem] = useState(undefined);
    const currentUser = useContext(UserContext);

    useEffect(() => {
        const loadData = async () => {
            try {
                setWorking(true);
                const {data} = await axios.get(`${SERVER_URL}/affiliate-institution-provider`, {
                    withCredentials: false,
                    headers: {Authorization: `Bearer ${currentUser.data.token}`},
                });
                setData(data);
                setWorking(false);
            } catch (e) {
                console.log(e);
                setWorking(false);
            }
        };
        loadData().catch(e => console.log(e));
    }, []);

    useEffect(() => {
        form.resetFields();
        form.setFieldsValue(item)
    }, [item]);

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Code',
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: 'Active',
            dataIndex: 'active',
            key: 'active',
            align: 'center',
            render: (text, record) => (
                <Switch size="small" disabled checked={record.active}/>
            )
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            align: 'center',
            render: (text, record) => (
                <Tooltip title={`Edit ${record.name}`} placement='topLeft'>
                    <EditOutlined onClick={() => handleEditProvider(record)}
                                  style={{textDecoration: 'none', color: 'black'}}/>
                </Tooltip>
            ),
        }
    ];

    function handleEditProvider(record) {
        setItem(record);
        setModalVisible(true);
    }

    function handleCancel() {
        setModalVisible(false);
    }

    async function handleUpdate() {
        const values = await form.validateFields();
        form.resetFields();
        const {_id} = values;
        if (_id) {
            try {
                setWorking(true);
                const {data: res} = await axios.put(`${SERVER_URL}/affiliate-institution-provider/${values._id}`, values, {
                    withCredentials: false,
                    headers: {Authorization: `Bearer ${currentUser.data.token}`},
                });
                const index = data.findIndex(item => item._id === values._id);
                const updateData = {...data[index], ...res};
                setData(state => [...state.slice(0, index), updateData, ...state.slice(index + 1)]);
                setModalVisible(false);
                setWorking(false);
            } catch (e) {
                console.log(e);
                setWorking(false);
            }
        } else {
            try {
                setWorking(true);
                const {data: res} = await axios.post(`${SERVER_URL}/affiliate-institution-provider`, values, {
                    withCredentials: false,
                    headers: {Authorization: `Bearer ${currentUser.data.token}`},
                });
                setData(state => [...state, res]);
                setModalVisible(false);
                setWorking(false);
            } catch (e) {
                console.log(e);
                setWorking(false);
            }
        }
    }

    function handleAdd(e) {
        e.preventDefault();
        setItem(undefined);
        setModalVisible(true);
    }

    return (
        <div className='content-wrapper'>
            <Spin spinning={working} tip='Working...'>
                <div className='actions-block flex'>
                    <Button type='primary' onClick={handleAdd}>New</Button>
                </div>
                <Table pagination={false} rowKey="_id" bordered dataSource={data} columns={columns}/>
            </Spin>
            <Modal visible={modalVisible} confirmLoading={working} title='Edit Provider' onCancel={handleCancel}
                   onOk={handleUpdate}>
                <Form onFinish={handleUpdate} initialValues={item} form={form} layout='vertical' id="provider-form">
                    <Form.Item name="_id" label="ID" hidden>
                        <Input/>
                    </Form.Item>
                    <Form.Item label='Name' name='name' rules={[{required: true, message: 'Please input name.'}]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label='Code' name='code' rules={[{required: true, message: 'Please input code.'}]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label='Active' name='active' valuePropName="checked">
                        <Switch/>
                    </Form.Item>
                    <Form.Item label="Description" name="description">
                        <Input/>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default Provider;
