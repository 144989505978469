import React, { useState, useEffect, useContext } from 'react';
import { Button, Tooltip, notification, Spin, Table, Tag, Popconfirm, Modal } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import Axios from 'axios';
import { UserContext } from '../../App';
import { SERVER_URL } from '../../config';
import { Link, useHistory } from 'react-router-dom';
import { ExportToExcel } from '../../components/csv';
import CKEditor from '../../components/base/CKEditor';

const ViewInstitutionRankings = (props) => {
  const currentuser = useContext(UserContext);
  const history = useHistory();
  const { id } = props.match.params;

  const [working, setWorking] = useState(false);
  const [items, setItems] = useState([]);
  const [threshold, setThreshold] = useState(null); // graduation rate threshold
  const [modal, setModal] = useState({ visible: false, unitid: null, name: null });
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState(null);

  useEffect(() => {
    const viewData = async () => {
      try {
        setWorking(true);

        // get items
        const dataView = await Axios.get(`${SERVER_URL}/rankings-universal/${id}`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
        if (dataView) {
          setItems(dataView.data.items);
          setCategory(dataView.data.category);
          setThreshold(dataView.data.gradRateThreshold);
        }

        setWorking(false);
      } catch (error) {
        setWorking(false);
        console.log(error);
        notification.error({
          message: 'Error',
          description: 'Problem with fetching institutional ranking items',
          placement: 'bottomRight',
        });
      }
    };
    viewData();
  }, [currentuser.data.token]);

  const handleSave = async () => {
    try {
      setWorking(true);

      await Axios.patch(
        `${SERVER_URL}/rankings-universal/${id}`,
        { items },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );

      notification.success({
        message: 'Institutional ranking updated',
        placement: 'bottomRight',
        duration: 3,
      });

      history.push('/admin/rankings/undergraduate');
      setWorking(false);
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Problem with updating ranking url',
        placement: 'bottomRight',
        duration: 3,
      });
    }
  };

  const columns = [
    {
      title: 'RANK',
      dataIndex: 'rank',
      key: 'rank',
    },
    {
      title: 'UNITID',
      dataIndex: ['unitid'],
      key: 'unitid',
    },
    {
      title: 'NAME',
      dataIndex: ['name'],
      key: 'name',
    },
    {
      title: 'CITY',
      dataIndex: ['city'],
      key: 'city',
    },
    {
      title: 'NET COST',
      dataIndex: ['netCost'],
      key: 'netCost',
      align: 'right',
      render: (text) => (text && text === 9999999 ? 'N/A' : text ?? 'N/A'),
    },
    {
      title: 'EARNINGS',
      dataIndex: ['earnings'],
      key: 'earnings',
      align: 'right',
      render: (text) =>
        text && text === -9999999
          ? 'N/A'
          : text
          ? text.toLocaleString('us', { maximumFractionDigits: 0 })
          : text ?? 'N/A',
    },
    {
      title: 'PAYBACK',
      dataIndex: ['payback'],
      key: 'payback',
      align: 'right',
      render: (text) =>
        text && text === 9999999
          ? 'N/A'
          : text
          ? text.toLocaleString('us', { maximumFractionDigits: 2 })
          : text ?? 'N/A',
    },
    {
      title: 'EARNINGPLUS',
      dataIndex: ['degreePremium'],
      key: 'degreePremium',
      align: 'right',
      render: (text) =>
        text && text === -9999999
          ? 'N/A'
          : text
          ? text.toLocaleString('us', { maximumFractionDigits: 0 })
          : text ?? 'N/A',
    },
    {
      title: 'ECONOMIC SCORE',
      dataIndex: ['valueRank'],
      key: 'valueRank',
      align: 'right',
      render: (text) =>
        text && text === 9999999
          ? 'N/A'
          : text
          ? text.toLocaleString('us', { maximumFractionDigits: 2 })
          : text ?? 'N/A',
    },
    {
      title: 'GRADUATION RATE',
      dataIndex: ['graduationRate'],
      key: 'graduationRate',
      align: 'right',
      render: (text, record) => (
        <Tag color={record.graduationRate < threshold ? 'red' : 'green'}>{text ? `${text}%` : 'N/A'}</Tag>
      ),
    },
  ];

  columns.push({
    title: 'Description',
    key: 'description',
    render: (text, record) => {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '15px',
          }}
        >
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
            <Tooltip title='Edit description' placement='topLeft'>
              <EditOutlined
                style={{ textDecoration: 'none', color: 'black' }}
                onClick={() => {
                  setModal({
                    visible: true,
                    unitid: record.unitid,
                    name: record.name,
                  });
                  setDescription(record.description ? record.description : '');
                }}
              />
            </Tooltip>
          </div>
        </div>
      );
    },
  });

  let filename = '';
  let excelData = [];
  if (items.length > 0) {
    filename = `${category}_ranking_schools`;
    excelData = [...items];
  }

  const onOk = () => {
    const newData = items.map((item) => {
      if (item.unitid === modal.unitid) {
        item.description = description;
      }
      return item;
    });
    setItems([...newData]);
    setModal({ visible: false });
  };

  return (
    <div className='content-wrapper'>
      <Spin spinning={working} tip='Working...'>
        <div className='actions-block flex'>
          <h2>
            <strong>{category}</strong>
          </h2>

          <ExportToExcel
            csvData={excelData}
            fileName={filename}
            propsToRemove={['_id', 'fullOnline', 'position']}
            disabled={items.length === 0}
          />

          <Link to={'/admin/rankings/undergraduate'}>
            <Tooltip title='View all bachelor rankings'>
              <Button type='primary' style={{ marginRight: '8px', float: 'left' }}>
                ALL RANKINGS
              </Button>
            </Tooltip>
          </Link>

          <p>Total Schools: {items.length}</p>
        </div>

        {!working && items && items.length > 0 && (
          <>
            <Table
              size='middle'
              bordered
              dataSource={items}
              columns={columns}
              rowKey={(record) => record._id}
              pagination={{
                pageSize: 20,
                // total: total || 0,
                // current: page,
                // defaultCurrent: 1,
                // position: 'bottom',
                // showSizeChanger: false,
                // onChange: (page) => pageHandler(page),
                // hideOnSinglePage: true,
              }}
              id='institutionalItemsTable'
            />

            <Modal
              centered
              width='70%'
              visible={modal.visible}
              title={
                <p>
                  Description for<strong> {modal.name}</strong>
                </p>
              }
              okText='SAVE'
              cancelText='CANCEL'
              maskClosable={false}
              onCancel={() => {
                setModal({ visible: false });
              }}
              onOk={onOk}
              confirmLoading={working}
              getContainer={document.getElementById('institutionalItemsTable')}
            >
              <div>
                <p style={{ fontSize: 15, marginTop: 20, marginRight: 10 }}>Description: </p>
                <CKEditor content={description} setContent={setDescription} />
              </div>
            </Modal>

            <br></br>

            <Popconfirm
              title={`Are you sure you want to save this ranking?`}
              onConfirm={handleSave}
              okText='Yes'
              cancelText='No'
            >
              <Button style={{ width: '90px', float: 'right' }} type={'primary'}>
                Save
              </Button>
            </Popconfirm>
          </>
        )}
      </Spin>
    </div>
  );
};

export default ViewInstitutionRankings;
