import React, { useContext } from 'react';
import { saveAs as fileSaveAs } from 'file-saver';
import { utils as XLSXUtils, write as XSLXWrite } from 'xlsx';
import { Button, Tooltip, notification } from 'antd';
import { ExportOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import axios from 'axios';
import { SERVER_URL } from '../../config';

const ExportToExcelCustomForProfiles = ({
  urlQuery,
  propsToRemove,
  header,
  format,
  disabled,
  setVisible,
  useKPI,
  useChar,
  useCost,
  useGradRates,
  useAdmissions,
  useRepayment,
  useCrime,
  cipcode,
  degree,
  setWorking,
}) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const currentuser = useContext(UserContext);

  const getDataForExcel = async (urlQuery) => {
    try {
      const res = await axios.get(`${SERVER_URL}/all-school-profiles`, {
        params: {
          ...urlQuery,
          ...{ pagination: 1 },
          ...{ useKPI, useChar, useCost, useAdmissions, useGradRates, useRepayment, useCrime },
        },
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      return res.data.profiles;
    } catch (error) {
      let message = error.message;
      if (
        error.response &&
        error.response.data &&
        error.response.data.message &&
        error.response.data.message.includes('16MB')
      ) {
        message = 'Mongo limit reached (16MB)';
      }
      notification.error({
        message: 'Error',
        placement: 'bottomRight',
        description: message,
      });
      return null;
    }
  };

  const removeProperties = (object, ...keys) =>
    Object.entries(object).reduce(
      (prev, [key, value]) => ({
        ...prev,
        ...(!keys.includes(key) && { [key]: value }),
      }),
      {},
    );

  const dataForExcel = async (data) => {
    let retData = [];
    if (format === 'custom_name') {
      let promises = data.map(async (item) => {});
      await Promise.all(promises);
    } else {
      retData = await data.map((item) => removeProperties(item, ...propsToRemove));
    }
    return retData;
  };

  const exportToExcel = async () => {
    setWorking(true);

    let fileName = cipcode ? 'school_profiles_cipcode_' + cipcode : 'school_profiles';

    if (cipcode && degree) fileName = fileName + `_${degree}`;
    if (useChar) fileName = fileName + '_characteristics';
    if (useAdmissions) fileName = fileName + '_admissions';
    if (useCost) fileName = fileName + '_cost';
    if (useGradRates) fileName = fileName + '_gradRates';
    if (useKPI) fileName = fileName + '_KPI';
    if (useRepayment) fileName = fileName + '_repayment';
    if (useCrime) fileName = fileName + '_crime';
    let exportData = [];

    exportData = await getDataForExcel(urlQuery);
    exportData = exportData.filter((item) => !item.closed);

    // reformat excel dats
    if (exportData) {
      exportData = exportData.map((item) => {
        if (!useAdmissions && !useChar && !useCost && !useGradRates && !useKPI && !useRepayment && !useCrime) {
          if (!cipcode) {
            return {
              UNITID: item.unitid,
              NAME: item.name,
              STATE: item.state,
              'TOTAL NUMBER OF STUDENTS': item.totalSize,
              'TOTAL NUMBER OF UNDERGRADUATE STUDENTS': item.UGDS,
              'NET ANNUAL COST': item['Average net price'],
              'YEARS TO GRADUATE': item['Average completion time'],
              'EARNINGS 10 years after entry': item.MD_EARN_WNE_P10,
              'EARNINGS 4 years after completion': item.MD_EARN_WNE_4YR,
              PAYBACK: item.Payback,
              'EARNING PLUS':
                item.degreePremium && item.degreePremium.premiumAmount ? item.degreePremium.premiumAmount : null,
              'EARNING PLUS %':
                item.degreePremium && item.degreePremium.premiumPercentage
                  ? item.degreePremium.premiumPercentage
                  : null,
              'ECONOMIC SCORE':
                item.degreePremium && item.degreePremium.rankingScore ? item.degreePremium.rankingScore : null,
              'PERCENTAGE AGAINST OTHER SCHOOLS': item.esCompare,
            };
          } else {
            return {
              UNITID: item.unitid,
              NAME: item.name,
              STATE: item.state,
              'DEGREE LEVEL': item.credential,
              CONFERRALS: item.conferrals,
              'NET ANNUAL COST': item['Average net price'],
              'YEARS TO GRADUATE': item['Average completion time'],
              EARNINGS: item.EARN,
              PAYBACK: item.Payback,
              'EARNING PLUS':
                item.degreePremium && item.degreePremium.premiumAmount ? item.degreePremium.premiumAmount : null,
              'EARNING PLUS %':
                item.degreePremium && item.degreePremium.premiumPercentage
                  ? item.degreePremium.premiumPercentage
                  : null,
              'ECONOMIC SCORE':
                item.degreePremium && item.degreePremium.rankingScore ? item.degreePremium.rankingScore : null,
            };
          }
        } else {
          let resObj = { unitid: item.unitid, name: item.name, state: item.state };
          if (useChar) {
            resObj = {
              ...resObj,
              city: item.city,
              'CAMPUS LOCATION': item.LOCALE ? item.LOCALE.LOCALE_codelabel : 'N/A',
              CONTROL: item.control,
              ICLEVEL: item.ICLEVEL,
              'TOTAL NUMBER OF STUDENTS': item.totalSize,
              'TOTAL NUMBER OF UNDERGRADUATE STUDENTS': item.UGDS,
              'IN STATE STUDENT %': item.inStateStudentPerc,
              CARNEGIE: item.carnegieClass,
              'Online/Hybrid': item.fullOnline,
              REGION: item.region,
              'First generation student percentage': item.FIRST_GEN,
              "Women's college": item.WOMENONLY,
              "Men's college": item.MENONLY,
              'Religious affiliation': item.RELIGIOUS_AFFILIATION,
              'HBCU colleges': item.HBCU && item.HBCU === 1 ? 'Yes' : 'No',
              'HSI colleges': item.HSI && item.HSI === 1 ? 'Yes' : 'No',
              'PBI colleges': item.PBI && item.PBI === 1 ? 'Yes' : 'No',
              'Yellow ribbon participant': item.VET1 && item.VET1 === 1 ? 'Yes' : 'No',
              'Credit for military service': item.VET2 && item.VET2 === 1 ? 'Yes' : 'No',
              'Main or branch campus': item.MAIN_CAMPUS && item.MAIN_CAMPUS === 1 ? 'Main' : 'Branch',
              'Title IV': item.PSET4FLG,
              'Offers degree or certificate': item.UGOFFER,
              Male: item.MALE,
              Female: item.FEMALE,
              White: item?.RACE_ETHNICITY?.UGDS_WHITE,
              Black: item?.RACE_ETHNICITY?.UGDS_BLACK,
              Hispanic: item?.RACE_ETHNICITY?.UGDS_HISP,
              Asian: item?.RACE_ETHNICITY?.UGDS_ASIAN,
              'American Indian': item?.RACE_ETHNICITY?.UGDS_AIAN,
              'Pacific Islander': item?.RACE_ETHNICITY?.UGDS_NHPI,
              Other: item?.RACE_ETHNICITY?.UGDS_OTHER,
              'Faculty-White': item?.RACE_ETHNICITY_FACULTY?.white,
              'Faculty-Black': item?.RACE_ETHNICITY_FACULTY?.black,
              'Faculty-Hispanic': item?.RACE_ETHNICITY_FACULTY?.hispanic,
              'Faculty-Asian': item?.RACE_ETHNICITY_FACULTY?.asian,
              'Faculty-American Indian': item?.RACE_ETHNICITY_FACULTY?.aian,
              'Faculty-Pacific Islander': item?.RACE_ETHNICITY_FACULTY?.nhpi,
              'Average age of entry': item.AGE_ENTRY,
              'Percent of students over 23 at entry': item.AGEGE24,
              'Share of veteran students': item.VETERAN,
              'Share of married students': item.MARRIED,
              'Percentage of undergraduates aged 25 and above': item.UG25ABV,
              'Percentage of students who are financially independent': item.DEP_STAT_PCT_IND,
              'Student-Faculty ratio': item.student_faculty_ratio,
            };
          }
          if (useAdmissions) {
            resObj = {
              ...resObj,
              'OPEN ADMISSIONS': item['Open admissions'],
              ...item.admissions,
              'Average GPA': item.AVG_GPA,
              'Rolling Admissions': item.earlyAdmissions ? (item.earlyAdmissions.rollingPolicy ? 'Yes' : 'No') : 'N/A',
              'Early Action Deadline': item.earlyAdmissions && item.earlyAdmissions.earlyActionDeadline,
              'Early Action Notification': item.earlyAdmissions && item.earlyAdmissions.earlyActionNotification,
              'Early Decision Deadline': item.earlyAdmissions && item.earlyAdmissions.earlyDecisionDeadline,
              'Early Decision II Deadline': item.earlyAdmissions && item.earlyAdmissions.earlyDecision2Deadline,
              'Early Decision Notification': item.earlyAdmissions && item.earlyAdmissions.earlyDecisionNotification,
            };
          }
          if (useCost) {
            resObj = {
              ...resObj,
              DEBT_MDN: item.DEBT_MDN,
              'In state tuition/fees': item.TUITIONFEE_IN,
              'In state tuition/fees': item.TUITIONFEE_IN,
              'Out of state tuition/fees': item.TUITIONFEE_OUT,
              'Books and supplies': item.BOOKSUPPLY,
              'Campus housing': item.ROOMBOARD_ON,
              'Other expenses': item.OTHEREXPENSE_ON,
              'Off-campus housing': item.ROOMBOARD_OFF,
              'Off-campus other expenses': item.OTHEREXPENSE_OFF,
              'Average net price': item['Average net price'],
              'Average net price for $0 - $30.000 family income': item.NPT41_PUB ? item.NPT41_PUB : item.NPT41_PRIV,
              'Number of students for $0 - $30.000 family income': item.cost_number_of_students
                ? item.cost_number_of_students.NUM41_PUB
                  ? item.cost_number_of_students.NUM41_PUB
                  : item.cost_number_of_students.NUM41_PRIV
                : null,
              'Average net price for $30.001 - $48.000 family income': item.NPT42_PUB
                ? item.NPT42_PUB
                : item.NPT42_PRIV,
              'Number of students for $30.001 - $48.000 family income': item.cost_number_of_students
                ? item.cost_number_of_students.NUM42_PUB
                  ? item.cost_number_of_students.NUM42_PUB
                  : item.cost_number_of_students.NUM42_PRIV
                : null,
              'Average net price for $48.001 - $75.000 family income': item.NPT43_PUB
                ? item.NPT43_PUB
                : item.NPT43_PRIV,
              'Number of students for $48.001 - $75.000 family income': item.cost_number_of_students
                ? item.cost_number_of_students.NUM43_PUB
                  ? item.cost_number_of_students.NUM43_PUB
                  : item.cost_number_of_students.NUM43_PRIV
                : null,
              'Average net price for $75.001 - $110.000 family income': item.NPT44_PUB
                ? item.NPT44_PUB
                : item.NPT44_PRIV,
              'Number of students for $75.001 - $110.000 family income': item.cost_number_of_students
                ? item.cost_number_of_students.NUM44_PUB
                  ? item.cost_number_of_students.NUM44_PUB
                  : item.cost_number_of_students.NUM44_PRIV
                : null,
              'Average net price for $110.000+ family income': item.NPT45_PUB ? item.NPT45_PUB : item.NPT45_PRIV,
              'Number of students for $110.000+ family income': item.cost_number_of_students
                ? item.cost_number_of_students.NUM45_PUB
                  ? item.cost_number_of_students.NUM45_PUB
                  : item.cost_number_of_students.NUM45_PRIV
                : null,
              'Pell percentage': item.grants && item.grants['Pell percentage'] ? item.grants['Pell percentage'] : 'N/A',
              'Pell grant amount average all undergrad':
                item.grants && item.grants.UPGRNTA ? item.grants.UPGRNTA : 'N/A',
              'Average total grant amount': item.grants && item.grants.AGRNT_A ? item.grants.AGRNT_A : 'N/A',
              'Percentage awarded grant': item.grants && item.grants.AGRNT_P ? item.grants.AGRNT_P : 'N/A',
              'Average federal loan amount': item.grants && item.grants.FLOAN_A ? item.grants.FLOAN_A : 'N/A',
              'Percentage undergrads that receive federal loan':
                item.grants && item.grants.FLOAN_P ? item.grants.FLOAN_P : 'N/A',
              'Federal grant average': item.grants && item.grants.FGRNT_A ? item.grants.FGRNT_A : 'N/A',
              'Federal grant percentage': item.grants && item.grants.FGRNT_P ? item.grants.FGRNT_P : 'N/A',
              'State grant average': item.grants && item.grants.SGRNT_A ? item.grants.SGRNT_A : 'N/A',
              'State grant percentage': item.grants && item.grants.SGRNT_P ? item.grants.SGRNT_P : 'N/A',
              'Other grant average': item.grants && item.grants.OFGRT_A ? item.grants.OFGRT_A : 'N/A',
              'Other grant percentage': item.grants && item.grants.OFGRT_P ? item.grants.OFGRT_P : 'N/A',
              'Institutional grant average': item.grants && item.grants.IGRNT_A ? item.grants.IGRNT_A : 'N/A',
              'Institutional grant percentage': item.grants && item.grants.IGRNT_P ? item.grants.IGRNT_P : 'N/A',
              'Total loan average': item.grants && item.grants.LOAN_A ? item.grants.LOAN_A : 'N/A',
              'Total loan percentage': item.grants && item.grants.LOAN_P ? item.grants.LOAN_P : 'N/A',
              'Tuition reciprocity': item.tuitionReciprocity ? item.tuitionReciprocity : 'No',
            };
          }
          if (useGradRates) {
            resObj = {
              ...resObj,
              'Total grad rate': item.OMgradRate,
              'Grad rate 4 years':
                item.gradRateOM && item.gradRateOM.gradRate4Yrs ? item.gradRateOM.gradRate4Yrs : 'N/A',
              'Grad rate 6 years':
                item.gradRateOM && item.gradRateOM.gradRate6Yrs ? item.gradRateOM.gradRate6Yrs : 'N/A',
              'Grad rate 8 years':
                item.gradRateOM && item.gradRateOM.gradRate8Yrs ? item.gradRateOM.gradRate8Yrs : 'N/A',
              'Average completion time': item['Average completion time'],
              'Total pell grad rate': item.gradRateTotalPellOM,
              'Pell grad rate 4 years':
                item.gradRatePellOM && item.gradRatePellOM.gradRate4YrsPell
                  ? item.gradRatePellOM.gradRate4YrsPell
                  : 'N/A',
              'Pell grad rate 6 years':
                item.gradRatePellOM && item.gradRatePellOM.gradRate6YrsPell
                  ? item.gradRatePellOM.gradRate6YrsPell
                  : 'N/A',
              'Pell grad rate 8 years':
                item.gradRatePellOM && item.gradRatePellOM.gradRate8YrsPell
                  ? item.gradRatePellOM.gradRate8YrsPell
                  : 'N/A',
              'Pell average completion time': item['Average completion time pell'],
              'Pell performance gap': item['Pell performance gap'],
              'Adjusted pell grad rate': item['Adjusted pell grad rate'],
              'Total no-pell grad rate':
                item.gradRateNoPellOM && item.gradRateNoPellOM.totalGradRateNoPell
                  ? item.gradRateNoPellOM.totalGradRateNoPell
                  : 'N/A',
              'Full time students': item.student_OM_percentages && item.student_OM_percentages.fullTimeStudent,
              'Part time students': item.student_OM_percentages && item.student_OM_percentages.partTimeStudent,
              'First time students': item.student_OM_percentages && item.student_OM_percentages.firstTimeStudent,
              'Non first time students': item.student_OM_percentages && item.student_OM_percentages.nonFirstTimeStudent,
              'Grad rate 100%': item.L4GR100,
              'Grad rate 150%': item.L4GR150,
              'Grad rate 200%': item.L4GR200,
            };
          }
          if (useKPI) {
            resObj = {
              ...resObj,
              'KPI Earnings': !cipcode ? item.MD_EARN_WNE_P10 : item.EARN,
              'KPI Earnings Count': !cipcode ? item.COUNT_WNE_P10 : item.EARN_COUNT,
              'KPI Earnings 4 years after completion': !cipcode ? item.MD_EARN_WNE_4YR : item.EARN,
              'KPI Earnings Count 4 years after completion': !cipcode ? item.COUNT_WNE_4YR : item.EARN_COUNT,
              'KPI Total cost':
                item['Average net price'] && item['Average completion time']
                  ? item['Average net price'] * item['Average completion time']
                  : 'N/A',
              'KPI Payback': item.Payback,
              'KPI Earning plus':
                item.degreePremium && item.degreePremium.premiumAmount ? item.degreePremium.premiumAmount : 'N/A',
              'KPI Earning plus%':
                item.degreePremium && item.degreePremium.premiumPercentage
                  ? item.degreePremium.premiumPercentage
                  : 'N/A',
              'KPI Economic score':
                item.degreePremium && item.degreePremium.rankingScore ? item.degreePremium.rankingScore : 'N/A',
              'EMI earnings': item.MD_EARN_WNE_INC1_P10,
              'EMI earnings count': item.COUNT_WNE_INC1_P10,
              'Cost (public)': item.NPT41_PUB,
              'Cost (private)': item.NPT41_PRIV,
              'Pell total cost':
                item.NPT41_PUB && item['Average completion time pell']
                  ? item.NPT41_PUB * item['Average completion time pell']
                  : item.NPT41_PRIV && item['Average completion time pell']
                  ? item.NPT41_PRIV * item['Average completion time pell']
                  : 'N/A',
              'EMI Payback': item.Payback10perc,
              'EMI Earning plus':
                item.degreePremiumEMI && item.degreePremiumEMI.premiumAmountEMI
                  ? item.degreePremiumEMI.premiumAmountEMI
                  : 'N/A',
              'EMI Earning plus%':
                item.degreePremiumEMI && item.degreePremiumEMI.premiumPercentageEMI
                  ? item.degreePremiumEMI.premiumPercentageEMI
                  : 'N/A',
              'EMI Economic score':
                item.degreePremiumEMI && item.degreePremiumEMI.rankingScoreEMI
                  ? item.degreePremiumEMI.rankingScoreEMI
                  : 'N/A',
              'EMI percentile': item.EMI_percentile,
              'Pell percentage': item.grants && item.grants['Pell percentage'] ? item.grants['Pell percentage'] : 'N/A',
              EMI: item.EMI,
              'EMI rank': item.rankEMI,
              '10 year earnings middle tercile': item.MD_EARN_WNE_INC2_P10,
              '10 year earnings count middle tercile': item.COUNT_WNE_INC2_P10,
              '10 year earnings highest tercile': item.MD_EARN_WNE_INC3_P10,
              '10 year earnings count highest tercile': item.COUNT_WNE_INC3_P10,
              '0k-30k cost': item.incomeLevels && item.incomeLevels['0-30'].cost,
              '0k-30k payback': item.incomeLevels && item.incomeLevels['0-30'].payback,
              '0k-30k economic score': item.incomeLevels && item.incomeLevels['0-30'].es,
              '30k-48k cost': item.incomeLevels && item.incomeLevels['30-48'].cost,
              '30k-48k payback': item.incomeLevels && item.incomeLevels['30-48'].payback,
              '30k-48k economic score': item.incomeLevels && item.incomeLevels['30-48'].es,
              '48k-75k cost': item.incomeLevels && item.incomeLevels['48-75'].cost,
              '48k-75k payback': item.incomeLevels && item.incomeLevels['48-75'].payback,
              '48k-75k economic score': item.incomeLevels && item.incomeLevels['48-75'].es,
              '75k-110k cost': item.incomeLevels && item.incomeLevels['75-110'].cost,
              '75k-110k payback': item.incomeLevels && item.incomeLevels['75-110'].payback,
              '75k-110k economic score': item.incomeLevels && item.incomeLevels['75-110'].es,
              '110k+ cost': item.incomeLevels && item.incomeLevels['110-plus'].cost,
              '110k+ payback': item.incomeLevels && item.incomeLevels['110-plus'].payback,
              '110k+ economic score': item.incomeLevels && item.incomeLevels['110-plus'].es,
            };
          }
          if (useRepayment) {
            resObj = {
              ...resObj,
              // '2018 1-year repayment rate borrower count': item?.repayment?.['2018']?.['1_yr_db_fed_repayment']?.count,
              // '2018 1-year repayment rate numerator':
              //   item?.repayment?.['2018']?.['1_yr_db_fed_repayment']?.numerator &&
              //   item?.repayment?.['2018']?.['1_yr_db_fed_repayment']?.count
              //     ? item?.repayment?.['2018']?.['1_yr_db_fed_repayment']?.numerator /
              //       item?.repayment?.['2018']?.['1_yr_db_fed_repayment']?.count
              //     : '',
              // '2018 1-year repayment rate denominator':
              //   item?.repayment?.['2018']?.['1_yr_db_fed_repayment']?.denominator &&
              //   item?.repayment?.['2018']?.['1_yr_db_fed_repayment']?.count
              //     ? item?.repayment?.['2018']?.['1_yr_db_fed_repayment']?.denominator /
              //       item?.repayment?.['2018']?.['1_yr_db_fed_repayment']?.count
              //     : '',
              // '2018 1-year repayment rate': item?.repayment?.['2018']?.['1_yr_db_fed_repayment']?.rate,
              // '2018 4-year repayment rate borrower count': item?.repayment?.['2018']?.['4_yr_db_fed_repayment']?.count,
              // '2018 4-year repayment rate numerator':
              //   item?.repayment?.['2018']?.['4_yr_db_fed_repayment']?.numerator &&
              //   item?.repayment?.['2018']?.['4_yr_db_fed_repayment']?.count
              //     ? item?.repayment?.['2018']?.['4_yr_db_fed_repayment']?.numerator /
              //       item?.repayment?.['2018']?.['4_yr_db_fed_repayment']?.count
              //     : '',
              // '2018 4-year repayment rate denominator':
              //   item?.repayment?.['2018']?.['4_yr_db_fed_repayment']?.denominator &&
              //   item?.repayment?.['2018']?.['4_yr_db_fed_repayment']?.count
              //     ? item?.repayment?.['2018']?.['4_yr_db_fed_repayment']?.denominator /
              //       item?.repayment?.['2018']?.['4_yr_db_fed_repayment']?.count
              //     : '',
              // '2018 4-year repayment rate': item?.repayment?.['2018']?.['4_yr_db_fed_repayment']?.rate,
              // '2018 5-year repayment rate borrower count': item?.repayment?.['2018']?.['5_yr_db_fed_repayment']?.count,
              // '2018 5-year repayment rate numerator':
              //   item?.repayment?.['2018']?.['5_yr_db_fed_repayment']?.numerator &&
              //   item?.repayment?.['2018']?.['5_yr_db_fed_repayment']?.count
              //     ? item?.repayment?.['2018']?.['5_yr_db_fed_repayment']?.numerator /
              //       item?.repayment?.['2018']?.['5_yr_db_fed_repayment']?.count
              //     : '',
              // '2018 5-year repayment rate denominator':
              //   item?.repayment?.['2018']?.['5_yr_db_fed_repayment']?.denominator &&
              //   item?.repayment?.['2018']?.['5_yr_db_fed_repayment']?.count
              //     ? item?.repayment?.['2018']?.['5_yr_db_fed_repayment']?.denominator /
              //       item?.repayment?.['2018']?.['5_yr_db_fed_repayment']?.count
              //     : '',
              // '2018 5-year repayment rate': item?.repayment?.['2018']?.['5_yr_db_fed_repayment']?.rate,
              // '2018 10-year repayment rate borrower count':
              //   item?.repayment?.['2018']?.['10_yr_db_fed_repayment']?.count,
              // '2018 10-year repayment rate numerator':
              //   item?.repayment?.['2018']?.['10_yr_db_fed_repayment']?.numerator &&
              //   item?.repayment?.['2018']?.['10_yr_db_fed_repayment']?.count
              //     ? item?.repayment?.['2018']?.['10_yr_db_fed_repayment']?.numerator /
              //       item?.repayment?.['2018']?.['10_yr_db_fed_repayment']?.count
              //     : '',
              // '2018 10-year repayment rate denominator':
              //   item?.repayment?.['2018']?.['10_yr_db_fed_repayment']?.denominator &&
              //   item?.repayment?.['2018']?.['10_yr_db_fed_repayment']?.count
              //     ? item?.repayment?.['2018']?.['10_yr_db_fed_repayment']?.denominator /
              //       item?.repayment?.['2018']?.['10_yr_db_fed_repayment']?.count
              //     : '',
              // '2018 10-year repayment rate': item?.repayment?.['2018']?.['10_yr_db_fed_repayment']?.rate,
              // '2018 20-year repayment rate borrower count':
              //   item?.repayment?.['2018']?.['20_yr_db_fed_repayment']?.count,
              // '2018 20-year repayment rate numerator':
              //   item?.repayment?.['2018']?.['20_yr_db_fed_repayment']?.numerator &&
              //   item?.repayment?.['2018']?.['20_yr_db_fed_repayment']?.count
              //     ? item?.repayment?.['2018']?.['20_yr_db_fed_repayment']?.numerator /
              //       item?.repayment?.['2018']?.['20_yr_db_fed_repayment']?.count
              //     : '',
              // '2018 20-year repayment rate denominator':
              //   item?.repayment?.['2018']?.['20_yr_db_fed_repayment']?.denominator &&
              //   item?.repayment?.['2018']?.['20_yr_db_fed_repayment']?.count
              //     ? item?.repayment?.['2018']?.['20_yr_db_fed_repayment']?.denominator /
              //       item?.repayment?.['2018']?.['20_yr_db_fed_repayment']?.count
              //     : '',
              // '2018 20-year repayment rate': item?.repayment?.['2018']?.['20_yr_db_fed_repayment']?.rate,
              // '2018 Federal student loan 1-year borrower count':
              //   item?.repayment?.['2018']?.['1_yr_bb_fed_repayment']?.count,
              // '2018 Percentage of federal student loan borrowers in default after 1 year':
              //   item?.repayment?.['2018']?.['1_yr_bb_fed_repayment']?.default,
              // '2018 Percentage of federal student loan borrowers in delinquency after 1 year':
              //   item?.repayment?.['2018']?.['1_yr_bb_fed_repayment']?.delinquent,
              // '2018 Percentage of federal student loan borrowers in forbearance after 1 year':
              //   item?.repayment?.['2018']?.['1_yr_bb_fed_repayment']?.forbearance,
              // '2018 Percentage of federal student loan borrowers in deferment after 1 year':
              //   item?.repayment?.['2018']?.['1_yr_bb_fed_repayment']?.deferment,
              // '2018 Percentage of federal student loan borrowers not making progress after 1 year':
              //   item?.repayment?.['2018']?.['1_yr_bb_fed_repayment']?.noprogress,
              // '2018 Percentage of federal student loan borrowers making progress after 1 year':
              //   item?.repayment?.['2018']?.['1_yr_bb_fed_repayment']?.makingprogress,
              // '2018 Percentage of federal student loan borrowers paid in full after 1 year':
              //   item?.repayment?.['2018']?.['1_yr_bb_fed_repayment']?.fullypaid,
              // '2018 Percentage of federal student loan borrowers with all loans discharged after 1 year':
              //   item?.repayment?.['2018']?.['1_yr_bb_fed_repayment']?.discharge,
              // '2018 Federal student loan 2-year borrower count':
              //   item?.repayment?.['2018']?.['2_yr_bb_fed_repayment']?.count,
              // '2018 Percentage of federal student loan borrowers in default after 2 year':
              //   item?.repayment?.['2018']?.['2_yr_bb_fed_repayment']?.default,
              // '2018 Percentage of federal student loan borrowers in delinquency after 2 year':
              //   item?.repayment?.['2018']?.['2_yr_bb_fed_repayment']?.delinquent,
              // '2018 Percentage of federal student loan borrowers in forbearance after 2 year':
              //   item?.repayment?.['2018']?.['2_yr_bb_fed_repayment']?.forbearance,
              // '2018 Percentage of federal student loan borrowers in deferment after 2 year':
              //   item?.repayment?.['2018']?.['2_yr_bb_fed_repayment']?.deferment,
              // '2018 Percentage of federal student loan borrowers not making progress after 2 year':
              //   item?.repayment?.['2018']?.['2_yr_bb_fed_repayment']?.noprogress,
              // '2018 Percentage of federal student loan borrowers making progress after 2 year':
              //   item?.repayment?.['2018']?.['2_yr_bb_fed_repayment']?.makingprogress,
              // '2018 Percentage of federal student loan borrowers paid in full after 2 year':
              //   item?.repayment?.['2018']?.['2_yr_bb_fed_repayment']?.fullypaid,
              // '2018 Percentage of federal student loan borrowers with all loans discharged after 2 year':
              //   item?.repayment?.['2018']?.['2_yr_bb_fed_repayment']?.discharge,
              // '2018 Federal student loan 3-year borrower count':
              //   item?.repayment?.['2018']?.['3_yr_bb_fed_repayment']?.count,
              // '2018 Percentage of federal student loan borrowers in default after 3 year':
              //   item?.repayment?.['2018']?.['3_yr_bb_fed_repayment']?.default,
              // '2018 Percentage of federal student loan borrowers in delinquency after 3 year':
              //   item?.repayment?.['2018']?.['3_yr_bb_fed_repayment']?.delinquent,
              // '2018 Percentage of federal student loan borrowers in forbearance after 3 year':
              //   item?.repayment?.['2018']?.['3_yr_bb_fed_repayment']?.forbearance,
              // '2018 Percentage of federal student loan borrowers in deferment after 3 year':
              //   item?.repayment?.['2018']?.['3_yr_bb_fed_repayment']?.deferment,
              // '2018 Percentage of federal student loan borrowers not making progress after 3 year':
              //   item?.repayment?.['2018']?.['3_yr_bb_fed_repayment']?.noprogress,
              // '2018 Percentage of federal student loan borrowers making progress after 3 year':
              //   item?.repayment?.['2018']?.['3_yr_bb_fed_repayment']?.makingprogress,
              // '2018 Percentage of federal student loan borrowers paid in full after 3 year':
              //   item?.repayment?.['2018']?.['3_yr_bb_fed_repayment']?.fullypaid,
              // '2018 Percentage of federal student loan borrowers with all loans discharged after 3 year':
              //   item?.repayment?.['2018']?.['3_yr_bb_fed_repayment']?.discharge,
              // '2018 Federal student loan 4-year borrower count':
              //   item?.repayment?.['2018']?.['4_yr_bb_fed_repayment']?.count,
              // '2018 Percentage of federal student loan borrowers in default after 4 year':
              //   item?.repayment?.['2018']?.['4_yr_bb_fed_repayment']?.default,
              // '2018 Percentage of federal student loan borrowers in delinquency after 4 year':
              //   item?.repayment?.['2018']?.['4_yr_bb_fed_repayment']?.delinquent,
              // '2018 Percentage of federal student loan borrowers in forbearance after 4 year':
              //   item?.repayment?.['2018']?.['4_yr_bb_fed_repayment']?.forbearance,
              // '2018 Percentage of federal student loan borrowers in deferment after 4 year':
              //   item?.repayment?.['2018']?.['4_yr_bb_fed_repayment']?.deferment,
              // '2018 Percentage of federal student loan borrowers not making progress after 4 year':
              //   item?.repayment?.['2018']?.['4_yr_bb_fed_repayment']?.noprogress,
              // '2018 Percentage of federal student loan borrowers making progress after 4 year':
              //   item?.repayment?.['2018']?.['4_yr_bb_fed_repayment']?.makingprogress,
              // '2018 Percentage of federal student loan borrowers paid in full after 4 year':
              //   item?.repayment?.['2018']?.['4_yr_bb_fed_repayment']?.fullypaid,
              // '2018 Percentage of federal student loan borrowers with all loans discharged after 4 year':
              //   item?.repayment?.['2018']?.['4_yr_bb_fed_repayment']?.discharge,
              // '2019 1-year repayment rate borrower count': item?.repayment?.['2019']?.['1_yr_db_fed_repayment']?.count,
              // '2019 1-year repayment rate numerator':
              //   item?.repayment?.['2019']?.['1_yr_db_fed_repayment']?.numerator &&
              //   item?.repayment?.['2019']?.['1_yr_db_fed_repayment']?.count
              //     ? item?.repayment?.['2019']?.['1_yr_db_fed_repayment']?.numerator /
              //       item?.repayment?.['2019']?.['1_yr_db_fed_repayment']?.count
              //     : '',
              // '2019 1-year repayment rate denominator':
              //   item?.repayment?.['2019']?.['1_yr_db_fed_repayment']?.denominator &&
              //   item?.repayment?.['2019']?.['1_yr_db_fed_repayment']?.count
              //     ? item?.repayment?.['2019']?.['1_yr_db_fed_repayment']?.denominator /
              //       item?.repayment?.['2019']?.['1_yr_db_fed_repayment']?.count
              //     : '',
              // '2019 1-year repayment rate': item?.repayment?.['2019']?.['1_yr_db_fed_repayment']?.rate,
              // '2019 4-year repayment rate borrower count': item?.repayment?.['2019']?.['4_yr_db_fed_repayment']?.count,
              // '2019 4-year repayment rate numerator':
              //   item?.repayment?.['2019']?.['4_yr_db_fed_repayment']?.numerator &&
              //   item?.repayment?.['2019']?.['4_yr_db_fed_repayment']?.count
              //     ? item?.repayment?.['2019']?.['4_yr_db_fed_repayment']?.numerator /
              //       item?.repayment?.['2019']?.['4_yr_db_fed_repayment']?.count
              //     : '',
              // '2019 4-year repayment rate denominator':
              //   item?.repayment?.['2019']?.['4_yr_db_fed_repayment']?.denominator &&
              //   item?.repayment?.['2019']?.['4_yr_db_fed_repayment']?.count
              //     ? item?.repayment?.['2019']?.['4_yr_db_fed_repayment']?.denominator /
              //       item?.repayment?.['2019']?.['4_yr_db_fed_repayment']?.count
              //     : '',
              // '2019 4-year repayment rate': item?.repayment?.['2019']?.['4_yr_db_fed_repayment']?.rate,
              // '2019 5-year repayment rate borrower count': item?.repayment?.['2019']?.['5_yr_db_fed_repayment']?.count,
              // '2019 5-year repayment rate numerator':
              //   item?.repayment?.['2019']?.['5_yr_db_fed_repayment']?.numerator &&
              //   item?.repayment?.['2019']?.['5_yr_db_fed_repayment']?.count
              //     ? item?.repayment?.['2019']?.['5_yr_db_fed_repayment']?.numerator /
              //       item?.repayment?.['2019']?.['5_yr_db_fed_repayment']?.count
              //     : '',
              // '2019 5-year repayment rate denominator':
              //   item?.repayment?.['2019']?.['5_yr_db_fed_repayment']?.denominator &&
              //   item?.repayment?.['2019']?.['5_yr_db_fed_repayment']?.count
              //     ? item?.repayment?.['2019']?.['5_yr_db_fed_repayment']?.denominator /
              //       item?.repayment?.['2019']?.['5_yr_db_fed_repayment']?.count
              //     : '',
              // '2019 5-year repayment rate': item?.repayment?.['2019']?.['5_yr_db_fed_repayment']?.rate,
              // '2019 10-year repayment rate borrower count':
              //   item?.repayment?.['2019']?.['10_yr_db_fed_repayment']?.count,
              // '2019 10-year repayment rate numerator':
              //   item?.repayment?.['2019']?.['10_yr_db_fed_repayment']?.numerator &&
              //   item?.repayment?.['2019']?.['10_yr_db_fed_repayment']?.count
              //     ? item?.repayment?.['2019']?.['10_yr_db_fed_repayment']?.numerator /
              //       item?.repayment?.['2019']?.['10_yr_db_fed_repayment']?.count
              //     : '',
              // '2019 10-year repayment rate denominator':
              //   item?.repayment?.['2019']?.['10_yr_db_fed_repayment']?.denominator &&
              //   item?.repayment?.['2019']?.['10_yr_db_fed_repayment']?.count
              //     ? item?.repayment?.['2019']?.['10_yr_db_fed_repayment']?.denominator /
              //       item?.repayment?.['2019']?.['10_yr_db_fed_repayment']?.count
              //     : '',
              // '2019 10-year repayment rate': item?.repayment?.['2019']?.['10_yr_db_fed_repayment']?.rate,
              // '2019 20-year repayment rate borrower count':
              //   item?.repayment?.['2019']?.['20_yr_db_fed_repayment']?.count,
              // '2019 20-year repayment rate numerator':
              //   item?.repayment?.['2019']?.['20_yr_db_fed_repayment']?.numerator &&
              //   item?.repayment?.['2019']?.['20_yr_db_fed_repayment']?.count
              //     ? item?.repayment?.['2019']?.['20_yr_db_fed_repayment']?.numerator /
              //       item?.repayment?.['2019']?.['20_yr_db_fed_repayment']?.count
              //     : '',
              // '2019 20-year repayment rate denominator':
              //   item?.repayment?.['2019']?.['20_yr_db_fed_repayment']?.denominator &&
              //   item?.repayment?.['2019']?.['20_yr_db_fed_repayment']?.count
              //     ? item?.repayment?.['2019']?.['20_yr_db_fed_repayment']?.denominator /
              //       item?.repayment?.['2019']?.['20_yr_db_fed_repayment']?.count
              //     : '',
              // '2019 20-year repayment rate': item?.repayment?.['2019']?.['20_yr_db_fed_repayment']?.rate,
              // '2019 Federal student loan 1-year borrower count':
              //   item?.repayment?.['2019']?.['1_yr_bb_fed_repayment']?.count,
              // '2019 Percentage of federal student loan borrowers in default after 1 year':
              //   item?.repayment?.['2019']?.['1_yr_bb_fed_repayment']?.default,
              // '2019 Percentage of federal student loan borrowers in delinquency after 1 year':
              //   item?.repayment?.['2019']?.['1_yr_bb_fed_repayment']?.delinquent,
              // '2019 Percentage of federal student loan borrowers in forbearance after 1 year':
              //   item?.repayment?.['2019']?.['1_yr_bb_fed_repayment']?.forbearance,
              // '2019 Percentage of federal student loan borrowers in deferment after 1 year':
              //   item?.repayment?.['2019']?.['1_yr_bb_fed_repayment']?.deferment,
              // '2019 Percentage of federal student loan borrowers not making progress after 1 year':
              //   item?.repayment?.['2019']?.['1_yr_bb_fed_repayment']?.noprogress,
              // '2019 Percentage of federal student loan borrowers making progress after 1 year':
              //   item?.repayment?.['2019']?.['1_yr_bb_fed_repayment']?.makingprogress,
              // '2019 Percentage of federal student loan borrowers paid in full after 1 year':
              //   item?.repayment?.['2019']?.['1_yr_bb_fed_repayment']?.fullypaid,
              // '2019 Percentage of federal student loan borrowers with all loans discharged after 1 year':
              //   item?.repayment?.['2019']?.['1_yr_bb_fed_repayment']?.discharge,
              // '2019 Federal student loan 2-year borrower count':
              //   item?.repayment?.['2019']?.['2_yr_bb_fed_repayment']?.count,
              // '2019 Percentage of federal student loan borrowers in default after 2 year':
              //   item?.repayment?.['2019']?.['2_yr_bb_fed_repayment']?.default,
              // '2019 Percentage of federal student loan borrowers in delinquency after 2 year':
              //   item?.repayment?.['2019']?.['2_yr_bb_fed_repayment']?.delinquent,
              // '2019 Percentage of federal student loan borrowers in forbearance after 2 year':
              //   item?.repayment?.['2019']?.['2_yr_bb_fed_repayment']?.forbearance,
              // '2019 Percentage of federal student loan borrowers in deferment after 2 year':
              //   item?.repayment?.['2019']?.['2_yr_bb_fed_repayment']?.deferment,
              // '2019 Percentage of federal student loan borrowers not making progress after 2 year':
              //   item?.repayment?.['2019']?.['2_yr_bb_fed_repayment']?.noprogress,
              // '2019 Percentage of federal student loan borrowers making progress after 2 year':
              //   item?.repayment?.['2019']?.['2_yr_bb_fed_repayment']?.makingprogress,
              // '2019 Percentage of federal student loan borrowers paid in full after 2 year':
              //   item?.repayment?.['2019']?.['2_yr_bb_fed_repayment']?.fullypaid,
              // '2019 Percentage of federal student loan borrowers with all loans discharged after 2 year':
              //   item?.repayment?.['2019']?.['2_yr_bb_fed_repayment']?.discharge,
              // '2019 Federal student loan 3-year borrower count':
              //   item?.repayment?.['2019']?.['3_yr_bb_fed_repayment']?.count,
              // '2019 Percentage of federal student loan borrowers in default after 3 year':
              //   item?.repayment?.['2019']?.['3_yr_bb_fed_repayment']?.default,
              // '2019 Percentage of federal student loan borrowers in delinquency after 3 year':
              //   item?.repayment?.['2019']?.['3_yr_bb_fed_repayment']?.delinquent,
              // '2019 Percentage of federal student loan borrowers in forbearance after 3 year':
              //   item?.repayment?.['2019']?.['3_yr_bb_fed_repayment']?.forbearance,
              // '2019 Percentage of federal student loan borrowers in deferment after 3 year':
              //   item?.repayment?.['2019']?.['3_yr_bb_fed_repayment']?.deferment,
              // '2019 Percentage of federal student loan borrowers not making progress after 3 year':
              //   item?.repayment?.['2019']?.['3_yr_bb_fed_repayment']?.noprogress,
              // '2019 Percentage of federal student loan borrowers making progress after 3 year':
              //   item?.repayment?.['2019']?.['3_yr_bb_fed_repayment']?.makingprogress,
              // '2019 Percentage of federal student loan borrowers paid in full after 3 year':
              //   item?.repayment?.['2019']?.['3_yr_bb_fed_repayment']?.fullypaid,
              // '2019 Percentage of federal student loan borrowers with all loans discharged after 3 year':
              //   item?.repayment?.['2019']?.['3_yr_bb_fed_repayment']?.discharge,
              // '2019 Federal student loan 4-year borrower count':
              //   item?.repayment?.['2019']?.['4_yr_bb_fed_repayment']?.count,
              // '2019 Percentage of federal student loan borrowers in default after 4 year':
              //   item?.repayment?.['2019']?.['4_yr_bb_fed_repayment']?.default,
              // '2019 Percentage of federal student loan borrowers in delinquency after 4 year':
              //   item?.repayment?.['2019']?.['4_yr_bb_fed_repayment']?.delinquent,
              // '2019 Percentage of federal student loan borrowers in forbearance after 4 year':
              //   item?.repayment?.['2019']?.['4_yr_bb_fed_repayment']?.forbearance,
              // '2019 Percentage of federal student loan borrowers in deferment after 4 year':
              //   item?.repayment?.['2019']?.['4_yr_bb_fed_repayment']?.deferment,
              // '2019 Percentage of federal student loan borrowers not making progress after 4 year':
              //   item?.repayment?.['2019']?.['4_yr_bb_fed_repayment']?.noprogress,
              // '2019 Percentage of federal student loan borrowers making progress after 4 year':
              //   item?.repayment?.['2019']?.['4_yr_bb_fed_repayment']?.makingprogress,
              // '2019 Percentage of federal student loan borrowers paid in full after 4 year':
              //   item?.repayment?.['2019']?.['4_yr_bb_fed_repayment']?.fullypaid,
              // '2019 Percentage of federal student loan borrowers with all loans discharged after 4 year':
              //   item?.repayment?.['2019']?.['4_yr_bb_fed_repayment']?.discharge,
              '2020 1-year repayment rate borrower count': item?.repayment?.['2020']?.['1_yr_db_fed_repayment']?.count,
              '2020 1-year repayment rate numerator':
                item?.repayment?.['2020']?.['1_yr_db_fed_repayment']?.numerator &&
                item?.repayment?.['2020']?.['1_yr_db_fed_repayment']?.count
                  ? item?.repayment?.['2020']?.['1_yr_db_fed_repayment']?.numerator /
                    item?.repayment?.['2020']?.['1_yr_db_fed_repayment']?.count
                  : '',
              '2020 1-year repayment rate denominator':
                item?.repayment?.['2020']?.['1_yr_db_fed_repayment']?.denominator &&
                item?.repayment?.['2020']?.['1_yr_db_fed_repayment']?.count
                  ? item?.repayment?.['2020']?.['1_yr_db_fed_repayment']?.denominator /
                    item?.repayment?.['2020']?.['1_yr_db_fed_repayment']?.count
                  : '',
              '2020 1-year repayment rate': item?.repayment?.['2020']?.['1_yr_db_fed_repayment']?.rate,
              '2020 4-year repayment rate borrower count': item?.repayment?.['2020']?.['4_yr_db_fed_repayment']?.count,
              '2020 4-year repayment rate numerator':
                item?.repayment?.['2020']?.['4_yr_db_fed_repayment']?.numerator &&
                item?.repayment?.['2020']?.['4_yr_db_fed_repayment']?.count
                  ? item?.repayment?.['2020']?.['4_yr_db_fed_repayment']?.numerator /
                    item?.repayment?.['2020']?.['4_yr_db_fed_repayment']?.count
                  : '',
              '2020 4-year repayment rate denominator':
                item?.repayment?.['2020']?.['4_yr_db_fed_repayment']?.denominator &&
                item?.repayment?.['2020']?.['4_yr_db_fed_repayment']?.count
                  ? item?.repayment?.['2020']?.['4_yr_db_fed_repayment']?.denominator /
                    item?.repayment?.['2020']?.['4_yr_db_fed_repayment']?.count
                  : '',
              '2020 4-year repayment rate': item?.repayment?.['2020']?.['4_yr_db_fed_repayment']?.rate,
              '2020 5-year repayment rate borrower count': item?.repayment?.['2020']?.['5_yr_db_fed_repayment']?.count,
              '2020 5-year repayment rate numerator':
                item?.repayment?.['2020']?.['5_yr_db_fed_repayment']?.numerator &&
                item?.repayment?.['2020']?.['5_yr_db_fed_repayment']?.count
                  ? item?.repayment?.['2020']?.['5_yr_db_fed_repayment']?.numerator /
                    item?.repayment?.['2020']?.['5_yr_db_fed_repayment']?.count
                  : '',
              '2020 5-year repayment rate denominator':
                item?.repayment?.['2020']?.['5_yr_db_fed_repayment']?.denominator &&
                item?.repayment?.['2020']?.['5_yr_db_fed_repayment']?.count
                  ? item?.repayment?.['2020']?.['5_yr_db_fed_repayment']?.denominator /
                    item?.repayment?.['2020']?.['5_yr_db_fed_repayment']?.count
                  : '',
              '2020 5-year repayment rate': item?.repayment?.['2020']?.['5_yr_db_fed_repayment']?.rate,
              '2020 10-year repayment rate borrower count':
                item?.repayment?.['2020']?.['10_yr_db_fed_repayment']?.count,
              '2020 10-year repayment rate numerator':
                item?.repayment?.['2020']?.['10_yr_db_fed_repayment']?.numerator &&
                item?.repayment?.['2020']?.['10_yr_db_fed_repayment']?.count
                  ? item?.repayment?.['2020']?.['10_yr_db_fed_repayment']?.numerator /
                    item?.repayment?.['2020']?.['10_yr_db_fed_repayment']?.count
                  : '',
              '2020 10-year repayment rate denominator':
                item?.repayment?.['2020']?.['10_yr_db_fed_repayment']?.denominator &&
                item?.repayment?.['2020']?.['10_yr_db_fed_repayment']?.count
                  ? item?.repayment?.['2020']?.['10_yr_db_fed_repayment']?.denominator /
                    item?.repayment?.['2020']?.['10_yr_db_fed_repayment']?.count
                  : '',
              '2020 10-year repayment rate': item?.repayment?.['2020']?.['10_yr_db_fed_repayment']?.rate,
              '2020 20-year repayment rate borrower count':
                item?.repayment?.['2020']?.['20_yr_db_fed_repayment']?.count,
              '2020 20-year repayment rate numerator':
                item?.repayment?.['2020']?.['20_yr_db_fed_repayment']?.numerator &&
                item?.repayment?.['2020']?.['20_yr_db_fed_repayment']?.count
                  ? item?.repayment?.['2020']?.['20_yr_db_fed_repayment']?.numerator /
                    item?.repayment?.['2020']?.['20_yr_db_fed_repayment']?.count
                  : '',
              '2020 20-year repayment rate denominator':
                item?.repayment?.['2020']?.['20_yr_db_fed_repayment']?.denominator &&
                item?.repayment?.['2020']?.['20_yr_db_fed_repayment']?.count
                  ? item?.repayment?.['2020']?.['20_yr_db_fed_repayment']?.denominator /
                    item?.repayment?.['2020']?.['20_yr_db_fed_repayment']?.count
                  : '',
              '2020 20-year repayment rate': item?.repayment?.['2020']?.['20_yr_db_fed_repayment']?.rate,
              '2020 Federal student loan 1-year borrower count':
                item?.repayment?.['2020']?.['1_yr_bb_fed_repayment']?.count,
              '2020 Percentage of federal student loan borrowers in default after 1 year':
                item?.repayment?.['2020']?.['1_yr_bb_fed_repayment']?.default,
              '2020 Percentage of federal student loan borrowers in delinquency after 1 year':
                item?.repayment?.['2020']?.['1_yr_bb_fed_repayment']?.delinquent,
              '2020 Percentage of federal student loan borrowers in forbearance after 1 year':
                item?.repayment?.['2020']?.['1_yr_bb_fed_repayment']?.forbearance,
              '2020 Percentage of federal student loan borrowers in deferment after 1 year':
                item?.repayment?.['2020']?.['1_yr_bb_fed_repayment']?.deferment,
              '2020 Percentage of federal student loan borrowers not making progress after 1 year':
                item?.repayment?.['2020']?.['1_yr_bb_fed_repayment']?.noprogress,
              '2020 Percentage of federal student loan borrowers making progress after 1 year':
                item?.repayment?.['2020']?.['1_yr_bb_fed_repayment']?.makingprogress,
              '2020 Percentage of federal student loan borrowers paid in full after 1 year':
                item?.repayment?.['2020']?.['1_yr_bb_fed_repayment']?.fullypaid,
              '2020 Percentage of federal student loan borrowers with all loans discharged after 1 year':
                item?.repayment?.['2020']?.['1_yr_bb_fed_repayment']?.discharge,
              '2020 Federal student loan 2-year borrower count':
                item?.repayment?.['2020']?.['2_yr_bb_fed_repayment']?.count,
              '2020 Percentage of federal student loan borrowers in default after 2 year':
                item?.repayment?.['2020']?.['2_yr_bb_fed_repayment']?.default,
              '2020 Percentage of federal student loan borrowers in delinquency after 2 year':
                item?.repayment?.['2020']?.['2_yr_bb_fed_repayment']?.delinquent,
              '2020 Percentage of federal student loan borrowers in forbearance after 2 year':
                item?.repayment?.['2020']?.['2_yr_bb_fed_repayment']?.forbearance,
              '2020 Percentage of federal student loan borrowers in deferment after 2 year':
                item?.repayment?.['2020']?.['2_yr_bb_fed_repayment']?.deferment,
              '2020 Percentage of federal student loan borrowers not making progress after 2 year':
                item?.repayment?.['2020']?.['2_yr_bb_fed_repayment']?.noprogress,
              '2020 Percentage of federal student loan borrowers making progress after 2 year':
                item?.repayment?.['2020']?.['2_yr_bb_fed_repayment']?.makingprogress,
              '2020 Percentage of federal student loan borrowers paid in full after 2 year':
                item?.repayment?.['2020']?.['2_yr_bb_fed_repayment']?.fullypaid,
              '2020 Percentage of federal student loan borrowers with all loans discharged after 2 year':
                item?.repayment?.['2020']?.['2_yr_bb_fed_repayment']?.discharge,
              '2020 Federal student loan 3-year borrower count':
                item?.repayment?.['2020']?.['3_yr_bb_fed_repayment']?.count,
              '2020 Percentage of federal student loan borrowers in default after 3 year':
                item?.repayment?.['2020']?.['3_yr_bb_fed_repayment']?.default,
              '2020 Percentage of federal student loan borrowers in delinquency after 3 year':
                item?.repayment?.['2020']?.['3_yr_bb_fed_repayment']?.delinquent,
              '2020 Percentage of federal student loan borrowers in forbearance after 3 year':
                item?.repayment?.['2020']?.['3_yr_bb_fed_repayment']?.forbearance,
              '2020 Percentage of federal student loan borrowers in deferment after 3 year':
                item?.repayment?.['2020']?.['3_yr_bb_fed_repayment']?.deferment,
              '2020 Percentage of federal student loan borrowers not making progress after 3 year':
                item?.repayment?.['2020']?.['3_yr_bb_fed_repayment']?.noprogress,
              '2020 Percentage of federal student loan borrowers making progress after 3 year':
                item?.repayment?.['2020']?.['3_yr_bb_fed_repayment']?.makingprogress,
              '2020 Percentage of federal student loan borrowers paid in full after 3 year':
                item?.repayment?.['2020']?.['3_yr_bb_fed_repayment']?.fullypaid,
              '2020 Percentage of federal student loan borrowers with all loans discharged after 3 year':
                item?.repayment?.['2020']?.['3_yr_bb_fed_repayment']?.discharge,
              '2020 Federal student loan 4-year borrower count':
                item?.repayment?.['2020']?.['4_yr_bb_fed_repayment']?.count,
              '2020 Percentage of federal student loan borrowers in default after 4 year':
                item?.repayment?.['2020']?.['4_yr_bb_fed_repayment']?.default,
              '2020 Percentage of federal student loan borrowers in delinquency after 4 year':
                item?.repayment?.['2020']?.['4_yr_bb_fed_repayment']?.delinquent,
              '2020 Percentage of federal student loan borrowers in forbearance after 4 year':
                item?.repayment?.['2020']?.['4_yr_bb_fed_repayment']?.forbearance,
              '2020 Percentage of federal student loan borrowers in deferment after 4 year':
                item?.repayment?.['2020']?.['4_yr_bb_fed_repayment']?.deferment,
              '2020 Percentage of federal student loan borrowers not making progress after 4 year':
                item?.repayment?.['2020']?.['4_yr_bb_fed_repayment']?.noprogress,
              '2020 Percentage of federal student loan borrowers making progress after 4 year':
                item?.repayment?.['2020']?.['4_yr_bb_fed_repayment']?.makingprogress,
              '2020 Percentage of federal student loan borrowers paid in full after 4 year':
                item?.repayment?.['2020']?.['4_yr_bb_fed_repayment']?.fullypaid,
              '2020 Percentage of federal student loan borrowers with all loans discharged after 4 year':
                item?.repayment?.['2020']?.['4_yr_bb_fed_repayment']?.discharge,
            };
          }
          if (useCrime) {
            resObj = {
              ...resObj,
              Year: item.year,
              Type: item.crimeType,
              'Institution Size': item['Institution Size'],
              'Murder/Non-negligent manslaughter': item['Murder/Non-negligent manslaughter'],
              'Negligent manslaughter': item['Negligent manslaughter'],
              Rape: item.Rape,
              Fondling: item.Fondling,
              Incest: item.Incest,
              'Statutory rape': item['Statutory rape'],
              Robbery: item.Robbery,
              'Aggravated assault': item['Aggravated assault'],
              Burglary: item.Burglary,
              'Motor vehicle theft': item['Motor vehicle theft'],
              Arson: item.Arson,
            };
          }

          return resObj;
        }
      });

      const filteredData = await dataForExcel(exportData);
      const workSheet = XLSXUtils.json_to_sheet(filteredData, { header: header, cellDates: true });
      const workBook = { Sheets: { data: workSheet }, SheetNames: ['data'] };
      const excelBuffer = XSLXWrite(workBook, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      setVisible(false);
      fileSaveAs(data, fileName + fileExtension);
    }

    setWorking(false);
  };

  return (
    <>
      <Tooltip title='If nothing is checked, export will contain the table columns data by default'>
        <Button
          size='small'
          style={{ marginLeft: '15px', marginRight: '0px' }}
          onClick={exportToExcel}
          disabled={disabled}
          type='primary'
        >
          <ExportOutlined /> {'Export to excel'}
        </Button>
      </Tooltip>
    </>
  );
};

export default ExportToExcelCustomForProfiles;
