import React, { useState, useEffect, useContext, useRef } from 'react';
import { Button, notification, Spin, Table, Input, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import { UserContext } from '../../App';
import { SERVER_URL } from '../../config';
import Highlighter from 'react-highlight-words';
import { ExportToExcel } from '../../components/csv';

const SocCipView = (props) => {
  const currentuser = useContext(UserContext);
  const [working, setWorking] = useState(false);
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const { cipcode } = props.match.params;

  useEffect(() => {
    const fetchCipSoc = async () => {
      try {
        setWorking(true);

        const cipSocRes = await Axios.get(`${SERVER_URL}/soc-cip/${cipcode}`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
        if (cipSocRes && cipSocRes.data) {
          setData(cipSocRes.data);
        }

        setWorking(false);
      } catch (error) {
        setWorking(false);
        console.log(error);
        notification.error({
          message: 'Error on loading cip/soc file',
          placement: 'bottomRight',
          duration: 2,
        });
      }
    };

    fetchCipSoc();
  }, []);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size='small'
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : undefined, fontSize: '15px' }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: 'OCC CODE',
      dataIndex: 'soc',
      key: 'soc',
      ...getColumnSearchProps('soc'),
    },
    {
      title: 'TITLE',
      dataIndex: 'socTitle',
      key: 'socTitle',
      ...getColumnSearchProps('socTitle'),
    },
  ];

  // export to excel
  let filename = '';
  if (data && data?.length > 0) {
    filename = `degree_profiles_cip_soc_${data[0]?.cipcode}`;
  }

  return (
    <div className='content-wrapper'>
      <Spin spinning={working} tip='Working...'>
        <div className='actions-block flex'>
          {data && data.length > 0 && (
            <h4>
              <strong>
                {data[0]?.cipTitle} ({data[0]?.cipcode?.substring(0, 5)})
              </strong>
            </h4>
          )}

          <Link to='/admin/degree-profiles/cipcodes'>
            <Button type='primary'>All Cipcodes</Button>
          </Link>

          {!currentuser.data.role.includes('guest') && data && data.length > 0 && (
            <ExportToExcel
              csvData={data}
              fileName={filename}
              propsToRemove={['_id']}
              disabled={currentuser.data.role.includes('viewer')}
            />
          )}
        </div>

        <div>
          <Table size='middle' bordered dataSource={data} columns={columns} rowKey={(record) => record._id} />
        </div>
      </Spin>
    </div>
  );
};

export default SocCipView;
