import React, {useContext, useEffect, useState} from "react";
import {Button, Col, Form, Image, Input, Modal, Row, Select, Space, Spin, Table, Tag} from "antd";
import {UserContext} from "../../App";
import axios from "axios";
import {SERVER_URL} from "../../config";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";

const {confirm} = Modal;

const Institutions = () => {
    const [form] = Form.useForm();
    const [institutions, setInstitutions] = useState([]);
    const [working, setWorking] = useState(true);
    const [item, setItem] = useState(undefined);
    const [tags, setTags] = useState([]);
    const [visible, setVisible] = useState(false);
    const [query, setQuery] = useState('');
    const currentUser = useContext(UserContext);

    const fetchData = async (page = 1, limit = 10, query = '') => {
        setWorking(true);
        const response = await axios.get(`${SERVER_URL}/affiliate-institution?page=${page}&limit=${limit}&query=${query}`, {
            withCredentials: false,
            headers: {Authorization: `Bearer ${currentUser.data.token}`},
        });
        setInstitutions(response.data);
        setWorking(false);
    }

    const fetchTags = async () => {
        const response = await axios.get(`${SERVER_URL}/affiliate-institution-tag/all`, {
            withCredentials: false,
            headers: {Authorization: `Bearer ${currentUser.data.token}`},
        });
        setTags(response.data);
    }

    useEffect(() => {
        fetchData().catch(e => console.log(e));
        fetchTags().catch(e => console.log(e));
    }, [])

    useEffect(() =>{
        form.resetFields();
        form.setFieldsValue(item)
    }, [item]);
    function handleEdit(record) {
        setItem(record);
        setVisible(true);
    }

    function handleDelete(record) {
        confirm({
            title: 'Are you sure delete this institution?',
            content: 'This action cannot be undone.',
            onOk:
                async () => {
                    setWorking(true);
                    await axios.delete(`${SERVER_URL}/affiliate-institution/${record._id}`, {
                        withCredentials: false,
                        headers: {Authorization: `Bearer ${currentUser.data.token}`},
                    });
                    setWorking(false);
                    await fetchData(1, 10, query);
                },
        })
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'State',
            dataIndex: 'state',
            key: 'state',
        },
        {
            title: 'City',
            dataIndex: 'city',
            key: 'city',
        },
        {
            title: 'Tags',
            dataIndex: 'tags',
            key: 'tags',
            render: (tags) => (
                <>
                    {tags.map(tag => {
                        return (
                            <Tag key={tag}>
                                {tag}
                            </Tag>
                        );
                    }
                    )}
                </>
            ),
        },
        {
            title: 'Logo',
            dataIndex: 'logoUrl',
            key: 'logoUrl',
            render: (text, record) => <Image src={!text ? 'error' : text} alt={record.name}
                                             fallback="https://via.placeholder.com/100?text=No+Image"
                                             width={50}/>,

        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <DeleteOutlined  onClick={() => handleDelete(record)}/>
                    <EditOutlined onClick={() => handleEdit(record)}/>
                </Space>
            )
        }
    ];

    function handleAdd(e) {
        e.preventDefault();
        setItem(undefined);
        setVisible(true);
    }

    async function handleFinish() {
        try {
            const values = await form.validateFields();
            form.resetFields();
            const {_id} = values;
            if (_id) {
                setWorking(true);
                await axios.put(`${SERVER_URL}/affiliate-institution/${_id}`, values, {
                    withCredentials: false,
                    headers: {Authorization: `Bearer ${currentUser.data.token}`},
                });
                setWorking(false);
                setVisible(false);
                await fetchData(1, 10, query);
            } else {
                setWorking(true);
                await axios.post(`${SERVER_URL}/affiliate-institution`, values, {
                    withCredentials: false,
                    headers: {Authorization: `Bearer ${currentUser.data.token}`},
                });
                setWorking(false);
                setVisible(false);
                await fetchData(1, 10, query);
            }
        } catch (e) {
            console.log(e);
        }
    }

    async function handleSearchTable(value) {
        setQuery(value);
        await fetchData(1, 10, value);
    }

    if (!institutions)
        return <Spin/>

    let handlePaginationChange = async (pagination) => {
        const {current, pageSize} = pagination;
        await fetchData(current, pageSize, query);
    }
    return (
        <div className='content-wrapper'>
            <Spin spinning={working} tip='Working...'>
                <div className='actions-block flex'>
                    <Button type='primary' onClick={handleAdd}>New</Button>
                    <Input.Search style={{width: 400}}
                                  placeholder="Search by..."
                                  enterButton
                                  onSearch={handleSearchTable}
                    />
                </div>
                <Table
                    pagination={{
                        total: institutions.totalDocs,
                        pageSize: institutions.limit,
                        current: institutions.page,
                    }}
                    onChange={handlePaginationChange}
                    rowKey={record => record._id}
                    columns={columns}
                    dataSource={institutions.docs || []}/>

                <Modal confirmLoading={working} title="New Institution" visible={visible} onOk={handleFinish}
                       onCancel={() => setVisible(false)}>
                    <Form form={form} initialValues={item} id="code-form"
                          layout="vertical">
                        <Row gutter={12}>
                            <Col span={12}>
                                <Form.Item label="unitId" name="unitId"
                                           rules={[{required: true, message: 'Unit ID is required.'}]}>
                                    <Input name="unitId" placeholder="Unit ID"/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Name" name="name"
                                           rules={[{required: true, message: 'Name is required.'}]}>
                                    <Input placeholder="Name"/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={12}>
                            <Col span={12}>
                                <Form.Item label="State" name="state"
                                           rules={[{required: true, message: 'State is required.'}]}>
                                    <Input placeholder="State"/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="City" name="city"
                                           rules={[{required: true, message: 'City is required.'}]}>
                                    <Input placeholder="City"/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={12}>
                            <Col span={24}>
                                <Form.Item label="Logo URL" name="logoUrl">
                                    <Input placeholder="Logo URL"/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item label="Tags" name="tags">
                                    <Select mode="tags"  placeholder="TAGS">
                                        {tags.map(tag => <Select.Option key={tag._id} value={tag.name}>{tag.name}</Select.Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item name="_id" hidden>
                            <Input/>
                        </Form.Item>
                    </Form>
                </Modal>
            </Spin>
        </div>
    );
}

export default Institutions;
