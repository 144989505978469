import React, { useEffect, useContext, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { BlsTable } from '../../components/tables';
import { ImportExcelFile } from '../../components/csv';
import { SERVER_URL } from '../../config';

const SORT = {
  ascend: 1,
  descend: -1,
};

const BlsData = () => {
  const currentuser = useContext(UserContext);
  const [bls, fetchBls] = useAxios('', [], currentuser.data.token, 'get');
  const [search, setSearch] = useState({});
  const [filter, setFilter] = useState(null);
  const [sort, setSort] = useState(JSON.stringify({ areaTitle: 1 }));
  const [page, setPage] = useState(1);

  useEffect(() => {
    let urlQuery = '';
    if (page) urlQuery += `&offset=${(page ? +page - 1 : 0) * 20}&limit=20`;
    if (filter) urlQuery += `&filter=${filter}`;
    if (sort) urlQuery += `&sorter=${sort}`;

    fetchBls(`${SERVER_URL}/bls-data?table_type=bls_national${urlQuery}`, [page, filter]);
  }, [fetchBls, page, filter, sort]);

  const filterHandler = (prop, value) => {
    if (Array.isArray(prop)) prop = prop.join('.');

    let filterQuery = null;
    let prevFilter = {};
    if (filter) {
      prevFilter = JSON.parse(filter);
    }

    if (value !== null) {
      prevFilter[prop] = value;
      filterQuery = `${JSON.stringify({ [prop]: value.toString(), ...prevFilter })}`;
    } else {
      delete prevFilter[prop];
      filterQuery = `${JSON.stringify({ ...prevFilter })}`;
    }

    setFilter(filterQuery);
  };

  const sortHandler = (prop, order) => {
    if (Array.isArray(prop)) prop = prop.join('.');

    let sortQuery = null;
    if (order) {
      sortQuery = `${JSON.stringify({ [prop]: SORT[order] })}`;
    }

    setSort(sortQuery);
  };

  const uploadExcel = (upload) => {
    try {
      if (upload.file.response) {
        if (upload.file.status === 'done') {
          notification.success({
            message: upload.file.response.message,
            placement: 'bottomRight',
          });
        } else {
          notification.error({
            message: upload.file.response.message,
            placement: 'bottomRight',
          });
        }
        // history.push('/admin');
        // history.push('/admin/bls');
      }
    } catch (err) {
      notification.error({
        message: 'Problem with upload.',
        placement: 'bottomRight',
      });
    }
  };

  return (
    <div className='content-wrapper'>
      <div className='actions-block flex'>
        <ImportExcelFile
          disabled={true}
          onChange={uploadExcel}
          headers={{ Authorization: currentuser.data.token }}
          action={`${SERVER_URL}/bls-upload-excel`}
          name='excelData'
          toolTip='Import BLS table'
          label='Import Excel'
        />

        <h2 style={{ margin: 0 }}>Total BLS records: {bls.data?.count || 0}</h2>
      </div>

      <div style={{ textAlign: 'center' }}>
        {bls.isLoading && !bls.data && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}

        {bls.data?.items && (
          <BlsTable
            data={bls.data.items}
            isLoading={bls.isLoading}
            filterHandler={filterHandler}
            pageHandler={setPage}
            sortHandler={sortHandler}
            search={search}
            setSearch={setSearch}
            title='BLS'
            viewPath='/admin/view-bls/'
            page={page}
            total={bls.data?.count || 0}
            filter={filter ? JSON.parse(filter) : null}
            sort={sort ? JSON.parse(sort) : null}
          />
        )}
      </div>
    </div>
  );
};

export default BlsData;
