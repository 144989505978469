import React, { useEffect, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Button, Layout, Tabs, Typography, Card } from 'antd';
import { EditOutlined, LoadingOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { UserLog } from '../../components/forms';
import moment from 'moment';

const { Content, Sider } = Layout;
const { Title, Text } = Typography;
const { TabPane } = Tabs;

/**
 * To change which activities are shown in chart, table and excel file
 * You can modify ACTIVITIES array
 * @prop {value} string - backend log value in server/utils/log.js
 * @prop {label} string - chart line label
 * @prop {stroke} string - chart line color
 */
const ACTIVITIES = [
  {
    value: 'AUTH_LOGIN',
    label: 'Login count',
    stroke: '#ffc658',
  },
  {
    value: 'PROFILE_INSERT',
    label: 'Profiles created',
    stroke: '#8884d8',
  },
  {
    value: 'PROFILE_UPDATE',
    label: 'Profiles updated',
    stroke: 'yellow',
  },
  {
    value: 'CATEGORY_INSERT',
    label: 'Categories created',
    stroke: '#82ca9d',
  },
  {
    value: 'CAREER_INSERT',
    label: 'Careers created',
    stroke: 'black',
  },
  {
    value: 'CAREER_SYNC',
    label: 'Careers synced',
    stroke: 'red',
  },
  {
    value: 'all',
    label: 'All',
  },
];

const ViewUser = (props) => {
  const { id } = props.match.params;
  const currentuser = useContext(UserContext);
  const [user, fetchUser] = useAxios('', null, currentuser.data.token, 'get');
  const [userActivities, fetchUserActivities] = useAxios('', null, currentuser.data.token, 'get');
  const [activity, setActivity] = useState('AUTH_LOGIN');
  const [csv, fetchCSV] = useAxios('', null, currentuser.data.token, 'get');

  // date range for logs
  const oneMonthBefore = moment(new Date()).subtract(1, 'month');
  const [range, setRange] = useState([oneMonthBefore, moment(new Date())]);

  useEffect(() => {
    if (id) {
      const [from, to] = range;
      const fromDate = new Date(from).toISOString();
      const toDate = new Date(to).toISOString();
      const activities =
        activity === 'all'
          ? JSON.stringify(ACTIVITIES.filter((a) => a.value !== 'all').map((a) => a.value))
          : JSON.stringify([activity]);
      fetchUserActivities(`${SERVER_URL}/user-logs/${id}?activities=${activities}&from=${fromDate}&to=${toDate}`, []);
    }
  }, [id, fetchUserActivities, activity, range]);

  useEffect(() => {
    if (id) {
      fetchUser(`${SERVER_URL}/users/${id}`, []);
    }
  }, [id, fetchUser]);

  useEffect(() => {
    if (id) {
      const [from, to] = range;
      const fromDate = new Date(from).toISOString();
      const toDate = new Date(to).toISOString();
      const activities =
        activity === 'all'
          ? JSON.stringify(ACTIVITIES.filter((a) => a.value !== 'all').map((a) => a.value))
          : JSON.stringify([activity]);
      fetchCSV(`${SERVER_URL}/user-logs-csv/${id}?from=${fromDate}&to=${toDate}&activities=${activities}`, []);
    }
  }, [id, fetchCSV, activity, range]);

  return (
    <div className='content-wrapper'>
      <div className='actions-block'>
        <Link to='/admin/users'>
          <Button type='primary'>All users</Button>
        </Link>
      </div>

      <div className='content content-full-height'>
        {id && user.isLoading && <LoadingOutlined className='loader' spin />}

        {id && !user.isLoading && user.isError && <h2 style={{ marginTop: '5rem' }}>Something went wrong :(</h2>}

        {id && user && !user.isLoading && !user.isError && (
          <Layout className='site-layout-background'>
            <Sider className='site-layout-background' width={300} theme='light'>
              <div className='logo'>
                <Avatar size={100} src={user.data && user.data.avatar ? user.data.avatar.url : 'Logo'} />

                <Link to={'/admin/edit-user/' + id}>
                  <Button type='primary' block icon={<EditOutlined />}>
                    EDIT USER
                  </Button>
                </Link>
              </div>

              <Content style={{ padding: '15px', minHeight: 280 }}>
                <Title style={{ fontSize: '14px' }}> ROLE</Title>
                <p style={{ display: 'block', fontSize: '14px', margin: '0 0 15px', textTransform: 'capitalize' }}>
                  {' '}
                  {user.data ? user.data.role[0] : 'Role'}
                </p>
                <Title style={{ fontSize: '14px' }}> EMAIL</Title>
                <p style={{ display: 'block', fontSize: '14px', margin: '0 0 15px' }}>
                  {' '}
                  {user.data ? user.data.email : 'Email'}
                </p>
              </Content>
            </Sider>

            <Content style={{ padding: '0 15px', minHeight: 280 }}>
              <Tabs defaultActiveKey='1' /* onChange={callback} */>
                <TabPane tab='PROFILE' key='1'>
                  <div className='card-wrapper'>
                    <Card title='User information' bordered={false}>
                      <p>
                        <Text strong>First Name: </Text>
                        {user.data ? user.data.firstName : 'No data'}
                      </p>
                      <p>
                        <Text strong>Last Name: </Text>
                        {user.data ? user.data.lastName : 'No data'}
                      </p>
                      <p>
                        <Text strong>Email: </Text>
                        {user.data ? user.data.email : 'No data'}
                      </p>
                      <p>
                        <Text strong>Role: </Text>
                        {user.data ? user.data.role[0] : 'No data'}
                      </p>
                      <p>
                        <Text strong>Status: </Text>
                        {user.data ? user.data.status : 'No data'}
                      </p>
                    </Card>
                  </div>
                </TabPane>

                <TabPane tab='LOGS' key='2'>
                  {userActivities.data ? (
                    <UserLog
                      ACTIVITIES={ACTIVITIES}
                      activity={activity}
                      setActivity={setActivity}
                      range={range}
                      setRange={setRange}
                      userActivities={userActivities.data}
                      userName={`${user.data.firstName}_${user.data.lastName}`}
                      csvData={csv.data}
                    />
                  ) : (
                    'No Data'
                  )}
                </TabPane>
              </Tabs>
            </Content>
          </Layout>
        )}
      </div>
    </div>
  );
};

export default ViewUser;
