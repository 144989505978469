import React, { useContext, useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import { Button, Input, Table, Tooltip, Form, Tag } from 'antd';
import { DeleteOutlined, EyeOutlined, SearchOutlined } from '@ant-design/icons';

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({ title, editable, children, dataIndex, record, handleSave, ...restProps }) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      if (record.occCode !== values.occCode) {
        handleSave({ ...record, ...values });
      }
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className='editable-cell-value-wrap'
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return (
    <td {...restProps}>
      {editable && <Tooltip title='Click to edit'>{childNode}</Tooltip>}
      {!editable && childNode}
    </td>
  );
};

const ScrapesTable = ({
  data,
  search,
  setSearch,
  filterHandler,
  pageHandler,
  sortHandler,
  handleSave,
  deleteHandler,
  title,
  viewPath,
  page,
  total,
  filter,
}) => {
  let searchInput;

  const getColumnSearchProps = (dataIndex) => {
    if (!['createdAt', 'updatedAt'].includes(dataIndex)) {
      if (Array.isArray(dataIndex)) dataIndex = dataIndex.join('.');
      const prop = dataIndex;
      return {
        filteredValue: (filter && filter[prop] && [filter[prop]]) || null,
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
          return (
            <div style={{ padding: 8 }}>
              <Input
                ref={(node) => {
                  searchInput = node;
                }}
                placeholder={`Search ${prop}`}
                value={selectedKeys}
                onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => handleSearch(selectedKeys, confirm, prop)}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
              />
              <Button
                type='primary'
                onClick={() => handleSearch(selectedKeys, confirm, prop)}
                size='small'
                style={{ width: 90, marginRight: 8 }}
              >
                Search
              </Button>
              <Button onClick={() => handleReset(clearFilters, prop)} size='small' style={{ width: 90 }}>
                Reset
              </Button>
            </div>
          );
        },
        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />,
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => {
              searchInput.select();
            });
          }
        },
        sorter: true,
        defaultSortOrder: prop === 'title' ? 'ascend' : undefined,
        sortDirections: ['ascend', 'descend'],
        render: (text) =>
          search[prop] ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[search[prop]]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
            />
          ) : (
            text
          ),
      };
    } else if (['createdAt', 'updatedAt'].includes(dataIndex)) {
      return {
        sorter: true,
        sortDirections: ['ascend', 'descend'],
      };
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    if (Array.isArray(dataIndex)) dataIndex = dataIndex.join('.');
    const newSearch = { ...search };
    newSearch[dataIndex] = selectedKeys[0];
    setSearch(newSearch);
    filterHandler(dataIndex, selectedKeys);
    confirm();
  };

  const handleReset = (clearFilters, prop) => {
    if (Array.isArray(prop)) prop = prop.join('.');
    const newSearch = { ...search };
    setSearch(newSearch);
    delete newSearch[prop];
    filterHandler(prop, null);
    clearFilters();
  };

  const columnKeys = ['error', 'jobTitle', 'occCode', 'highJobMeaning', 'report', 'state', 'degreeLevel', 'updatedAt'];

  const columns = columnKeys.map((item) => {
    const dataIndex = item;
    if (dataIndex === 'rankings') {
      return {
        key: item,
        title: item.toUpperCase(),
        dataIndex,
        render: (text) => text.length,
      };
    }

    if (dataIndex === 'error') {
      return {
        key: item,
        title: item.toUpperCase(),
        dataIndex,
        render: (text, record) => {
          if (record.data.RetryAfterMilliseconds) {
            return (
              <Tooltip title='Report has errors'>
                <Tag color='red'>YES</Tag>
              </Tooltip>
            );
          }
          return (
            <Tooltip title='No errors in report'>
              <Tag color='green'>NO</Tag>
            </Tooltip>
          );
        },
      };
    }

    if (dataIndex === 'occCode') {
      return {
        key: item,
        title: item.toUpperCase(),
        dataIndex,
        render: (text) => (text === null || !text ? 'N/A' : text),
        onCell: (record) => ({
          record,
          editable: true,
          dataIndex,
          title: item,
          handleSave: handleSave,
        }),
      };
    }

    if (dataIndex === 'highJobMeaning') {
      return {
        key: item,
        title: item.toUpperCase(),
        dataIndex,
        render: (text) => (text === null || !text ? 'N/A' : text),
        onCell: (record) => ({
          record,
          editable: true,
          dataIndex,
          title: item,
          handleSave: handleSave,
        }),
      };
    }

    return {
      key: item,
      title: item.toUpperCase(),
      dataIndex,
      render: (text) => {
        if (text && ['createdAt', 'updatedAt'].includes(item)) {
          return new Date(text).toLocaleString('en-US');
        } else {
          return text;
        }
      },
      ...getColumnSearchProps(dataIndex),
    };
  });

  columns.push({
    title: 'Action',
    render: (text, record) => (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '15px',
        }}
      >
        {viewPath && (
          <Tooltip title={`View ${title}`} placement='topLeft'>
            <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='table-actions'>
              <Link to={viewPath + record._id}>
                <EyeOutlined style={{ textDecoration: 'none', color: 'black' }} />
              </Link>
            </div>
          </Tooltip>
        )}

        {deleteHandler && (
          <Tooltip title={`Delete ${title}`} placement='topLeft'>
            <div
              style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}
              className='table-actions'
              onClick={async () => {
                deleteHandler(record._id, record.url);
              }}
            >
              <DeleteOutlined />
            </div>
          </Tooltip>
        )}
      </div>
    ),
  });

  const onChangeTable = (addPagination, addFilters, addSorter) => {
    pageHandler(addPagination.current);
    // console.log({ addSorter });
    sortHandler(addSorter.field, addSorter.order);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  return (
    <div>
      <Table
        size='middle'
        onChange={onChangeTable}
        bordered
        components={components}
        dataSource={data}
        columns={columns}
        rowKey={(record) => record._id}
        rowClassName={() => 'editable-row'}
        pagination={{
          pageSize: 20,
          total: total || 0,
          current: page,
          defaultCurrent: 1,
          position: 'bottom',
          showSizeChanger: false,
          // onChange: (page) => pageHandler(page),
          hideOnSinglePage: true,
        }}
      />
    </div>
  );
};

export default ScrapesTable;
