import React from 'react';
import { Table, Button, Space, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

const Crime = ({ crimeData }) => {
  let searchInput;
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size='small'
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };
  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const columns = [
    {
      title: 'YEAR',
      dataIndex: ['year'],
      key: 'year',
      ...getColumnSearchProps(['year']),
      fixed: 'left',
    },
    {
      title: 'TYPE',
      dataIndex: ['crimeType'],
      key: 'crimeType',
      ...getColumnSearchProps(['crimeType']),
      fixed: 'left',
    },
    {
      title: 'INSTITUTION SIZE',
      dataIndex: ['data', 'Institution Size'],
      key: 'Institution Size',
      render: (text, record) => (text ? text.toLocaleString('en-US') : 'N/A'),
      sorter: (a, b) => a.data['Institution Size'] - b.data['Institution Size'],
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Murder/Non-negligent manslaughter',
      dataIndex: ['data', 'Murder/Non-negligent manslaughter'],
      key: 'Murder/Non-negligent manslaughter',
      sorter: (a, b) => a.data['Murder/Non-negligent manslaughter'] - b.data['Murder/Non-negligent manslaughter'],
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Negligent manslaughter',
      dataIndex: ['data', 'Negligent manslaughter'],
      key: 'Negligent manslaughter',
      sorter: (a, b) => a.data['Negligent manslaughter'] - b.data['Negligent manslaughter'],
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Rape',
      dataIndex: ['data', 'Rape'],
      key: 'Rape',
      sorter: (a, b) => a.data['Rape'] - b.data['Rape'],
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Fondling',
      dataIndex: ['data', 'Fondling'],
      key: 'Fondling',
      sorter: (a, b) => a.data['Fondling'] - b.data['Fondling'],
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Incest',
      dataIndex: ['data', 'Incest'],
      key: 'Incest',
      sorter: (a, b) => a.data['Incest'] - b.data['Incest'],
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Statutory rape',
      dataIndex: ['data', 'Statutory rape'],
      key: 'Statutory rape',
      sorter: (a, b) => a.data['Statutory rape'] - b.data['Statutory rape'],
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Robbery',
      dataIndex: ['data', 'Robbery'],
      key: 'Robbery',
      sorter: (a, b) => a.data['Robbery'] - b.data['Robbery'],
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Aggravated assault',
      dataIndex: ['data', 'Aggravated assault'],
      key: 'Aggravated assault',
      sorter: (a, b) => a.data['Aggravated assault'] - b.data['Aggravated assault'],
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Burglary',
      dataIndex: ['data', 'Burglary'],
      key: 'Burglary',
      sorter: (a, b) => a.data['Burglary'] - b.data['Burglary'],
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Motor vehicle theft',
      dataIndex: ['data', 'Motor vehicle theft'],
      key: 'Motor vehicle theft',
      sorter: (a, b) => a.data['Motor vehicle theft'] - b.data['Motor vehicle theft'],
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Arson',
      dataIndex: ['data', 'Arson'],
      key: 'Arson',
      sorter: (a, b) => a.data['Arson'] - b.data['Arson'],
      sortDirections: ['ascend', 'descend'],
    },
  ];

  return (
    <div>
      <Table
        size='middle'
        bordered
        dataSource={crimeData}
        columns={columns}
        rowKey={(record) => record._id}
        scroll={{
          x: 2000,
        }}
      />

      <br></br>
    </div>
  );
};

export default Crime;
